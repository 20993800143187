import React, { useEffect, useMemo, useState } from "react";
import { RiArrowDropRightLine, RiFullscreenLine } from "react-icons/ri";
import { IoIosArrowBack, IoIosArrowForward, IoMdClose } from "react-icons/io";
import { BiExitFullscreen, BiFullscreen } from "react-icons/bi";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  closeFullScreen,
  closeVipBar,
  openFullScreen,
  openVipBar,
  storeGameConfig,
} from "../../../redux/action/game/gameAction";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { languageTransfer } from "../../../utils/languageTransfer";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import useGetNowGame from "../../../hook/useGetNowGame";
import ToggleFavorite from "../../../components/Favorite/toggleFavorite";
import LazyImage from "../../../components/Loading/lazyImage";
import LoadingLine from "../../../components/Loading/LoadingLine";
import useGameIsHasDescription from "../gameDescription/gameIsHasDescription";
import { openGameDescriptionPop } from "../../../redux/action/member/memberAction";
import { FaScroll } from "react-icons/fa";
import UseGetGameBoxBackPath from "../../../hook/useGetGameBoxBackPath";
import {
  Box,
  Flex,
  Icon,
  Image,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import useCurrentCurrencyIsCrypto from "../../../hook/useCurrentCurrencyIsCrypto";
import { COMMON_WEB_PATH } from "../../../constant";
import useVipInfo from "../../../hook/useVipInfo";
import CreditPage from "../../../utils/credit/credit_page";
import VipOpenPage from "../../../utils/credit/vip_open_page";

const GameBoxHeader = ({ en_Name, type }) => {
  const { t } = useTranslation();
  const i18n_common = (key) => t(`common.${key}`);

  const nowGameConfig = useGetNowGame();

  const dispatch = useDispatch();

  const getPrevPath = UseGetGameBoxBackPath();

  const { to } = useParams();

  const { current_level } = useVipInfo();

  useEffect(() => {
    dispatch(storeGameConfig(nowGameConfig));
  }, [nowGameConfig]);

  const isOpenFullScreen = useSelector((state) => state.isOpenFullScreen);
  const isVipBarOpen = useSelector((state) => state.isVipBar);

  const navigate = useNavigate();

  const headerBg = useColorModeValue("light.100", "dark.200");
  const vipbarBg = useColorModeValue("light.100", "dark.100");
  const textColor = useColorModeValue("dark.100", "light.100");

  const currentCurrencyIsCrypto = useCurrentCurrencyIsCrypto();

  const [favorite, setFavorite] = useState(false);

  const gameConfig = useSelector((state) => state.isGameConfig);

  const { game_platform, gameCode, game_subtype, SubPlatformEnum } = gameConfig;
  const gameIsHasDescription = useGameIsHasDescription({ game_platform });

  const gameName = useMemo(() => {
    return gameConfig?.[
      `gameName${languageTransfer({ lang: i18next.language })}`
    ];
  }, [gameConfig, i18next.language]);

  return (
    <Flex
      w="100%"
      alignItems={`center`}
      justifyContent={`space-between`}
      py="8px"
      px="20px"
      h="52px"
      bg={headerBg}
    >
      {" "}
      <Box as="section" fontWeight={`semibold`} color="brand.500">
        <Box
          as="section"
          mb="0"
          fontSize="xl"
          display={`flex`}
          alignItems={`center`}
        >
          <Text as="span" className="special.1">
            <LoadingLine className={`min-w-[100px] !mt-0`} customInverted={1}>
              {SubPlatformEnum || game_platform}-{i18n_common(game_subtype)}
            </LoadingLine>
          </Text>{" "}
          <RiArrowDropRightLine className="text-4xl" />
          <Text as="span" className="special.1">
            <LoadingLine className={`min-w-[100px] !mt-0`} customInverted={1}>
              {gameName}
            </LoadingLine>
          </Text>
        </Box>
      </Box>
      <Flex alignItems={`center`} gap="10px" fontSize="3xl" color="light.600">
        <ToggleFavorite />
        {isOpenFullScreen ? (
          <Icon
            onClick={() => {
              dispatch(closeFullScreen());
            }}
            cursor="pointer"
            as={BiExitFullscreen}
            fontSize="2xl"
            color={textColor}
          />
        ) : (
          <Icon
            onClick={() => {
              dispatch(openFullScreen());
            }}
            cursor="pointer"
            as={BiFullscreen}
            fontSize="2xl"
            color={textColor}
          />
        )}
        {gameIsHasDescription && (
          <Icon
            onClick={() => {
              dispatch(
                openGameDescriptionPop({
                  game: game_platform,
                })
              );
            }}
            cursor="pointer"
            as={FaScroll}
            fontSize="2xl"
            color={textColor}
          />
        )}
        <Icon
          onClick={() => {
            navigate(
              `/${getPrevPath}/category/${
                SubPlatformEnum || game_platform
              }/AllGames`
            );
          }}
          cursor="pointer"
          as={IoMdClose}
          fontSize="2xl"
          color={textColor}
        />

        <Box
          py="2px"
          px="10px"
          borderRadius={`full`}
          cursor={`pointer`}
          bg={vipbarBg}
          onClick={() => {
            if (isVipBarOpen) {
              dispatch(closeVipBar());
            } else {
              dispatch(openVipBar());
            }
          }}
        >
          <Flex position={`relative`} alignItems={`center`}>
            {!isVipBarOpen && (
              <Icon as={IoIosArrowBack} fontSize="xl" color={textColor} />
            )}
            <CreditPage>
              <VipOpenPage>
                <Image
                  src={`${COMMON_WEB_PATH}/vip/vip${current_level}_l.avif`}
                  w="35px"
                  objectFit={`cover`}
                />
              </VipOpenPage>
            </CreditPage>

            {isVipBarOpen && (
              <Icon as={IoIosArrowForward} fontSize="xl" color={textColor} />
            )}
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
};

export default GameBoxHeader;
