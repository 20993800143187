import React, { useMemo } from "react";
import { useSelector } from "react-redux";

const useCurrencyList = ({
  dontFilterCrypto = false,
  filterStatus = false,
} = {}) => {
  const isCurrencyExchange = useSelector((state) => state.isCurrencyExchange);

  const currencyList = useMemo(() => {
    const list = Object.keys(isCurrencyExchange);
    if (dontFilterCrypto) {
      if (filterStatus) {
        return list?.filter((item) => {
          return isCurrencyExchange[item]?.status_front === 1;
        });
      } else {
        return list;
      }
    } else {
      if (filterStatus) {
        return list?.filter((item) => {
          return (
            isCurrencyExchange[item]?.status_front === 1 &&
            isCurrencyExchange[item]?.is_crypto !== 1
          );
        });
      } else {
        return list?.filter((item) => {
          return isCurrencyExchange[item]?.is_crypto !== 1;
        });
      }
    }
  }, [isCurrencyExchange, dontFilterCrypto, filterStatus]);

  return currencyList;
};

export default useCurrencyList;
