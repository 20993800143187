import { Box, Text, useColorMode, useColorModeValue } from "@chakra-ui/react";
import React from "react";

const PromotionCard = ({ className, icon, promotionProps, label }) => {
  const { description } = promotionProps;

  const cardBg = useColorModeValue("light.100", "dark.100");
  const cardText = useColorModeValue("dark.100", "light.100");

  return (
    <Box
      px="8px"
      py="5px"
      borderRadius={`5px`}
      display={`flex`}
      alignItems={`center`}
      justifyContent={`space-between`}
      bg={cardBg}
      className={`${className}`}
    >
      <Box
        color={cardText}
        display={`flex`}
        alignItems={`center`}
        gap={`5px`}
        fontSize={`sm`}
        fontWeight={`medium`}
      >
        {icon}
        <Text as="p" fontSize={`sm`}>
          {label}
        </Text>
      </Box>
      <Text color={cardText} fontSize={`xs`}>
        {description}
      </Text>
    </Box>
  );
};

export default PromotionCard;
