import { Splide, SplideSlide } from "@splidejs/react-splide";
import React from "react";
import LoaderGameList from "./LoadingGameList";

import { Grid } from "@splidejs/splide-extension-grid";
import LoadingImage from "./LoadingImage";
import LoadingLine from "./LoadingLine";
import { Box, Flex, useColorMode, useColorModeValue } from "@chakra-ui/react";

const LoadingForLiveLayout = ({ itemClass }) => {
  const boxBg = useColorModeValue("light.300", "dark.100");
  const boxColor = useColorModeValue("dark.100", "light.200");

  return (
    <Box px="common-x">
      <Flex justifyContent={`space-between`} height={`35px`} mb={`5px`}>
        <Flex alignItems={`center`}>
          <LoadingLine
            className={`w-[130px] h-[20px] rounded-full`}
          ></LoadingLine>
        </Flex>
        <Flex alignItems={`center`} gap={`5px`}>
          <>
            <Flex
              cursor={`pointer`}
              bg={boxBg}
              color={boxColor}
              py={`8px`}
              px={`10px`}
              height={`40px`}
              fontWeight={`medium`}
              fontSize={`sm`}
              alignItems={`center`}
              borderRadius={`common`}
            >
              {" "}
              <LoadingLine
                className={`min-w-[60px] !h-[10px] !mt-0`}
              ></LoadingLine>
            </Flex>
          </>
        </Flex>
      </Flex>

      <Splide
        className={`h-full w-full`}
        extensions={{ Grid }}
        options={{
          type: "slide",
          pagination: false,
          arrows: false,
          drag: "free",
          perMove: 1,
          perPage: 3,
          gap: 10,
          lazyLoad: "nearby",
          dragMinThreshold: {
            mouse: 0,
            touch: 10,
          },
          preloadPages: 3,
          keyboard: false,
          breakpoints: {
            640: {
              perPage: 1,
              grid: {
                dimensions: [[2, 2]],
                gap: {
                  row: 10,
                  col: 10,
                },
              },
            },
            768: {
              perPage: 3,
            },
            1024: {
              perPage: 3,
            },
            1280: {
              perPage: 3,
            },
          },
        }}
      >
        {Array.from({ length: 8 }, (_, i) => (
          <SplideSlide key={`loading-${i}`}>
            <LoaderGameList
              commonLoading={true}
              className={`${itemClass} lg:h-[185px] md:h-[160px] h-[130px]`}
            />
          </SplideSlide>
        ))}
      </Splide>
    </Box>
  );
};

export default LoadingForLiveLayout;
