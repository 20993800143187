import React, { useMemo } from "react";
import { useSelector } from "react-redux";

const useRenameMultiPlatform = ({ origin_data = [] }) => {
  const { origin_platform } =
    useSelector((state) => state.isHandleAllGame) || {};

  const process_origin_data = useMemo(() => {
    const game_record = origin_data?.map((plat) => {
      const find_platform = origin_platform?.find(
        (platform) => platform.platform === plat.platform
      );
      if (find_platform?.SubPlatformEnum?.length > 0) {
        const combineLabel = find_platform?.SubPlatformEnum?.map((sub) => {
          return sub?.SubPlatformEnum;
        });
        return {
          ...plat,
          platform: combineLabel?.join("+"),
        };
      } else {
        return plat;
      }
    });

    return game_record;
  }, [origin_data, origin_platform]);

  return process_origin_data;
};

export default useRenameMultiPlatform;
