import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import WalletList from "./walletList";
import { COMMON_WEB_PATH, CURRENCY_SYMBOL } from "../../../constant";
import { IoIosAdd } from "react-icons/io";
import MyBalanceV3 from "../../../pages/Profile/components/balance_v3";
import { useNavigate, useLocation } from "react-router";
import {
  AbsoluteCenter,
  Box,
  Flex,
  Icon,
  Image,
  Popover,
  PopoverTrigger,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import CreditPage from "../../../utils/credit/credit_page";
import { MdOutlineAdd } from "react-icons/md";
import { RiUserFill } from "react-icons/ri";

const isCredit = process.env.REACT_APP_CREDIT == 1;
const BalanceV4 = () => {
  const memberData = useSelector((state) => state.isMemberInfo);

  const { isOpen, onToggle, onClose } = useDisclosure();

  const baseBg = useColorModeValue("light.100", "#262D47");
  const baseHoverBg = useColorModeValue("light.300", "dark.500");
  const linkColor = useColorModeValue("light.100", "#394367");

  const [openWallet, setOpenWallet] = useState(false);

  const { pathname } = useLocation();

  const navigate = useNavigate();

  return (
    <Popover
      variant={"brandPrimary"}
      isOpen={isOpen}
      onClose={onClose}
      placement="bottom"
    >
      <Flex
        borderRadius={`10px`}
        _hover={{
          bg: baseHoverBg,
        }}
        bg={baseBg}
        px="10px"
        alignItems="center"
        gap="5px"
        height="48px"
        cursor="pointer"
        position="relative"
        mr={{
          sm: "10px",
        }}
        shadow="sm"
        justifyContent={"space-between"}
        minW="130px"
      >
        <PopoverTrigger>
          <Box
            onClick={() => {
              if (pathname.includes("gamebox")) {
                return;
              } else {
                if (isCredit) {
                  return;
                }
                onToggle();
              }
            }}
            display={`flex`}
            alignItems={`center`}
            gap="5px"
          >
            <Image
              src="https://d1h3qe8voog6w4.cloudfront.net/v4-layout/signin.svg"
              w="16px"
              h="14px"
            />
            {/* <Icon as={RiUserFill} color="v3-second-brand.500" />  */}
            <MyBalanceV3
              hiddenSymbol
              balanceStyle={{
                fontSize: "16px",
                fontWeight: "700",
              }}
            />
          </Box>
        </PopoverTrigger>
        <Link to="profile/deposit">
          <Box
            w="24px"
            h="24px"
            display="flex"
            alignItems={"center"}
            justifyContent={"center"}
            bg={linkColor}
            borderRadius={"5px"}
          >
            <Flex
              color="light.100"
              borderRadius={"100%"}
              // bg="v3-second-brand.500"
              alignItems={"center"}
              justifyContent={"center"}
              w="18px"
              h="18px"
            >
              <Image
                src="https://d1h3qe8voog6w4.cloudfront.net/v4-layout/add.svg"
                w="16px"
                h="16px"
              />
              {/* <Icon size="xs" as={MdOutlineAdd} /> */}
            </Flex>
          </Box>
        </Link>
      </Flex>
      <WalletList onClose={onClose} />
    </Popover>
  );
};

export default BalanceV4;
