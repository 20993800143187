import React, { useEffect, useState } from "react";
import { getNewNotice } from "../../../api/getApi";
import LoadingBlock from "../../../components/Loading/LoadingBlock";
import NotFound from "../../../components/ProgressBlock/NotFound";
import InboxItem from "../InboxItem";

const NewsList = () => {
  const [inboxList, setInboxList] = useState([]);
  const [apiCalling, setApiCalling] = useState(true);

  useEffect(() => {
    setApiCalling(true);
    getNewNotice()
      .then((data) => {
        setInboxList(data.data);
      })
      .finally(() => {
        setApiCalling(false);
      });
  }, []);

  return inboxList.length === 0 ? (
    <NotFound />
  ) : (
    inboxList.map((item, index) => {
      return <InboxItem key={index} data={item} />;
    })
  );
};

export default NewsList;
