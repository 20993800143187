import React, { useMemo } from "react";
import SearchV2 from "./search_v2";
import Search from "./search";
import { WEB_LAYOUT } from "../../constant";

const SearchVersionSelector = () => {
  const search = useMemo(() => {
    switch (WEB_LAYOUT) {
      case "v2":
        return <SearchV2 />;
      case "v3":
        return <SearchV2 />;
      default:
        return <Search />;
    }
  }, [WEB_LAYOUT]);
  return search;
};

export default SearchVersionSelector;
