import { Text } from "@chakra-ui/react";
import React from "react";

const MainText = ({ children }) => {
  return (
    <Text as="h4" fontSize="xl" fontWeight={`bold`}>
      {children}
    </Text>
  );
};

export default MainText;
