import { HiChevronLeft } from "react-icons/hi";
import CloseDetailPage from "../../../components/CloseComponent/CloseDetailPage";
import DetailWrapper from "../../../components/detailWrapper";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Divider,
  Flex,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import CustomInput from "../../../components/InputComponents/customInput";
import { MdAccountBox, MdMarkEmailRead } from "react-icons/md";
import { RiUser3Fill } from "react-icons/ri";
import { FaBirthdayCake } from "react-icons/fa";
import { updateMemberBasicData } from "../../../api/postApi";
import { notice } from "../../../redux/action/action";
import dayjs from "dayjs";
import { CgPassword } from "react-icons/cg";

const VerifyEmail = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`settings.${key}`);
  const i18n_common = (key) => t(`common.${key}`);

  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    code: "",
  });
  const [buttonLoading, setButtonLoading] = useState(false);

  const { code } = formData || {};

  const navigate = useNavigate();

  const handleVerifyEmail = async () => {
    setButtonLoading(true);
    const email = localStorage.getItem("email") || "";
    try {
      await updateMemberBasicData({
        paramsData: {
          email: email,
          code: code,
        },
      }).then((data) => {
        dispatch(
          notice({
            content: "Verify Email Success",
            type: "success",
          })
        );
        dispatch({ type: "GET_MEMBER_DATA" });
        navigate("../");
      });
    } finally {
      setButtonLoading(false);
    }
  };

  return (
    <DetailWrapper
      closeAnimate
      wrapperClassName={`h-full w-full md:absolute fixed top-0 left-0 max-h-full z-[10] bg-gradient-to-br`}
    >
      <CloseDetailPage
        hasPrev={
          <HiChevronLeft
            className="cursor-pointer"
            onClick={() => navigate("..")}
          />
        }
        title={i18n("personalInformation")}
      />
      <Alert
        status="warning"
        variant="subtle"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        height="200px"
      >
        <AlertIcon boxSize="40px" mr={0} />
        <AlertTitle mt={4} mb={1} fontSize="lg">
          {i18n("verify_already_send")}
        </AlertTitle>
        <AlertDescription maxWidth="sm">
          {i18n("verify_content")}
        </AlertDescription>
      </Alert>
      <Box
        as="article"
        display="flex"
        flexDirection={`column`}
        gap="10px"
        p="15px"
      >
        <CustomInput
          props={{
            name: "code",
            label: i18n("input_verify_code"),
            icon: <CgPassword />,
            value: code,
            onChange: (e) => {
              setFormData({
                ...formData,
                code: e.target.value,
              });
            },
          }}
        />
        <Button
          onClick={handleVerifyEmail}
          isLoading={buttonLoading}
          isDisabled={buttonLoading}
          mt="5px"
          variant={`brandPrimary`}
        >
          {i18n("verify")}
        </Button>
      </Box>
    </DetailWrapper>
  );
};

export default VerifyEmail;
