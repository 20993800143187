import React, { useMemo } from "react";
import useCasinoType from "./useCasinoType";
import { useSelector } from "react-redux";

const useGetGamePlatform = () => {
  const handleAllGame = useSelector((state) => state.isHandleAllGame);
  //用 useSelector Hook 從 Redux store 中選擇 isHandleAllGame 的 state，並將其賦值給 handleAllGame 變數

  const casinoType = useCasinoType(); //用了另一個 Hook useCasinoType 它是用來取當前館別的，並將返回的值賦值給 casinoType 變量

  const platformDefined = () => {
    //根據 casinoType 的值返回一個陣列，該陣列包含了應該從遊戲列表中提取的平台類別
    switch (casinoType) {
      case "casino":
        return ["FH", "SLOT", "EGAME", "TABLE", "BLOCKCHAIN"];
      case "live-casino":
        return ["LIVE"];
      case "sports":
        return ["SPORT"];
      case "lottery":
        return ["LOTTERY"];
      case "bingo":
        return ["BINGO"];
      default:
        return [];
    }
  };

  // 從 gameList 中提取出 categories 中指定的類別的平台，並去除重複的平台
  const getUniquePlatforms = (categories, gameList) => {
    const platforms = new Set();
    if (gameList) {
      // 確保 gameList 存在
      categories?.forEach((category) => {
        if (gameList[category]) {
          // 確保 gameList[category] 存在
          Object?.keys(gameList[category])?.forEach((platform) => {
            platforms?.add(platform); //將當前的平台添加到 platforms Set 中。由於 Set 只能存儲唯一的值，所以這將確保 platforms 中不會有重複的平台
          });
        }
      });
    }
    return Array.from(platforms);
  };

  const platformsToShow = useMemo(() => {
    if (handleAllGame.gameList) {
      return getUniquePlatforms(
        platformDefined(casinoType),
        handleAllGame?.gameList
      );
    }
    return [];
  }, [casinoType, handleAllGame]);

  return platformsToShow;
};

export default useGetGamePlatform;
