import React, { useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { routeMapping } from "../GameTypeSelector/utils/routeMapping";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import {
  Box,
  Flex,
  Icon,
  Image,
  Link,
  Text,
  chakra,
  shouldForwardProp,
  useColorMode,
} from "@chakra-ui/react";
import LazyImage from "../../Loading/lazyImage";
import { COMMON_WEB_PATH } from "../../../constant";
import { thisImageNeedDark } from "../../../utils/thisImageNeedDark";
import { IoLogoGameControllerB } from "react-icons/io";
import { useTranslation } from "react-i18next";
import NotFound from "../../ProgressBlock/NotFound";
import { TiThSmallOutline } from "react-icons/ti";
import TitleIcon from "./components/titleIcon";
import { gameListFromBottom } from "../../../animateConstant";
import { isValidMotionProp, motion } from "framer-motion";

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const PlatformSelector = ({ gameMainTypeFromProps }) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);
  const isHandleAllGame = useSelector((state) => state.isHandleAllGame);

  const { colorMode, toggleColorMode } = useColorMode();

  const { gameList } = isHandleAllGame || {};

  const scrollRef = useRef();
  const splideRef = useRef();

  const navigate = useNavigate();
  const location = useLocation();

  const { pathname } = location;

  //   const getGameType = useMemo(() => {
  //     return pathname.split("/")[1];
  //   }, [pathname]);
  const { gameMainType, gamePlatform } = useParams();

  const getPlatform = useMemo(() => {
    const mainType = gameMainTypeFromProps || gameMainType;
    if (gameList) {
      const current_game_type = routeMapping(mainType)?.origin;
      return {
        platform_list: Object?.keys(gameList[current_game_type] || {}),
      };
    }
  }, [gameList, gameMainType, gameMainTypeFromProps]);

  const platformList = useMemo(() => {
    const mainType = gameMainTypeFromProps || gameMainType;
    const { platform_list } = getPlatform || {};
    const current_game_type = routeMapping(mainType)?.origin;

    return platform_list?.map((item) => {
      const game_length = gameList?.[current_game_type]?.[item]?.length;
      return (
        <SplideSlide key={item}>
          <Link
            as={NavLink}
            to={`/${mainType}/category/${item}`}
            h="50px"
            _hover={{
              textDecoration: "none",
            }}
          >
            {({ isActive }) => {
              return (
                <Flex
                  minW="80px"
                  minH="50px"
                  justifyContent={`center`}
                  gap="10px"
                  direction="column"
                  alignItems={`center`}
                  bgGradient={
                    isActive
                      ? "linear(to-b, transparent, brand.500 120%)"
                      : "linear(to-b, transparent, brand.500 400%)"
                  }
                  borderBottom={isActive ? `3px solid` : ``}
                  //   borderX={isActive ? `1px solid` : ``}
                  //   borderTop={isActive ? `1px solid` : ``}
                  //   borderRadius={isActive ? `2px` : ``}
                  borderRadius={`common`}
                  borderColor={isActive ? `brand.300` : ``}
                  py="10px"
                  position={`relative`}
                >
                  <Image
                    className="object-contain w-[67%] h-[42.5px]"
                    src={`${COMMON_WEB_PATH}/platformIcon/${thisImageNeedDark(
                      item,
                      colorMode === "dark"
                    )}.png`}
                    alt={item}
                  />

                  <Box
                    display="flex"
                    alignItems={`center`}
                    gap="5px"
                    borderRadius={`full`}
                    py="3px"
                    px="13px"
                    color="dark.200"
                    bg="special.gold"
                  >
                    <Icon fontSize="14px" as={TiThSmallOutline} />
                    <Text fontWeight={`700`} fontSize="12px">
                      {game_length}
                    </Text>
                  </Box>
                </Flex>
              );
            }}
          </Link>
        </SplideSlide>
      );
    });
  }, [getPlatform, gameList, gameMainTypeFromProps]);

  useEffect(() => {
    if (scrollRef.current) {
      if (gamePlatform) {
        const elementPosition = scrollRef.current.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - 100;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    }
  }, [gamePlatform]);

  useEffect(() => {
    const { platform_list } = getPlatform || {};

    if (splideRef.current) {
      const splide = splideRef.current.splide;

      splide.go(platform_list?.indexOf(gamePlatform));
    }
  }, [getPlatform, gamePlatform]);

  return (
    <Box ref={scrollRef}>
      <Box mb="10px">
        <Flex>
          <Flex alignItems={`center`} gap="10px" fontWeight={`700`}>
            {/* <Icon
              color="special.gold"
              fontSize="16px"
              as={IoLogoGameControllerB}
            /> */}
            <TitleIcon icon={IoLogoGameControllerB} />
            <Text fontSize="16px">{i18n("Providers")}</Text>
          </Flex>
        </Flex>
      </Box>
      {getPlatform?.platform_list?.length === 0 && <NotFound />}
      <ChakraBox
        key={gameMainTypeFromProps || gameMainType}
        {...gameListFromBottom}
      >
        <Splide
          ref={splideRef}
          options={{
            pagination: false,
            arrows: false,
            drag: "free",
            // perPage: 4,
            // perMove: 1,
            gap: 5,
            lazyLoad: "nearby",
            fixedWidth: "90px",
            dragMinThreshold: {
              mouse: 0,
              touch: 10,
            },
          }}
        >
          {platformList}
        </Splide>
      </ChakraBox>
    </Box>
  );
};

export default PlatformSelector;
