const cryptoReducers = (state = {}, action) => {
  switch (action.type) {
    case "StoreCryptoRatio":
      return action.payload;
    case "ClearCryptoRatio":
      return (state = {});
    default:
      return state;
  }
};
export default cryptoReducers;
