import React, { useMemo, useState } from "react";
import CloseDetailPage from "../../../components/CloseComponent/CloseDetailPage";
import DetailWrapper from "../../../components/detailWrapper";
import { useNavigate } from "react-router";
import { HiChevronLeft } from "react-icons/hi";
import {
  transactionExpress,
  transactionExpressForInput,
} from "../../../regex_constanst";
import { useDispatch, useSelector } from "react-redux";
import { notice } from "../../../redux/action/action";
import { useTranslation } from "react-i18next";
import {
  resetWithdrawPassword,
  setWithdrawPassword,
} from "../../../api/postApi";
import PleaseSetUserIDCard from "../../Withdraw/pleaseSetUserIDCard";
import { Box, Button, Divider, useColorModeValue } from "@chakra-ui/react";
import CustomInput from "../../../components/InputComponents/customInput";
import { FaArrowRight } from "react-icons/fa";
import { RiLockPasswordFill } from "react-icons/ri";
import CustomInputV4 from "../../../components/InputComponents/customInput_v4";

const iconStyle = "text-gray-300";

const ResetWithdrawPasswordV4 = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`settings.${key}`);
  const i18n_common = (key) => t(`common.${key}`);
  const i18n_formHint = (key, props) => t(`formHint.${key}`, { ...props });

  const inputBg = useColorModeValue("light.200", "dark.250");

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const memberData = useSelector((state) => state.isMemberInfo);

  const withdraw_password_set = useMemo(() => {
    return memberData?.withdraw_password_set == 1;
  }, [memberData]);

  const [data, setData] = useState({
    old_withdraw_password: "",
    new_withdraw_password: "",
    new_withdraw_password_again: "",
  }); //這裡的old_password取名可以自己取，後面預設值""意思是一開始進來的時候是空的
  const [buttonLoading, setButtonLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setButtonLoading(true);
    const {
      old_withdraw_password,
      new_withdraw_password,
      new_withdraw_password_again,
    } = data;
    if (transactionExpress.test(new_withdraw_password)) {
      if (new_withdraw_password === new_withdraw_password_again) {
        if (withdraw_password_set) {
          resetWithdrawPassword({
            old_pwd: old_withdraw_password, //這裡設定old_pwd是因為後端要求 後面的old_withdraw_password是因為這裡的data是從useState來的
            new_pwd: new_withdraw_password,
          }).then((data) => {
            dispatch(
              notice({ title: i18n_formHint("setSuccess"), type: "success" })
            );
            setButtonLoading(false);
            dispatch({ type: "GET_MEMBER_DATA" });
            navigate("../reset-withdraw-password-success");
          });
        } else {
          setWithdrawPassword({
            new_pwd: new_withdraw_password,
            confirm_pwd: new_withdraw_password_again,
          }).then((data) => {
            dispatch(
              notice({ title: i18n_formHint("setSuccess"), type: "success" })
            );
            setButtonLoading(false);
            dispatch({ type: "GET_MEMBER_DATA" });
            navigate("../reset-withdraw-password-success");
          });
        }
      } else {
        setButtonLoading(false);

        dispatch(
          notice({ title: i18n_formHint("twoPassword"), type: "error" })
        );
      }
    } else {
      setButtonLoading(false);
      dispatch(
        notice({
          title: i18n_formHint("passwordFormatError", { number: 4 }),
          type: "error",
        })
      );
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const formData = useMemo(() => {
    return [
      ...(withdraw_password_set
        ? [
            {
              name: "old_withdraw_password",
              label: i18n("form.old_withdraw_password"),
              type: "password",
              placeholder: i18n("form.old_withdraw_password"),
              pattern: transactionExpressForInput,
              onChange: (e) => {
                handleChange(e);
              },
              icon: <RiLockPasswordFill className={iconStyle} />,
            },
          ]
        : []),
      {
        name: "new_withdraw_password",
        label: "New Withdraw Password",
        type: "password",
        placeholder: i18n_formHint("includeFourNumber"),
        pattern: transactionExpressForInput,
        onChange: (e) => {
          handleChange(e);
        },
        icon: <RiLockPasswordFill className={iconStyle} l />,
      },
      {
        name: "new_withdraw_password_again",
        label: "New Withdraw Password Again",
        type: "password",
        placeholder: i18n_formHint("includeFourNumber"),
        pattern: transactionExpressForInput,
        onChange: (e) => {
          handleChange(e);
        },
        icon: <RiLockPasswordFill className={iconStyle} l />,
      },
    ];
  }, [withdraw_password_set]);
  return memberData.isBindAccount == 0 ? (
    <PleaseSetUserIDCard customPath="../bind-id-card" />
  ) : (
    <DetailWrapper
      wrapperClassName={`h-full w-full md:absolute fixed top-0 left-0 max-h-full z-[10] bg-gradient-to-br`}
    >
      <CloseDetailPage
        hasPrev={
          <HiChevronLeft
            className="cursor-pointer"
            onClick={() => navigate("..")}
          />
        }
        title={`${withdraw_password_set ? i18n("reset") : i18n("set")}${i18n(
          "resetWithdrawPassword"
        )}`}
      />
      <Box
        as="article"
        display="flex"
        flexDirection={`column`}
        gap="10px"
        p="15px"
      >
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          {formData.map((item, index) => {
            return (
              <Box mb="12px">
                <CustomInputV4
                  props={{
                    ...item,
                    label: i18n(`form.${item.name}`),
                    bg: inputBg,
                    sx: {
                      background: inputBg,
                      _dark: {
                        background: "dark.250",
                        borderColor: "dark.450",
                        color: "white",
                      },
                    },
                  }}
                />
              </Box>
            );
          })}
          <Divider my="10px" />
          <Button
            isLoading={buttonLoading}
            isDisabled={buttonLoading}
            variant={`brandPrimary`}
            rightIcon={<FaArrowRight />}
            type="submit"
            disabled={!Object.keys(data).every((item) => data?.[item] !== "")}
            size="md"
            w="100%"
          >
            {i18n_common("SAVE")}
          </Button>
        </form>
      </Box>
    </DetailWrapper>
  );
};

export default ResetWithdrawPasswordV4;
