import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import CloseDetailPage from "../../components/CloseComponent/CloseDetailPage";
import DetailWrapper from "../../components/detailWrapper";
import { BiSolidLockAlt, BiSolidUser, BiSolidUserCircle } from "react-icons/bi";
import { HiChevronRight } from "react-icons/hi";
import { MdLockPerson, MdOutlineLanguage } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  cleanMemberInfo,
  clearTransferWallet,
  openCache,
  openChat,
  openColorMode,
  openLanguagePop,
} from "../../redux/action/member/memberAction";
import { useNavigate } from "react-router";
import { RiProfileFill } from "react-icons/ri";
import { BsShieldFillCheck } from "react-icons/bs";
import { IoIosMoon, IoMdSunny } from "react-icons/io";
import ThemeTab from "./components/themeTab";
import { Box, Flex, useColorModeValue, Text } from "@chakra-ui/react";
import { FaHeadphonesAlt } from "react-icons/fa";
import { WEB_LAYOUT } from "../../constant";
import { AiOutlineClear } from "react-icons/ai";
import CachePop from "../PROFILE_SETTING/components/cachePop";
import { actionLogout } from "../../api/postApi";
import { notice } from "../../redux/action/action";
import UserLogout from "../../api/UserLogout";
import { clearFavoriteGameList } from "../../redux/action/game/gameAction";
import usePackageVersion from "../../hook/usePackageVersion";
import CachePopV4 from "../PROFILE_SETTING/components/cachePop_v4";

const isCredit = process.env.REACT_APP_CREDIT == 1;

const SettingsOther = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`settings.${key}`);
  const i18n_common = (key) => t(`common.${key}`);

  const cachePop = useSelector((state) => state.isCachePop);
  const dispatch = useDispatch();
  const isDarkMode = useSelector((state) => state.isTheme === "dark");
  const memberData = useSelector((state) => state.isMemberInfo);

  const withdraw_password_set = useMemo(() => {
    return memberData?.withdraw_password_set == 1;
  }, [memberData]);

  const boxBg = useColorModeValue("light.100", "dark.250");
  const textColor = useColorModeValue("light.450", "light.100");
  const textHoverColor = useColorModeValue("brand.500", "light.100");
  const borderColor = useColorModeValue("light.300", "dark.300");
  const dividerColor = useColorModeValue("light.350", "dark.350");

  const navigate = useNavigate();

  const versionCode = usePackageVersion();

  const handleLogoutUser = () => {
    actionLogout().then((data) => {
      dispatch(
        notice({
          title: i18n("Logout Success"),
          type: "success",
        })
      );
      // signOut();
      window?.google?.accounts?.id.disableAutoSelect();
      UserLogout();
      dispatch(cleanMemberInfo());
      dispatch(clearFavoriteGameList());
      dispatch(clearTransferWallet());
      navigate("/");
    });
  };

  const settings_list = [
    {
      label: i18n_common("color-mode"),
      icon: <IoIosMoon />,
      link: "color-mode",
      border: true,
      function: () => {
        dispatch(openColorMode());
      },
      hidden: WEB_LAYOUT == "v2",
    },
    {
      label: i18n_common("language"),
      icon: <MdOutlineLanguage />,
      link: "language",
      border: true,
      function: () => {
        dispatch(openLanguagePop());
      },
    },
    // {
    //   label: i18n_common("clearcache"),
    //   icon: <AiOutlineClear />,
    //   link: "clear-cache",

    //   function: () => {
    //     dispatch(openCache());
    //   },
    // },
  ];

  const cache_setting = {
    label: i18n_common("clearcache"),
    icon: <AiOutlineClear />,
    link: "clear-cache",
    function: () => {
      dispatch(openCache());
    },
  };

  return (
    <DetailWrapper>
      <Box as="section">
        <CloseDetailPage title={i18n("title")} />

        <Flex
          direction={`column`}
          gap="0px"
          border={`1px solid`}
          borderColor={borderColor}
          borderRadius="common"
        >
          {settings_list
            ?.filter((route) => {
              return !route.hidden;
            })
            ?.map((item, index, filteredArray) => {
              return (
                <Box
                  key={index}
                  as="section"
                  bg={boxBg}
                  cursor={`pointer`}
                  borderTopRadius={index === 0 ? "common" : ""}
                  borderBottomRadius={
                    index === filteredArray.length - 1 ? "common" : ""
                  }
                  py="16px"
                  px="20px"
                  color="special.1"
                  fontSize="lg"
                  fontWeight={`bold`}
                  display={`flex`}
                  flexDirection={`column`}
                  gap="10px"
                  borderBottom={!item.border ? `1px solid` : "none"}
                  borderColor={dividerColor}
                >
                  <Box
                    as="section"
                    display={`flex`}
                    alignItems={`center`}
                    justifyContent={`space-between`}
                    onClick={() => {
                      if (item.function) {
                        item.function();
                      } else if (item.link || item.path) {
                        navigate(item.link || item.path);
                      }
                    }}
                    _hover={{
                      color: textHoverColor,
                    }}
                  >
                    <Flex alignItems={`center`} gap="5px">
                      {/* <Text as="span" fontSize={`xl`} color={textColor}>
                        {item.icon}
                      </Text> */}
                      <Text
                        as="span"
                        fontWeight={`bold`}
                        fontSize="16px"
                        color={textColor}
                      >
                        {item.label}
                      </Text>
                    </Flex>
                    <HiChevronRight
                      size="22px"
                      color={textColor}
                      className={`${item.active && "rotate-90"}`}
                    />
                  </Box>
                </Box>
              );
            })}
        </Flex>
        <Flex
          direction={`column`}
          gap="0px"
          border={`1px solid`}
          borderColor={borderColor}
          borderRadius="common"
          mt="20px"
        >
          <Box
            as="section"
            bg={boxBg}
            cursor={`pointer`}
            borderRadius="common"
            py="16px"
            px="20px"
            color="special.1"
            fontSize="lg"
            fontWeight={`bold`}
            display={`flex`}
            flexDirection={`column`}
            gap="10px"
          >
            <Box
              as="section"
              display={`flex`}
              alignItems={`center`}
              justifyContent={`space-between`}
              onClick={cache_setting.function}
              _hover={{
                color: textHoverColor,
              }}
            >
              <Flex alignItems={`center`} gap="5px">
                <Text
                  as="span"
                  fontWeight={`bold`}
                  fontSize="16px"
                  color={textColor}
                >
                  {cache_setting.label}
                </Text>
              </Flex>
              <HiChevronRight
                size="22px"
                color={textColor}
                className={`${cache_setting.active && "rotate-90"}`}
              />
            </Box>
          </Box>
        </Flex>
        <Flex
          direction={`column`}
          gap="0px"
          border={`1px solid`}
          borderColor={borderColor}
          borderRadius="common"
          mt="20px"
        >
          <Box
            as="section"
            bg={boxBg}
            borderRadius="common"
            py="16px"
            px="20px"
            color="special.1"
            fontSize="lg"
            fontWeight={`bold`}
            display={`flex`}
            flexDirection={`column`}
            gap="10px"
          >
            <Box
              as="section"
              display={`flex`}
              alignItems={`center`}
              justifyContent={`space-between`}
              _hover={{
                color: textHoverColor,
              }}
            >
              <Text
                as="span"
                fontWeight={`bold`}
                fontSize="16px"
                color={textColor}
              >
                {i18n_common("version")}
              </Text>
              <Text
                as="span"
                color={textColor}
                fontSize="14px"
                fontWeight="normal"
              >
                {versionCode}
              </Text>
            </Box>
          </Box>
        </Flex>
      </Box>
      <Box
        onClick={() => {
          handleLogoutUser();
        }}
        mt="40px"
        mx="20px"
        py="15px"
        px="12px"
        display="flex"
        alignItems={`center`}
        justifyContent={`center`}
        borderRadius={`full`}
        cursor={`pointer`}
        border={`1px solid`}
        borderColor="#4C74F5"
      >
        <Text as="span" color="#4C74F5" fontWeight="bold">
          {i18n_common("logout")}
        </Text>
      </Box>
      {cachePop && <CachePopV4 />}
    </DetailWrapper>
  );
};

export default SettingsOther;
