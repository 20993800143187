import React, { useMemo } from "react";

const colorList = [
  { from: "from-[#ffb47d]", to: "to-[#ff6d01]" },
  { from: "from-[#f687bd]", to: "to-[#f151a5]" },
  { from: "from-[#fec93c]", to: "to-[#feb132]" }, // 注意: 这里的from属性有额外的方括号
  { from: "from-[#9583fc]", to: "to-[#6b6bec]" },
  { from: "from-[#26b7f7]", to: "to-[#10a7fc]" },
  { from: "from-[#feb982]", to: "to-[#ee5f7a]" },
  { from: "from-[#74ecbe]", to: "to-[#2a9f86]" },
  { from: "from-[#d374fe]", to: "to-[#a03cf3]" },
  { from: "from-[#5ee5fe]", to: "to-[#1fb4d5]" },
  { from: "from-[#9cdb8c]", to: "to-[#679e46]" },
  { from: "from-[#ff8989]", to: "to-[#c6403f]" },
  { from: "from-[#7c85fd]", to: "to-[#353ebc]" },
];
export const colorSelector = ({ index }) => {
  index = index > colorList.length - 1 ? index % colorList.length : index;
  const from = colorList?.[index]?.from;
  const to = colorList?.[index]?.to;
  return `bg-gradient-to-b ${from}  ${to}`;
};
