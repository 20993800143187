import React from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Text,
  Heading,
  VStack,
  useColorModeValue,
  Divider,
  Flex,
  Icon,
} from "@chakra-ui/react";
import { appName } from "../../config";
import { FaArrowLeft } from "react-icons/fa6";
import { Link } from "react-router-dom";

const AboutUs = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`aboutUs.${key}`);

  const boxBg = useColorModeValue("light.100", "dark.100");

  return (
    <VStack
      w="full"
      mt="10px"
      rounded="5px"
      px="10px"
      py="16px"
      bg="base.light.white"
      color="base.deep.dark"
      _dark={{ bg: "base.light.dark", color: "base.light.white" }}
      mb="15px"
      spacing="15px"
      align="center"
      fontSize="medium"
    >
      <Flex w="100%" justifyContent="flex-start">
        <Link to={-1}>
          <Icon as={FaArrowLeft} fontSize="2xl" />
        </Link>
      </Flex>
      <Heading
        my="2"
        mt="2"
        color="brand.500"
        fontSize={{ base: "xl", md: "3xl" }}
        fontWeight="semibold"
      >
        Our Advantages
      </Heading>
      <Divider my="10px" />

      <Box w="100%" color="special.1" borderRadius={`10px`} p="15px">
        {appName} is a highly acclaimed and reputable gambling website. With the
        rise of digital currencies, more and more players are choosing {appName}
        . Therefore, we have launched {appName} cryptocurrency gambling website
        to provide players with a safer, faster, and more private gaming
        experience.
        <br />
        With globalized services, multilingual support, site-wide cryptocurrency
        betting, and virtual currency deposits and withdrawals, {appName}
        cryptocurrency gambling website has become a gambling platform that is
        not restricted by currency or borders, bringing you a more exciting
        gaming experience.
        <br />
        We offer a rich variety of games, including over 4000 slot machine
        games, dice games, roulette, and popular Japanese pachinko games,
        allowing you to enjoy the fun of gaming. In addition, we also provide
        live dealers for interactive play, allowing you to experience the
        atmosphere of a real casino. <br />
        To enhance your gaming pleasure, we offer various promotions and bonus
        schemes, making it easy for you to earn rewards while gaming. Come and
        join us to start your journey in cryptocurrency gambling!
      </Box>
    </VStack>
  );
};

export default AboutUs;
