import { isAndroid, isIOS } from "mobile-device-detect";
import { useMemo } from "react";
import { parseSomething } from "../../../utils/parseSomething";

const useAppDownloadLink = () => {
  const link = useMemo(() => {
    const app_download_parse = parseSomething(window.app_download_url);
    if (isAndroid) {
      return app_download_parse?.Android;
    } else if (isIOS) {
      return app_download_parse?.Ios;
    } else {
      return app_download_parse?.Ios;
    }
  }, [isAndroid, isIOS, window?.app_download_link]);

  return link;
};

export default useAppDownloadLink;
