import { useState } from "react";

import { BiMessageSquareAdd } from "react-icons/bi";
import { GiHamburgerMenu } from "react-icons/gi";
import {
  RiHome3Line,
  RiHomeSmileFill,
  RiMoneyDollarCircleLine,
} from "react-icons/ri";
import { IoGift, IoQrCode } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router";
import { loading, loadingDestroy, openMenu } from "../redux/action/action";
import { useDispatch } from "react-redux";
import { HiShare } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import { MdEventAvailable, MdHistory } from "react-icons/md";
import usePwaDetected from "../hook/usePwaDetected";
import {
  AbsoluteCenter,
  Box,
  Flex,
  Image,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaHeadphonesAlt, FaUser } from "react-icons/fa";
import useDeviceType from "../hook/useDeviceType";
import { AiOutlineVerticalAlignTop } from "react-icons/ai";
import { openChat } from "../redux/action/member/memberAction";
import useCheckLogin from "../hook/useCheckLogin";
import { TbReportAnalytics } from "react-icons/tb";
import { COMMON_WEB_PATH } from "../constant";

const iconStyle = "text-2xl";

const isCredit = process.env.REACT_APP_CREDIT == 1;

const FooterV3 = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`footer.${key}`);

  const [isFooterOpen, setIsFooterOpen] = useState("Home");
  const isPwa = usePwaDetected();

  const footerBg = useColorModeValue("light.100", "dark.100");
  const footerText = useColorModeValue("dark.300", "brand.500");

  const { isLogin } = useCheckLogin();

  const deviceType = useDeviceType();

  const dispatch = useDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const FOOTER_LIST = [
    {
      icon: <GiHamburgerMenu className={iconStyle} />,
      text: "Menu",
      image_name: "menu",
    },
    {
      icon: <IoGift className={iconStyle} />,
      text: "Rewards",
      link: "profile/rewards",
      auth: true,
      hidden: isCredit,
    },
    {
      icon: <TbReportAnalytics className={iconStyle} />,
      text: "betRecord",
      link: "profile/betRecord",
      image_name: "record",
      auth: true,
      hidden: !isCredit,
    },
    {
      icon: <RiHome3Line className={iconStyle} />,
      // icon: (
      //   <AbsoluteCenter
      //     w="80px"
      //     h="70px"
      //     top="25px"
      //     border="1.5px solid"
      //     borderColor="brand.900"
      //     outline={"1px solid #2C263E"}
      //     // bg="brand.500"
      //     borderTopRadius={`common`}
      //     bgGradient={
      //       "linear(to-br,brand.200,brand.400,brand.800,brand.300,brand.800)"
      //     }
      //     display="flex"
      //     alignItems={`center`}
      //     justifyContent={`center`}
      //     shadow="md"
      //     overflow="hidden"
      //     // className="home-icon-shadow"
      //   >
      //     <Box
      //       position="absolute"
      //       w="5px"
      //       h="5px"
      //       opacity=".5"
      //       borderRadius={"full"}
      //       bg="white"
      //       top="2px"
      //       left="2px"
      //     ></Box>
      //     <Box
      //       position="absolute"
      //       w="3.5px"
      //       h="3.5px"
      //       opacity=".5"
      //       borderRadius={"full"}
      //       bg="white"
      //       top="1px"
      //       right="3px"
      //     ></Box>
      //     <Box
      //       position="absolute"
      //       bgGradient={`linear(to-r,brand.500 ,brand.700)`}
      //       opacity={"0.15"}
      //       w="180%"
      //       h="400%"
      //       top="35%"
      //       left="-11%"
      //       borderRadius={`full`}
      //     ></Box>
      //     <Flex
      //       position="relative"
      //       zIndex={1}
      //       direction={"column"}
      //       gap="2.5px"
      //       pt="10px"
      //     >
      //       <Image
      //         w="35px"
      //         h="35px"
      //         ml="3px"
      //         src={`${COMMON_WEB_PATH}/b69-home/v3-layout-icon/footer-home.png`}
      //       />
      //       <Text
      //         as="p"
      //         mb="0px"
      //         fontWeight={`medium`}
      //         fontSize={`sm`}
      //         w="100%"
      //         textAlign="center"
      //         className="text-overflow"
      //       >
      //         {i18n("home")}
      //       </Text>
      //     </Flex>
      //   </AbsoluteCenter>
      // ),
      text: "home",
      link: "/",
    },
    {
      icon: <MdHistory className={iconStyle} />,
      text: "History",
      link: "profile/transaction",
      image_name: "transaction",
      auth: true,
      hidden: isCredit,
    },
    {
      icon: <FaHeadphonesAlt className={iconStyle} />,
      text: "Refer",
      link: "profile/refer-code",
      auth: true,
      function: () => {
        dispatch(openChat());
      },
      hidden: !isCredit,
    },
    {
      icon: <FaUser className={iconStyle} />,
      text: "Profile",
      image_name: "profile",
      link: deviceType === "Mobile" ? "profile" : "profile/data",
      auth: true,
    },
  ];

  return (
    <Box as="footer" w="100%" position="fixed" bottom="0" left="0" zIndex={999}>
      <Box
        as="section"
        w="100%"
        h="60px"
        // mb="10px"
        // borderTopRadius={`34px`}
        // borderTop="1.5px solid"
        // borderTopColor="dark.700"
        // bg={footerBg}
        // bg="brand.700"
        bg="#311F07"
        display="grid"
        gridTemplateColumns="repeat(5, minmax(0, 1fr))"
        // mx="auto"
        position="relative"
        borderTopRadius={"common"}
        borderTop="1px solid"
        borderColor="brand.300"
        // className="footer-shadow"
      >
        {FOOTER_LIST?.filter((item) => !item.hidden)?.map((footer, index) => {
          return (
            <Flex
              alignItems="center"
              flexDirection={`column`}
              // justifyContent={`flex-end`}
              position="relative"
              py="10px"
              px="5px"
              zIndex={footer?.text === "home" ? 2 : 1}
              gap="2.5px"
              key={index}
              color={footerText}
              // borderRight={"1px solid"}
              // borderRightColor="#71498C"
              // borderLeft="1px solid"
              // borderLeftColor="brand.100"
              onClick={() => {
                setIsFooterOpen(footer.text);
                if (footer.function) {
                  footer.function();
                  return;
                }
                if (footer.auth && !isLogin) {
                  navigate("signin");
                } else if (footer.text === "Menu") {
                  dispatch(openMenu());
                } else {
                  dispatch(loading());

                  setTimeout(() => {
                    navigate(`${footer.link}`);
                    dispatch(loadingDestroy());
                  }, 200);
                }
              }}
            >
              {footer.icon}
              <Text
                as="p"
                mb="0px"
                fontWeight={`medium`}
                fontSize={`xs`}
                w="100%"
                textAlign="center"
                // className="text-overflow"
              >
                {i18n(footer.text?.toLowerCase())}
              </Text>
            </Flex>
          );
        })}
      </Box>
    </Box>
  );
};

export default FooterV3;
