import { useWillChange } from "framer-motion";

export const popUpVariant = {
  initial: { y: "20%", opacity: 0 },
  animate: { y: "0%", opacity: 1 },
  exit: { opacity: 0 },
};

export const popUpFromTopVariant = {
  initial: { y: "-20%", opacity: 0 },
  animate: { y: "0%", opacity: 1 },
  exit: { y: "-20%", opacity: 0 },
};

export const pageVariants = {
  initial: (dir) => {
    return {
      x: dir == "POP" ? "130%" : "-130%",
    };
  },
  target: {
    x: "0%",
  },
  exit: (dir) => {
    return {
      x: dir == "POP" ? "-130%" : "130%",
    };
  },
};

export const animatePage = {
  visible: { x: 0, opacity: 1 },
  hiddenRight: { x: 0, opacity: 0 },
  hiddenLeft: { x: 0, opacity: 0 },
  transition: { duration: 0.2, ease: "linear" },
};

export const animatePageNotOpacity = {
  visible: { x: 0 },
  hiddenRight: { x: -100 },
  hiddenLeft: { x: 100 },
  transition: { duration: 0.2, ease: "linear" },
};

export const commonOpacity = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.2, ease: "easeInOut" },
};

export const popFromBottom = {
  initial: { y: "10%" },
  animate: { y: 0 },
  exit: { y: "10%" },
  transition: { type: "spring", stiffness: 300, damping: 30 },
  style: { useWillChange },
};

export const gameListFromBottom = {
  initial: { y: "10%", opacity: 0 },
  animate: { y: 0, opacity: 1 },
  exit: { y: "5%" },
  transition: { type: "spring", stiffness: 300, damping: 30 },
  style: { useWillChange },
};
