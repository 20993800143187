import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import WalletList from "./walletList";
import { CURRENCY_SYMBOL } from "../../../constant";
import { IoIosAdd } from "react-icons/io";
import MyBalance from "../../../pages/Profile/components/balance";
import MyBalanceV2 from "../../../pages/Profile/components/balance_v2";
import { useNavigate, useLocation } from "react-router";
import {
  Box,
  Flex,
  Popover,
  PopoverTrigger,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import CreditPage from "../../../utils/credit/credit_page";

const isCredit = process.env.REACT_APP_CREDIT == 1;
const BalanceV2 = () => {
  const memberData = useSelector((state) => state.isMemberInfo);

  const { isOpen, onToggle, onClose } = useDisclosure();

  const baseBg = useColorModeValue("light.200", "dark.300");
  const baseHoverBg = useColorModeValue("light.300", "dark.500");

  const [openWallet, setOpenWallet] = useState(false);

  const { pathname } = useLocation();

  const navigate = useNavigate();

  return (
    <Popover
      variant={"brandPrimary"}
      isOpen={isOpen}
      onClose={onClose}
      placement="bottom"
    >
      <Flex
        borderRadius={`full`}
        _hover={{
          bg: baseHoverBg,
        }}
        bg={baseBg}
        pr={isCredit ? "10px" : "3px"}
        pl="10px"
        alignItems="center"
        gap="15px"
        height="30px"
        cursor="pointer"
        position="relative"
        mr={{
          sm: "10px",
        }}
      >
        <PopoverTrigger>
          <Box
            onClick={() => {
              if (pathname.includes("gamebox")) {
                return;
              } else {
                if (isCredit) {
                  return;
                }
                onToggle();
              }
            }}
            display={`flex`}
            alignItems={`center`}
            gap="5px"
          >
            <MyBalanceV2
              balanceStyle={{
                fontSize: "16px",
                fontWeight: "500",
              }}
            />
          </Box>
        </PopoverTrigger>
        <CreditPage>
          <Link to="profile/deposit">
            <Box
              w="33px"
              h="33px"
              bg="brand.500"
              borderRadius={`full`}
              color="white"
              display="flex"
              alignItems={`center`}
              justifyContent={`center`}
            >
              <IoIosAdd className="text-2xl " />
            </Box>
          </Link>
        </CreditPage>
      </Flex>
      <WalletList onClose={onClose} />
    </Popover>
  );
};

export default BalanceV2;
