import React from "react";
import useRouterType from "../../hook/useRouterType";
import { pageVariants } from "../../animateConstant";
import { AnimatePresence, motion } from "framer-motion";

const PageAnimateWrapper = ({ children } = {}) => {
  const routerType = useRouterType();

  return (
    <motion.section
      variants={pageVariants}
      custom={routerType}
      initial="initial"
      animate="target"
      exit="exit"
      transition={{ type: "spring", duration: 0.55 }}
    >
      <AnimatePresence initial={false} mode="sync" custom={routerType}>
        {children}
      </AnimatePresence>
    </motion.section>
  );
};

export default PageAnimateWrapper;
