import React, { useCallback, useState } from "react";
import useCasinoType from "./useCasinoType";

export const filterType = ({ gameList, casinoType } = {}) => {
  if (!Array.isArray(gameList)) return [];
  if (casinoType === "live-casino") {
    return gameList?.filter((item) => item.game_type === "LIVE");
  } else if (casinoType === "casino") {
    return gameList?.filter(
      (item) =>
        item.game_type === "SLOT" ||
        item.game_type === "FH" ||
        item.game_type === "TABLE" ||
        item.game_type === "EGAME" ||
        item.game_type === "BLOCKCHAIN"
    );
  } else if (casinoType === "sports") {
    return gameList?.filter((item) => item.game_type === "SPORT");
  } else if (casinoType === "lottery") {
    return gameList?.filter((item) => item.game_type === "LOTTERY");
  } else if (casinoType === "bingo") {
    return gameList?.filter((item) => item.game_type === "BINGO");
  } else {
    return gameList;
  }
};
