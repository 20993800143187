import { BiMessageSquareAdd, BiSolidGame } from "react-icons/bi";
import { BsFillHeartFill, BsFillRocketTakeoffFill } from "react-icons/bs";
import {
  FaChess,
  FaCrown,
  FaDice,
  FaFish,
  FaSmile,
  FaStar,
} from "react-icons/fa";
import {
  GiClick,
  GiJesterHat,
  GiPodiumWinner,
  GiPokerHand,
  GiTigerHead,
} from "react-icons/gi";
import { GoNumber } from "react-icons/go";
import { IoDiamond, IoGiftSharp, IoHome, IoTvSharp } from "react-icons/io5";
import { MdRecommend, MdWhatshot } from "react-icons/md";
import { RiBook3Fill, RiHome2Fill } from "react-icons/ri";
import { TbCherryFilled, TbGridDots, TbPokerChip } from "react-icons/tb";
import { COMMON_WEB_PATH } from "../../../constant";
import { Image } from "@chakra-ui/react";
import { TiHeart } from "react-icons/ti";

const iconClassName =
  "text-2xl mr-[10px] group-hover:text-main-color w-[22px] h-[22px] object-contain";

export const menuIcon = (text, type, { custom = "" } = {}) => {
  const commonProps = {
    className: `${iconClassName} ${custom}`,
  };
  switch (text) {
    case "Home":
      return type === "icon" ? (
        IoHome
      ) : (
        <Image
          src={`${COMMON_WEB_PATH}/menu-icon/lobby.webp`}
          {...commonProps}
        />
      );
    case "AllGames":
      return type === "icon" ? (
        TbGridDots
      ) : (
        <Image src={`${COMMON_WEB_PATH}/menu-icon/all.webp`} {...commonProps} />
      );

    case "News":
      return type === "icon" ? (
        BiMessageSquareAdd
      ) : (
        <Image src={`${COMMON_WEB_PATH}/menu-icon/new.webp`} {...commonProps} />
      );
    case "Top":
      return type === "icon" ? (
        MdWhatshot
      ) : (
        <Image src={`${COMMON_WEB_PATH}/menu-icon/hot.webp`} {...commonProps} />
      );
    case "Others":
      return type === "icon" ? (
        FaCrown
      ) : (
        <Image
          src={`${COMMON_WEB_PATH}/menu-icon/other.webp`}
          {...commonProps}
        />
      );
    case "Jackpot":
      return type === "icon" ? (
        IoDiamond
      ) : (
        <Image
          src={`${COMMON_WEB_PATH}/menu-icon/pool.webp`}
          {...commonProps}
        />
      );
    case "Baccarat":
      return type === "icon" ? (
        GiPokerHand
      ) : (
        <Image
          src={`${COMMON_WEB_PATH}/menu-icon/barca.webp`}
          {...commonProps}
        />
      );
    case "Roulette":
      return type === "icon" ? (
        TbPokerChip
      ) : (
        <Image
          src={`${COMMON_WEB_PATH}/menu-icon/roulett.webp`}
          {...commonProps}
        />
      );
    case "TVBasedGames":
      return type === "icon" ? (
        IoTvSharp
      ) : (
        <Image src={`${COMMON_WEB_PATH}/menu-icon/TV.webp`} {...commonProps} />
      );
    case "Blackjack":
      return type === "icon" ? (
        GiJesterHat
      ) : (
        <Image
          src={`${COMMON_WEB_PATH}/menu-icon/twentyone.webp`}
          {...commonProps}
        />
      );
    case "Megaways":
      return type === "icon" ? (
        BiSolidGame
      ) : (
        <Image
          src={`${COMMON_WEB_PATH}/menu-icon/hundred.webp`}
          {...commonProps}
        />
      );
    case "NumberGames":
      return type === "icon" ? (
        GoNumber
      ) : (
        <Image
          src={`${COMMON_WEB_PATH}/menu-icon/number.webp`}
          {...commonProps}
        />
      );
    case "Bonus":
      return type === "icon" ? (
        IoGiftSharp
      ) : (
        <Image
          src={`${COMMON_WEB_PATH}/menu-icon/prize.webp`}
          {...commonProps}
        />
      );
    case "AllTheBooks":
      return type === "icon" ? (
        RiBook3Fill
      ) : (
        <Image
          src={`${COMMON_WEB_PATH}/menu-icon/book.webp`}
          {...commonProps}
        />
      );
    case "InstantWin":
      return type === "icon" ? (
        BsFillRocketTakeoffFill
      ) : (
        <Image
          src={`${COMMON_WEB_PATH}/menu-icon/instant.webp`}
          {...commonProps}
        />
      );
    case "DragonTiger":
      return type === "icon" ? (
        GiTigerHead
      ) : (
        <Image
          src={`${COMMON_WEB_PATH}/menu-icon/tiger.webp`}
          {...commonProps}
        />
      );
    case "Sicbo":
      return type === "icon" ? (
        FaDice
      ) : (
        <Image
          src={`${COMMON_WEB_PATH}/menu-icon/scibo.webp`}
          {...commonProps}
        />
      );
    case "Lobby":
      return type === "icon" ? (
        RiHome2Fill
      ) : (
        <Image
          src={`${COMMON_WEB_PATH}/menu-icon/live.webp`}
          {...commonProps}
        />
      );
    case "Slots":
      return type === "icon" ? (
        TbCherryFilled
      ) : (
        <Image
          src={`${COMMON_WEB_PATH}/menu-icon/slot.webp`}
          {...commonProps}
        />
      );
    case "DropsWins":
      return type === "icon" ? (
        GiPodiumWinner
      ) : (
        <Image
          src={`${COMMON_WEB_PATH}/menu-icon/roll.webp`}
          {...commonProps}
        />
      );
    case "Table":
      return type === "icon" ? (
        FaChess
      ) : (
        <Image
          src={`${COMMON_WEB_PATH}/menu-icon/table.webp`}
          {...commonProps}
        />
      );
    case "Fish":
      return type === "icon" ? (
        FaFish
      ) : (
        <Image
          src={`${COMMON_WEB_PATH}/menu-icon/fish.webp`}
          {...commonProps}
        />
      );
    case "MyFavorite":
      return type === "icon" ? (
        TiHeart
      ) : (
        <Image
          src={`${COMMON_WEB_PATH}/menu-icon/favorite.webp`}
          {...commonProps}
        />
      );
    case "ClickedGames":
      return type === "icon" ? (
        GiClick
      ) : (
        <Image
          src={`${COMMON_WEB_PATH}/menu-icon/lastplay.webp`}
          {...commonProps}
        />
      );
    case "ExclusiveGame":
      return type === "icon" ? (
        MdRecommend
      ) : (
        <Image
          src={`${COMMON_WEB_PATH}/menu-icon/only.webp`}
          {...commonProps}
        />
      );
    default:
      return FaStar;
  }
};
