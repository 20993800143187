import React, { useCallback, useMemo, useState } from "react";
import CloseDetailPage from "../../../components/CloseComponent/CloseDetailPage";
import { LANGUAGES, LANGUAGES_MENU } from "../../../i18n/config";
import i18next from "i18next";
import { AiFillCheckCircle } from "react-icons/ai";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { closeLanguagePop } from "../../../redux/action/member/memberAction";
import { useTranslation } from "react-i18next";
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Icon,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const LanguagePageV2 = () => {
  const { t, i18n } = useTranslation();
  const { lang } = useParams();
  const i18n_common = (key) => t(`common.${key}`);

  const boxBg = useColorModeValue("light.100", "dark.100");
  const itemHoverBg = useColorModeValue("light.300", "dark.300");
  const textActiveColor = useColorModeValue("dark.100", "light.100");

  const website_language = window.web_language || [];

  const memberData = useSelector((state) => state.isMemberInfo);
  const { test } = memberData || {};

  const language_filter = useMemo(() => {
    return LANGUAGES_MENU?.filter((item) => {
      if (test == 1) {
        return item;
      } else {
        return website_language?.includes(item.value);
      }
    });
  }, [LANGUAGES_MENU, test, website_language]);
  console.log(language_filter);
  const onClose = async () => {
    await dispatch(closeLanguagePop());
  };

  const dispatch = useDispatch();
  return (
    <Modal
      variant={`brandPrimary`}
      onClose={onClose}
      isOpen={true}
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent
        mt={{
          base: "auto",
        }}
        mb={{
          base: "unset",
          md: "auto",
        }}
        borderTopRadius={{
          base: "24px",
        }}
        borderBottomRadius={{
          base: "unset",
          md: "24px",
        }}
        h={550}
      >
        <ModalHeader>Language</ModalHeader>
        <ModalCloseButton />
        <ModalBody h={500}>
          <Grid
            templateColumns={"repeat(2, minmax(0, 1fr))"}
            as="section"
            display="grid"
            flexDirection={`column`}
            gap="10px"
            borderRadius={`common`}
            py="8px"
          >
            {language_filter?.map((item, index) => {
              return (
                <GridItem>
                  <Box
                    onClick={async (event) => {
                      event.stopPropagation();
                      await i18next.changeLanguage(item.value);
                      localStorage.setItem("i18nextLng", item.value);
                      // const targetPath = item.value === "tw" ? "" : item.value;
                      const targetPath = item.value;

                      window.location.assign("/" + targetPath);
                    }}
                    key={`${item.language}-${index}`}
                    cursor={`pointer`}
                    borderRadius={`common`}
                    p="10px"
                    display="flex"
                    flexDirection={`column`}
                    alignItems={`center`}
                    justifyContent={`space-between`}
                    bg={boxBg}
                    border={
                      i18next.language === item.value ? "1px solid" : "none"
                    }
                    borderColor={
                      i18next.language === item.value
                        ? "special.gold"
                        : "transparent"
                    }
                    _hover={{
                      bg: itemHoverBg,
                    }}
                    h="110px"
                    position="relative"
                  >
                    <Image
                      src={`https://flagsapi.com/${item.flag?.toUpperCase()}/flat/64.png`}
                    />
                    <Flex alignItems={`center`} gap="5px">
                      {/* <Flag name={item.flag} className="!w-[20px]" height="15" /> */}
                      <Text
                        as="span"
                        fontWeight={`medium`}
                        color={
                          lang === item.value ? textActiveColor : "special.1"
                        }
                      >
                        {item.text}
                      </Text>
                    </Flex>
                    {i18next.language === item.value && (
                      <Icon
                        fontSize="2xl"
                        position="absolute"
                        top="-5px"
                        right="-5px"
                        as={AiFillCheckCircle}
                        color="green.500"
                      />
                    )}
                  </Box>
                </GridItem>
              );
            })}
          </Grid>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default LanguagePageV2;
