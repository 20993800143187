import {
  AbsoluteCenter,
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { actionSignIn, actionSignUp } from "../../api/postApi";
import { waitTime } from "../../utils/waitTime";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash, FaMobileAlt } from "react-icons/fa";
import { RiUser3Fill, RiUserSmileFill } from "react-icons/ri";
import { BiKey } from "react-icons/bi";
import GoogleLoginButton from "./components/googleLogin";
import TelegramLogin from "./components/telegramLogin";
import GoogleLoginV2 from "./components/GoogleLogin_v2";
import CreditPage from "../../utils/credit/credit_page";

const iconStyle = "brand.";

const SigninFormV3 = () => {
  const { t } = useTranslation();
  const i18n = (key, props) => t(`header.${key}`, { ...props });
  const i18n_common = (key) => t(`common.${key}`);
  const i18n_common_form = (key) => t(`settings.form.${key}`);
  const i18n_formHint = (key, props) => t(`formHint.${key}`, { ...props });
  const i18n_backend_response = (key) => t(`backend_response.${key}`);

  const toast = useToast();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    user_name: "",
    password: "",
  });
  const [buttonLoading, setButtonLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const { user_name, password } = formData;

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSeePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonLoading(true);

    await actionSignIn({
      account: user_name,
      passwd: password,
    })
      .then((data) => {
        if (Cookies.get("token")) {
          Cookies.remove("token");
        }
        Cookies.set("token", data.token, { expires: 30 });

        dispatch({
          type: "INITIALIZE_API",
          navigate: navigate,
        });

        navigate("/");
      })
      .finally(() => {
        setButtonLoading(false);
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <VStack spacing={`7px`}>
        <Flex
          w="100%"
          h="100%"
          direction={`column`}
          justifyContent={`space-between`}
          gap="30px"
        >
          <Flex direction={`column`} gap="5px">
            <Flex direction={`column`} gap="20px">
              <FormControl isRequired>
                {/* <FormLabel>{i18n_common_form("account")}</FormLabel> */}
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <Icon as={RiUser3Fill} color="brand.900" />
                  </InputLeftElement>
                  <Input
                    type="text"
                    variant={`v3SignBrandPrimary`}
                    name="user_name"
                    value={user_name}
                    onChange={handleInputChange}
                    placeholder={`${i18n_common(
                      "pleaseInput"
                    )} ${i18n_common_form("account")}`}
                  />
                </InputGroup>
              </FormControl>
              <FormControl isRequired>
                {/* <FormLabel>{i18n_common_form("password")}</FormLabel> */}
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <Icon as={BiKey} color="brand.900" />
                  </InputLeftElement>
                  <Input
                    type={showPassword ? "text" : "password"}
                    variant={`v3SignBrandPrimary`}
                    name="password"
                    value={password}
                    onChange={handleInputChange}
                    placeholder={`${i18n_common(
                      "pleaseInput"
                    )} ${i18n_common_form("password")}`}
                  />
                  <InputRightElement>
                    {showPassword ? (
                      <FaEyeSlash
                        className="cursor-pointer"
                        onClick={handleSeePassword}
                      />
                    ) : (
                      <FaEye
                        className="cursor-pointer"
                        onClick={handleSeePassword}
                      />
                    )}
                  </InputRightElement>
                </InputGroup>
              </FormControl>
            </Flex>
            {/* <CreditPage> */}
            {window.open_registration == 1 && (
              <Flex
                justifyContent={"flex-end"}
                fontSize="14px"
                color="brand.500"
              >
                <Link to="../signup">Sign up now!</Link>
              </Flex>
            )}

            {/* </CreditPage> */}
          </Flex>
          <AbsoluteCenter top="100%">
            <Button
              variant={"secondBrandPrimary"}
              type="submit"
              borderRadius={"20px"}
              isLoading={buttonLoading}
              disabled={buttonLoading}
              py="25px"
              px="35px"
              fontWeight={"900"}
              fontSize="20px"
              // borderX="2px solid"
              borderTop="2px solid"
              // borderBottom="5px solid"
              borderColor="v3-second-brand.200"
              outline="2px solid"
              outlineColor={"v3-second-brand.900"}
              position="relative"
              overflow="hidden"
              boxShadow="0px 4px 10px black"
            >
              <Box
                bg="v3-second-brand.500"
                position="absolute"
                w="120%"
                h="200%"
                top="-145%"
                borderRadius={"full"}
              ></Box>
              <Text position="relative" zIndex={1}>
                SIGN IN
              </Text>
            </Button>
          </AbsoluteCenter>
          <CreditPage>
            <AbsoluteCenter top="calc(100% + 130px)">
              <Text textAlign={"center"} fontSize="xl" mb="10px">
                or sign in with...
              </Text>
              <Flex direction="row" gap="15px" w="100%">
                <GoogleLoginV2 />
                <TelegramLogin
                  setButtonLoading={setButtonLoading}
                  buttonLoading={buttonLoading}
                />
              </Flex>
            </AbsoluteCenter>
          </CreditPage>
        </Flex>
      </VStack>
    </form>
  );
};

export default SigninFormV3;
