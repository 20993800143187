import { Select } from "@chakra-ui/react";
import { useMemo } from "react";
import { useSelector } from "react-redux";

const GamePlatformSelect = ({ className, setPlatformSelect, customList }) => {
  const gamePlatformList = useSelector(
    (state) => state.isHandleAllGame.origin_platform
  );

  const totalList = useMemo(() => {
    if (gamePlatformList) {
      const process_platform = gamePlatformList?.map((plat) => {
        if (plat?.SubPlatformEnum?.length > 0) {
          const combineLabel = plat?.SubPlatformEnum.map((sub) => {
            return sub?.SubPlatformEnum;
          });
          return {
            label: combineLabel?.join("+"),
            value: plat?.platform,
          };
        } else {
          return {
            label: plat?.platform,
            value: plat?.platform,
          };
        }
      });
      return [...customList, ...process_platform];
    } else {
      return customList;
    }
  }, [customList, gamePlatformList]);
  return (
    <Select
      {...className}
      variant={`brandPrimary`}
      onChange={(e) => {
        setPlatformSelect(e.target.value);
      }}
      border="0px"
      size={`sm`}
    >
      {totalList?.map((option) => {
        return (
          <option value={option?.value}>{option?.label?.toUpperCase()}</option>
        );
      })}
    </Select>
  );
};

export default GamePlatformSelect;
