import React, { useEffect, useMemo, useState } from "react";
import { formatNumber } from "../../../utils/formatNumber";
import { useTranslation } from "react-i18next";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { AiFillDollarCircle } from "react-icons/ai";
import PromotionCard from "../../Vip/components/promotionCard";
import { IoCheckmarkCircle, IoCloseCircle } from "react-icons/io5";
import { FaExclamationCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import PaymentCurrencySettings from "../../Settings/components/PaymentCurrencySettings";
import {
  Box,
  Divider,
  Flex,
  Icon,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import { getRestrictWagering } from "../../../api/getApi";
import useCurrentWager from "../../../hook/useCurrentWager";
import LoadingIcon from "../../../components/Loading/LoadingIcon";
import { waitTime } from "../../../utils/waitTime";

const FromWithdrawHintV4 = ({
  washConfig,
  onCurrencyChange,
  selectedCurrency,
}) => {
  const { t } = useTranslation();
  const i18n = (key, props) => t(`vip.${key}`, { ...props });

  const { washPoint, betAmount, unlockTime, canWithdraw } = washConfig;

  const isCurrencyExchange = useSelector((state) => state.isCurrencyExchange);

  const currencyList = useMemo(() => {
    return Object.keys(isCurrencyExchange).filter(
      (item) => isCurrencyExchange[item]?.status_front === 1
    );
  }, [isCurrencyExchange]);

  const { wagering, restricted_amount, can_withdraw } =
    useCurrentWager({
      customCurrency: selectedCurrency,
    }) || {};

  const [loading, setLoading] = useState(false);

  const boxBg = useColorModeValue("light.100", "dark.100");
  const textColor = useColorModeValue("dark.100", "light.100");

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await waitTime(2000);
      setLoading(false);
    };
    fetchData();
  }, []);

  return (
    <>
      {!can_withdraw ? (
        // 未達成提款所需條件的提示
        <Flex
          alignItems={`center`}
          justifyContent={`space-between`}
          gap="5px"
          as="section"
          borderRadius={`common`}
          p="10px"
          px="30px"
          mb="10px"
          bg={boxBg}
        >
          <Box className="relative">
            <Text
              as="span"
              color={textColor}
              fontSize="lg"
              fontWeight={`medium`}
              letterSpacing={`tighter`}
            >
              {i18n("withdrawNotAchieved")}
            </Text>
            <Flex>
              <Flex direction={`column`} alignItems={`flex-end`}>
                <LoadingIcon commonLoading={loading}>
                  <Text
                    as="span"
                    color={textColor}
                    fontSize="sm"
                    letterSpacing={`tighter`}
                    className="text-overflow"
                  >
                    <Text as="span" color="red.300">
                      {formatNumber(wagering, { point: false })}
                    </Text>{" "}
                    /{" "}
                    {formatNumber(restricted_amount, {
                      point: false,
                    })}
                  </Text>
                </LoadingIcon>
              </Flex>
              <Flex justifyContent={`flex-end`} w="100%">
                <Text
                  as="span"
                  color="brand.500"
                  fontSize="sm"
                  letterSpacing={`tighter`}
                >
                  <Text as="span" color="red.300">
                    {i18n("bet")}
                  </Text>
                  {""}/{""}
                  {i18n("washPoint")}
                </Text>
              </Flex>
            </Flex>

            <Text fontSize="sm" color="brand.500">
              <span>{i18n("washPointPeriod")}</span>

              <span>
                {dayjs.unix(unlockTime).format("YYYY-MM-DD HH:mm") || "-"}
              </span>
            </Text>
          </Box>
          <Icon as={IoCloseCircle} color={"red.300"} fontSize="2rem" ml="5px" />{" "}
        </Flex>
      ) : (
        // 已達成提款所需條件的提示
        <Box
          as="section"
          borderRadius={`common`}
          display="flex"
          flexDirection={`column`}
          gap="5px"
          p="10px"
          px="30px"
          mb="10px"
          bg={boxBg}
        >
          <Flex
            alignItems={`center`}
            justifyContent={`space-between`}
            gap="5px"
            as="section"
            bg={boxBg}
          >
            <Box className="relative">
              <Text
                as="span"
                color={textColor}
                fontSize="lg"
                fontWeight={`medium`}
                letterSpacing={`tighter`}
              >
                {i18n("withdrawAchieved")}
              </Text>
              <Text fontSize="sm" color="brand.500">
                <span>{i18n("washPointPeriod")}</span>

                <span>
                  {dayjs.unix(unlockTime).format("YYYY-MM-DD HH:mm") || "-"}
                </span>
              </Text>
            </Box>
            <Icon
              as={IoCheckmarkCircle}
              color={"green.300"}
              fontSize="2rem"
              ml="5px"
            />{" "}
          </Flex>
          {currencyList?.length > 1 ? (
            <>
              <Divider />
              <Flex
                alignItems={`center`}
                justifyContent={`space-between`}
                w="100%"
                gap="10px"
                my="5px"
              >
                <Text
                  as="span"
                  w="100%"
                  color={textColor}
                  letterSpacing={`tighter`}
                  fontWeight={`medium`}
                >
                  {i18n("withdrawCurrency")}
                </Text>
                <PaymentCurrencySettings
                  onCurrencyChange={onCurrencyChange}
                  selectedCurrency={selectedCurrency}
                  filterFrontStatus
                />
              </Flex>
            </>
          ) : null}
        </Box>
      )}
    </>
  );
};

export default FromWithdrawHintV4;
