import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";

import i18next from "i18next";
import { getTranslate } from "../api/getApi";
import { lngsList } from "./config";

// if (
//   !lngsList.some((item) => {
//     return item === localStorage.getItem("i18nextLng");
//   })
// ) {
//   localStorage.setItem("i18nextLng", process.env.REACT_APP_DEFAULT_LANGUAGE);
// }

i18n.on("languageChanged", (lng) => {
  document.documentElement.setAttribute("lang", lng);
});

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    cleanCode: true,
    nonExplicitSupportedLngs: false,
    whitelist: lngsList,
    fallbackLng: process.env.REACT_APP_DEFAULT_LANGUAGE,
    supportedLngs: lngsList,
    detection: {
      order: ["path", "localStorage", "cookie", "htmlTag", "subdomain"],
      caches: [""],
      lookupFromPathIndex: 0,
      checkWhitelist: true,
      whitelist: lngsList,
    },
    // localePath: `${DATA2_PATH}/locales`,
    backend: {
      requestOptions: {
        cache: "default",
        credentials: "same-origin",
        mode: "no-cors",
      },
      crossDomain: true,
      request: async (options, url, payload, callback) => {
        try {
          await getTranslate({
            lng: i18next.language || process.env.REACT_APP_DEFAULT_LANGUAGE,
          })
            .then((response) => {
              callback(null, {
                data: response,
                status: 200,
              });
            })
            .catch((error) => {
              console.log(error.response);
            });
        } catch (e) {
          callback(null, {
            status: 500,
          });
        }
      },
    },
    debug: false,
    // lng:
    //   Cookies.get("language") !== undefined ? Cookies.get("language") : "zh_TW",
    // resources:
    //   process.env.REACT_APP_SECRET_TYPE !== "local"
    //     ? {
    //         tw: { translation: tw },
    //         en: { translation: en },
    //         cn: { translation: zh_chs },
    //         vi: { translation: vi },
    //         id: { translation: id },
    //         jp: { translation: jp },
    //         //tag: { translation: tag },
    //       }
    //     : false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
