import CryptoJS from "crypto-js";

export const customCryptoPassword = (password) => {
  const passphrase = "mySecretPassphrase";

  const iv = CryptoJS.lib.WordArray.random(16);

  // 將passphrase轉換為16 bytes的key
  const key = CryptoJS.PBKDF2(passphrase, iv, {
    keySize: 128 / 32,
    iterations: 1000,
  });

  // 使用CBC模式和PKCS7 padding進行加密
  const ciphertext = CryptoJS.AES.encrypt(password, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  // 將iv和ciphertext轉換為Base64格式的字符串
  const ivBase64 = iv.toString(CryptoJS.enc.Base64);
  const ciphertextBase64 = ciphertext.toString();
  return ivBase64 + ":" + ciphertextBase64;
};
