import Cookies from "js-cookie";

const mainThemeReducers = (
  state = Cookies.get("color-mode") || "dark",
  action
) => {
  switch (action.type) {
    case "setTheme":
      return action.payload;
    default:
      return state;
  }
};
export default mainThemeReducers;
