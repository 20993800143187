import React, { useCallback, useEffect, useMemo, useState } from "react";
import OriginInputCustom from "../../components/InputComponents/originInputCustom";
import { AiOutlineArrowDown, AiOutlineArrowRight } from "react-icons/ai";
import ListHeader from "./components/listHeader";
import { IoReloadOutline } from "react-icons/io5";
import GetParams from "../../utils/getParams";
import { useTranslation } from "react-i18next";
import { MdOutlineCircle } from "react-icons/md";
import { TbTransferVertical } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { getTransferWallet, getUserInfo } from "../../api/getApi";
import {
  storeMemberInfo,
  storeTransferWallet,
} from "../../redux/action/member/memberAction";
import { flattenWalletObject } from "../../utils/flattenObject";
import { formatNumber } from "../../utils/formatNumber";
import { transferWalletToGame } from "../../api/postApi";
import { transferGameToWallet } from "../../api/postApi";
import { singleWalletToGame } from "../../api/postApi";
import { singleGameToWallet } from "../../api/postApi";
import { notice } from "../../redux/action/action";
import { DEPOSIT_AMOUNT_MIN } from "../../constant";
import Cookies from "js-cookie";
import { RiMapPin2Fill } from "react-icons/ri";
import { triggerGame } from "../../redux/action/game/gameAction";
import CommonConfirmModal from "../../components/Modal/commonConfirmModal";
import TransferLimitHint from "./components/transferLimitHint";
import {
  Button,
  Divider,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Spinner,
} from "@chakra-ui/react";

const TabTransfer = ({ activeSelect }) => {
  const { t } = useTranslation();
  const i18n = (key, props) => t(`pointQuery.${key}`, { ...props });
  const i18n_backendRes = (key, props) =>
    t(`backend_response.${key}`, { ...props });

  const navigate = useNavigate();
  const location = useLocation();

  const isGameBox = useMemo(() => {
    return location.pathname.includes("gamebox");
  }, [location.pathname]);

  const dispatch = useDispatch();
  const memberData = useSelector((state) => state.isMemberInfo);
  const transferType = useSelector(
    (state) => state.isGameConfig?.extensionGameGroup
  );
  const isLoading = useSelector((state) => state.isLoaderStatus);
  const isDarkMode = useSelector((state) => state.isTheme === "dark");
  const transferData = useSelector((state) => state.isTransferWallet); //把redux中的isTransferWallet的值存儲在transferData變量中
  const isHandleAllGame = useSelector((state) => state.isHandleAllGame); // 这行代码使用了 Redux 中的 useSelector 钩子，用于从 Redux 存储中获取状态。它接受一个回调函数，回调函数的参数 state 表示整个 Redux 存储的状态，然后从中选择特定的状态属性。在这里，它选择了名为 isHandleAllGame 的状态属性。
  const { allGamePlatform } = isHandleAllGame || {}; //接下来，它使用了解构赋值来从 isHandleAllGame 对象中提取 allGamePlatform 属性。这里还使用了空对象 {} 的默认值，以避免在 isHandleAllGame 为 null 或 undefined 时出现错误。
  const singleWalletList = useSelector((state) =>
    Object.keys(state.isMemberInfo.gameWalletInfo || {}).map((item) => {
      return {
        label: item,
        type: "Single",
        value: item,
        balance: state.isMemberInfo.gameWalletInfo[item],
      };
    })
  );
  const transferWalletList = useSelector((state) =>
    Object.keys(state.isTransferWallet || {}).map((item) => {
      return {
        label: item,
        type: "Transfer",
        value: item,
        balance: state.isTransferWallet[item],
      };
    })
  );

  const { to, gameUid } = useParams();
  const [rotate, setRotate] = useState(false); //這個變數用來控制轉換錢包的箭頭圖示
  const [amount, setAmount] = useState(0); //這個變量用於存儲用戶輸入的充值金額amount。它的初始值為 0。無論是主錢包到遊戲或遊戲到主錢包 金額是同一個變量 只是名稱不同 所以只要用同一個變量傳給後端就好
  const [buttonLoading, setButtonLoading] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false); //這個變量用於控制彈出確認框
  const [payment, setPayment] = useState({
    from: to ? "main_wallet" : "",
    to: to
      ? `${to}${transferType ? `-${transferType.replace(to, "")}` : ""}`
      : "",
  });
  const [trigger, setTrigger] = useState(false);
  const [transferLoading, setTransferLoading] = useState(false); //這個變量用於控制轉換錢包時的loading 把它的初始值設為false
  const splitString = (string) => {
    if (string === "") return []; //首先检查输入的字符串 string 是否为空。如果输入字符串为空，函数会返回一个空数组 []，表示没有有效的数据可以分割。这是一个防御性编程的做法，可以避免后续代码出现错误。
    const array = string.split("-"); //如果输入字符串不为空，接下来的代码将使用 - 字符将字符串分割成一个数组，存储在 array 变量中。例如，如果输入字符串是 "platform-mode"，则 array 将包含 ["platform", "mode"]。
    const findPlatform = allGamePlatform?.find(
      (item) => item.platform === array[0]
    ); //在名为 allGamePlatform 的数组中查找与 array[0] 相匹配的对象。array[0] 包含分割后的第一个部分，例如 "platform"。findPlatform 变量将包含匹配到的对象，如果没有找到匹配的对象，则它将是 undefined。
    return {
      //最后，这段代码返回一个对象，其中包含以下属性：
      platform: findPlatform?.platformUid, //它从 findPlatform 对象中提取 platformUid 属性，如果没有匹配的对象，它将为 undefined。
      platformMode: findPlatform?.platformMode, //同样，它从 findPlatform 对象中提取 platformMode 属性，如果没有匹配的对象，它将为 undefined。
      transferWalletType: findPlatform?.platform + (array[1] || ""), //它从分割后的数组 array 中提取第二个部分（array[1]），如果没有第二个部分，它将为空字符串。
      exchangeRate: findPlatform?.exchangeRate,
    };
  };

  const paymentToObject = useMemo(() => {
    //避免產生非同步問題 所以用useMemo
    return splitString(
      //调用 splitString 函数，将参数传递给它，然后将结果存储在 paymentToObject 变量中。
      payment.to === "main_wallet" ? payment.from : payment.to
    ); //参数是一个条件表达式，它根据 payment.to 的值决定传递给 splitString 函数的字符串。如果 payment.to 等于 "main_wallet"，则传递 payment.from，否则传递 payment.to。
  }, [payment.to, payment.from]); //useMemo 中的依赖项列表包括 payment.to 和 payment.from，这意味着只有在这两个属性的值发生变化时，paymentToObject 才会重新计算。

  useEffect(() => {
    if (to) {
      setPayment({
        from: "main_wallet",
        to: `${to}${transferType ? `-${transferType.replace(to, "")}` : ""}`,
      });
    }
  }, [to, transferType]);

  useEffect(() => {
    if (Cookies.get("token") === undefined) {
      navigate("/casino/signin");
      return;
    }
    if (paymentToObject.platformMode === "transfer") {
      //判斷如果遊戲錢包是轉帳模式的話:
      setTransferLoading(true); //把轉換錢包的loading設為true
      dispatch({ type: "GET_BALANCE" });
      getTransferWallet({
        //調用getTransferWallet函數 把paymentToObject.platform和paymentToObject.transferWalletType的值傳給getTransferWallet函數
        platformUid: paymentToObject.platform,
        platformType: paymentToObject.transferWalletType,
        realPoint: 1,
      })
        .then((data) => {
          const updateTransferWallet = flattenWalletObject(
            data.transferGameWalletInfo
          ); //是把data.transferGameWalletInfo的值傳給flattenWalletObject函數
          dispatch(
            storeTransferWallet({ ...transferData, ...updateTransferWallet })
          ); //這個是把transferData和updateTransferWallet的值傳給storeTransferWallet函數 dispatch函數是用來觸發一個操作的，通常用於在前端應用程序中顯示通知或錯誤消息。它的參數是兩個字符串，第一個字符串是通知的內容，第二個字符串表示通知的類型，這裡是 "error"，表示錯誤通知。
        })
        .catch((err) => {})
        .finally(() => {
          setTransferLoading(false);
        });
    }

    return;
  }, [paymentToObject.platform, paymentToObject.transferWalletType, trigger]); //意思是如果paymentToObject.platform和paymentToObject.transferWalletType的值發生變化時才會執行useEffect裡面的代碼

  const main = useMemo(() => {
    return {
      label: i18n("main_wallet"),
      value: "main_wallet",
      balance: memberData.vpoint,
    };
  }, [memberData.vpoint, trigger]);

  const handleSwitchWallet = () => {
    setPayment({
      from: payment.to,
      to: payment.from,
    });
    setAmount(0);
  };

  const walletList = useMemo(() => {
    const list = [...singleWalletList, ...transferWalletList]; //创建了一个名为 list 的数组，它是由两个数组 singleWalletList 和 transferWalletList 合并而来的。这个数组包含了单钱包列表和转账钱包列表的所有元素
    const wallet = list.map((item) => {
      return {
        label: `${item.label?.toUpperCase()}${
          item.type ? `(${item.type})` : ""
        }`, //label 属性的值被转换成大写字母，使用 .toUpperCase() 方法。如果元素具有 type 属性，那么将 type 包含在括号中，否则为空字符串。
        value: item.label, //value 属性被设置为原始元素的 label 属性的值
        balance: item.balance, //balance 属性被设置为原始元素的 balance 属性的值
      };
    });
    return [main, ...wallet]; //函数返回一个新的数组，其中包括 main 和上一步骤中映射得到的所有 wallet 对象。这个数组作为 walletList 的最终值。
  }, [singleWalletList, transferWalletList, payment, main]);
  //useMemo 中的依赖项列表包括了 singleWalletList、transferWalletList 和 payment。只有在这些依赖项中的任何一个发生变化时，useMemo 才会重新计算 walletList。而在其他情况下，它将返回之前计算的结果，从而减少不必要的计算。

  const paymentBalance = useMemo(() => {
    const fromPayment = walletList.find((item) => {
      return item.value === payment.from;
    });
    //用find函数来查找满足指定条件的第一个数组元素，并将其存储在 fromPayment 变量中。：检查walletList数组內的元素的 value 属性是否等于 payment.from。如果找到了满足条件的元素（即元素的 value 等于 payment.from），find 函数将停止遍历，并将该元素存储在 fromPayment 变量中。如果没有找到满足条件的元素，fromPayment 将为 undefined，表示没有找到匹配的元素。
    const toPayment = walletList.find((item) => {
      return item.value === payment.to;
    });
    return {
      from: fromPayment?.balance, //这行代码设置对象的 from 属性的值为 fromPayment 变量的 balance 属性的值。它使用了可选链操作符 ?.，表示在 fromPayment 为 undefined 或 null 时，不会引发错误，而是将 from 设置为 undefined。这是一种安全的方式来访问可能不存在的属性。
      to: toPayment?.balance,
    };
  }, [payment, walletList]); //這個是把payment.from和payment.to的值存儲在paymentBalance變量中。用useMemo去把payment.from和payment.to的值存儲在paymentBalance變量中。useMemo的第二個參數是一個陣列，包含了影響此useMemo的依賴項。在這裡，useMemo會在payment發生變化時執行。

  const toWalletList = useMemo(() => {
    if (payment.from !== "main_wallet") {
      return walletList.filter((item) => {
        return item.value === "main_wallet";
      });
    }
    return walletList.filter((item) => {
      return payment.from !== item.value;
    });
  }, [payment.from, transferWalletList, payment.to]);

  const getGameProportion = useMemo(() => {
    const from = splitString(payment.from);
    const to = splitString(payment.to);

    const exchangeRate = () => {
      if (payment.from === "main_wallet") {
        return `${parseInt(from?.exchangeRate || to?.exchangeRate)}:1`;
      } else {
        return `1:${parseInt(to?.exchangeRate || from?.exchangeRate)}`;
      }
    };

    return {
      exchangeRate: exchangeRate(),
      rate: parseInt(from?.exchangeRate || to?.exchangeRate),
    };
  }, [payment.from, payment.to, transferWalletList]);

  const handleSubmit = (e) => {
    e.preventDefault(); //阻止表單提交的預設行為 先檢查下面的條件 如果條件成立就會執行下面的代碼 如果條件不成立就會跳過下面的代碼 不提交表單

    //检查用户输入的充值金額或提款金額小於最小金額DEPOSIT_AMOUNT_MIN。如果太低，会调用 dispatch 函数发送一条错误通知，然后返回，不继续执行后续代码。
    if (to == "GR" || to == "EVOPLEY" || to == "BBIN") {
      dispatch(triggerGame());
    }
    setButtonLoading(true);
    const successCallback = (data) => {
      dispatch(
        notice({ title: i18n_backendRes("TransferSuccess"), type: "success" })
      );
      dispatch({ type: "GET_BALANCE" });
      setAmount(0);

      setTrigger(!trigger);
    }; //把then中的成功回調函數提取出來，並命名為successCallback
    const finallyCallback = () => {
      setButtonLoading(false);
      setIsConfirm(false);
      setAmount(0);
    };

    const resultAmount = () => {
      if (payment.from === "main_wallet") {
        return amount;
      } else {
        return amount * getGameProportion?.rate;
      }
    };

    if (payment.from === "main_wallet") {
      //主钱包轉到游戏钱包
      //条件语句检查 payment.from 的值是否为 "main_wallet"。如果是，它执行以下代码块：
      if (paymentToObject.platformMode === "transfer") {
        transferWalletToGame({
          platformUid: paymentToObject.platform,
          platformType: paymentToObject.transferWalletType,
          depositAmount: resultAmount(),
        }) //調用transferWalletToGame函數 把paymentToObject.platform、paymentToObject.transferWalletType和amount的值傳給transferWalletToGame函數
          .then(successCallback) //這裡的then是promise的then 呼叫成功回調函數successCallback 避免重複代碼
          .finally(() => {
            finallyCallback();
          });
        //如果 paymentToObject.platformMode 为 "transfer"，则执行一个名为 transferWalletToGame 的函数，将一些参数传递给它，然后使用 .then() 处理成功的回调。这部分代码可能是将钱从主钱包转移到游戏中的特定子钱包的逻辑。成功后，它会打印数据到控制台，并发送一条成功的通知。
      } else {
        //single api 單一錢包模式 主钱包轉到游戏钱包
        singleWalletToGame({
          platformUid: paymentToObject.platform,
          platformType: paymentToObject.transferWalletType,
          depositAmount: resultAmount(),
        })
          .then(successCallback)
          .finally(() => {
            finallyCallback();
          });
      }
    } else {
      // game to wallet 遊戲錢包到主帳戶
      if (paymentToObject.platformMode === "transfer") {
        transferGameToWallet({
          platformUid: paymentToObject.platform,
          platformType: paymentToObject.transferWalletType,
          withdrawAmount: resultAmount(),
        })
          .then(successCallback)
          .finally(() => {
            finallyCallback();
          });
      } else {
        //single api 單一錢包模式 遊戲錢包到主帳戶
        singleGameToWallet({
          platformUid: paymentToObject.platform,
          platformType: paymentToObject.transferWalletType,
          depositAmount: resultAmount(),
        })
          .then(successCallback)
          .finally(() => {
            finallyCallback();
          });
      }
    }
  };

  return (
    <form
      onSubmit={(e) => {
        handleSubmit(e);
      }}
      className="flex items-center gap-[10px]"
    >
      <section className="h-full w-[30px]">
        <div className="flex flex-col items-center justify-between relative">
          <MdOutlineCircle className="text-green-500" />
          <div className="dashed-line"></div>
          <MdOutlineCircle className="text-red-500" />
          <div className="w-[20px] h-[20px] rounded-full bg-main-color flex items-center justify-center absolute absolute-center">
            <TbTransferVertical className="text-white" />
          </div>
        </div>
      </section>
      <section className="flex flex-col gap-[10px] w-full text-transaction-text">
        <section className="flex flex-col items-start justify-between">
          <div className="w-full">
            <span className="flex dark:text-base-light-white items-center gap-[5px]">
              {i18n("selectPayment")}
              <span className="text-sm">
                {i18n("balance")}：
                {transferLoading ? (
                  <Spinner variant={`brandPrimary`} size="xl" />
                ) : (
                  formatNumber(paymentBalance.from)
                )}
              </span>
            </span>
            <OriginInputCustom
              onChange={(e) => {
                if (e.target.value !== "main_wallet") {
                  setPayment({ from: e.target.value, to: "main_wallet" });
                  return;
                }
                if (
                  e.target.value === "main_wallet" &&
                  payment.to === "main_wallet"
                ) {
                  setPayment({ from: e.target.value, to: "WM" });
                  return;
                }
                setPayment({ ...payment, from: e.target.value });
              }}
              disabled={activeSelect ? false : gameUid}
              value={payment.from}
              name="from"
              inputType="select"
              selectOption={walletList}
            />
          </div>
          <div className="flex items-center w-full  justify-center text-xl">
            <TbTransferVertical
              onClick={() => {
                setRotate(!rotate);
                handleSwitchWallet();
              }}
              className={`mt-[15px] ${
                rotate ? "rotate-180	" : ""
              } shadow border-main-color text-main-color transition duration-300 rounded-full p-[3px] border border-[#B6BBC4] text-3xl cursor-pointer text-[#B6BBC4]`}
            />
          </div>
          <div className="w-full">
            <span className="flex items-center dark:text-base-light-white gap-[5px]">
              {i18n("depositTo")}
              <span className="text-sm">
                {i18n("balance")}：
                {transferLoading ? (
                  <Spinner variant={`brandPrimary`} size="xl" />
                ) : (
                  formatNumber(paymentBalance.to)
                )}
              </span>
            </span>
            <OriginInputCustom
              onChange={(e) => {
                setPayment({ ...payment, to: e.target.value });
              }}
              value={payment.to}
              disabled={activeSelect ? false : !payment.from || gameUid}
              name="to"
              inputType="select"
              selectOption={toWalletList}
            />
          </div>
        </section>
        {payment.from !== "" && payment.to !== "" ? (
          <section className="flex flex-col">
            <span className="text-red-500">
              *{i18n("gamePointProportion")}
              {getGameProportion?.exchangeRate}
            </span>
          </section>
        ) : null}
        <Divider className="!my-[5px]" />
        <div>
          <div className="flex gap-[3px]">
            <span className="dark:text-base-light-white">
              {i18n("amounts")}
            </span>
            <TransferLimitHint />
          </div>
          <OriginInputCustom
            onBlur={(e) => {
              if (e.target.value < 0) {
                setAmount(0);
                return;
              }
              const rate = DEPOSIT_AMOUNT_MIN / getGameProportion?.rate / 10;
              if (payment?.from === "main_wallet") {
                setAmount(Math.floor(e.target.value / 1000) * 1000);
              } else {
                if (rate == 1) {
                  setAmount(Math.floor(e.target.value / 1) * 1);
                } else if (rate == 100) {
                  setAmount(Math.floor(e.target.value / 100) * 100);
                } else if (rate == 1000) {
                  setAmount(Math.floor(e.target.value / 1000) * 1000);
                }
              }
            }}
            name={"amount"}
            value={amount}
            onChange={(e) => {
              setAmount(e.target.value); //意思是把用戶輸入的值存儲在amount變量中
            }}
            placeholder={`${i18n("atLeast", {
              min_amount:
                payment?.from === "main_wallet"
                  ? 1000
                  : DEPOSIT_AMOUNT_MIN / getGameProportion?.rate,
            })}`}
            min={DEPOSIT_AMOUNT_MIN / getGameProportion?.rate / 10}
            max={paymentBalance.from}
            type="number"
            style={{ marginBottom: "10px" }}
          />
          {paymentBalance.from > 0 && (
            <>
              <div className="grid grid-cols-2 w-full px-[5px] text-base-light-dark dark:text-base-light-white">
                <div className="flex justify-start">
                  <span
                    onClick={() => {
                      setAmount(0);
                    }}
                    className="cursor-pointer text-xs text-white rounded-[5px] bg-main-color py-[2px] px-[6px] flex items-center"
                  >
                    {i18n("reset")}
                  </span>
                </div>

                <div className="flex justify-end">
                  <div
                    onClick={() => {
                      const rate =
                        DEPOSIT_AMOUNT_MIN / getGameProportion?.rate / 10;
                      if (payment?.from === "main_wallet") {
                        setAmount(
                          Math.floor(paymentBalance.from / 1000) * 1000
                        );
                      } else {
                        if (rate == 1) {
                          setAmount(Math.floor(paymentBalance.from / 1) * 1);
                        } else if (rate == 100) {
                          setAmount(
                            Math.floor(paymentBalance.from / 100) * 100
                          );
                        } else if (rate == 1000) {
                          setAmount(
                            Math.floor(paymentBalance.from / 1000) * 1000
                          );
                        }
                      }
                    }}
                    className="cursor-pointer text-xs text-white rounded-[5px] bg-main-color py-[2px] px-[6px] flex items-center"
                  >
                    <span>{i18n("all")}</span>
                  </div>
                </div>
              </div>
              <Slider
                value={amount}
                min={0}
                max={paymentBalance.from}
                step={
                  getGameProportion?.rate == 1
                    ? 1000
                    : payment?.to === "main_wallet"
                    ? getGameProportion?.rate / getGameProportion?.rate
                    : getGameProportion?.rate
                }
                onChange={(value) => {
                  setAmount(value);
                }}
              >
                <SliderTrack bg="brand.500">
                  <SliderFilledTrack bg="tomato" />
                </SliderTrack>
                <SliderThumb boxSize={6} />
              </Slider>
            </>
          )}
        </div>
        <section className="flex gap-[10px] justify-between">
          <Button
            isLoading={buttonLoading}
            isDisabled={buttonLoading || amount === 0}
            onClick={() => {
              if (isGameBox) {
                setIsConfirm(true);
                return;
              }
            }}
            type={isGameBox ? "button" : "submit"}
            size="lg"
            variant="brandPrimary"
          >
            {i18n("submit")}
          </Button>
        </section>
      </section>
      {isConfirm && (
        <CommonConfirmModal
          size="mini"
          modalOpen={isConfirm}
          setModalOpen={setIsConfirm}
          onConfirm={(e) => {
            handleSubmit(e);
          }}
          modalProps={{
            title: i18n("notice"),
            okButtonText: i18n("confirm"),
            cancelButtonText: i18n("cancel"),
            buttonLoading: buttonLoading,
            content: (
              <div className="flex flex-col gap-[3px]">
                <span>
                  {payment.from === "main_wallet"
                    ? i18n("main_wallet")
                    : payment.from}{" "}
                  {i18n("depositTo")}{" "}
                  {payment.to === "main_wallet"
                    ? i18n("main_wallet")
                    : payment.to}
                </span>
                <span>
                  {i18n("amount")}：{amount}
                </span>
                {to == "GR" || to == "EVOPLEY" || to == "BBIN" ? (
                  <span className="text-red-500 text-sm">
                    ({i18n("transferHint")})
                  </span>
                ) : null}
              </div>
            ),
          }}
        ></CommonConfirmModal>
      )}
    </form>
  );
};

export default TabTransfer;
