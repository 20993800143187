import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Text,
  Heading,
  VStack,
  Input,
  Icon,
  Grid,
  Flex,
  useColorModeValue,
  Divider,
} from "@chakra-ui/react";
import CustomInput from "../InputComponents/customInput";
import { MdSearch } from "react-icons/md";
import { FaUser } from "react-icons/fa";
import { BiTransferAlt } from "react-icons/bi";
import { IoShieldCheckmark } from "react-icons/io5";
import AccountIssues from "./helpCenter/account_issues";
import TransactionIssues from "./helpCenter/transaction_issues";
import SecurityConcern from "./helpCenter/security_concern";
import { Link } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa6";

const tab = [
  { label: "Account Issues", icon: FaUser, text: "Forgot Your Password?" },
  {
    label: "Transaction Issues",
    icon: BiTransferAlt,
    text: "Deposit and Withdrawal Strategy",
  },
  {
    label: "Security Concerns",
    icon: IoShieldCheckmark,
    text: "Is My Basic Information Secure？",
  },
];

const HelpCenter = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`help-center.${key}`);

  const [selectTab, setSelectTab] = useState(tab[0]?.label);

  const boxBg = useColorModeValue("light.100", "dark.100");

  const components = useMemo(() => {
    switch (selectTab) {
      case "Account Issues":
        return <AccountIssues />;
      case "Transaction Issues":
        return <TransactionIssues />;
      case "Security Concerns":
        return <SecurityConcern />;
      default:
        return <AccountIssues />;
    }
  }, [selectTab]);

  return (
    <Box
      w="full"
      rounded="5"
      p="10px"
      py="16px"
      bg="base.light.white"
      _dark={{ bg: "base.light.dark" }}
      mb="15px"
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap="15px"
      fontSize="medium"
    >
      <Flex w="100%" justifyContent="flex-start">
        <Link to={-1}>
          <Icon as={FaArrowLeft} fontSize="2xl" />
        </Link>
      </Flex>
      <Heading
        my="2"
        mt="2"
        color="brand.500"
        fontSize={{ base: "xl", md: "3xl" }}
        fontWeight="semibold"
      >
        {i18n("Have problems? We are here to help!")}
      </Heading>
      {/* <CustomInput
        props={{
          icon: <Icon as={MdSearch} fontSize="xl" />,
          placeholder: "Search for help",
        }}
      /> */}
      <Divider my="10px" />
      <Grid
        w="100%"
        gap={{
          base: "10px",
          md: "20px",
        }}
        templateColumns={"repeat(3, minmax(0, 1fr))"}
      >
        {tab.map((item) => {
          return (
            <Flex
              direction={`column`}
              bg={boxBg}
              borderRadius={`10px`}
              py="15px"
              px="15px"
              gap="10px"
              onClick={() => {
                setSelectTab(item.label);
              }}
              cursor={`pointer`}
              border={selectTab == item?.label ? "1px solid" : " "}
              borderColor={selectTab == item?.label ? "brand.500" : " "}
            >
              <Icon color="brand.500" as={item.icon} fontSize="40px" />
              <Text
                fontSize={{
                  base: "md",
                  md: "xl",
                }}
                fontWeight={`semibold`}
                as="h5"
              >
                {i18n(item.label)}
              </Text>
              <Text
                fontSize={{
                  base: "sm",
                  md: "md",
                }}
                color="special.1"
                fontWeight={`medium`}
                as="h5"
              >
                {i18n(item.text)}
              </Text>
            </Flex>
          );
        })}
      </Grid>
      <Box w="100%" bg={boxBg} borderRadius={`10px`} p="15px">
        {components}
      </Box>
    </Box>
  );
};

export default HelpCenter;
