import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { closeColorMode } from "../../../redux/action/member/memberAction";
import ThemeTab from "./themeTab";
import ThemeTabSelector from "./themeTabSelector";
import { useTranslation } from "react-i18next";

const ColorModePage = () => {
  const { t } = useTranslation();
  const i18n_common = (key) => t(`common.${key}`);
  const dispatch = useDispatch();

  const bgColor = useColorModeValue("white", "#262D47");

  const onClose = async () => {
    await dispatch(closeColorMode());
  };

  return (
    <Modal
      isCentered
      onClose={onClose}
      isOpen={true}
      p="5px"
      variant={`brandPrimary`}
      size="xs"
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent h={250} sx={{ bg: bgColor + " !important" }}>
        <ModalHeader>{i18n_common("color-mode")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody h={200}>
          <Box
            as="section"
            display="flex"
            flexDirection={`column`}
            gap="5px"
            borderRadius={`common`}
            p="8px"
            h="100%"
          >
            <ThemeTabSelector />
          </Box>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ColorModePage;
