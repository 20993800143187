import { useTranslation } from "react-i18next";
import { COMISSION_RULES, formatTurnoverForDisplay } from "./Rule";
import i18next from "i18next";
import { Box, Flex, useColorModeValue } from "@chakra-ui/react";

const RuleTable = ({ special }) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`recommend.${key}`);

  const textColor = useColorModeValue("dark.200", "light.200");

  return (
    <Box as="section">
      <Box
        border="1px solid"
        borderColor="special.1"
        borderRadius={`common`}
        color={textColor}
        mb="15px"
      >
        <Flex borderBottom={"1px solid"} borderColor="special.1">
          <div className="flex-1 border-r p-2 text-center">{i18n("level")}</div>
          <div className="flex-1 border-r p-2 text-center">
            {i18n("betAcceptance")}
          </div>
          <div className="flex-1 p-2 text-center">{i18n("backMoney")}</div>
        </Flex>
        <Flex borderBottom={"1px solid"} borderColor="special.1">
          <div className="flex-1 border-r p-2 text-center">1</div>
          <div className="flex-1 border-r p-2 text-center">{i18n("800")}</div>
          <div className="flex-1 p-2 text-center">0.1 %</div>
        </Flex>
        <Flex borderBottom={"1px solid"} borderColor="special.1">
          <div className="flex-1 border-r p-2 text-center">2</div>
          <div className="flex-1 border-r p-2 text-center">{i18n("8000")}</div>
          <div className="flex-1 p-2 text-center">0.2 %</div>
        </Flex>
        <Flex borderBottom={"1px solid"} borderColor="special.1">
          <div className="flex-1 border-r p-2 text-center">3</div>
          <div className="flex-1 border-r p-2 text-center">
            {i18n("2billion")}
          </div>
          <div className="flex-1 p-2 text-center">0.3 %</div>
        </Flex>
        <Flex borderBottom={"1px solid"} borderColor="special.1">
          <div className="flex-1 border-r p-2 text-center">4</div>
          <div className="flex-1 border-r p-2 text-center">
            {i18n("5billion")}
          </div>
          <div className="flex-1 p-2 text-center">0.4 %</div>
        </Flex>
        <Flex borderBottom={"1px solid"} borderColor="special.1">
          <div className="flex-1 border-r p-2 text-center">5</div>
          <div className="flex-1 border-r p-2 text-center">
            {i18n("12billion")}
          </div>
          <div className="flex-1 p-2 text-center">0.5 %</div>
        </Flex>
        <Flex borderBottom={"1px solid"} borderColor="special.1">
          <div className="flex-1 border-r p-2 text-center">6</div>
          <div className="flex-1 border-r p-2 text-center">
            {i18n("25billion")}
          </div>
          <div className="flex-1 p-2 text-center">0.55 %</div>
        </Flex>
        <Flex borderBottom={"1px solid"} borderColor="special.1">
          <div className="flex-1 border-r p-2 text-center">7</div>
          <div className="flex-1 border-r p-2 text-center">
            {i18n("51billion")}
          </div>
          <div className="flex-1 p-2 text-center">0.6 %</div>
        </Flex>
        <Flex borderBottom={"1px solid"} borderColor="special.1">
          <div className="flex-1 border-r p-2 text-center">8</div>
          <div className="flex-1 border-r p-2 text-center">
            {i18n("103billion")}
          </div>
          <div className="flex-1 p-2 text-center">0.65 %</div>
        </Flex>
        <Flex borderBottom={"1px solid"} borderColor="special.1">
          <div className="flex-1 border-r p-2 text-center">9</div>
          <div className="flex-1 border-r p-2 text-center">
            {i18n("207billion")}
          </div>
          <div className="flex-1 p-2 text-center">0.7 %</div>
        </Flex>
        <Flex>
          <div className="flex-1 border-r p-2 text-center">10</div>
          <div className="flex-1 border-r p-2 text-center">
            {i18n("400billion")}
          </div>
          <div className="flex-1 p-2 text-center">0.8 %</div>
        </Flex>
      </Box>
    </Box>
  );
};

export default RuleTable;
