import { Box } from "@chakra-ui/react";
import { stylesConfig } from "../../../helpers/stylesConfig";

const PromoCardMobile = () => {
  return (
    <Box
      px="10px"
      display={{
        base: "block",
        sm: "none",
      }}
      mb="10px"
    >
      <Box
        h="120px"
        w="100%"
        borderRadius={`15px`}
        style={{
          backgroundImage: `url(${stylesConfig.mainLogo})`,
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      ></Box>
    </Box>
  );
};

export default PromoCardMobile;
