import React from "react";

const LeaderBoardWrapper = ({ children }) => {
  return process.env.REACT_APP_SECRET_TYPE == "local"
    ? children
    : window?.profit_ranking_status == 1
    ? children
    : null;
};

export default LeaderBoardWrapper;
