import React, { useMemo } from "react";
import { useSelector } from "react-redux";

const useCurrencySymbol = ({ origin = false, custom_currency = "" } = {}) => {
  const walletCurrency = useSelector((state) => state.isWalletCurrency);
  const isCurrencyExchange = useSelector((state) => state.isCurrencyExchange);

  const symbol = useMemo(() => {
    const compare = custom_currency || walletCurrency;
    if (origin) {
      return isCurrencyExchange?.[compare]?.origin_symbol;
    } else {
      return isCurrencyExchange?.[compare]?.symbol;
    }
  }, [walletCurrency, isCurrencyExchange, origin, custom_currency]);
  return symbol;
};

export default useCurrencySymbol;
