import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CURRENCY_MENU } from "../../../i18n/config";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { storeGameCurrency } from "../../../redux/action/member/memberAction";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useMemo } from "react";
import { Flex, Select, Text } from "@chakra-ui/react";
import { isNotEmptyObject } from "@chakra-ui/utils";

const CurrencySettings = ({ className, selectProps } = {}) => {
  const dispatch = useDispatch(); //返回一個可以發送action到redux store的dispatch函數 代表把action傳到reducer

  const location = useLocation(); //取得網址的資訊

  const gameConfig = useSelector((state) => state.isGameConfig); //從redux store中從isGameConfig中取gameConfig的值
  const gameCurrencyNow = useSelector((state) => state.isGameCurrency); //從redux裡取isWalletCurrency的值 存到gameCurrencyNow

  const { currencyCode } = gameConfig || {};

  const filteredCurrencyMenu = useMemo(() => {
    return currencyCode;
  }, [currencyCode]);

  const handleChange = (e) => {
    dispatch(storeGameCurrency(e.target.value)); // 把gameCurrentCurrency的值傳到storeGameCurrency內
  };

  useEffect(() => {
    if (isNotEmptyObject(gameConfig)) {
      if (currencyCode?.length !== 0) {
        if (gameCurrencyNow) {
          if (gameConfig?.currencyCode?.indexOf(gameCurrencyNow) === -1) {
            dispatch(storeGameCurrency(currencyCode?.[0]));
          } else {
            dispatch(storeGameCurrency(gameCurrencyNow));
          }
        } else {
          dispatch(storeGameCurrency(currencyCode?.[0]));
        }
      }
    }
  }, [gameCurrencyNow, currencyCode, gameConfig]);

  return (
    <Select
      minW="100px"
      display="flex"
      value={gameCurrencyNow}
      onChange={handleChange}
      variant={`brandPrimary`}
      {...selectProps}
    >
      {filteredCurrencyMenu?.map((item) => {
        return <option value={item}>{item}</option>;
      })}
    </Select>
  );
};

export default CurrencySettings;
