import { useMemo } from "react";
import GameConfirmV2 from "./gameConfirm_v2";
import GameConfirm from "./gameConfirm";
import GameConfirmV3 from "./gameConfirm_v3";
import { WEB_LAYOUT } from "../../../constant";

const GameConfirmSelector = () => {
  const gameConfirm = useMemo(() => {
    switch (WEB_LAYOUT) {
      case "v2":
        return <GameConfirmV2 />;
      case "v3":
        return <GameConfirmV3 />;
      default:
        return <GameConfirm />;
    }
  }, [WEB_LAYOUT]);
  return gameConfirm;
};

export default GameConfirmSelector;
