import React from "react";
import { filterNumberColor, filterSign } from "../../../utils/formatNumber";
import LoadingIcon from "../../../components/Loading/LoadingIcon";
import { useTranslation } from "react-i18next";
import {
  Box,
  Divider,
  Flex,
  Tag,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import CopyIcon from "../../../components/CopyComponent/CopyIcon";
import dayjs from "dayjs";
import useVipInfo from "../../../hook/useVipInfo";

const BufferBetLogDashboard = ({
  index,
  gameName,
  bufferAmount = 0,
  lockTime = "-",
  unlockTime = "-",
  orderNumber = "-",
  currency,
  status,
  updatedAt = "-",
}) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`betRecord.${key}`);

  const cardBg = useColorModeValue("light.100", "dark.100");
  const textColor = useColorModeValue("dark.100", "light.100");

  const { current_symbol } = useVipInfo();

  return (
    <Box
      index={index}
      as="section"
      bg={cardBg}
      borderRadius={`common`}
      py="common-y"
      px="common-x"
    >
      <Flex alignItems={`flex-start`} justifyContent={`space-between`}>
        <Text
          color={textColor}
          fontSize={`lg`}
          fontWeight={`semibold`}
          display="flex"
          alignItems={`center`}
        >
          {gameName}({currency})
        </Text>
        <Tag colorScheme={`green`} variant="solid">
          {i18n(status)}
        </Tag>
      </Flex>
      <Divider my="3px" />
      {/* <Flex alignItems={`center`}> */}
      <Flex alignItems={`center`}>
        <Text fontSize={`xs`} color="special.1">
          {i18n("orderNumber")}：
        </Text>
        <Flex gap="5px" alignItems={`center`}>
          <Text fontSize={`sm`} fontWeight={`bold`} color={textColor}>
            {orderNumber}
          </Text>
          <CopyIcon copyLink={orderNumber} />
        </Flex>
      </Flex>

      <Flex alignItems={`center`}>
        <Text fontSize={`xs`} color="special.1">
          {i18n("lock_time")}：
        </Text>
        <Text fontSize={`xs`} color={textColor}>
          {lockTime}
        </Text>
      </Flex>
      <Flex alignItems={`center`}>
        <Text fontSize={`xs`} color="special.1">
          {i18n("unlock_time")}：
        </Text>
        <Text fontSize={`xs`} color={textColor}>
          {unlockTime}
        </Text>
      </Flex>
      <Flex alignItems={`center`}>
        <Text fontSize={`xs`} color="special.1">
          {i18n("updated_at")}：
        </Text>
        <Text fontSize={`xs`} color={textColor}>
          {dayjs(updatedAt).format("YYYY-MM-DD HH:mm:ss")}
        </Text>
      </Flex>
      {/* </Flex> */}
      <Divider my="3px" />
      <Flex justifyContent={`space-between`}>
        <Flex direction={`column`} color="special.1">
          <Text fontSize={`xs`}>{i18n("bufferAmount")}</Text>
          <Text color={textColor} fontWeight={`semibold`}>
            {bufferAmount} {current_symbol}
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
};

export default BufferBetLogDashboard;
