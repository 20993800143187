import React, { useMemo } from "react";
import { CURRENCY_SYMBOL } from "../../../constant";
import {
  filterNumberColor,
  filterSign,
  formatNumber,
} from "../../../utils/formatNumber";
import LoadingIcon from "../../../components/Loading/LoadingIcon";
import { useTranslation } from "react-i18next";
import {
  Box,
  Divider,
  Flex,
  Tag,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import TransactionTag from "./TransactionTag";
import CopyIcon from "../../../components/CopyComponent/CopyIcon";
import i18next from "i18next";
import { languageMapping } from "../../../i18n/config";
import useVipInfo from "../../../hook/useVipInfo";

const EventRecordDashboard = ({ method, props }) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`transaction.${key}`);

  const cardBg = useColorModeValue("light.100", "dark.100");
  const textColor = useColorModeValue("dark.100", "light.100");

  const { current_symbol } = useVipInfo();

  const {
    created_at,
    transfer_point,
    transfer_after,
    status = 2,
    title,
    remark = "",
    type,
  } = props || {};

  const event_title = useMemo(() => {
    return title?.[languageMapping(i18next.language)] || title?.en_US;
  }, [i18next, title]);

  return (
    <Box
      as="section"
      bg={cardBg}
      borderRadius={`common`}
      py="common-y"
      px="common-x"
    >
      <Flex justifyContent={`space-between`} gap="10px" alignItems={`start`}>
        <Text
          fontSize={`lg`}
          fontWeight={`semibold`}
          mb="7px"
          display="flex"
          alignItems={`center`}
          color="brand.500"
          className="text-overflow"
        >
          {event_title}
        </Text>
        <TransactionTag status={status} />
      </Flex>
      <Flex alignItems={`center`}>
        <Text fontSize={`sm`} color="special.1">
          {i18n("transactionTime")}：
        </Text>
        <Text fontSize={`sm`} fontWeight={`bold`} color="special.1">
          {created_at}
        </Text>
      </Flex>
      <Flex alignItems={`center`}>
        <Text fontSize={`sm`} color="special.1">
          {i18n("memo")}：
        </Text>
        <Text fontSize={`sm`} fontWeight={`bold`} color="special.1">
          {remark || "-"}
        </Text>
      </Flex>
      <Divider my="5px" />
      {status == 2 && (
        <Flex justifyContent={`space-between`}>
          <Flex direction={`column`} color="special.1">
            <Text fontSize={`sm`}>{i18n("reward")}</Text>
            <Text color="special.1" fontWeight={`bold`}>
              {formatNumber(transfer_point)}
              {/* {current_symbol} */}
            </Text>
          </Flex>

          <Flex direction={`column`} color="special.1">
            <Text fontSize="sm">{i18n("lastBalance")}</Text>
            <Text color="special.1" fontWeight={`bold`}>
              {formatNumber(transfer_after)} {current_symbol}
            </Text>
          </Flex>
        </Flex>
      )}
    </Box>
  );
};

export default EventRecordDashboard;
