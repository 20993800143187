import React, { useMemo } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { formatNumber } from "../../../utils/formatNumber";
import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { COMMON_WEB_PATH } from "../../../constant";
import useNextVipLevelConfig from "../../../hook/useNextVipLevelConfig";
import useVipInfo from "../../../hook/useVipInfo";

const ProgressCard = ({ currentLevel, currentWager }) => {
  const { t } = useTranslation();
  const i18n = (key, props) => t(`vip.${key}`, { ...props });

  const vipList = useSelector((state) => state.isVipDetail);

  const textColor = useColorModeValue("dark.100", "light.100");

  const { next_level_criteria_data } = useNextVipLevelConfig({
    level: currentLevel + 1,
  });

  const { current_symbol } = useVipInfo();

  return (
    <Flex alignItems="center" gap="10px">
      <Flex alignItems="center" gap="2.5">
        <Box>
          <Box position="relative" w="35px" h="35px">
            <CircularProgressbar
              className="!w-[35px]"
              styles={buildStyles({
                trailColor: "rgb(196 196 196) !important",
                pathColor: `rgba(${
                  vipList[currentLevel + 1]?.lineBackground
                },1)`,
              })}
              strokeWidth={6}
              value={
                (currentWager / next_level_criteria_data?.criteria_amount) * 100
              }
            />
            <img
              src={`${COMMON_WEB_PATH}/vip/vip${currentLevel + 1}_l.avif`}
              className="absolute absolute-center text-[#9D9D9D] w-[21px] h-[21px] text-2xl object-scale-down"
              alt="vipIcon"
            />
          </Box>
        </Box>
        <Text
          fontSize="xl"
          fontWeight="medium"
          color="base.light.dark"
          _dark={{ color: "baseLightWhite" }}
        >
          {vipList[currentLevel + 1]?.name?.toUpperCase()}
        </Text>
      </Flex>
      <Text
        as="span"
        color={textColor}
        letterSpacing={`tighter`}
        fontWeight={`medium`}
        fontSize="sm"
      >
        ({formatNumber(currentWager, { point: true })} {current_symbol}/{" "}
        {formatNumber(next_level_criteria_data?.criteria_amount, {
          point: false,
        })}{" "}
        {current_symbol}
        {i18n("bet")})
      </Text>
    </Flex>
  );
};

export default ProgressCard;
