import { useEffect } from "react";
import { Navigate, useNavigate } from "react-router";
import useCheckLogin from "../hook/useCheckLogin";
import { Box, Spinner, useColorModeValue } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const Authenticator = ({ children }) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`gamebox.${key}`);

  const navigate = useNavigate();

  const { isLogin, isLoading } = useCheckLogin();

  const bgColor = useColorModeValue("rgb(255,255,255,0.6)", "rgb(0,0,0,0.6)");

  // isLoading ? (
  //   <>
  //     {children}
  //     <Box
  //       position="fixed"
  //       top="0"
  //       left="0"
  //       display="flex"
  //       alignItems={"center"}
  //       justifyContent={`center`}
  //       w="100vw"
  //       h="100vh"
  //       backdropFilter={`blur(12px)`}
  //       zIndex={99999999}
  //       bg={bgColor}
  //     >
  //       <Spinner variant={`brandPrimary`} size={"xl"}></Spinner>
  //     </Box>
  //   </>
  // ) :
  return isLoading ? (
    children
  ) : isLogin ? (
    children
  ) : (
    <Navigate to="/signin" replace />
  );
};

export default Authenticator;
