import Cookies from "js-cookie";
import React from "react";
import { IoIosMoon, IoMdSunny } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { setTheme } from "../../../redux/action/action";
import {
  Button,
  Flex,
  Image,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { COMMON_WEB_PATH } from "../../../constant";

const ColorSwitcherV4 = ({ wordHidden, colorClassName }) => {
  const isDarkMode = useSelector((state) => state.isTheme === "dark");
  const { colorMode, toggleColorMode } = useColorMode();
  const baseBg = useColorModeValue("light.100", "#262D47");

  const dispatch = useDispatch();

  const toggleTheme = (mode) => {
    dispatch(setTheme(mode));
    if (mode !== colorMode) {
      toggleColorMode();
    }
    Cookies.set("color-mode", mode);
  };

  return (
    <Flex
      alignItems="center"
      bg={baseBg}
      p="3px"
      borderRadius="10px"
      w="100px"
      h="48px"
    >
      <Button
        role="group"
        borderRadius="10px"
        w="50%"
        h="full"
        p="0"
        minW="0"
        onClick={() => toggleTheme("light")}
        bg={colorMode === "light" ? "#F3F4FF" : "transparent"}
        border={colorMode === "light" ? "1px solid #6C5DD3" : "none"}
        _hover={{ bg: colorMode === "light" ? "#F3F4FF" : "gray.50" }}
        _active={{ bg: colorMode === "light" ? "#F3F4FF" : "gray.50" }}
        position="relative"
      >
        {/* 默認圖片 */}
        <Image
          src={`${COMMON_WEB_PATH}/v4-layout/menu-icon/${
            colorMode === "light" ? "sun1" : "sun2"
          }.webp`}
          w="20px"
          h="20px"
          transition="all 0.2s"
          position="absolute"
          _groupHover={{ opacity: 0 }}
        />
        {/* Hover 時顯示的圖片 */}
        <Image
          src={`${COMMON_WEB_PATH}/v4-layout/menu-icon/sun1.webp`}
          w="20px"
          h="20px"
          opacity={0}
          transition="all 0.2s"
          _groupHover={{ opacity: 1 }}
        />
        {/* <IoMdSunny
          color={colorMode === "light" ? "#6C5DD3" : "#A0AEC0"}
          size={20}
        /> */}
      </Button>
      <Button
        role="group"
        borderRadius="10px"
        w="50%"
        h="full"
        p="0"
        minW="0"
        onClick={() => toggleTheme("dark")}
        bg={colorMode === "dark" ? "#202741" : "transparent"}
        border={colorMode === "dark" ? "1px solid #6C5DD3" : "none"}
        _hover={{ bg: colorMode === "dark" ? "#F3F4FF" : "gray.50" }}
        _active={{ bg: colorMode === "dark" ? "#F3F4FF" : "gray.50" }}
        position="relative"
      >
        {/* 默認圖片 */}
        <Image
          src={`${COMMON_WEB_PATH}/v4-layout/menu-icon/${
            colorMode === "light" ? "moon2" : "moon1"
          }.webp`}
          w="20px"
          h="20px"
          transition="all 0.2s"
          position="absolute"
          _groupHover={{ opacity: 0 }}
        />
        {/* Hover 時顯示的圖片 */}
        <Image
          src={`${COMMON_WEB_PATH}/v4-layout/menu-icon/moon1.webp`}
          w="20px"
          h="20px"
          opacity={0}
          transition="all 0.2s"
          _groupHover={{ opacity: 1 }}
        />
        {/* <IoIosMoon
          color={colorMode === "dark" ? "#6C5DD3" : "#A0AEC0"}
          size={20}
        /> */}
      </Button>
    </Flex>
  );
};

export default ColorSwitcherV4;
