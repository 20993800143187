const SubMenuReducers = (
  state = {
    casino: false,
    "live-casino": false,
    sports: false,
    lottery: false,
    promotion: false,
  },
  action
) => {
  switch (action.type) {
    case "controlSubMenu":
      return {
        ...state,
        [action.payload]: !state[action.payload],
      };
    default:
      return state;
  }
};
export default SubMenuReducers;
