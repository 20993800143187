import React from "react";
import { useTranslation } from "react-i18next";

const C911 = () => {
  const { t } = useTranslation();
  const i18n = (key, props) => t(`gameRules.c911.${key}`, props);

  return (
    <div className="py-[10px]">
      <p className="font-bold">{i18n("title")}</p>
      <p className="font-bold">{i18n("note")}</p>
      <h2>{i18n("bankerRulesTitle")}</h2>
      <p>{i18n("bankerRulesDescription")}</p>
      <ul>
        <li>{i18n("bankerRulesList.rule1")}</li>
        <li>{i18n("bankerRulesList.rule2")}</li>
        <li>{i18n("bankerRulesList.rule3")}</li>
        <li>{i18n("bankerRulesList.rule4")}</li>
        <li>{i18n("bankerRulesList.rule5")}</li>
        <li>{i18n("bankerRulesList.rule6")}</li>
        <li>{i18n("bankerRulesList.rule7")}</li>
      </ul>
      <p>{i18n("bettingDistributionFormula")}</p>
      <h2>{i18n("diceGameRulesTitle")}</h2>
      <ul>
        <li>{i18n("diceGameRulesList.rule1")}</li>
        <li>{i18n("diceGameRulesList.rule2")}</li>
        <li>{i18n("diceGameRulesList.rule3")}</li>
        <li>{i18n("diceGameRulesList.rule4")}</li>
        <li>{i18n("diceGameRulesList.rule5")}</li>
        <li>{i18n("diceGameRulesList.rule6")}</li>
      </ul>
    </div>
  );
};

export default C911;
