export const thisImageNeedDark = (platformName = "", darkMode = false) => {
  const darkList = [
    "EVO",
    "IMSPRIBE",
    "SABA",
    "QTECH",
    "IMESPORTS",
    "EVOE",
    "YGG",
    "HACK",
  ];
  if (darkList.includes(platformName) && darkMode) {
    return `${platformName}_dark`;
  } else {
    return platformName;
  }
};
