import {
  Box,
  Button,
  Flex,
  Grid,
  Icon,
  Image,
  Text,
  chakra,
  shouldForwardProp,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AiFillDollarCircle } from "react-icons/ai";
import { BiMoneyWithdraw } from "react-icons/bi";
import { BsCashCoin } from "react-icons/bs";
import { FaHistory } from "react-icons/fa";
import { FaPiggyBank } from "react-icons/fa6";
import { MdHistory } from "react-icons/md";
import { TbPigMoney, TbReportAnalytics } from "react-icons/tb";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BalanceV2 from "../../Profile/components/balance_v2";
import { PiChartLineUpBold } from "react-icons/pi";
import { gameListFromBottom } from "../../../animateConstant";
import { isValidMotionProp, motion } from "framer-motion";
import BalanceV3 from "../../Profile/components/balance_v3";
import { GoPlus } from "react-icons/go";
import { COMMON_WEB_PATH } from "../../../constant";

const isCredit = process.env.REACT_APP_CREDIT == 1;

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const common_tab = [
  {
    icon: TbPigMoney,
    text: "Deposit",
    link: "deposit",
    // hidden: isCredit,
    useImage: true,
    image: {
      light: `${COMMON_WEB_PATH}/v4-layout/member-icon/deposit2.svg`,
      dark: `${COMMON_WEB_PATH}/v4-layout/member-icon/deposit3.svg`,
    },
  },
  {
    icon: BiMoneyWithdraw,
    text: "Withdraw",
    link: "withdraw",
    hidden: isCredit,
    useImage: true,
    image: {
      light: `${COMMON_WEB_PATH}/v4-layout/member-icon/withdraw2.svg`,
      dark: `${COMMON_WEB_PATH}/v4-layout/member-icon/withdraw3.svg`,
    },
  },
  {
    icon: TbReportAnalytics,
    text: "Bet Record",
    link: "betRecord",
  },
  {
    icon: MdHistory,
    text: "Transaction",
    link: "transaction",
    useImage: true,
    image: {
      light: `${COMMON_WEB_PATH}/v4-layout/member-icon/records2.svg`,
      dark: `${COMMON_WEB_PATH}/v4-layout/member-icon/records3.svg`,
    },
  },
  {
    icon: PiChartLineUpBold,
    text: "Rebate",
    link: "my-rebate",
    auth: true,
    hidden: !isCredit,
  },
];

const CommonSelectTabV4 = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);
  const i18n_profile = (key) => t(`profile.${key}`);

  const cardBg = useColorModeValue("light.250", "dark.250");
  const cardHoverBg = useColorModeValue("light.200", "dark.300");
  const textColor = useColorModeValue("light.100", "dark.100");
  const iconBg = useColorModeValue("#EFEFF5", "dark.200");
  const iconColor = useColorModeValue("#111", "#EFEFF5");
  const currentTheme = useColorModeValue("light", "dark");

  const location = useLocation();
  const navigate = useNavigate();

  const currentPath = useCallback(
    (target = "") => {
      const splitPathname = location.pathname.split("/");

      if (target) {
        return splitPathname.some((item) => item === target);
      }
    },
    [location.pathname]
  );
  return (
    <ChakraBox
      {...gameListFromBottom}
      gap="5px"
      as="section"
      borderRadius={`20px`}
      bg={cardBg}
      //shadow="0px 2px 5px #a2a2a2"
      overflow={"hidden"}
    >
      <Flex
        bg="brand.500"
        borderRadius={`20px`}
        //shadow="0px 2px 5px #a2a2a2"
        p="15px 10px"
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Flex
          color="light.100"
          alignItems={"center"}
          direction={`column`}
          gap="0px"
        >
          <Text fontSize="md" fontWeight={`500`}>
            {i18n_profile("Balance")}
          </Text>
          <BalanceV3
            balanceStyle={{
              color: "light.100",
            }}
            iconColor="light.100"
            fontSize="3xl"
          />
        </Flex>
      </Flex>

      <Grid
        gridTemplateColumns={`repeat(4, minmax(0, 1fr))`}
        gap="10px"
        overflow="hidden"
        px="20px"
        py="15px"
      >
        {common_tab
          ?.filter((item) => !item.hidden)
          ?.map((item) => {
            const active = currentPath(item.link);

            return (
              <Link className="overflow-hidden" to={`../profile/${item.link}`}>
                <Flex
                  role="group"
                  direction={`column`}
                  alignItems={`center`}
                  gap="5px"
                >
                  <Flex
                    direction={`column`}
                    gap="3px"
                    justifyContent={`center`}
                    alignItems={`center`}
                    // _groupHover={{
                    //   bg: cardHoverBg,
                    // }}
                    w="45px"
                    h="45px"
                    borderRadius={`full`}
                    bg={iconBg}
                    py="7px"
                    className="transition duration-100"
                  >
                    {item.useImage ? (
                      // 如果有圖片，就顯示圖片
                      <Image
                        src={item.image[currentTheme]}
                        alt={item.text}
                        w="24px"
                        h="24px"
                        objectFit="contain"
                        // filter={
                        //   item.filterRule === "reverse"
                        //     ? currentTheme === "light"
                        //       ? "none"
                        //       : "brightness(0)"
                        //     : currentTheme === "light"
                        //     ? "brightness(0)"
                        //     : "none"
                        // }
                      />
                    ) : (
                      // 如果沒有圖片，就用 icon
                      <Icon
                        as={item.icon}
                        fontSize={`2xl`}
                        color={iconColor}
                        // _groupHover={{
                        //   color: "brand.500",
                        // }}
                      />
                    )}
                  </Flex>
                  <Text
                    fontSize="xs"
                    letterSpacing={`tight`}
                    // _groupHover={{
                    //   fontWeight: `700`,
                    // }}
                    w="100%"
                    textAlign={`center`}
                    fontWeight={active ? `700` : "400"}
                    color={iconColor}
                  >
                    {i18n(item.text?.toLowerCase())}
                  </Text>
                </Flex>
              </Link>
            );
          })}
      </Grid>
    </ChakraBox>
  );
};

export default CommonSelectTabV4;
