import { useState } from "react";

import { BiMessageSquareAdd } from "react-icons/bi";
import { GiHamburgerMenu } from "react-icons/gi";
import { RiHomeSmileFill, RiMoneyDollarCircleLine } from "react-icons/ri";
import { IoGift, IoQrCode } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router";
import { loading, loadingDestroy, openMenu } from "../redux/action/action";
import { useDispatch } from "react-redux";
import { HiShare } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import { MdEventAvailable, MdHistory } from "react-icons/md";
import usePwaDetected from "../hook/usePwaDetected";
import {
  AbsoluteCenter,
  Box,
  Flex,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaHeadphonesAlt, FaUser } from "react-icons/fa";
import useDeviceType from "../hook/useDeviceType";
import { AiOutlineVerticalAlignTop } from "react-icons/ai";
import { openChat } from "../redux/action/member/memberAction";
import useCheckLogin from "../hook/useCheckLogin";
import { TbReportAnalytics } from "react-icons/tb";

const iconStyle = "text-2xl";

const isCredit = process.env.REACT_APP_CREDIT == 1;

const Footer = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`footer.${key}`);

  const [isFooterOpen, setIsFooterOpen] = useState("Home");
  const isPwa = usePwaDetected();

  const footerBg = useColorModeValue("light.100", "dark.100");
  const footerText = useColorModeValue("dark.300", "light.200");

  const { isLogin } = useCheckLogin();

  const deviceType = useDeviceType();

  const dispatch = useDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const FOOTER_LIST = [
    {
      icon: <GiHamburgerMenu className={iconStyle} />,
      text: "Menu",
    },
    {
      icon: <IoGift className={iconStyle} />,
      text: "Rewards",
      link: "profile/rewards",
      auth: true,
      hidden: isCredit,
    },
    {
      icon: <TbReportAnalytics className={iconStyle} />,
      text: "betRecord",
      link: "profile/betRecord",
      auth: true,
      hidden: !isCredit,
    },
    {
      icon: (
        <AbsoluteCenter
          w="55px"
          h="55px"
          borderBottomColor="second-brand.700"
          borderBottomWidth="2px"
          borderXColor="#da5200"
          borderXWidth="1px"
          borderTopColor="second-brand.500"
          borderTopWidth="0.5px"
          // bg="brand.500"
          bgGradient="linear(to-b, second-brand.500,second-brand.700)"
          display="flex"
          alignItems={`center`}
          justifyContent={`center`}
          borderRadius={`full`}
          className="home-icon-shadow"
        >
          <RiHomeSmileFill
            className={`${iconStyle} text-base-white dark:text-base-white text-4xl`}
          />
        </AbsoluteCenter>
      ),
      text: "home",
      link: "/",
    },
    {
      icon: <MdHistory className={iconStyle} />,
      text: "Hitory",
      link: "profile/transaction",
      auth: true,
      hidden: !isCredit,
    },
    {
      icon: <FaHeadphonesAlt className={iconStyle} />,
      text: "Refer",
      link: "profile/refer-code",
      auth: true,
      function: () => {
        dispatch(openChat());
      },
      hidden: isCredit,
    },
    {
      icon: <FaUser className={iconStyle} />,
      text: "Profile",
      link: deviceType === "Mobile" ? "profile" : "profile/data",
      auth: true,
    },
    // ...(isPwa
    //   ? [
    //       {
    //         icon: <MdEventAvailable className={iconStyle} />,
    //         text: "promotion",
    //         link: "promotion",
    //       },
    //     ]
    //   : [
    //       {
    //         icon: <FaUser className={iconStyle} />,
    //         text: "Profile",
    //         link: deviceType === "Mobile" ? "profile" : "profile/data",
    //       },
    //     ]),
    // {
    //   icon: <MdEventAvailable className={iconStyle} />,
    //   text: "promotion",
    //   link: "promotion",
    // },
  ];

  return (
    <Box as="footer" w="100%" position="fixed" bottom="0" left="0" zIndex={999}>
      <Box
        as="section"
        w="95%"
        h="72px"
        mb="10px"
        borderRadius={`full`}
        bg={footerBg}
        p="16px"
        display="grid"
        gridTemplateColumns="repeat(5, minmax(0, 1fr))"
        mx="auto"
        position="relative"
        className="footer-shadow"
      >
        {FOOTER_LIST?.filter((item) => !item.hidden)?.map((footer, index) => {
          return (
            <Flex
              alignItems="center"
              flexDirection={`column`}
              justifyContent={`center`}
              gap="5px"
              key={index}
              color={footerText}
              onClick={() => {
                setIsFooterOpen(footer.text);
                if (footer.function) {
                  footer.function();
                  return;
                }
                if (footer.auth && !isLogin) {
                  navigate("signin");
                } else if (footer.text === "Menu") {
                  dispatch(openMenu());
                } else {
                  dispatch(loading());

                  setTimeout(() => {
                    navigate(`${footer.link}`);
                    dispatch(loadingDestroy());
                  }, 200);
                }
              }}
            >
              {footer.icon}
              {/* <Text
              as="p"
              mb="0px"
              fontWeight={`medium`}
              fontSize={`sm`}
              w="100%"
              textAlign="center"
                className="text-overflow">
                {i18n(footer.text?.toLowerCase())}
              </Text> */}
            </Flex>
          );
        })}
      </Box>
    </Box>
  );
};

export default Footer;
