import React, { useEffect, useMemo, useState } from "react";
import GameCatalog from "././GameCatalog";
import { useSelector } from "react-redux";
import useCasinoType from "../hook/useCasinoType";
import { filterType } from "../hook/useGameListFilter";
import { getUserGameClickList } from "../api/getApi";
import useCheckLogin from "../hook/useCheckLogin";

const UserClickGameCatalog = ({
  swiperProps,
  dontFilter,
  hiddenButtonNavigator,
}) => {
  const [userClickGames, setUserClickGames] = useState([]);

  const { isLogin } = useCheckLogin();

  const casinoType = useCasinoType();

  const gameLink = useSelector((state) => state.isGameLink);

  useEffect(() => {
    const fetchApi = async () => {
      if (isLogin) {
        try {
          const data = await getUserGameClickList();
          setUserClickGames(data.userUserGameClick);
        } catch (error) {
          console.log(error);
        }
      }
    };
    fetchApi();
  }, [gameLink]);

  const clickedGames = useMemo(() => {
    return dontFilter
      ? userClickGames
      : filterType({
          gameList: userClickGames || [], // 检查userClickGames是否有数据，如果没有，使用空数组
          casinoType: casinoType,
        });
  }, [userClickGames, casinoType, dontFilter]);

  return (
    isLogin && (
      <GameCatalog
        forceShow
        list={clickedGames}
        mainTitle={"ClickedGames"}
        wrapperClassName={clickedGames.length === 0 && "hidden"}
        typeTitle={``}
        observeFalse
        itemClass="2xl:h-[155px] xl:h-[155px]"
        hiddenButtonNavigator={hiddenButtonNavigator}
        swiperProps={{
          customPerView: "game-box-layout",
        }}
      />
    )
  );
};

export default UserClickGameCatalog;
