import React, { useMemo } from "react";
import { WEB_LAYOUT } from "../../constant";
import BetRecord from "./BetRecord";
import BetRecordV4 from "./BetRecord_v4";

const BetRecordSelector = () => {
  const betRecord = useMemo(() => {
    switch (WEB_LAYOUT) {
      case "v2":
        return <BetRecord />;
      case "v3":
        return <BetRecord />;
      case "v4":
        return <BetRecordV4 />;
      default:
        return <BetRecord />;
    }
  }, [WEB_LAYOUT]);
  return betRecord;
};

export default BetRecordSelector;
