import { Box, Image } from "@chakra-ui/react";
import QRCode, { QRCodeCanvas } from "qrcode.react";
import React, { useEffect, useRef, useState } from "react";

const ReferQrcode = ({ value, className }) => {
  const qrRef = useRef(null);
  const [imgSrc, setImgSrc] = useState("");
  useEffect(() => {
    if (qrRef.current) {
      const canvas = qrRef.current.children[0];
      const image = canvas.toDataURL("image/png");
      setImgSrc(image);
    }
  }, [value]);
  return (
    <Box>
      <Box display="none" ref={qrRef}>
        <QRCodeCanvas value={value} />
      </Box>
      {imgSrc && (
        <Image
          w="128px"
          h="128px"
          className={`img-can-select`}
          {...className}
          src={imgSrc}
          alt="QR Code"
        />
      )}
    </Box>
  );
};

export default ReferQrcode;
