import { useMemo } from "react";
import LanguagePageV2 from "./LanguagePage_v2";
import LanguagePage from "./LanguagePage";
import { WEB_LAYOUT } from "../../../constant";
import LanguagePageV4 from "./LanguagePage_v4";

const LanguageVersionSelector = () => {
  const languagePage = useMemo(() => {
    switch (WEB_LAYOUT) {
      case "v2":
        return <LanguagePageV2 />;
      case "v3":
        return <LanguagePageV2 />;
      case "v4":
        return <LanguagePageV4 />;
      default:
        return <LanguagePage />;
    }
  }, [WEB_LAYOUT]);
  return languagePage;
};

export default LanguageVersionSelector;
