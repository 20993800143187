const LoadingStatusReducers = (state = true, action) => {
  switch (action.type) {
    case "startLoading":
      return (state = true);
    case "stopLoading":
      return (state = false);
    default:
      return state;
  }
};
export default LoadingStatusReducers;
