import React, { useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { routeMapping } from "../GameTypeSelector/utils/routeMapping";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import {
  AbsoluteCenter,
  Box,
  Flex,
  Icon,
  Image,
  Link,
  Text,
  chakra,
  shouldForwardProp,
  useColorMode,
} from "@chakra-ui/react";
import LazyImage from "../../Loading/lazyImage";
import { COMMON_WEB_PATH } from "../../../constant";
import { thisImageNeedDark } from "../../../utils/thisImageNeedDark";
import { IoLogoGameControllerB } from "react-icons/io";
import { useTranslation } from "react-i18next";
import NotFound from "../../ProgressBlock/NotFound";
import { TiThSmallOutline } from "react-icons/ti";
import TitleIcon from "./components/titleIcon";
import { gameListFromBottom } from "../../../animateConstant";
import { isValidMotionProp, motion } from "framer-motion";

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const PlatformSelector = ({ gameMainTypeFromProps }) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);
  const isHandleAllGame = useSelector((state) => state.isHandleAllGame);

  const { colorMode, toggleColorMode } = useColorMode();

  const { gameList } = isHandleAllGame || {};

  const scrollRef = useRef();
  const splideRef = useRef();

  const navigate = useNavigate();
  const location = useLocation();

  const { pathname } = location;

  //   const getGameType = useMemo(() => {
  //     return pathname.split("/")[1];
  //   }, [pathname]);
  const { gameMainType, gamePlatform } = useParams();

  const getPlatform = useMemo(() => {
    const mainType = gameMainTypeFromProps || gameMainType;
    if (gameList) {
      const current_game_type = routeMapping(mainType)?.origin;
      return {
        platform_list: Object?.keys(gameList[current_game_type] || {}),
      };
    }
  }, [gameList, gameMainType, gameMainTypeFromProps]);
  const platformList = useMemo(() => {
    const mainType = gameMainTypeFromProps || gameMainType;
    const { platform_list } = getPlatform || {};
    const current_game_type = routeMapping(mainType)?.origin;

    return platform_list?.map((item) => {
      const game_length = gameList?.[current_game_type]?.[item]?.length;
      return (
        <SplideSlide key={item}>
          <Link
            as={NavLink}
            to={`/${mainType}/category/${item}/AllGames`}
            h="50px"
            _hover={{
              textDecoration: "none",
            }}
          >
            {({ isActive }) => {
              return (
                <Box
                // borderRadius={`9px`}
                // border="1px solid"
                // borderColor="v3-second-brand.300"
                >
                  <Flex
                    minW="90px"
                    h="50px"
                    justifyContent={`center`}
                    gap="2.5px"
                    direction="column"
                    alignItems={`center`}
                    bg={isActive ? "brand.500" : "#E9E9F2"}
                    borderRadius={"30px"}
                    position={`relative`}
                    overflow={`hidden`}
                    py="20px"
                  >
                    {/* <AbsoluteCenter
                      h="100%"
                      borderRadius={"full"}
                      w="125%"
                      top="35%"
                      bg={
                        isActive
                          ? "v3-second-brand.500"
                          : "v3-second-brand.500-opacity"
                      }
                    ></AbsoluteCenter> */}
                    <Image
                      w="50%"
                      maxH={"20.5px"}
                      minH={"20.5px"}
                      className="object-contain relative z-[1]"
                      src={`${COMMON_WEB_PATH}/platformIcon/${thisImageNeedDark(
                        item,
                        colorMode === "dark"
                      )}.png`}
                      alt={item}
                    />

                    <Box
                      display="flex"
                      alignItems={`center`}
                      gap="5px"
                      borderRadius={`full`}
                      color={isActive ? "white" : "#757576"}
                      position="relative"
                      zIndex="1"
                    >
                      <Icon fontSize="14px" as={TiThSmallOutline} />
                      <Text fontWeight={`700`} fontSize="12px">
                        {game_length}
                      </Text>
                    </Box>
                  </Flex>
                </Box>
              );
            }}
          </Link>
        </SplideSlide>
      );
    });
  }, [getPlatform, gameList, gameMainTypeFromProps]);

  // useEffect(() => {
  //   if (scrollRef.current) {
  //     if (gamePlatform) {
  //       const elementPosition = scrollRef.current.getBoundingClientRect().top;
  //       const offsetPosition = elementPosition + window.pageYOffset;
  //       console.log(elementPosition, offsetPosition, window.pageYOffset);
  //       window.scrollTo({
  //         top: offsetPosition,
  //         behavior: "smooth",
  //       });
  //     }
  //   }
  // }, [gamePlatform]);

  useEffect(() => {
    const { platform_list } = getPlatform || {};

    if (splideRef.current) {
      const splide = splideRef.current.splide;

      splide.go(platform_list?.indexOf(gamePlatform));
    }
  }, [getPlatform, gamePlatform]);

  return (
    <Box position="sticky" top="65px" ref={scrollRef}>
      {/* <Box mb="10px">
        <Flex>
          <Flex alignItems={`center`} gap="10px" fontWeight={`700`}>
             <TitleIcon icon={IoLogoGameControllerB} />
            <Text fontSize="16px">{i18n("Providers")}</Text>
          </Flex>
        </Flex>
      </Box> */}
      {getPlatform?.platform_list?.length === 0 && <NotFound />}
      <ChakraBox
        key={gameMainTypeFromProps || gameMainType}
        {...gameListFromBottom}
      >
        <Splide
          ref={splideRef}
          options={{
            pagination: false,
            arrows: false,
            drag: "free",
            // perPage: 4,
            // perMove: 1,
            gap: "5px",
            lazyLoad: "nearby",
            fixedWidth: "90px",
            dragMinThreshold: {
              mouse: 0,
              touch: 10,
            },
          }}
        >
          {platformList}
        </Splide>
      </ChakraBox>
    </Box>
  );
};

export default PlatformSelector;
