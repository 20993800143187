const MemberInfoReducers = (state = {}, action) => {
  switch (action.type) {
    case "storeMemberInfo":
      return action.payload;
    case "cleanMemberInfo":
      return {};
    default:
      return state;
  }
};
export default MemberInfoReducers;
