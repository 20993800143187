import React, { useEffect, useMemo, useRef } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";

import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import useSubTypeList from "../../hook/useSubTypeList";
import { Link } from "react-router-dom";
import useCasinoType from "../../hook/useCasinoType";
import { useTranslation } from "react-i18next";
import LoadingLine from "../../components/Loading/LoadingLine";
import i18next from "i18next";
import { colorSelector } from "./utils/colorSelector";
import { handleTriggerMenu } from "../../hook/useTriggerMenu";
import { hiddenSelectIcon } from "../../hook/useHiddenSelectIcon";
import { Flex, Icon, Text } from "@chakra-ui/react";
import { menuIcon } from "../../components/Menu/helper/menuIcon";

const ListSelect = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);

  const isHandleAllGame = useSelector((state) => state.isHandleAllGame);
  const { game_subtype, sortedByPlatformAndCategoryGames } = isHandleAllGame;

  const splideRef = useRef();

  const subList = useSubTypeList();
  const casinoType = useCasinoType();

  const navigate = useNavigate();
  const location = useLocation();

  const { gamePlatform, subType } = useParams();
  const { pathname } = location;

  useEffect(() => {
    if (subType && splideRef.current) {
      const splide = splideRef.current.splide;

      splide.go(subList?.indexOf(subType));
    }
  }, []);

  const gameSubTypeList = useMemo(() => {
    if (subList) {
      return [
        ...subList.map((item, index) => {
          const hiddenIcon = hiddenSelectIcon({
            iconName: item,
            casinoType: casinoType,
          });
          const listClassName = handleTriggerMenu(
            {
              border: "2px solid",
              borderColor: "brand.500",
            },
            item,
            {
              pathname,
              casinoType,
              subType,
            }
          );
          return (
            <SplideSlide key={index}>
              <Link
                to={
                  item === "Home"
                    ? `/${casinoType}`
                    : item === "AllGames" &&
                      gamePlatform !== "all" &&
                      gamePlatform
                    ? `/${casinoType}/category/${gamePlatform}/AllGames`
                    : `/${casinoType}/category/${gamePlatform || "all"}/${item}`
                }
              >
                <Flex
                  direction={`column`}
                  alignItems={`center`}
                  overflow={`hidden`}
                  position={`relative`}
                  p={`5px`}
                  borderRadius={`common`}
                  color={`white`}
                  height={`50px`}
                  gap={`5px`}
                  justifyContent={`space-between`}
                  {...listClassName}
                  className={`${hiddenIcon} ${colorSelector({
                    index: index,
                  })}`}
                >
                  <LoadingLine className={`w-[18px] h-[18px] rounded-full`}>
                    <Icon
                      as={menuIcon(item, "icon")}
                      fontSize={`20px`}
                      mb="10px"
                    />
                  </LoadingLine>
                  {/* <LoadingLine className={`!mt-0`}> */}
                  <Text
                    as="span"
                    fontSize={`xs`}
                    fontWeight={`semibold`}
                    letterSpacings="tighter"
                    width="100%"
                    textAlign={`center`}
                    className="text-overflow"
                  >
                    {i18n(item)}
                  </Text>
                  {/* </LoadingLine> */}
                </Flex>
              </Link>
            </SplideSlide>
          );
        }),
      ];
    }
  }, [subList, pathname, i18next.language]);

  return (
    <Splide
      ref={splideRef}
      options={{
        pagination: false,
        arrows: false,
        drag: "free",
        perPage: 6,
        perMove: 1,
        gap: 10,
        lazyLoad: "nearby",
        fixedWidth: "50px",
        dragMinThreshold: {
          mouse: 0,
          touch: 10,
        },
      }}
    >
      {gameSubTypeList}
    </Splide>
  );
};

export default ListSelect;
