import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import useCasinoType from "./useCasinoType";
import i18next from "i18next";
import { routeMapping } from "../components/v2Layout/GameTypeSelector/utils/routeMapping";

const useSubTypeListV2 = ({
  tabIndex = 2,
  forceShowAll,
  gameMainTypeFromProps,
} = {}) => {
  const isHandleAllGame = useSelector((state) => state.isHandleAllGame);
  const {
    sortByGameTypeAndPlatformAndSubType,
    sortedByPlatformAndCategoryGames,
    gameListByTypeAndSubType,
  } = isHandleAllGame || {};
  const memberData = useSelector((state) => Boolean(state.isMemberInfo?.memId));

  const { gameMainType, gamePlatform } = useParams();

  const subList = useMemo(() => {
    const mainType = gameMainTypeFromProps || gameMainType;
    const current_game_type = routeMapping(mainType)?.origin;
    if (forceShowAll) {
      if (gameListByTypeAndSubType && current_game_type) {
        const current_platform_sub_type =
          gameListByTypeAndSubType[current_game_type];
        const sub_type_list = Object.keys(
          current_platform_sub_type || {}
        ).reverse();
        return [
          "AllGames",
          ...(memberData ? ["MyFavorite"] : []),
          ...(memberData ? ["ClickedGames"] : []),
          "News",
          "Top",
          ...sub_type_list,
        ];
      }
    } else {
      if (sortByGameTypeAndPlatformAndSubType && gamePlatform) {
        const sub_type_list = Object?.keys(
          sortByGameTypeAndPlatformAndSubType?.[current_game_type]?.[
            gamePlatform
          ] || {}
        );
        return [
          "AllGames",
          ...(memberData ? ["MyFavorite"] : []),
          ...(memberData ? ["ClickedGames"] : []),
          "News",
          "Top",
          ...sub_type_list,
        ];
      }
    }
  }, [
    gameListByTypeAndSubType,
    gamePlatform,
    memberData,
    gameMainType,
    i18next.language,
    forceShowAll,
    gameMainTypeFromProps,
    sortByGameTypeAndPlatformAndSubType,
  ]);

  const location = useLocation();
  const { pathname } = location;

  const findCasinoOrLiveCasino = useCasinoType();

  // const subList = useMemo(() => {
  //   if (gamePlatform && gamePlatform !== "all" && !forceShowAll) {
  //     const sub = Object?.keys(
  //       sortedByPlatformAndCategoryGames?.[gamePlatform] || {}
  //     );
  //     return [
  //       "AllGames",
  //       ...(memberData ? ["MyFavorite"] : []),
  //       ...(memberData ? ["ClickedGames"] : []),
  //       "News",
  //       "Top",
  //       ...sub,
  //     ];
  //   }
  //   if (game_subtype) {
  //     const sub = game_subtype
  //       ?.filter((item) => {
  //         if (item.belong_type === null) {
  //           return item;
  //         } else if (findCasinoOrLiveCasino === "casino") {
  //           return item.belong_type?.toLowerCase() === "slots";
  //         } else if (findCasinoOrLiveCasino === "live-casino") {
  //           return item.belong_type?.toLowerCase() === findCasinoOrLiveCasino;
  //         } else if (findCasinoOrLiveCasino === "sports") {
  //           return item.belong_type?.toLowerCase() === "sport";
  //         } else if (findCasinoOrLiveCasino === "lottery") {
  //           return item.belong_type?.toLowerCase() === "lottery";
  //         } else {
  //           return item;
  //         }
  //       })
  //       ?.map((item) => item.game_subtype);

  //     if (findCasinoOrLiveCasino === "casino") {
  //       return [
  //         "AllGames",
  //         ...(memberData ? ["MyFavorite"] : []),
  //         ...(memberData ? ["ClickedGames"] : []),
  //         "News",
  //         "Top",
  //         ...sub.reverse(),
  //       ];
  //     } else {
  //       return [
  //         "AllGames",
  //         ...(memberData ? ["MyFavorite"] : []),
  //         ...(memberData ? ["ClickedGames"] : []),
  //         ...sub.reverse(),
  //       ];
  //     }
  //   }
  // }, [game_subtype, gamePlatform, findCasinoOrLiveCasino, i18next.language]);

  return subList;
};

export default useSubTypeListV2;
