import { AnimatePresence } from "framer-motion";
import React from "react";
import useDeviceType from "../../hook/useDeviceType";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Menu from "./Menu";
import { Box } from "@chakra-ui/react";
import { CloseMenu } from "../../redux/action/action";
import MenuVersionSelector from "./MenuVersionSelector";

const MenuBar = () => {
  const deviceType = useDeviceType();

  const isMenuOpen = useSelector((state) => state.isMenuOpen);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <AnimatePresence>
      {/* {isMenuOpen === "Menu" && ( */}
      {deviceType === "PC" || deviceType === "Tablet" ? (
        <MenuVersionSelector />
      ) : (
        isMenuOpen && (
          <Box
            position="fixed"
            top="0px"
            left="0px"
            w="100%"
            h="100%"
            zIndex={`9999`}
            bg={`rgba(0,0,0,0.5)`}
            className="backdrop-blur-md"
            onClick={() => {
              dispatch(CloseMenu());
            }}
          >
            <MenuVersionSelector />
          </Box>
        )
      )}
      {/* )} */}
    </AnimatePresence>
  );
};

export default MenuBar;
