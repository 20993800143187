import React, { useMemo } from "react";
import CasinoGroupsV2 from "./CasinoGroups_v2";
import CasinoGroups from "./CasinoGroups";
import { WEB_LAYOUT } from "../../constant";
import CasinoGroupsV4 from "./CasinoGroups_v4";

const CasinoGroupsVersionSelector = () => {
  const casinoGroup = useMemo(() => {
    switch (WEB_LAYOUT) {
      case "v2":
        return <CasinoGroupsV2 />;
      case "v3":
        return <CasinoGroupsV2 />;
      case "v4":
        return <CasinoGroupsV4 />;
      default:
        return <CasinoGroups />;
    }
  }, [WEB_LAYOUT]);
  return casinoGroup;
};

export default CasinoGroupsVersionSelector;
