import React from "react";
import DetailWrapper from "../../components/detailWrapper";
import CloseDetailPage from "../../components/CloseComponent/CloseDetailPage";
import { GoAlertFill } from "react-icons/go";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import ResultPage from "../../components/ResultPage/ResultPage";

const PleaseSetUserIDCard = ({ children, customPath, selectedCurrency }) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`formHint.${key}`);

  const navigate = useNavigate();

  const handleClick = () => {
    navigate(
      customPath
        ? customPath
        : `../settings/bind-id-card?currency=${selectedCurrency}`
      //這邊的selectedCurrency是從上一層傳下來的 加上?currency=${selectedCurrency}就可以把幣別傳給bind-id-card 這樣bind-id-card就可以知道要綁哪個幣別的卡
    );
  };

  return (
    <ResultPage
      buttonProps={{
        content: i18n("goToBindCard"),
        onClick: handleClick,
      }}
      resultContent={`${i18n("youCanGo")} ${i18n("bindRoute")}，${i18n("view")}
      ${i18n("bindData")}`}
      resultText={i18n("pleaseGoToBindCard")}
      pageType="warning"
      pageTitle={i18n("goToBindCard")}
      children={children}
    />
  );
};

export default PleaseSetUserIDCard;
