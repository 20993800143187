import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
// import { getMemberInfo, setNowCurrency } from "../../../api/api";
import { loading, loadingDestroy, notice } from "../../../redux/action/action";
import { closeWalletSettingPop } from "../../../redux/action/member/memberAction";
import { useTranslation } from "react-i18next";
import { NowCurrencyChange } from "../../../api/postApi";
import Cookies from "js-cookie";
import {
  AbsoluteCenter,
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  Spinner,
  Text,
} from "@chakra-ui/react";
import useHideZeroAmountStatus from "../../../hook/useHideZeroAmountStatus";
import useCryptoInFiat from "../../../hook/useCryptoInFiat";
import useCurrencyList from "../../../hook/useCurrencyList";
import { waitTime } from "../../../utils/waitTime";
import LoadingContainer from "../../Loading/LoadingContainer";

const WalletSetting = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`header.${key}`);
  const i18n_common = (key) => t(`common.${key}`);

  const navigate = useNavigate();

  const walletOpen = useSelector((state) => state.isWalletSettingPop); //從redux的isWalletSettingPop取得錢包設置的狀態
  const isMemberInfo = useSelector((state) => state.isMemberInfo); //從redux的isMemberInfo取得會員資料
  const dispatch = useDispatch();

  const [buttonLoading, setButtonLoading] = useState(false); //設定按鈕loading
  const [form, setForm] = useState({
    hideZeroAmount: "",
    displayCryptoInFiat: "",
    currency: "",
  });
  const [modalLoading, setModalLoading] = useState(false); //設定modal loading
  const { hideZeroAmount, displayCryptoInFiat, currency } = form;

  const hideZeroStatus = useHideZeroAmountStatus();
  const { currency_code, cryptoInFiatStatus } = useCryptoInFiat() || {};
  const currencyList = useCurrencyList();

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(loading());
    setButtonLoading(true);

    await NowCurrencyChange({
      hide_zero: hideZeroAmount,
      display_cryptoInFiat: displayCryptoInFiat,
      now_currency: currency,
    }).then((response) => {
      dispatch(
        notice({
          title: "Success",
          type: "success",
        })
      );
      dispatch({ type: "GET_BALANCE" });
      dispatch({ type: "GET_MEMBER_DATA" });
    });

    // 在保存操作完成後，銷毀加載狀態並關閉錢包設置彈出窗口
    dispatch(loadingDestroy());
    dispatch(closeWalletSettingPop());
  };

  const onClose = () => {
    dispatch(closeWalletSettingPop());
  };

  useEffect(() => {
    const loadingModal = async () => {
      setModalLoading(true);
      await setForm({
        hideZeroAmount: hideZeroStatus,
        displayCryptoInFiat: cryptoInFiatStatus,
        currency: currency_code,
      });
      await waitTime(500);
      setModalLoading(false);
    };
    loadingModal();
  }, [currency_code, cryptoInFiatStatus, hideZeroStatus]);

  return (
    <Modal
      isCentered
      onClose={onClose}
      isOpen={walletOpen}
      motionPreset="slideInBottom"
      variant={`brandPrimary`}
    >
      <ModalOverlay />
      <ModalContent>
        <form className="relative" onSubmit={handleSubmit}>
          {modalLoading && <LoadingContainer />}
          <ModalHeader>{i18n("walletSet")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex direction={`column`} gap="10px">
              <Flex alignItems="center">
                <Checkbox
                  variant={`brandPrimary`}
                  mr="10px"
                  isChecked={hideZeroAmount}
                  name="hideZeroAmount"
                  onChange={(e) => {
                    setForm((prev) => ({
                      ...prev,
                      [e.target.name]: e.target.checked,
                    }));
                  }}
                />
                <Box>
                  <Text as="p" color="brand.500" mb="0" fontWeight={`semibold`}>
                    {i18n("hiddenZero")}
                  </Text>
                  <Text as="p" color="special.1" mb="0">
                    {i18n("hiddenZeroHint")}
                  </Text>
                </Box>
              </Flex>

              <Flex alignItems={`center`}>
                <Checkbox
                  variant={`brandPrimary`}
                  mr="10px"
                  isChecked={displayCryptoInFiat}
                  name="displayCryptoInFiat"
                  onChange={(e) => {
                    setForm((prev) => ({
                      ...prev,
                      [e.target.name]: e.target.checked,
                    }));
                  }}
                />
                <Box>
                  <Text as="p" color="brand.500" mb="0" fontWeight={`semibold`}>
                    {i18n("currencyShowAmount")}
                  </Text>
                  <Text as="p" color="special.1" mb="0">
                    {i18n("currencyShowAmountHint")}
                  </Text>
                </Box>
              </Flex>
            </Flex>
            <Divider my="10px" />
            <Box display="grid" py="10px" gap="10px" className="grid-cols-4">
              {currencyList?.map((option, index) => {
                return (
                  <Flex alignItems={`center`} key={`${option}-${index}`}>
                    <Radio
                      key={index}
                      value={option}
                      name="currency"
                      isChecked={currency === option} //檢查現在的currency幣別是否等於CURRENCY_MENU裡面的幣別 如果等於就打勾
                      label={option}
                      mr="5px"
                      fontWeight={`bold`}
                      onChange={(e) => {
                        setForm((prev) => ({
                          ...prev,
                          [e.target.name]: e.target.value,
                        }));
                      }}
                      isDisabled={!displayCryptoInFiat} //如果沒有勾選以法定貨幣顯示加密貨幣就不能選擇幣別
                    />
                    <Flex alignItems={`center`} gap="5px">
                      <Text
                        as="p"
                        color="brand.500"
                        mb="0"
                        fontWeight={`semibold`}
                      >
                        {option}
                      </Text>
                    </Flex>
                  </Flex>
                );
              })}
            </Box>
            <Divider my="10px" />

            <Text as="span" color="red.500" fontWeight={`bold`}>
              {i18n("alert")}
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button
              variant={`brandPrimary`}
              isLoading={buttonLoading} //按鈕loading
              isDisabled={buttonLoading} //按鈕loading
              type="submit"
            >
              {i18n("store")}
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default WalletSetting;
