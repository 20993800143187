import store from "../redux/store";

const numeral = require("numeral");

export const formatNumber = (
  NUMBER,
  { point = true, ignore_crypto = false, format_to_eight = false } = {}
) => {
  const { isWalletCurrency, isCurrencyExchange } = store.getState();
  const is_crypto = isCurrencyExchange?.[isWalletCurrency]?.is_crypto == 1;
  if (typeof NUMBER === "string") {
    NUMBER = Number(NUMBER);
  }
  if (format_to_eight) {
    return formatEightNumber(NUMBER);
  }
  if (is_crypto && !ignore_crypto) {
    return NUMBER;
  }
  NUMBER = Math.floor(NUMBER * 100) / 100;
  if (point) {
    return numeral(NUMBER).format("0,0.00");
  } else {
    return numeral(NUMBER).format("0,0");
  }
};

export const formatEightNumber = (NUMBER) => {
  return numeral(NUMBER).format("0,0.00000000");
};

export const filterNumberColor = (NUMBER) => {
  if (typeof NUMBER === "string") {
    NUMBER = Number(NUMBER);
  }

  if (NUMBER > 0) {
    return "!text-green-500";
  } else if (NUMBER < 0) {
    return "!text-red-500";
  } else {
    return "!text-light-text";
  }
};

export const filterSign = (NUMBER) => {
  if (typeof NUMBER === "string") {
    NUMBER = parseInt(NUMBER);
  }
  if (NUMBER > 0) {
    return "+";
  } else if (NUMBER === 0) {
    return "";
  } else {
    return "-";
  }
};
