export const storeMemberInfo = (data) => {
  return {
    type: "storeMemberInfo",
    payload: data,
  };
};
export const cleanMemberInfo = () => {
  return {
    type: "cleanMemberInfo",
  };
};

export const StoreCryptoRatio = (data) => {
  return {
    type: "StoreCryptoRatio",
    payload: data,
  };
};
export const storeReferDetail = (data) => {
  return {
    type: "storeReferDetail",
    payload: data,
  };
};
export const openLanguagePop = () => {
  return {
    type: "openLanguagePop",
  };
};
export const closeLanguagePop = () => {
  return {
    type: "closeLanguagePop",
  };
};
export const openWalletSettingPop = () => {
  return {
    type: "openWalletSettingPop",
  };
};
export const closeWalletSettingPop = () => {
  return {
    type: "closeWalletSettingPop",
  };
};
export const openComissionListPop = () => {
  return {
    type: "openComissionListPop",
  };
};
export const closeComissionListPop = () => {
  return {
    type: "closeComissionListPop",
  };
};
export const uploadFile = (file) => {
  return {
    type: "uploadFile",
    payload: file,
  };
};
export const storeTransferWallet = (wallet) => {
  return {
    type: "storeTransferWallet",
    payload: wallet,
  };
};
export const storeCurrencyExchange = (data) => {
  return {
    type: "storeCurrencyExchange",
    payload: data,
  };
};

export const storeVipDetail = (vipDetail) => {
  return {
    type: "storeVipDetail",
    payload: vipDetail,
  };
};
export const clearTransferWallet = () => {
  return {
    type: "clearTransferWallet",
  };
};
export const openGameDescriptionPop = ({ game } = {}) => {
  return {
    type: "openGameDescriptionPop",
    payload: {
      state: true,
      game,
    },
  };
};
export const closeGameDescriptionPop = () => {
  return {
    type: "closeGameDescriptionPop",
  };
};
export const storeInboxDetail = (data) => {
  return {
    type: "storeInboxDetail",
    payload: data,
  };
};
export const openChat = () => {
  return {
    type: "openChat",
  };
};
export const closeChat = () => {
  return {
    type: "closeChat",
  };
};
export const triggerGameBoxTransfer = () => {
  return {
    type: "triggerGameBoxTransfer",
  };
};
export const closeGameBoxTransfer = () => {
  return {
    type: "closeGameBoxTransfer",
  };
};
export const storeMarqueeList = (data) => {
  return {
    type: "storeMarqueeList",
    payload: data,
  };
};
export const clearMarqueeList = () => {
  return {
    type: "clearMarqueeList",
  };
};
export const openCache = () => {
  return {
    type: "openCache",
  };
};
export const closeCache = () => {
  return {
    type: "closeCache",
  };
};

export const storeInboxList = (data) => {
  return {
    type: "storeInboxList",
    payload: data,
  };
};

export const storeCurrency = (curr) => {
  //目前以法幣顯示的幣別
  return {
    type: "storeCurrency",
    payload: curr,
  };
};

export const storeWalletCurrency = (curr) => {
  //目前右上錢包幣別
  return {
    type: "storeWalletCurrency",
    payload: curr,
  };
};

export const storeGameCurrency = (curr) => {
  //遊戲內頁的幣別
  return {
    type: "storeGameCurrency",
    payload: curr,
  };
};

export const openHideZeroAmount = () => {
  return {
    type: "openHideZeroAmount",
  };
};
export const closeHideZeroAmount = () => {
  return {
    type: "closeHideZeroAmount",
  };
};

export const openDisplayCryptoInFiat = () => {
  return {
    type: "openDisplayCryptoInFiat",
  };
};
export const closeDisplayCryptoInFiat = () => {
  return {
    type: "closeDisplayCryptoInFiat",
  };
};

export const storeEventList = (data) => {
  return {
    type: "storeEventList",
    payload: data,
  };
};

export const clearEventList = () => {
  return {
    type: "clearEventList",
  };
};

export const openColorMode = () => {
  return {
    type: "openColorMode",
  };
};
export const closeColorMode = () => {
  return {
    type: "closeColorMode",
  };
};
export const storeBonus = (data) => {
  return {
    type: "storeBonus",
    payload: data,
  };
};
export const storeWagerConfig = (data) => {
  return {
    type: "storeWagerConfig",
    payload: data,
  };
};
export const isDetectedInvalidAuth = () => {
  return {
    type: "isDetectedInvalidAuth",
  };
};

export const resetInvalidAuth = () => {
  return {
    type: "resetInvalidAuth",
  };
};

export const storeCarouselList = (data) => {
  return {
    type: "storeCarouselList",
    payload: data,
  };
};
export const playingGameStatus = (data) => {
  return {
    type: "playing",
  };
};

export const playedGameStatus = (data) => {
  return {
    type: "played",
  };
};

export const defaultGameStatus = (data) => {
  return {
    type: "default",
  };
};

export const openOpenFreezeModal = (data) => {
  return {
    type: "openOpenFreezeModal",
  };
};

export const closeOpenFreezeModal = (data) => {
  return {
    type: "closeOpenFreezeModal",
  };
};
