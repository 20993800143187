import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FaExclamationCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
import { CURRENCY_SYMBOL } from "../../../constant";
import { Divider, Tooltip } from "@chakra-ui/react";

const TransferLimitHint = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`profile.${key}`);

  const { allGamePlatform } = useSelector((state) => state.isHandleAllGame);
  const isTransferWallet = useSelector((state) => state.isTransferWallet);
  const isDarkMode = useSelector((state) => state.isTheme === "dark");

  const transferWallet = useMemo(() => {
    return isTransferWallet || {};
  }, [isTransferWallet]);

  const rateList = useMemo(() => {
    return Object.keys(transferWallet).map((key) => {
      const array = key?.split("-");
      const findPlatform = allGamePlatform?.find(
        (item) => item.platform === array[0]
      );
      return {
        name: key,
        rate: parseInt(findPlatform?.exchangeRate),
      };
    });
  }, [transferWallet, allGamePlatform]);

  return (
    <Tooltip
      label={
        <div className="flex flex-col">
          <span className="text-sm">{i18n("rateHint")}</span>
          <Divider className="!my-[5px]" />
          <section className="flex gap-[5px]">
            <section className="">
              {rateList?.map((item) => {
                return (
                  <>
                    <div className="text-right">{item.name}:</div>
                  </>
                );
              })}
            </section>
            <section className="">
              {rateList?.map((item) => {
                return (
                  <>
                    <div className="font-bold">
                      {1000 / item.rate}
                      {CURRENCY_SYMBOL}
                    </div>
                  </>
                );
              })}
            </section>
          </section>
        </div>
      }
      fontSize="md"
    >
      <span>
        <FaExclamationCircle className="text-gold-text text-lg" />
      </span>
    </Tooltip>
  );
};

export default TransferLimitHint;
