import React from "react";
import { filterNumberColor, formatNumber } from "../../../utils/formatNumber";
import { useTranslation } from "react-i18next";
import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import useVipInfo from "../../../hook/useVipInfo";

const Record = ({ gameName, jackpotWin, bet, winLose, index }) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`betRecord.${key}`);

  const cardBg = useColorModeValue("light.100", "dark.100");
  const textColor = useColorModeValue("dark.100", "light.100");

  const { current_symbol } = useVipInfo();

  return (
    <Box
      index={index}
      as="section"
      bg={cardBg}
      borderRadius={`common`}
      py="common-y"
      px="common-x"
    >
      <Text
        color={textColor}
        fontSize={`lg`}
        fontWeight={`semibold`}
        display="flex"
        alignItems={`center`}
      >
        {gameName}{" "}
      </Text>
      <Flex justifyContent={`space-between`}>
        <Flex direction={`column`} color="special.1">
          <Text fontSize={`sm`}>{i18n("Bet")}</Text>
          <Text color="special.1" fontWeight={`bold`}>
            {formatNumber(bet)} {current_symbol}
          </Text>
        </Flex>

        <Flex direction={`column`} alignItems="flex-end" color="special.1">
          <Text fontSize="sm">{i18n("Win/Lose")}</Text>
          <Text fontWeight={`bold`} className={`${filterNumberColor(winLose)}`}>
            {formatNumber(winLose)} {current_symbol}
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Record;
