import React, { useCallback, useMemo, useState } from "react";
import CloseDetailPage from "../../../components/CloseComponent/CloseDetailPage";
import { LANGUAGES, LANGUAGES_MENU } from "../../../i18n/config";
import i18next from "i18next";
import { AiFillCheckCircle } from "react-icons/ai";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { closeLanguagePop } from "../../../redux/action/member/memberAction";
import { useTranslation } from "react-i18next";
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Icon,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const LanguagePageV4 = () => {
  const { t, i18n } = useTranslation();
  const { lang } = useParams();
  const i18n_common = (key) => t(`common.${key}`);

  const modalBg = useColorModeValue("light.100", "#1A2036");
  const boxBg = useColorModeValue("light.200", "dark.250");
  const itemHoverBg = useColorModeValue("light.300", "dark.300");
  const textActiveColor = useColorModeValue("dark.100", "light.100");

  const website_language = window.web_language || [];

  const memberData = useSelector((state) => state.isMemberInfo);
  const { test } = memberData || {};

  const language_filter = useMemo(() => {
    return LANGUAGES_MENU?.filter((item) => {
      if (test == 1) {
        return item;
      } else {
        return website_language?.includes(item.value);
      }
    });
  }, [LANGUAGES_MENU, test, website_language]);
  console.log(language_filter);
  const onClose = async () => {
    await dispatch(closeLanguagePop());
  };

  const dispatch = useDispatch();
  return (
    <Modal
      variant={`brandPrimary`}
      onClose={onClose}
      isOpen={true}
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent
        sx={{
          // 設定基本的 modal 背景變量和背景色，使用 !important 確保最高優先級
          "--modal-bg": `${modalBg} !important`,
          background: `${modalBg} !important`,

          // 針對 dark 模式的特定選擇器
          // .chakra-ui-dark & 表示在 dark 模式下的此元素
          // &[data-theme=dark] 表示具有 data-theme="dark" 屬性的此元素
          ".chakra-ui-dark &, &[data-theme=dark]": {
            "--modal-bg": `${modalBg} !important`,
            background: `${modalBg} !important`,
          },

          // 針對特定的 modal content 類名
          // &.chakra-modal__content 表示同時具有這兩個類名的元素
          "&.chakra-modal__content": {
            "--modal-bg": `${modalBg} !important`,
            background: `${modalBg} !important`,
          },

          // 針對 modal content container 的類名
          // 這是 Chakra UI modal 的外層容器
          "&.chakra-modal__content-container": {
            "--modal-bg": `${modalBg} !important`,
            background: `${modalBg} !important`,
          },
        }}
        mt={{
          base: "auto",
        }}
        mb={{
          base: "unset",
          md: "auto",
        }}
        borderTopRadius={{
          base: "24px",
        }}
        borderBottomRadius={{
          base: "unset",
          md: "24px",
        }}
        h={550}
      >
        <ModalHeader>{i18n_common("language")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody h={500} px="0">
          <Grid
            templateColumns={"repeat(1, minmax(0, 1fr))"}
            as="section"
            display="grid"
            flexDirection={`column`}
            //gap="10px"
            //borderRadius={`common`}
            py="8px"
          >
            {language_filter?.map((item, index) => {
              return (
                <GridItem>
                  <Box
                    onClick={async (event) => {
                      event.stopPropagation();
                      await i18next.changeLanguage(item.value);
                      localStorage.setItem("i18nextLng", item.value);
                      // const targetPath = item.value === "tw" ? "" : item.value;
                      const targetPath = item.value;

                      window.location.assign("/" + targetPath);
                    }}
                    key={`${item.language}-${index}`}
                    cursor={`pointer`}
                    //borderRadius={`common`}
                    p="10px"
                    px="30px"
                    display="flex"
                    flexDirection={`row`}
                    alignItems={`center`}
                    justifyContent={`space-between`}
                    sx={{
                      bg: i18next.language === item.value ? itemHoverBg : boxBg,
                      _hover: {
                        bg: itemHoverBg,
                      },
                    }}
                    border={
                      i18next.language === item.value ? "1px solid" : "none"
                    }
                    borderColor={
                      i18next.language === item.value
                        ? "#3163FF"
                        : "transparent"
                    }
                    // _hover={{
                    //   bg: itemHoverBg,
                    // }}
                    //h="50px"
                    position="relative"
                    gap="20px"
                  >
                    <Flex alignItems={`center`} gap="15px">
                      <Image
                        borderRadius="full"
                        w="38px"
                        h="auto"
                        src={`https://flagsapi.com/${item.flag?.toUpperCase()}/flat/64.png`}
                      />
                      <Flex alignItems={`center`} gap="5px">
                        {/* <Flag name={item.flag} className="!w-[20px]" height="15" /> */}
                        <Text
                          as="span"
                          fontWeight={`medium`}
                          color={textActiveColor}
                        >
                          {item.text}
                        </Text>
                      </Flex>
                    </Flex>
                    {i18next.language === item.value && (
                      <Icon
                        fontSize="2xl"
                        // position="absolute"
                        // top="-5px"
                        // right="-5px"
                        as={AiFillCheckCircle}
                        color="#3163FF"
                        bg="white"
                        borderRadius="full"
                      />
                    )}
                  </Box>
                </GridItem>
              );
            })}
          </Grid>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default LanguagePageV4;
