import { commonOpacity } from "../../animateConstant";
import CloseDetailPage from "../../components/CloseComponent/CloseDetailPage";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IoAddCircleOutline, IoAddCircleSharp } from "react-icons/io5";
import BalanceCard from "./components/balanceCard";
import DetailWrapper from "../../components/detailWrapper";
import { useEffect, useMemo, useState } from "react";
import WalletCard from "./components/walletCard";
import { getTransferWallet } from "../../api/getApi";
import {
  openOpenFreezeModal,
  storeTransferWallet,
} from "../../redux/action/member/memberAction";
import { flattenWalletObject } from "../../utils/flattenObject";
import LoadingLine from "../../components/Loading/LoadingLine";
import LoadingWalletCard from "../../components/Loading/LoadingWalletCard";
import OneClickTransfer from "../PointQuery/components/oneClickTransfer";
import TransferLimitHint from "../PointQuery/components/transferLimitHint";
import {
  Box,
  Flex,
  Grid,
  Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { waitTime } from "../../utils/waitTime";
import dayjs from "dayjs";
import NotFound from "../../components/ProgressBlock/NotFound";
import GAMEITEM from "../../components/GAMEITEM/GAMEITEM";
import { languageTransfer } from "../../utils/languageTransfer";
import i18next from "i18next";
import GameCatalog from "../../components/GameCatalog";
import useVipInfo from "../../hook/useVipInfo";
import { BsQuestionCircleFill } from "react-icons/bs";
import GameCatalogV2 from "../../components/GameCatalog_v2";

const isCredit = process.env.REACT_APP_CREDIT == "1";

const NewProfile = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`profile.${key}`);

  const cardBg = useColorModeValue("light.100", "dark.100");
  const cardHoverBg = useColorModeValue("light.200", "dark.300");
  const textColor = useColorModeValue("dark.100", "light.100");
  const titleColor = useColorModeValue("dark.200", "light.200");

  const dispatch = useDispatch();
  const memberData = useSelector((state) => state.isMemberInfo);
  const isHandleAllGame = useSelector((state) => state.isHandleAllGame);
  const { create_time } = memberData || {};
  const { favoriteGameList } = isHandleAllGame || {};

  const { current_level } = useVipInfo();

  const common_tab = useMemo(() => {
    return [
      {
        text: "Join",
        key: "create_time",
        value: dayjs(create_time).format("YYYY-MM-DD"),
      },
      {
        text: "Status",
        key: "status",
        value: i18n(memberData?.status),
        components: memberData?.status == 2 && (
          <Icon
            ml="5px"
            as={BsQuestionCircleFill}
            color="special.gold"
            cursor="pointer"
            onClick={() => {
              dispatch(openOpenFreezeModal());
            }}
          />
        ),
      },
      {
        text: "Vip Level",
        key: "vip",
        value: current_level?.toString(),
        hidden: isCredit,
      },
    ];
  }, [current_level, memberData]);
  return (
    <DetailWrapper>
      <CloseDetailPage title={i18n("title")} />
      <Grid
        gap="5px"
        p="3px"
        as="section"
        bg={cardBg}
        borderRadius={`common`}
        gridTemplateColumns="repeat(3, minmax(0, 1fr))"
        overflow="hidden"
        mb="10px"
      >
        {common_tab
          ?.filter((item) => !item?.hidden)
          ?.map((item) => {
            return (
              <Flex
                role="group"
                direction={`column`}
                gap="3px"
                justifyContent={`center`}
                alignItems={`center`}
                borderRadius={`common`}
                py="7px"
                className="transition duration-100"
              >
                <Text
                  color={`special.1`}
                  fontSize="xs"
                  letterSpacing={`tight`}
                  fontWeight={"medium"}
                >
                  {i18n(item.text)}
                </Text>
                <Text
                  color={textColor}
                  fontSize="xs"
                  letterSpacing={`tight`}
                  fontWeight={"medium"}
                >
                  {item.value || "-"}
                  {item.components}
                </Text>
              </Flex>
            );
          })}
      </Grid>
      <Box bg={cardBg} borderRadius={`common`} py="10px">
        <Text px="10px" mb="5px" color={textColor} fontSize="sm">
          {i18n("Favorite Games")}
        </Text>
        {favoriteGameList?.length === 0 ? (
          <NotFound />
        ) : (
          <GameCatalogV2
            list={favoriteGameList}
            observeFalse
            fullImageLayout
            itemClass={"!h-[140px] !min-w-full"}
            hiddenButtonNavigator
            swiperProps={{
              customPerView: "profile-layout",
            }}
            hiddenHeader
          />
        )}
      </Box>
    </DetailWrapper>
  );
};
export default NewProfile;
