import Cookies from "js-cookie";

export const removeCookies = () => {
  if (Cookies.get("uac")) {
    Cookies.remove("uac");
  }
  if (Cookies.get("ag")) {
    Cookies.remove("ag");
  }
};
