import {
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  NumberDecrementStepper,
  NumberIncrementStepper,
  InputLeftElement,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
} from "@chakra-ui/react";
import React from "react";

const CustomInput = ({ props }) => {
  const { type, icon, label, required, isInvalid } = props || {};
  switch (type) {
    case "text":
      return (
        <FormControl isInvalid={isInvalid} isRequired={required}>
          <FormLabel>{label}</FormLabel>
          <InputGroup>
            <InputLeftElement pointerEvents="none">{icon}</InputLeftElement>
            <Input variant={`brandPrimary`} {...props}></Input>
          </InputGroup>
        </FormControl>
      );
    case "number":
      return (
        <FormControl isRequired={required}>
          <FormLabel>{label}</FormLabel>
          <InputGroup>
            <InputLeftElement pointerEvents="none">{icon}</InputLeftElement>
            <NumberInput w="100%" variant={`brandPrimary`} {...props}>
              <NumberInputField pl="35px" />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </InputGroup>
        </FormControl>
      );
    case "select":
      return (
        <FormControl isRequired={required}>
          <FormLabel>{label}</FormLabel>
          <InputGroup>
            <Select variant={`brandPrimary`} {...props}>
              {props?.options.map((item) => {
                return <option value={item.value}>{item.label}</option>;
              })}
            </Select>
          </InputGroup>
        </FormControl>
      );
    case "date":
      return (
        <FormControl isRequired={required}>
          <FormLabel>{label}</FormLabel>
          <InputGroup>
            <InputLeftElement pointerEvents="none">{icon}</InputLeftElement>
            <Input variant={`brandPrimary`} {...props}></Input>
          </InputGroup>
        </FormControl>
      );
    default:
      return (
        <FormControl isRequired={required}>
          <FormLabel>{label}</FormLabel>
          <InputGroup>
            <InputLeftElement pointerEvents="none">{icon}</InputLeftElement>
            <Input variant={`brandPrimary`} {...props}></Input>
          </InputGroup>
        </FormControl>
      );
  }
};

export default CustomInput;
