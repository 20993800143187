import React from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Divider,
  SkeletonCircle,
  SkeletonText,
  useColorModeValue,
} from "@chakra-ui/react";

const LoadingBlogDetail = ({
  commonLoading,
  children,
  mapIndex = 2,
  noOfLines,
}) => {
  const loadingStatus = useSelector((state) => state.isLoaderStatus);

  const cardBg = useColorModeValue("light.100", "dark.100");

  return commonLoading || loadingStatus
    ? Array.from({ length: mapIndex }, (_, i) => i).map((item) => {
        return (
          <Box w="100%">
            <SkeletonText
              mt="4"
              noOfLines={noOfLines || 7}
              spacing="4"
              skeletonHeight="2"
            />
          </Box>
        );
      })
    : children;
};

export default LoadingBlogDetail;
