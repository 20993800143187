import { useEffect } from "react";
import { MENU_LIST } from "../../components/SignInUp/helper";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import UserLogout from "../../api/UserLogout";
import { notice } from "../../redux/action/action";
import {
  cleanMemberInfo,
  clearTransferWallet,
} from "../../redux/action/member/memberAction";
import { IoCloseOutline } from "react-icons/io5";
import { actionLogout } from "../../api/postApi";
import { HiChevronRight } from "react-icons/hi";
import { Link } from "react-router-dom";
import { isValidMotionProp, motion } from "framer-motion";
import { commonOpacity, gameListFromBottom } from "../../animateConstant";
import ProfileButton from "./components/profileButton";
import GoogleLogout from "../Singin/components/googleLogout";
import { clearFavoriteGameList } from "../../redux/action/game/gameAction";
import CachePop from "./components/cachePop";
import {
  Box,
  Flex,
  chakra,
  shouldForwardProp,
  useColorModeValue,
  Text,
  useClipboard,
  useToast,
  Grid,
  Icon,
} from "@chakra-ui/react";
import { IoIosCopy } from "react-icons/io";
import ProfileCard from "./components/profileCard";
import CommonSelectTab from "./components/commonSelectTab";
import ProfileCardNotLogin from "./components/profileCardNotLogin";
import useCheckLogin from "../../hook/useCheckLogin";
import CommonSelectTabV2 from "./components/commonSelectTab_v2";
import ProfileCardV2 from "./components/profileCard_v2";
import CommonSelectTabV3 from "./components/commonSelectTab_v3";

const motionProps = {
  initial: commonOpacity.initial,
  animate: commonOpacity.animate,
  exit: commonOpacity.exit,
  transition: commonOpacity.transition,
};

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const AsideV3 = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);

  const toast = useToast();

  const { isLogin } = useCheckLogin();

  const { onCopy, value, setValue, hasCopied } = useClipboard("");

  const { signOut } = GoogleLogout();

  const memberData = useSelector((state) => state.isMemberInfo);
  const cachePop = useSelector((state) => state.isCachePop);
  const dispatch = useDispatch();

  const menuColor = useColorModeValue("dark.200", "light.200");
  const menuBg = useColorModeValue(
    {
      base: "light.100",
      md: "light.100",
    },
    {
      base: "dark.100",
      md: "dark.100",
    }
  );
  const activeMenuBg = useColorModeValue("light.300", "dark.100");
  const menuHoverBg = useColorModeValue("light.300", "dark.100");
  const profileIconBg = useColorModeValue("light.100", "dark.100");
  const textColor = useColorModeValue("dark.100", "light.100");

  const menu = MENU_LIST({ className: "", dispatch });

  const navigate = useNavigate();
  const location = useLocation();

  const handleLogoutUser = () => {
    actionLogout().then((data) => {
      dispatch(
        notice({
          title: i18n("Logout Success"),
          type: "success",
        })
      );
      // signOut();
      window?.google?.accounts?.id.disableAutoSelect();
      UserLogout();
      dispatch(cleanMemberInfo());
      dispatch(clearFavoriteGameList());
      dispatch(clearTransferWallet());
      navigate("/slots");
    });
  };

  useEffect(() => {
    setValue(memberData?.uid);
  }, [memberData]);

  return (
    <ChakraBox
      display="flex"
      flexDirection={`column`}
      gap={{
        base: "3px",
        md: "5px",
      }}
      overflowY="scroll"
      h="100%"
      {...motionProps}
      className="no-scrollbar"
    >
      <Flex
        alignItems={`center`}
        justifyContent={{
          base: "space-between",
          md: "flex-start",
        }}
      >
        {" "}
        {/* {isLogin && (
          <ProfileButton
            className={{
              bg: profileIconBg,
            }}
          />
        )} */}
        <Flex direction={`column`} ml="10px"></Flex>
        <Box
          display={{
            base: "block",
            md: "none",
          }}
          color="special.100"
          fontSize={`4xl`}
        >
          <Link to="../">
            <IoCloseOutline className="cursor-pointer" />
          </Link>
        </Box>
      </Flex>
      <Box mb="10px">
        {isLogin ? <ProfileCardV2 /> : <ProfileCardNotLogin />}
        {/* <BalanceCard /> */}
      </Box>
      {isLogin && (
        <Box mb="10px">
          <CommonSelectTabV3 />
        </Box>
      )}
      <Flex as="section" direction={`column`} gap="15px">
        {Object?.keys(menu).map((route) => {
          const list = menu[route];
          return (
            <ChakraBox
              {...gameListFromBottom}
              gap="5px"
              as="section"
              bgGradient={
                "linear(to-b, v3-profile.banner-from,  v3-profile.banner-to)"
              }
              borderRadius={`20px`}
              borderBottom="5px solid"
              borderColor="v3-profile.banner-border"
              overflow="hidden"
            >
              <Text
                py="10px"
                px="20px"
                color="black"
                fontSize="md"
                fontWeight={`600`}
                bg="v3-profile.banner-title"
                borderBottom="1px solid"
                borderColor="brand.100"
                position="relative"
                _before={{
                  content: "''",
                  position: "absolute",
                  left: "0px",
                  bottom: "10px",
                  borderRightRadius: "10px",
                  width: "5px",
                  height: "20px",
                  bg: "brand.500",
                }}
              >
                {i18n(route)}
              </Text>
              <Grid
                pt="15px"
                pb="30px"
                gridTemplateColumns={`repeat(4, minmax(0, 1fr))`}
                gap="10px"
                overflow="hidden"
                px="20px"
              >
                {list
                  ?.filter((route) => {
                    return !route?.hidden;
                  })
                  ?.filter((item) => {
                    if (item.auth) {
                      if (item.verify) {
                        return false;
                      }
                      return isLogin === item.auth;
                    } else {
                      return true;
                    }
                  })
                  ?.map((item) => {
                    return (
                      <>
                        <Box
                          role="group"
                          onClick={() => {
                            if (item.func) {
                              item.func();
                              return;
                            }
                            if (item.hashed) {
                              navigate(`/${item.link}`);
                            } else {
                              navigate(`../profile/${item.link}`);
                            }
                          }}
                          color={menuColor}
                          // bg={
                          //   location.pathname.includes(item.link)
                          //     ? activeMenuBg
                          //     : menuBg
                          // }
                          display="flex"
                          gap="5px"
                          flexDirection={`column`}
                          alignItems={`center`}
                          // justifyContent={`center`}
                          // _hover={{
                          //   color: "brand.500",
                          //   bg: menuHoverBg,
                          // }}
                          cursor={`pointer`}
                          className={`${item.className}`}
                        >
                          <Box
                            w="45px"
                            h="45px"
                            display="flex"
                            alignItems={`center`}
                            justifyContent={`center`}
                            borderRadius={`full`}
                            bgGradient={"linear(to-b, brand.600, brand.900)"}
                          >
                            <Icon as={item.icon} fontSize="xl" color="white" />
                          </Box>
                          <Text
                            fontSize="xs"
                            letterSpacing={`tight`}
                            // _groupHover={{
                            //   fontWeight: `700`,
                            // }}
                            w="100%"
                            textAlign={`center`}
                            // className="text-overflow"
                            fontWeight={
                              location.pathname.includes(item.link)
                                ? "700"
                                : "400"
                            }
                            color="black"
                          >
                            {item.text}
                          </Text>
                          {/* <HiChevronRight className="md:hidden" /> */}
                        </Box>
                        {item.children && item.children}
                      </>
                    );
                  })}
              </Grid>
            </ChakraBox>
          );
        })}
      </Flex>
      <Box
        onClick={() => {
          handleLogoutUser();
        }}
        mt="20px"
        py="10px"
        px="12px"
        display="flex"
        alignItems={`center`}
        justifyContent={`center`}
        borderRadius={`lg`}
        cursor={`pointer`}
      >
        <Text as="span" color={textColor}>
          {i18n("logout")}
        </Text>
      </Box>
      {cachePop && <CachePop />}
    </ChakraBox>
  );
};

export default AsideV3;
