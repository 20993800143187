import { HiChevronLeft } from "react-icons/hi";
import CloseDetailPage from "../../../components/CloseComponent/CloseDetailPage";
import DetailWrapper from "../../../components/detailWrapper";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Flex,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import CustomInput from "../../../components/InputComponents/customInput";
import { MdAccountBox, MdMarkEmailRead, MdSmartphone } from "react-icons/md";
import { RiUser3Fill, RiUser5Fill } from "react-icons/ri";
import { FaBirthdayCake } from "react-icons/fa";
import { updateMemberBasicData } from "../../../api/postApi";
import { notice } from "../../../redux/action/action";
import dayjs from "dayjs";
import { waitTime } from "../../../utils/waitTime";
import { api } from "../../../api/baseApi";
import CustomInputV4 from "../../../components/InputComponents/customInput_v4";

const iconStyle = "text-gray-300";

const PersonalDataV4 = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`settings.${key}`);
  const i18n_common = (key) => t(`common.${key}`);

  const inputBg = useColorModeValue("light.250", "dark.250");

  const navigate = useNavigate();

  const memberData = useSelector((state) => state.isMemberInfo);
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    name: "",
    birthday: "",
    email: "",
    mobile: "",
    true_name: "",
  });
  const [basicButtonLoading, setBasicButtonLoading] = useState(false);
  const [emailButtonLoading, setEmailButtonLoading] = useState(false);

  const textColor = useColorModeValue("dark.100", "light.100");
  const { name, birthday, email, mobile, true_name } = formData || {};

  const email_is_verify = useMemo(() => {
    return memberData?.is_verify == 1;
  }, [memberData]);

  const handleUpdateBasicData = async () => {
    setBasicButtonLoading(true);
    await updateMemberBasicData({
      paramsData: {
        name,
        birthday: dayjs(birthday).format("YYYY-MM-DD"),
        email: email,
        mobile: mobile,
        true_name: true_name,
      },
    }).then((data) => {
      dispatch(
        notice({
          content: "Success",
          type: "success",
        })
      );
      dispatch({ type: "GET_MEMBER_DATA" });
    });
    setBasicButtonLoading(false);
  };

  const handleVerifyEmail = async () => {
    setEmailButtonLoading(true);
    try {
      await api
        .post("/getCode", {
          email: email,
        })
        .then((data) => {
          dispatch(
            notice({
              title: data?.message,
              type: "success",
            })
          );
        });
      await waitTime(1500);
      setEmailButtonLoading(false);
      navigate("../verify-email");
    } finally {
      setEmailButtonLoading(false);
    }
  };

  const personalData = [
    {
      name: "true_name",
      label: i18n("form.realName"),
      onChange: (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      },
      type: "text",
      value: true_name,
      icon: <RiUser5Fill className={iconStyle} />,
    },
    {
      name: "name",
      label: i18n("form.nick_name"),
      onChange: (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      },
      type: "text",
      value: name,
      icon: <RiUser3Fill className={iconStyle} />,
    },
    {
      name: "birthday",
      label: i18n("form.birthday"),
      onChange: (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      },
      type: "date",
      value: birthday,
      icon: <FaBirthdayCake className={iconStyle} />,
    },
  ];

  useEffect(() => {
    if (memberData?.uid) {
      setFormData({
        true_name: memberData.true_name,
        memId: memberData.memId,
        name: memberData.name,
        birthday: memberData.birthday,
        email: memberData.email,
        mobile: memberData.mobile,
      });
    }
  }, [memberData?.uid]);

  return (
    <DetailWrapper
      wrapperClassName={`h-full w-full md:absolute fixed top-0 left-0 max-h-full z-[10] bg-gradient-to-br`}
    >
      <CloseDetailPage
        hasPrev={
          <HiChevronLeft
            className="cursor-pointer"
            onClick={() => navigate("..")}
          />
        }
        title={i18n("personalInformation")}
      />
      <Box
        as="article"
        display="flex"
        flexDirection={`column`}
        gap="10px"
        p="15px"
      >
        {personalData.map((item, index) => {
          return (
            <CustomInputV4
              props={{
                ...item,
                bg: inputBg,
                sx: {
                  background: inputBg,

                  _dark: {
                    background: "dark.250",
                    borderColor: "dark.450",
                    color: "white",
                  },
                },
              }}
            />
          );
        })}
        {/* <Button
          mt="5px"
          onClick={handleUpdateBasicData}
          isLoading={basicButtonLoading}
          isDisabled={basicButtonLoading}
          variant={`brandPrimary`}
        >
          {i18n("update_basic_info")}
        </Button> */}
        <Divider my="5px" />
        <Flex
          gap="10px"
          direction="column"
          alignItems={`flex-end`}
          justifyContent={`space-between`}
        >
          <CustomInputV4
            props={{
              name: "email",
              label: i18n("form.email"),
              type: "email",
              value: email,
              icon: <MdMarkEmailRead className={iconStyle} />,
              disabled: email_is_verify,
              onChange: (e) => {
                setFormData({ ...formData, [e.target.name]: e.target.value });
                localStorage.setItem("email", e.target.value);
              },
              bg: inputBg,
              sx: {
                background: inputBg,

                _dark: {
                  background: "dark.250",
                  borderColor: "dark.450",
                  color: "white",
                },
              },
            }}
          />
          {!email_is_verify && (
            <Button
              onClick={handleVerifyEmail}
              isLoading={emailButtonLoading}
              isDisabled={emailButtonLoading}
              variant={`brandPrimary`}
            >
              {i18n("verify")}
            </Button>
          )}
          <CustomInputV4
            props={{
              name: "mobile",
              label: i18n("form.mobile"),
              type: "tel",
              value: mobile,
              icon: <MdSmartphone className={iconStyle} />,
              onChange: (e) => {
                setFormData({ ...formData, [e.target.name]: e.target.value });
                localStorage.setItem("mobile", e.target.value);
              },
              bg: inputBg,
              sx: {
                background: inputBg,

                _dark: {
                  background: "dark.250",
                  borderColor: "dark.450",
                  color: "white",
                },
              },
            }}
          />
          <Divider my="5px" />
          <Button
            mt="5px"
            w="100%"
            onClick={handleUpdateBasicData}
            isLoading={basicButtonLoading}
            isDisabled={basicButtonLoading}
            variant={`brandPrimary`}
          >
            {i18n("update_basic_info")}
          </Button>
        </Flex>
      </Box>
    </DetailWrapper>
  );
};

export default PersonalDataV4;
