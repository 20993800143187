import { Box, Flex } from "@chakra-ui/react";
import React, { Fragment } from "react";
import SubListSelector from "../../components/v4Layout/SubListSelector/SubListSelector";
import PlatformSelector from "../../components/v4Layout/PlatformSelector/PlatformSelector";
import Seo from "./seo";
import MarqueeListV2 from "../../components/marquee/marqueeList_v2";
import GameTypeSelectorV4 from "../../components/v4Layout/GameTypeSelector/GameTypeSelector_v4";
import GameSelector from "../../components/v4Layout/GameSelector/GameSelector";
import SubListSelectorV4 from "../../components/v4Layout/SubListSelector/SubListSelector_v4";
import PlatformSelectorV4 from "../../components/v4Layout/PlatformSelector/PlatformSelector_v4";
import MobileSearchV4 from "../Search/mobile-search_v4";

const SportsV4 = ({ gameMainTypeFromProps }) => {
  return (
    <Fragment>
      <Seo />
      <Box as="section" px="common-y">
        <GameTypeSelectorV4 gameMainTypeFromProps={gameMainTypeFromProps} />
      </Box>
      <Box as="section" pt="home-space" px="common-y">
        <SubListSelectorV4
          forceShowAll
          gameMainTypeFromProps={gameMainTypeFromProps}
        />
      </Box>
      {/* <Box as="section" pt="home-space" px="common-y">
        <MarqueeListV2 />
      </Box> */}

      <Box
        mt={{
          md: "0px",
          // base: "10px",
        }}
        pt="home-space"
      >
        <MobileSearchV4 listHidden />
      </Box>
      <Box overflowY="scroll" flex={1}>
        <Box pb="home-space" px="common-y">
          <PlatformSelectorV4 gameMainTypeFromProps={gameMainTypeFromProps} />
        </Box>
        <Box py="home-space" px="common-y">
          <GameSelector gameMainTypeFromProps={gameMainTypeFromProps} />
        </Box>
      </Box>
    </Fragment>
  );
};

export default SportsV4;
