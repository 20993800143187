import React from "react";
import Aside from "./aside";
import { Outlet, Route, Routes, useNavigate, useLocation } from "react-router";
import { usePreviousPagePath } from "../../hook/usePreviousPagePath";
import useDeviceType from "../../hook/useDeviceType";
import SimpleRewards from "../Rewards/simpleRewards";
import SimpleReferCode from "../ReferCode/simpleReferCode";
import { common_route } from "../../utils/routes";
import { Box, useColorModeValue } from "@chakra-ui/react";
import { AnimatePresence } from "framer-motion";
import AsideVersionSelector from "./asideVersionSelector";

const SettingIndexV4 = ({ children, showAside }) => {
  const indexBg = useColorModeValue("light.200", "dark.200");
  const borderBg = useColorModeValue("light.100", "dark.300");
  const location = useLocation();

  return (
    <Box
      as="main"
      position="fixed"
      top="0px"
      left="0px"
      w="100%"
      h="100%"
      zIndex="9999"
      display="flex"
      alignItems="center"
      justifyContent="center"
      px={{
        base: "",
        md: "common-x",
      }}
      className="box-shadow"
    >
      <Box
        as="section"
        w={{
          base: "100%",
          md: "755px",
        }}
        minH={{
          md: "710px",
        }}
        maxH={{
          md: "600px",
        }}
        h="100%"
        bg={indexBg}
        border="1px solid"
        borderColor={borderBg}
        borderRadius={{
          md: "15px",
        }}
        overflow="hidden"
        display="flex"
        justifyContent={`space-between`}
        onClick={(e) => e.stopPropagation()}
      >
        <Box
          as="aside"
          w={{
            base: "100%",
            md: "350px",
          }}
          py={{
            base: "10px",
            md: "15px",
          }}
          px="10px"
        >
          <AsideVersionSelector />
        </Box>
        <Box
          as="main"
          w={{
            md: "375px",
          }}
          position="relative"
          overflowX="hidden"
          display="flex"
          flexDirection={`column`}
          className="no-scrollbar"
        >
          <Routes>
            {common_route
              .filter((route) => !route.hidden)
              .filter((route) => route.profile)
              .map((route) => {
                return (
                  <Route
                    path={route.path}
                    element={
                      <>
                        {route.element}
                        <Outlet />
                      </>
                    }
                  >
                    {route.children?.map((item) => {
                      return (
                        <Route
                          key={item.label}
                          path={`${item.path}`}
                          element={
                            <>
                              {item.element}
                              <Outlet />
                            </>
                          }
                        />
                      );
                    })}
                  </Route>
                );
              })}
          </Routes>
        </Box>
      </Box>
    </Box>
  );
};

export default SettingIndexV4;
