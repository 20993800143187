import React, { useMemo } from "react";
import MenuV2 from "./Menu_v2";
import Menu from "./Menu";
import { WEB_LAYOUT } from "../../constant";
import MenuV4 from "./Menu_v4";
import useDeviceType from "../../hook/useDeviceType";
import MenuPcV4 from "./MenuPC_v4";

const MenuVersionSelector = () => {
  const deviceType = useDeviceType();

  const menu = useMemo(() => {
    switch (WEB_LAYOUT) {
      case "v2":
        return <MenuV2 />;
      case "v3":
        return <MenuV2 />;
      case "v4":
        return deviceType === "PC" || deviceType === "Tablet" ? (
          // 電腦版
          <MenuPcV4 />
        ) : (
          // 手機版
          <MenuV4 />
        );
      default:
        return <Menu />;
    }
  }, [WEB_LAYOUT]);
  return menu;
};

export default MenuVersionSelector;
