import React, { useState } from "react";
import LazyImage from "../Loading/lazyImage";
import {
  Box,
  Flex,
  Heading,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

const PROMOTIONITEM = ({ customWidth, itemClass, originProps }) => {
  const textColor = useColorModeValue("dark.200", "light.100");

  const bgColor = useColorModeValue("light.100", "dark.100");

  const { uid, main_title, sub_title, error_url, url } = originProps || {};

  return (
    <Link to={`/promotion/detail/${uid}`}>
      <Box
        borderRadius={`5px`}
        height={{
          lg: `185px`,
        }}
        mb={`5px`}
        minWidth={`110px`}
        position="relative"
        cursor={`pointer`}
        overflow={`hidden`}
        key={uid}
        className={`${customWidth} ${itemClass}`}
        // style={{ backgroundColor: specialColor }}
      >
        <LazyImage
          isPromotion
          className="object-cover h-full w-full group-hover:scale-[1.05] transition duration-300"
          src={url}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = error_url;
          }}
        />

        <Box
          position={`absolute`}
          bottom={`0px`}
          left={`0px`}
          width={`100%`}
          height={`96px`}
          color={textColor}
          className="promotion-item-bg"
        >
          <Flex py="12px" px="16px" direction={`column`} gap="5px">
            <Heading
              className="text-overflow"
              as="h3"
              size="md"
              my="0px"
              fontWeight={`semibold`}
            >
              {main_title}
            </Heading>
            <Heading as="h5" size="xs" fontWeight={`normal`} my="0px">
              {sub_title}
            </Heading>
          </Flex>
        </Box>
      </Box>
    </Link>
  );
};

export default PROMOTIONITEM;
