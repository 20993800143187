import i18next from "i18next";
import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { appName } from "../../config";

const Seo = () => {
  const { t } = useTranslation();
  const i18n = (key, props) => t(`seo.sports.${key}`, { ...props });

  return process.env.REACT_APP_DEVELOP_MODE == 1 ? null : (
    <Helmet>
      <meta charSet="utf-8" />
      <meta property="og:description" content={appName} />
      <meta property="og:title" content={appName} />
      <meta property="og:url" content={`${window.location.origin}/sports`} />
      <link rel="canonical" href={`${window.location.origin}/sports`} />
      <title lang={i18next.language}>{i18n("title", { appName })}</title>
      <meta lang={i18next.language} content={i18n("meta1", { appName })} />{" "}
    </Helmet>
  );
};

export default Seo;
