import { stylesConfig } from "../helpers/stylesConfig";
import { useLocation, useNavigate } from "react-router";
import { COMMON_ALT } from "../constant";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  chakra,
  Container,
  Flex,
  Icon,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  shouldForwardProp,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { MdOutlineLanguage } from "react-icons/md";
import { openLanguagePop } from "../redux/action/member/memberAction";
import { IoSearch } from "react-icons/io5";
import useCheckLogin from "../hook/useCheckLogin";
import BalanceV2 from "./SignInUp/components/Balance_v2";
import SignInDataV2 from "./SignInUp/SignInData_v3";
import CreditPage from "../utils/credit/credit_page";
import BalanceV3 from "./SignInUp/components/Balance_v3";
import SignInDataV3 from "./SignInUp/SignInData_v3";
import HeaderAppDownload from "../pages/App/HeaderAppDownload";
import BalanceV4 from "./SignInUp/components/Balance_v4";
import SignInDataV4 from "./SignInUp/SignInData_v4";
import ColorSwitcher from "../pages/MenuBar/components/colorSwitcher";
import { FaUser } from "react-icons/fa";
import ColorSwitcherV4 from "../pages/MenuBar/components/colorSwitcher_v4";
import { LANGUAGES_MENU } from "../i18n/config";

const isCredit = process.env.REACT_APP_CREDIT == 1;

const HeaderV4 = () => {
  const { t, i18n } = useTranslation();
  //const i18n = (key) => t(`common.${key}`);
  const i18n_header = (key) => t(`header.${key}`);
  const i18n_common = (key) => t(`common.${key}`);

  const headerBg = useColorModeValue("#F3F6FD", "#1A2036");
  const headerBgGradient = useColorModeValue(
    "--header-gradient.light",
    "--header-gradient.dark"
  );
  const signInColor = useColorModeValue("dark.100", "light.100");
  const headerBorder = useColorModeValue("", "dark.200");
  const { colorMode, toggleColorMode } = useColorMode();

  const iconBg = useColorModeValue("#E9E8F2", "#262D47");
  //const baseBg = useColorModeValue("light.100", "#262D47");
  const iconColor = useColorModeValue("brand.300", "#edeeef");
  const textColor = useColorModeValue("#7b7b7b", "light.100");
  const imageFilter = useColorModeValue("none", "brightness(0) invert(1)");

  const { isLogin } = useCheckLogin();

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation(); //返回一個location對象，其中包含當前URL的信息。它與window.location最大的區別是，它不會引起頁面重新加載。你可以在任何地方使用它，包括React Hooks組件中。

  const isGameBox = useMemo(() => {
    return location.pathname.includes("gamebox");
  }, [location.pathname]);

  const { pathname } = location; //取網址

  // 取當前語言文字
  const getCurrentLanguageText = () => {
    const currentLang = i18n.language.split("_")[0]; // 處理可能的格式如 "en_US"
    const languageItem = LANGUAGES_MENU.find(
      (lang) => lang.value === currentLang
    );
    return languageItem ? languageItem.text : ""; // 如果找不到對應語言，返回空字串
  };

  return (
    <Box
      width={{
        base: `100%`,
        xl: `1240px`,
      }}
      minHeight={{
        sm: "45px",
        base: "45px",
      }}
      position="sticky"
      flexDirection={"column"}
      mx="auto"
      top="0px"
      // left="0px"
      // bg={headerBg}
      zIndex="999"
      display="flex"
      alignItems="center"
      justifyContent="space-between"

      // borderColor={headerBorder}
    >
      <HeaderAppDownload />

      <Flex
        // ml={{
        //   base: "0px",
        //   lg: isGameBox ? "0px" : "219px",
        // }}
        bg={headerBg}
        // bgGradient="linear(to-b, #F3F6FD,#)"
        alignItems={`center`}
        justifyContent={`space-between`}
        pl={{
          md: "0px",
          base: "20px",
          // lg: isGameBox ? "0px" : "245px",
        }}
        pr={{
          md: "0px",
          base: "20px",
        }}
        py="18px"
        width={`100%`}
        // maxWidth={{
        //   sm: "1024px",
        //   lg: "1440px",
        // }}
        height="100%"
        overflowY="hidden"
      >
        {" "}
        <Flex
          // display={{
          //   base: "block",
          //   lg: isGameBox ? "block" : "none",
          // }}
          alignItems={`center`}
          color="light.100"
        >
          <Box>
            <Link to="/">
              <Image //用三元運算 (? :) 去判斷 如果已登入就顯示 stylesConfig.loggedInLogo 圖片，否則顯示 stylesConfig.mainLogo 圖片
                src={stylesConfig.mainLogo}
                cursor={"pointer"}
                mr={{
                  base: "0px",
                  lg: "45px",
                }}
                ml={{
                  base: "0px",
                  lg: "0px",
                }}
                mt={{
                  base: "5px",
                  lg: "5px",
                }}
                width={
                  isLogin
                    ? {
                        md: "135px",
                        base: "95px",
                      }
                    : {
                        md: "135px",
                        base: "95px",
                      }
                }
                pointerEvents={"all"}
                alt={COMMON_ALT}
              />
            </Link>
          </Box>
        </Flex>
        <Flex alignItems={`center`} gap="15px" justifyContent={`flex-end`}>
          <Flex
            display={{
              base: "none",
              md: "block",
            }}
            alignItems={`center`}
            color="light.100"
          >
            <Box>
              <Link to="search">
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <Icon as={IoSearch} color="gray.300" />
                  </InputLeftElement>
                  <Input
                    readOnly
                    type="text"
                    variant={`brandPrimary`}
                    borderRadius={`10px`}
                    width={{
                      md: "180px",
                      lg: "200px",
                      xl: "500px",
                      "2xl": "550px",
                    }}
                    bg={`${iconBg} !important`}
                    placeholder={i18n_common("Search")}
                  />
                </InputGroup>
              </Link>
            </Box>
          </Flex>
          <Box //電腦版
            display={{
              md: "flex",
              base: "none",
            }}
            gap={{
              md: "15px",
              base: "5px",
            }}
            alignItems={`center`}
          >
            {/* <IconCircleWrapper
              divProps={{
                onClick: () => navigate("search"),
              }}
              className={`hidden md:flex min-h-[38px] min-w-[38px]`}
            >
              <BiSearch className="text-xl" />
            </IconCircleWrapper> */}
            {isLogin ? <BalanceV4 /> : <></>}
            <ColorSwitcherV4
              colorClassName="!text-white dark:!text-base-dark"
              wordHidden
            />
            {/* <IconCircleWrapper
              divProps={{
                onClick: () => dispatch(openLanguagePop()),
              }}
              className={`hidden md:flex min-h-[38px] min-w-[38px]`}
            >
              <MdOutlineLanguage className="text-xl" />
            </IconCircleWrapper> */}
            <Box
              onClick={() => dispatch(openLanguagePop())}
              display={{
                base: "none",
                md: "flex",
              }}
              position="relative"
              cursor="pointer"
              borderRadius={"10px"}
              w="auto"
              minW="48px"
              px="12px"
              gap="8px"
              h="48px"
              bg={iconBg}
              alignItems={"center"}
              justifyContent={"center"}
              shadow="sm"
            >
              {/* <Icon
                as={MdOutlineLanguage}
                fontSize="23px"
                mb="3px"
                color={iconColor}
              /> */}
              <Image
                src="https://d1h3qe8voog6w4.cloudfront.net/v4-layout/language.svg"
                w="21px"
                h="21px"
                filter={imageFilter}
              />
              <Box color={textColor} fontSize="14px" fontWeight="medium">
                {" "}
                {getCurrentLanguageText()}
              </Box>
            </Box>
          </Box>
          {isLogin ? (
            <Flex gap="5px">
              <Box
                display={{
                  md: "none",
                  base: "flex",
                }}
              >
                <BalanceV4 />
              </Box>
              <SignInDataV4 />
            </Flex>
          ) : (
            <Flex gap="5px">
              <Link to="signin">
                <Box
                  initial={{ rotate: 0 }}
                  position="relative"
                  cursor="pointer"
                  borderRadius={"10px"}
                  w="40px"
                  h="40px"
                  bg={iconBg}
                  display="flex"
                  alignItems={"center"}
                  justifyContent={"center"}
                  shadow="sm"
                >
                  <Image
                    src="https://d1h3qe8voog6w4.cloudfront.net/v4-layout/signout.svg"
                    w="21px"
                    h="21px"
                    mb="3px"
                  />
                  {/* <Icon
                    as={FaUser}
                    cursor="pointer"
                    fontWeight={`semibold`}
                    fontSize="23px"
                    mb="3px"
                    color={iconColor}
                  /> */}
                </Box>
                {/* <Button
                  variant={`brandPrimary`}
                  type="button"
                  // bg={isCredit ? null : "none"}
                  fontWeight={`semibold`}
                  minWidth={`45px`}
                  height="2.5rem"
                  fontSize="sm"
                >
                  {i18n_header("signin")}
                </Button> */}
              </Link>
              <Box
                onClick={() => dispatch(openLanguagePop())}
                display={{
                  base: "flex",
                  md: "none",
                }}
                alignItems={`center`}
                position="relative"
                cursor="pointer"
                borderRadius={"10px"}
                w="40px"
                h="40px"
                bg={iconBg}
                justifyContent={"center"}
                shadow="sm"
              >
                {/* <Icon
                  as={MdOutlineLanguage}
                  fontSize="23px"
                  color={iconColor}
                /> */}
                <Image
                  src="https://d1h3qe8voog6w4.cloudfront.net/v4-layout/language.svg"
                  w="21px"
                  h="21px"
                  mb="3px"
                  filter={imageFilter}
                />
              </Box>

              {/* <CreditPage> */}
              {/* {window.open_registration == 1 && (
                <Link to="signup">
                  <Button
                    variant={"brandPrimary"}
                    type="button"
                    fontWeight={`semibold`}
                    minWidth={`45px`}
                    height="2.5rem"
                    fontSize={`sm`}
                  >
                    {i18n_header("signup")}
                  </Button>
                </Link>
              )} */}

              {/* </CreditPage> */}
            </Flex>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};

export default HeaderV4;
