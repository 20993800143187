import { useNavigate } from "react-router";

import { RiUserFill } from "react-icons/ri";
import { useEffect, useMemo, useRef, useState } from "react";
import useDeviceType from "../../hook/useDeviceType";
import IconCircleWrapper from "../Icon/IconCircleWrapper";
import { BiSolidBellRing } from "react-icons/bi";
import { useSelector } from "react-redux";
import {
  Box,
  Flex,
  Icon,
  Image,
  Text,
  chakra,
  shouldForwardProp,
  useColorModeValue,
} from "@chakra-ui/react";
import { isValidMotionProp, motion } from "framer-motion";
import { COMMON_WEB_PATH } from "../../constant";
import { CgBell } from "react-icons/cg";

const bellShakeAnimation = {
  rotate: [0, 20, -20, 20, -20, 0],
  transition: {
    duration: 0.5,
    ease: "easeInOut",
    times: [0, 0.2, 0.4, 0.6, 0.8, 1],
    repeat: Infinity,
    repeatDelay: 1,
  },
};

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const SignInDataV4 = ({}) => {
  const deviceType = useDeviceType();

  const iconBg = useColorModeValue("#E9E8F2", "#262D47");
  //const baseBg = useColorModeValue("light.100", "#262D47");
  const iconColor = useColorModeValue("#6F6F6F", "light.100");

  const inboxList = useSelector((state) => state.isInboxList);

  const navigate = useNavigate();

  const hasUnRead = useMemo(() => {
    return {
      unRead: inboxList?.filter((item) => item.status == "Unread")?.length > 0,
      unReadLength: inboxList?.filter((item) => item.status == "Unread")
        ?.length,
    };
  }, [inboxList]);

  const { unRead, unReadLength } = hasUnRead || {};

  return (
    <Flex alignItems={`center`} gap="5px">
      {/* <IconCircleWrapper
        divProps={{
          onClick: () => navigate("profile/inbox"),
        }}
      >
        {hasUnRead && (
          <Box
            position={`absolute`}
            top={`0px`}
            right={`0px`}
            width={`5px`}
            height={`5px`}
            borderRadius={`full`}
            bg="red"
          ></Box>
        )}
        <ChakraBox
          initial={{ rotate: 0 }}
          animate={hasUnRead && bellShakeAnimation}
          position="relative"
        >
          <BiSolidBellRing className="text-xl" />
        </ChakraBox>
      </IconCircleWrapper> */}
      <Box
        onClick={() => navigate("profile/inbox")}
        position="relative"
        cursor="pointer"
        borderRadius={"10px"}
        w="48px"
        h="48px"
        bg={iconBg}
        display="flex"
        alignItems={"center"}
        justifyContent={"center"}
        shadow="sm"
      >
        {unRead && (
          <Box
            position={`absolute`}
            top={`-2px`}
            right={`-2px`}
            width={`12px`}
            height={`12px`}
            borderRadius={`full`}
            bg="red.500"
            display="flex"
            alignItems={`center`}
            zIndex={2}
            justifyContent={`center`}
          >
            <Text color="#000" fontSize="10px">
              {unReadLength}
            </Text>
          </Box>
        )}

        <ChakraBox
          initial={{ rotate: 0 }}
          animate={unRead && bellShakeAnimation}
          position="relative"
        >
          <Icon
            as={CgBell}
            fontSize="21px"
            fontWeight="black"
            mb="3px"
            color={iconColor}
            boxSize="21px" // 替代 fontSize
            strokeWidth=".5" // 增加線條寬度
          />
        </ChakraBox>
      </Box>

      {/* <IconCircleWrapper
        divProps={{
          onClick: () => {
            if (deviceType === "Mobile") {
              navigate("profile");
            } else {
              navigate("profile/data");
            }
          },
          display: {
            base: "none",
            md: "flex",
          },
        }}
      >
        <RiUserFill className="text-xl" />
      </IconCircleWrapper> */}
      {/* <Box
        onClick={() => {
          if (deviceType === "Mobile") {
            navigate("profile");
          } else {
            navigate("profile/data");
          }
        }}
        display={{
          base: "flex",
          md: "flex",
        }}
        position="relative"
        cursor="pointer"
        borderRadius={"10px"}
        w="35px"
        h="35px"
        bg={iconBg}
        alignItems={"center"}
        justifyContent={"center"}
        shadow="sm"
      >
        <Icon as={RiUserFill} fontSize="md" mb="3px" color={iconColor} />
      </Box> */}
    </Flex>
  );
};

export default SignInDataV4;
