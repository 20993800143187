import React, { useEffect, useMemo, useState } from "react";
import { getEventList } from "../../../api/getApi";
import { languageMapping } from "../../../i18n/config";
import i18next from "i18next";
import { useSelector } from "react-redux";
import { checkEventStatus, checkEventWagerStatus } from "../../../api/postApi";
import RewardDashboard from "./rewardDashboard";
import NotFound from "../../../components/ProgressBlock/NotFound";
import LoadingBetRecord from "../../../components/Loading/LoadingBetRecord";
import { Flex } from "@chakra-ui/react";
import LoadingReward from "../../../components/Loading/LoadingReward";
import useProcessEventList from "../../../hook/useProcessEventList";
import { waitTime } from "../../../utils/waitTime";

const RewardList = ({ datePicker, direction, gameConfirmMode } = {}) => {
  const globalTrigger = useSelector((state) => state.isGlobalTrigger);
  const walletCurrency = useSelector((state) => state.isWalletCurrency);
  const eventList = useSelector((state) => state.isEventList);

  const processEventList = useProcessEventList({
    event_list: eventList,
  });

  const [apiCalling, setApiCalling] = useState(true);
  const [rewardStatus, setRewardStatus] = useState({});
  const [rewardDetail, setRewardDetail] = useState({});
  const [fetchingStatusLoading, setFetchingStatusLoading] = useState(true);

  const filter_null_status = useMemo(() => {
    return processEventList
      ?.filter((item) => {
        return item?.is_between_time;
      })
      ?.filter((item) => {
        return rewardStatus?.[item.uid] !== undefined;
      });
  }, [processEventList, rewardStatus]);

  useEffect(() => {
    setApiCalling(true);
    setTimeout(() => {
      setApiCalling(false);
    }, 2000);
  }, [datePicker, globalTrigger, walletCurrency]);

  useEffect(() => {
    const uid_list = processEventList.map((item) => item.uid);
    const fetchRewardStatus = async (event_uid) => {
      setFetchingStatusLoading(true);
      const response = await checkEventStatus({
        formData: {
          currency: [walletCurrency],
          event_uid: uid_list,
        },
      });
      await waitTime(1000);
      setFetchingStatusLoading(false);
      setRewardStatus(response?.data?.after?.event_status);
      setRewardDetail(response?.data?.event_status);
    };
    // const fetchWagerStatus = async () => {
    //   const response = await checkEventWagerStatus({});
    //    return response;
    // };

    fetchRewardStatus();
  }, [processEventList, walletCurrency]);
  return (
    <Flex
      direction={direction || `column`}
      overflowX={direction && "scroll"}
      gap="10px"
    >
      <LoadingReward gameConfirmMode commonLoading={apiCalling}>
        {filter_null_status?.length !== 0 ? (
          filter_null_status
            ?.sort((a, b) => {
              const sortOrder = {
                //禮包狀態馬
                1: 0,
                0: 1,
                2: 2,
              };
              const rankA = sortOrder[rewardStatus?.[a.uid]];
              const rankB = sortOrder[rewardStatus?.[b.uid]];
              return rankA - rankB;
            })
            ?.map((item) => {
              const reward_status = rewardStatus?.[item.uid];
              return (
                <RewardDashboard
                  fetchingStatusLoading={fetchingStatusLoading}
                  gameConfirmMode={gameConfirmMode}
                  props={{
                    ...item,
                    reward_status: reward_status,
                    reward_detail: rewardDetail,
                  }}
                />
              );
            })
        ) : (
          <NotFound />
        )}
      </LoadingReward>
    </Flex>
  );
};
export default RewardList;
