import React, { useEffect, useState } from "react";

const usePwaDetected = () => {
  const [isPwa, setIsPwa] = useState(false);

  useEffect(() => {
    const isIosPwa = window.navigator.standalone;

    const isOtherPwa = window.matchMedia("(display-mode: standalone)").matches;

    if (isIosPwa || isOtherPwa) {
      setIsPwa(true);
    }

    const handler = (e) => {
      setIsPwa(e.matches);
    };
    window
      .matchMedia("(display-mode: standalone)")
      .addEventListener("change", handler);

    return () => {
      window
        .matchMedia("(display-mode: standalone)")
        .removeEventListener("change", handler);
    };
  }, []);
  return isPwa;
};

export default usePwaDetected;
