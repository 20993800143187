import { AnimatePresence, isValidMotionProp, motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import { FaHeadphonesAlt } from "react-icons/fa";
import { MdKeyboardArrowDown, MdOutlineClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import useDeviceType from "../../hook/useDeviceType";
import { closeChat, openChat } from "../../redux/action/member/memberAction";
import { useNavigate } from "react-router";
import LoadingBlock from "../../components/Loading/LoadingBlock";

import { useTranslation } from "react-i18next";
import {
  Box,
  Image,
  Spinner,
  chakra,
  shouldForwardProp,
  useColorModeValue,
} from "@chakra-ui/react";
import { stylesConfig } from "../../helpers/stylesConfig";
import useCheckLogin from "../../hook/useCheckLogin";

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const ServicePing = ({ isGameBox }) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`gamebox.${key}`);

  const boxBg = useColorModeValue("light.100", "dark.100");

  const [iframeIsLoading, setIframeIsLoading] = useState(true);

  const isMobile = useDeviceType() === "Mobile";

  const { isLogin } = useCheckLogin();

  const memberData = useSelector((state) => state.isMemberInfo);
  const openChatRoom = useSelector((state) => state.isOpenChatRoom);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    setIframeIsLoading(true);
  }, [openChatRoom]);

  const handleOpenChat = () => {
    if (openChatRoom) {
      dispatch(closeChat());
    } else {
      if (isLogin) {
        dispatch(openChat());
      } else {
        navigate("service-group");
      }
    }
  };

  return (
    <>
      <Box
        as="section"
        onClick={() => {
          handleOpenChat();
        }}
        display={{
          base: "none",
          lg: isGameBox && isMobile ? "none" : "flex",
        }}
        cursor={"pointer"}
        color="light.100"
        fontSize="2xl"
        borderBottom={"3px solid"}
        borderBottomColor={"brand.900"}
        borderLeft={"1px solid"}
        borderRight={"1px solid"}
        borderLeftColor={"brand.900"}
        borderRightColor={"brand.900"}
        borderTop={"0.5px solid"}
        borderTopColor={"brand.900"}
        boxShadow={"lg"}
        bg={"brand.500"}
        alignItems={"center"}
        justifyContent={"center"}
        borderRadius={"full"}
        w={{
          base: "44px",
          md: "55px",
        }}
        h={{
          base: "44px",
          md: "55px",
        }}
      >
        <AnimatePresence>
          {openChatRoom && !isMobile ? (
            <ChakraBox
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0 }}
              transition={{ duration: 0.2 }}
              style={{ originX: 1, originY: 1 }}
              w={{
                base: "90vw",
                md: "400px",
              }}
              h={{
                base: "450px",
                md: "70vh",
              }}
              position={{
                base: "fixed",
                md: "absolute",
              }}
              bottom={"60px"}
              right="0"
              overflow={"hidden"}
              borderRadius={"xl"}
              bg={boxBg}
              boxShadow={"lg"}
              className={`max-[640px]:!transform-none`}
            >
              {iframeIsLoading && (
                <Box
                  h={"100%"}
                  w={"100%"}
                  display="flex"
                  flexDirection={"column"}
                  gap="5px"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Image
                    w="200px"
                    h="100px"
                    objectFit={"cover"}
                    src={stylesConfig?.mainLogo}
                  />
                  <Spinner variant={`brandPrimary`} size={"xl"}>
                    {i18n("loading")}
                  </Spinner>
                </Box>
              )}

              <iframe
                onLoad={() => {
                  setIframeIsLoading(false);
                }}
                className="w-full h-full"
                src={`https://chatsys4.911win.fun/mobile/index/home?visiter_id=${memberData?.uid}&visiter_name=${memberData?.memId}&avatar=&business_id=admin&groupid=0&lang=vn&ismobile=1`}
              ></iframe>
            </ChakraBox>
          ) : null}
        </AnimatePresence>
        {openChatRoom ? <MdKeyboardArrowDown /> : <FaHeadphonesAlt />}
      </Box>
      {/* </Draggable> */}
      <AnimatePresence>
        {openChatRoom && isMobile ? (
          <ChakraBox
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0 }}
            transition={{ duration: 0.2 }}
            style={{ originX: 1, originY: 1 }}
            zIndex={999999}
            position="fixed"
            top={0}
            right={0}
            w={"100vw"}
            overflow="hidden"
            bg={boxBg}
            boxShadow={"lg"}
            className="webkit-height"
          >
            <MdOutlineClose
              onClick={() => {
                dispatch(closeChat());
              }}
              className="absolute top-[10px] right-[10px] text-2xl text-white"
            />
            {iframeIsLoading && (
              <Box
                h="100%"
                w="100%"
                display="flex"
                flexDirection="column"
                gap="5px"
                alignItems="center"
                justifyContent="center"
              >
                <Image
                  w="200px"
                  h="100px"
                  objectFit="cover"
                  src={stylesConfig?.mainLogo}
                />
                <Spinner variant={`brandPrimary`} size={"xl"}>
                  {i18n("loading")}
                </Spinner>
              </Box>
            )}
            <iframe
              onLoad={() => {
                setIframeIsLoading(false);
              }}
              className="w-full h-full pb-[25px] bg-white"
              src={`https://chatsys4.911win.fun/mobile/index/home?visiter_id=${memberData?.uid}&visiter_name=${memberData?.memId}&avatar=&business_id=admin&groupid=0&lang=vn&ismobile=1`}
            ></iframe>
          </ChakraBox>
        ) : null}
      </AnimatePresence>
    </>
  );
};

export default ServicePing;
