import { useState } from "react";

import { BiMessageSquareAdd } from "react-icons/bi";
import { GiHamburgerMenu } from "react-icons/gi";
import {
  RiHome3Line,
  RiHomeSmileFill,
  RiMoneyDollarCircleLine,
  RiUserFill,
} from "react-icons/ri";
import { IoGift, IoQrCode } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router";
import { loading, loadingDestroy, openMenu } from "../redux/action/action";
import { useDispatch } from "react-redux";
import { HiShare } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import { MdEventAvailable, MdHistory } from "react-icons/md";
import usePwaDetected from "../hook/usePwaDetected";
import {
  AbsoluteCenter,
  Box,
  Flex,
  Image,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaHeadphonesAlt, FaUser } from "react-icons/fa";
import useDeviceType from "../hook/useDeviceType";
import { AiOutlineVerticalAlignTop } from "react-icons/ai";
import { openChat } from "../redux/action/member/memberAction";
import useCheckLogin from "../hook/useCheckLogin";
import { TbReportAnalytics } from "react-icons/tb";
import { COMMON_WEB_PATH } from "../constant";
import { GoHomeFill } from "react-icons/go";
import { NavLink } from "react-router-dom";
import { IoIosShareAlt } from "react-icons/io";

const iconStyle = "text-[1.8rem]";

const isCredit = process.env.REACT_APP_CREDIT == 1;

const FooterV4 = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`footer.${key}`);

  const [isFooterOpen, setIsFooterOpen] = useState("Home");
  const isPwa = usePwaDetected();

  const footerBg = useColorModeValue("#FFFFFF", "#46507A");
  const footerText = useColorModeValue("dark.300", "brand.500");

  const { isLogin } = useCheckLogin();

  const deviceType = useDeviceType();

  const dispatch = useDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const FOOTER_LIST = [
    {
      image: `${COMMON_WEB_PATH}/v4-layout/all.svg`,
      image_hover: `${COMMON_WEB_PATH}/v4-layout/all2.svg`,
      //icon: <GiHamburgerMenu className={iconStyle} />,
      text: "Menu",
      image_name: "menu",
      link: "#",
    },
    {
      image: `${COMMON_WEB_PATH}/v4-layout/award.svg`,
      image_hover: `${COMMON_WEB_PATH}/v4-layout/award2.svg`,
      //icon: <IoGift className={iconStyle} />,
      text: "Rewards",
      link: "profile/rewards",
      auth: true,
      hidden: isCredit,
    },
    {
      image: `${COMMON_WEB_PATH}/v4-layout/all.svg`,
      image_hover: `${COMMON_WEB_PATH}/v4-layout/all2.svg`,
      //icon: <TbReportAnalytics className={iconStyle} />,
      text: "betRecord",
      link: "profile/betRecord",
      image_name: "record",
      auth: true,
      hidden: !isCredit,
    },
    //{
    //   image: `${COMMON_WEB_PATH}/v4-layout/all.svg`,
    //   icon: <GoHomeFill className={iconStyle} />,
    //   // icon: (
    //   //   <AbsoluteCenter
    //   //     w="80px"
    //   //     h="70px"
    //   //     top="25px"
    //   //     border="1.5px solid"
    //   //     borderColor="brand.900"
    //   //     outline={"1px solid #2C263E"}
    //   //     // bg="brand.500"
    //   //     borderTopRadius={`common`}
    //   //     bgGradient={
    //   //       "linear(to-br,brand.200,brand.400,brand.800,brand.300,brand.800)"
    //   //     }
    //   //     display="flex"
    //   //     alignItems={`center`}
    //   //     justifyContent={`center`}
    //   //     shadow="md"
    //   //     overflow="hidden"
    //   //     // className="home-icon-shadow"
    //   //   >
    //   //     <Box
    //   //       position="absolute"
    //   //       w="5px"
    //   //       h="5px"
    //   //       opacity=".5"
    //   //       borderRadius={"full"}
    //   //       bg="white"
    //   //       top="2px"
    //   //       left="2px"
    //   //     ></Box>
    //   //     <Box
    //   //       position="absolute"
    //   //       w="3.5px"
    //   //       h="3.5px"
    //   //       opacity=".5"
    //   //       borderRadius={"full"}
    //   //       bg="white"
    //   //       top="1px"
    //   //       right="3px"
    //   //     ></Box>
    //   //     <Box
    //   //       position="absolute"
    //   //       bgGradient={`linear(to-r,brand.500 ,brand.700)`}
    //   //       opacity={"0.15"}
    //   //       w="180%"
    //   //       h="400%"
    //   //       top="35%"
    //   //       left="-11%"
    //   //       borderRadius={`full`}
    //   //     ></Box>
    //   //     <Flex
    //   //       position="relative"
    //   //       zIndex={1}
    //   //       direction={"column"}
    //   //       gap="2.5px"
    //   //       pt="10px"
    //   //     >
    //   //       <Image
    //   //         w="35px"
    //   //         h="35px"
    //   //         ml="3px"
    //   //         src={`${COMMON_WEB_PATH}/b69-home/v3-layout-icon/footer-home.png`}
    //   //       />
    //   //       <Text
    //   //         as="p"
    //   //         mb="0px"
    //   //         fontWeight={`medium`}
    //   //         fontSize={`sm`}
    //   //         w="100%"
    //   //         textAlign="center"
    //   //         className="text-overflow"
    //   //       >
    //   //         {i18n("home")}
    //   //       </Text>
    //   //     </Flex>
    //   //   </AbsoluteCenter>
    //   // ),
    //   text: "home",
    //   link: "/",
    // },
    {
      image: `${COMMON_WEB_PATH}/v4-layout/wallet.svg`,
      image_hover: `${COMMON_WEB_PATH}/v4-layout/wallet2.svg`,
      //icon: <MdHistory className={iconStyle} />,
      text: "Wallet",
      link: "profile",
      image_name: "transaction",
      auth: true,
      hidden: isCredit,
    },
    // {
    //   image: `${COMMON_WEB_PATH}/v4-layout/all.svg`,
    //   icon: <MdHistory className={iconStyle} />,
    //   text: "History",
    //   link: "profile/transaction",
    //   image_name: "transaction",
    //   auth: true,
    //   hidden: isCredit,
    // },
    {
      image: `${COMMON_WEB_PATH}/v4-layout/like.svg`,
      image_hover: `${COMMON_WEB_PATH}/v4-layout/like2.svg`,
      //icon: <MdHistory className={iconStyle} />,
      text: "History",
      link: "/promotion",
      image_name: "transaction",
      auth: true,
    },
    // {
    //   image: `${COMMON_WEB_PATH}/v4-layout/all.svg`,
    //   icon: <IoIosShareAlt className={iconStyle} />,
    //   text: "Refer",
    //   link: "profile/refer-code",
    //   auth: true,
    //   function: () => {
    //     dispatch(openChat());
    //   },
    //   hidden: isCredit,
    // },
    {
      icon: <FaHeadphonesAlt className={iconStyle} />,
      text: "Profile",
      image_name: "profile",
      link: "service-group",
      auth: true,
      function: async () => {
        await dispatch(openChat());
        navigate("/service-group"); // 導航到客服頁
      },
    },
    // {
    //   icon: <RiUserFill className={iconStyle} />,
    //   text: "Profile",
    //   image_name: "profile",
    //   link: deviceType === "Mobile" ? "profile" : "profile/data",
    //   auth: true,
    // },
  ];

  return (
    <Box as="footer" w="100%" position="fixed" bottom="0" left="0" zIndex={999}>
      <Box
        as="section"
        w="100%"
        h="60px"
        bg={footerBg}
        display="grid"
        gridTemplateColumns="repeat(5, minmax(0, 1fr))"
        // mx="auto"
        position="relative"
        borderTopRadius={"common"}
        shadow="0px 11px 30px black"
        alignItems={`center`}
        // className="footer-shadow"
      >
        {FOOTER_LIST?.filter((item) => !item.hidden)?.map((footer, index) => {
          return (
            <NavLink to={footer.auth && !isLogin ? "signin" : footer.link}>
              {(props) => {
                const isActive =
                  footer.text === "Menu" ? false : props.isActive; //Menu項目不要active
                return (
                  <Flex
                    alignItems="center"
                    flexDirection={`column`}
                    // justifyContent={`flex-end`}
                    justifyContent={`center`}
                    position="relative"
                    py="10px"
                    px="5px"
                    zIndex={footer?.text === "home" ? 2 : 1}
                    gap="2.5px"
                    key={index}
                    color={isActive ? "brand.500" : "#CFD1E1"}
                    // borderRight={"1px solid"}
                    // borderRightColor="#71498C"
                    // borderLeft="1px solid"
                    // borderLeftColor="brand.100"
                    onClick={() => {
                      if (footer.function) {
                        footer.function();
                        return;
                      }
                      if (footer.text === "Menu") {
                        dispatch(openMenu());
                      } else {
                        dispatch(loading());

                        setTimeout(() => {
                          navigate(`${footer.link}`);
                          dispatch(loadingDestroy());
                        }, 200);
                      }
                    }}
                  >
                    {footer.image ? (
                      <Image
                        src={isActive ? footer.image_hover : footer.image}
                        _hover={{
                          src: footer.image_hover,
                        }}
                        w={"24px"}
                        h={"24px"}
                      />
                    ) : (
                      <Box
                        transition="all 0.2s ease"
                        _hover={{
                          color: "brand.500",
                        }}
                      >
                        {footer.icon}
                      </Box>
                    )}
                    {/* <Text
                as="p"
                mb="0px"
                fontWeight={`medium`}
                fontSize={`xs`}
                w="100%"
                textAlign="center"
                // className="text-overflow"
              >
                {i18n(footer.text?.toLowerCase())}
              </Text> */}
                  </Flex>
                );
              }}
            </NavLink>
          );
        })}
      </Box>
    </Box>
  );
};

export default FooterV4;
