import { useMemo } from "react";
import { useParams } from "react-router-dom";

const ExceptWrapper = ({ exceptGameType = [], children }) => {
  const { gameMainType } = useParams();

  const isIncludeInExcept = useMemo(() => {
    if (gameMainType) {
      return exceptGameType.includes(gameMainType);
    }
  }, [exceptGameType, gameMainType]);

  return isIncludeInExcept ? null : children;
};

export default ExceptWrapper;
