import { useDispatch, useSelector } from "react-redux";
import { transactionExpressForInput } from "../../../regex_constanst";
import { useTranslation } from "react-i18next";
import { bankWithdraw } from "../../../api/postApi";
import { useEffect, useMemo, useState } from "react";
import { notice } from "../../../redux/action/action";
import { useNavigate } from "react-router";
import WithdrawNotice from "./withdrawNotice";
import { WITHDRAW_AMOUNT_MIN } from "../../../constant";
import { formatNumber } from "../../../utils/formatNumber";
import WithdrawTerm from "./withdrawTerm";
import ErrorHint from "../../../components/Hint/ErrorHint";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Icon,
  Skeleton,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { RiGitBranchFill, RiUser3Fill } from "react-icons/ri";
import { FaAddressCard } from "react-icons/fa";
import { IoIosLock, IoMdShare } from "react-icons/io";
import { MdAccountBox } from "react-icons/md";
import { AiFillDollarCircle } from "react-icons/ai";
import CustomInput from "../../../components/InputComponents/customInput";
import InputCopy from "../../../components/CopyComponent/InputCopy";
import CopyIcon from "../../../components/CopyComponent/CopyIcon";
import { waitTime } from "../../../utils/waitTime";
import ReferQrcode from "../../ReferCode/components/referQrcode";
import useCurrentWager from "../../../hook/useCurrentWager";
import { Link } from "react-router-dom";
import useCurrencySymbol from "../../../hook/useCurrencySymbol";
import GetParams from "../../../utils/getParams";
import { BsFillCalculatorFill } from "react-icons/bs";
import CryptoModal from "./cryptoModal";

const iconStyle = "text-gray-300";

const chain_list = [
  { label: "", value: "" },
  { label: "TRC-20", value: "TRC-20" },
];

const BankWithdraw = ({ washConfig, selectedCurrency }) => {
  const { t } = useTranslation();
  const i18n = (key, props) => t(`settings.${key}`, { ...props });
  const i18n_deposit = (key, props) => t(`deposit.${key}`, { ...props });
  const i18n_common = (key, props) => t(`common.${key}`, { ...props });

  const textColor = useColorModeValue("dark.100", "light.100");

  const toast = useToast();

  const memberData = useSelector((state) => state.isMemberInfo);
  const currency_code = useSelector((state) => state.isWalletCurrency);
  const exchangeCurrency = useSelector((state) => state.isCurrencyExchange);
  const dispatch = useDispatch();

  const { bankAccount, withdraw_password_set } = memberData || {};

  const currentBankAccount = useMemo(() => {
    return bankAccount?.[selectedCurrency];
  }, [selectedCurrency, bankAccount]);

  const { is_crypto } = currentBankAccount || {};

  const navigate = useNavigate();

  const currentCurrencySymbol = useCurrencySymbol({
    origin: true,
    custom_currency: selectedCurrency,
  });

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { wagering, restricted_amount, can_withdraw } = useCurrentWager() || {};

  const currency = GetParams("currency");

  const [buttonLoading, setButtonLoading] = useState(false);
  const [buttonRendering, setButtonRendering] = useState(false);
  const [apiLoading, setApiLoading] = useState(false);
  const [data, setData] = useState({
    bank_name: "",
    bank_branch_name: "",
    bank_card_number: "",
    bank_account: "",
    amount: 0,
    secret_pwd: "",
    understand: false,
  });
  const [openCryptoModal, setOpenCryptoModal] = useState(false);
  const {
    amount,
    secret_pwd,
    understand,
    bank_name,
    bank_branch_name,
    bank_card_number,
    bank_account,
  } = data || {};

  // 使用 selectedBankAccount 來取得相對應的值
  //bank_account 銀行帳號
  //bank_card_number 銀行卡號

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonLoading(true);
    await bankWithdraw({
      currency_code: currency || currency_code,
      withdrawal_amount: amount,
      bank_account_number: bank_account,
      withdrawal_code: secret_pwd,
    })
      .then((data) => {
        toast({
          title: i18n("withdrawalSuccess"),
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        dispatch({ type: "GET_BALANCE" });
        navigate("withdraw-success");
      })
      .catch((err) => {})
      .finally(() => {
        setButtonLoading(false);
      });
  };

  const form = useMemo(() => {
    return [
      {
        label: i18n("BankName"),
        name: "bank_name",
        disabled: true,
        value: bank_name,
        isReadOnly: true,
        icon: <RiUser3Fill className={iconStyle} />,
      },
      {
        label: i18n("BankCard"),
        name: "bank_card_number",
        disabled: true,
        value: bank_card_number,
        isReadonly: true,
        icon: <FaAddressCard className={iconStyle} />,
      },
      {
        label: i18n("BankBranch"),
        name: "bank_branch_name",
        disabled: true,
        value: bank_branch_name,
        isReadonly: true,
        icon: <IoMdShare className={iconStyle} />,
      },
      {
        label: i18n("BankAccount"),
        name: "bank_account",
        disabled: true,
        value: bank_account,
        isReadonly: true,
        icon: <MdAccountBox className={iconStyle} />,
      },
    ];
  }, [data, selectedCurrency]);

  const cryptoForm = useMemo(() => {
    return [
      {
        label: i18n("crypto_address"),
        name: "bank_account",
        value: bank_account,
        onChange: (e) => {
          setData({ ...data, bank_account: e.target.value });
        },
        icon: <MdAccountBox className={iconStyle} />,
      },
      {
        label: i18n("chain"),
        name: "bank_branch_name",
        value: bank_branch_name,
        type: "select",
        options: chain_list,
        onChange: (e) => {
          setData({ ...data, bank_branch_name: e.target.value });
        },
        // icon: <RiGitBranchFill className={iconStyle} />,
      },
    ];
  }, [data, selectedCurrency]);

  const inputForm = useMemo(() => {
    const { is_crypto } = currentBankAccount || {};
    const outputForm = is_crypto ? cryptoForm : form;

    return outputForm;
  }, [selectedCurrency, currentBankAccount, cryptoForm, form]);

  const commonForm = [
    {
      label: (
        <Text>
          {i18n("amount")}({currentCurrencySymbol})
          {is_crypto ? (
            <Icon
              as={BsFillCalculatorFill}
              ml="3px"
              cursor="pointer"
              color="special.gold"
              onClick={() => {
                setOpenCryptoModal(true);
              }}
            />
          ) : null}
        </Text>
      ),
      name: "amount",
      type: "number",
      value: amount,
      placeholder: i18n("atLeast50000", {
        min_amount: formatNumber(WITHDRAW_AMOUNT_MIN, { point: false }),
      }),
      min: 0,
      icon: <AiFillDollarCircle className={iconStyle} />,
      onChange: (value) => {
        setData({ ...data, amount: value });
      },
    },
    {
      label: (
        <Flex alignItems={`center`} gap="5px">
          <Text>{i18n("withdrawalPassword")}</Text>
          {withdraw_password_set != 1 && (
            <Link to="../settings/reset-withdraw-password">
              <Text fontSize="sm" color="brand.500">
                {i18n("not_yet_set")}？
              </Text>
            </Link>
          )}
        </Flex>
      ),
      type: "password",
      name: "secret_pwd",
      placeholder: i18n("idCard.setTransactionPasswordHint"),
      title: i18n("idCard.setTransactionPasswordHint"),
      pattern: transactionExpressForInput,
      icon: <IoIosLock className={iconStyle} />,
      isDisabled: withdraw_password_set != 1,
      onChange: (e) => {
        setData({ ...data, secret_pwd: e.target.value });
      },
    },
  ];

  useEffect(() => {
    const { is_crypto, ...rest } = currentBankAccount || {};

    const calling = async () => {
      if (!is_crypto) {
        setApiLoading(true);
        await setData({
          ...rest,
        });
        await waitTime(1000);
        setApiLoading(false);
      }
    };
    calling();
  }, [selectedCurrency]);

  useEffect(() => {
    const buttonCalling = async () => {
      setButtonRendering(true);

      await waitTime(2500);

      setButtonRendering(false);
    };

    buttonCalling();
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit} className="flex flex-col gap-[10px]">
        <WithdrawNotice selectedCurrency={selectedCurrency} />
        <Divider />
        {inputForm.map((item) => {
          return (
            <Skeleton isLoaded={!apiLoading}>
              <CustomInput props={item} />
            </Skeleton>
          );
        })}
        {commonForm.map((item) => {
          const { icon, ...rest } = item;
          return (
            <Skeleton isLoaded={!apiLoading}>
              <CustomInput props={item} />
            </Skeleton>
          );
        })}
        {/* {amount && <Text color="special.gold">(≈ )</Text>} */}
        <Checkbox
          variant={"brandPrimary"}
          onChange={(e) => {
            setData({ ...data, understand: e.target.checked });
          }}
        >
          <Text as="span" color={textColor}>
            {i18n_deposit("iUnderstand")}{" "}
          </Text>
          <Text
            as="span"
            onClick={onOpen}
            className="underline text-blue-500 cursor-pointer"
          >
            {i18n_deposit("withdrawTerms")}
          </Text>
        </Checkbox>
        <Skeleton isLoaded={!buttonRendering}>
          <Button
            w="100%"
            isLoading={buttonLoading}
            isDisabled={
              !amount ||
              !secret_pwd ||
              buttonLoading ||
              !understand ||
              !can_withdraw
            }
            variant={`brandPrimary`}
            size="lg"
            type="submit"
          >
            {i18n("Submit")}
          </Button>
        </Skeleton>
      </form>
      <WithdrawTerm isOpen={isOpen} onClose={onClose} />
      <CryptoModal
        isOpen={openCryptoModal}
        onClose={() => {
          setOpenCryptoModal(false);
        }}
        setData={setData}
        data={data}
      />
    </>
  );
};

export default BankWithdraw;
