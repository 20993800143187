import React, { useRef } from "react";
import { Box, Flex, Text, Input } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { IoIosLock } from "react-icons/io";
import { transactionExpressForInput } from "../../../regex_constanst";
import { Link } from "react-router-dom";

/**
 * 提款密碼輸入組件
 * @param {string} value - 密碼值
 * @param {function} onChange - 密碼更改處理函數
 * @param {number} withdraw_password_set - 提款密碼設置狀態（1: 已設置，其他: 未設置）
 */

const WithdrawPasswordInput = ({ value, onChange, withdraw_password_set }) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`settings.${key}`);

  // 創建四個輸入框的 refs，用來管理輸入框焦點
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

  /**
   * 處理輸入框值變化
   * @param {number} index - 當前輸入框的索引（0-3）
   * @param {string} inputValue - 輸入的值
   */
  // 處理輸入框值變化
  const handleInputChange = (index, inputValue) => {
    // 移除非數字字符，只保留數字
    const cleanValue = inputValue.replace(/[^0-9]/g, "");

    // 如果是一次輸入或貼上多個數字的情況
    if (cleanValue.length > 1) {
      // 限制取前4個數字分配到框框內，不足的部分保持原狀
      const newValue = cleanValue.slice(0, 4).padEnd(4, value);
      onChange(newValue);

      // 自動聚焦邏輯：如果輸入完整四位數 則聚焦到最後一格，否則聚焦到下一個空格
      // 如果輸入滿4位，焦點移到最後一個格子
      if (newValue.length === 4) {
        inputRefs[3].current?.focus();
      } else if (newValue.length > 0) {
        // 否則焦點移到下一個空輸入框
        inputRefs[newValue.length]?.current?.focus();
      }
    } else {
      // 單個數字輸入的情況
      const newValue = value.split("");
      newValue[index] = cleanValue;
      const updatedValue = newValue.join("");
      onChange(updatedValue);

      // 如果當前輸入框有值且不是最後一個，自動聚焦到下一個輸入框
      if (cleanValue && index < 3) {
        inputRefs[index + 1].current?.focus();
      }
    }
  };

  /**
   * 處理鍵盤事件
   * @param {number} index - 當前輸入框的索引
   * @param {KeyboardEvent} e - 鍵盤事件對象
   */
  // 處理鍵盤事件
  const handleKeyDown = (index, e) => {
    // 如果按下的是退格鍵，且當前輸入框為空，且不是第一個輸入框，則將焦點移到前一個輸入框
    if (e.key === "Backspace" && !value[index] && index > 0) {
      inputRefs[index - 1].current?.focus();
    }
  };

  // 處理複製事件
  const handlePaste = (e) => {
    e.preventDefault(); // 阻止默認複製行為
    // 取複製的內容，移除非數字字符
    const pastedData = e.clipboardData.getData("text").replace(/[^0-9]/g, "");
    // 只取前4位數字
    const newValue = pastedData.slice(0, 4).padEnd(4, "");
    onChange(newValue);
  };

  return (
    <Box>
      <Flex alignItems="center" gap="5px" mb={2}>
        <Text fontWeight="bold">{i18n("withdrawalPassword")}</Text>
        {/* 未設置密碼時顯示設置連結 */}
        {withdraw_password_set != 1 && (
          <Link to="../settings/reset-withdraw-password">
            <Text fontSize="sm" color="brand.500">
              {i18n("not_yet_set")}？
            </Text>
          </Link>
        )}
      </Flex>
      <Text mb={4} fontSize="sm" color="gray.500">
        {i18n("idCard.setTransactionPasswordHint")}
      </Text>
      <Flex justify="space-between" alignItems="center">
        {/* <IoIosLock className="text-gray-300" /> */}
        {[0, 1, 2, 3].map((index) => (
          <Input
            key={index}
            type="password" // 密碼樣式顯示
            name="secret_pwd"
            ref={inputRefs[index]}
            inputMode="numeric" // 移動設備上顯示數字鍵盤
            maxLength={1} // 限制每個輸入框只能輸入一位
            width="60px"
            height="60px"
            textAlign="center"
            fontSize="2xl"
            value={value[index] || ""}
            onChange={(e) => handleInputChange(index, e.target.value)}
            onKeyDown={(e) => handleKeyDown(index, e)}
            onPaste={handlePaste}
            // onChange={(e) => {
            //   const newValue = value.split("");
            //   newValue[index] = e.target.value;
            //   const updatedValue = newValue.join("");
            //   onChange(updatedValue);
            // }}
            isDisabled={withdraw_password_set !== 1} // 根據密碼設置狀態控制禁用
            pattern={transactionExpressForInput} // 輸入驗證模式
            title={i18n("idCard.setTransactionPasswordHint")}
          />
        ))}
      </Flex>
    </Box>
  );
};

export default WithdrawPasswordInput;
