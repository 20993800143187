import { Flex, Grid, Icon, Text, useColorModeValue } from "@chakra-ui/react";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AiFillDollarCircle } from "react-icons/ai";
import { BiMoneyWithdraw } from "react-icons/bi";
import { BsCashCoin } from "react-icons/bs";
import { FaHistory } from "react-icons/fa";
import { FaPiggyBank } from "react-icons/fa6";
import { MdHistory } from "react-icons/md";
import { TbPigMoney, TbReportAnalytics } from "react-icons/tb";
import { Link, useLocation } from "react-router-dom";

const isCredit = process.env.REACT_APP_CREDIT == 1;

const common_tab = [
  {
    icon: TbPigMoney,
    text: "Deposit",
    link: "deposit",
    // hidden: isCredit,
  },
  {
    icon: BiMoneyWithdraw,
    text: "Withdraw",
    link: "withdraw",
    hidden: isCredit,
  },
  {
    icon: TbReportAnalytics,
    text: "Bet Record",
    link: "betRecord",
  },
  {
    icon: MdHistory,
    text: "Transaction",
    link: "transaction",
  },
];

const CommonSelectTab = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);

  const cardBg = useColorModeValue("light.100", "dark.100");
  const cardHoverBg = useColorModeValue("light.200", "dark.300");
  const textColor = useColorModeValue("dark.100", "light.100");

  const location = useLocation();

  const currentPath = useCallback(
    (target = "") => {
      const splitPathname = location.pathname.split("/");

      if (target) {
        return splitPathname.some((item) => item === target);
      }
    },
    [location.pathname]
  );
  return (
    <Grid
      gap="5px"
      p="3px"
      as="section"
      bg={cardBg}
      borderRadius={`common`}
      gridTemplateColumns={`repeat(${
        common_tab?.filter((item) => !item.hidden).length
      }, minmax(0, 1fr))`}
      overflow="hidden"
    >
      {common_tab
        ?.filter((item) => !item.hidden)
        ?.map((item) => {
          const active = currentPath(item.link);

          return (
            <Link className="overflow-hidden" to={`../profile/${item.link}`}>
              <Flex
                role="group"
                direction={`column`}
                gap="3px"
                justifyContent={`center`}
                alignItems={`center`}
                _hover={{
                  bg: cardHoverBg,
                }}
                borderRadius={`common`}
                bg={active && cardHoverBg}
                py="7px"
                className="transition duration-100"
              >
                <Icon
                  as={item.icon}
                  fontSize={`2xl`}
                  color={active ? "brand.500" : textColor}
                  _groupHover={{
                    color: "brand.500",
                  }}
                />
                <Text
                  color={active ? "brand.500" : textColor}
                  fontSize="sm"
                  letterSpacing={`tight`}
                  _groupHover={{
                    color: "brand.500",
                    fontWeight: `semibold`,
                  }}
                  w="100%"
                  textAlign={`center`}
                  className="text-overflow"
                  fontWeight={active ? `semibold` : "medium"}
                >
                  {i18n(item.text?.toLowerCase())}
                </Text>
              </Flex>
            </Link>
          );
        })}
    </Grid>
  );
};

export default CommonSelectTab;
