import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { REPLACE_AIR } from "../../replace_str_constant";
import { useDispatch, useSelector } from "react-redux";
import {
  clearGameLink,
  startGameLoading,
  storeGameConfig,
} from "../../redux/action/game/gameAction";
import LazyImage from "../Loading/lazyImage";
import LoadingLine from "../Loading/LoadingLine";
import { COMMON_WEB_PATH } from "../../constant";
import useDeviceType from "../../hook/useDeviceType";
import { useTranslation } from "react-i18next";
import { CloseMenu, globalTrigger, notice } from "../../redux/action/action";
import { languageTransfer } from "../../utils/languageTransfer";
import i18next from "i18next";
import { BsTools } from "react-icons/bs";
import { BiSolidTag } from "react-icons/bi";
import Cookies from "js-cookie";
import {
  AbsoluteCenter,
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Skeleton,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { RiUser3Fill } from "react-icons/ri";
import useCheckLogin from "../../hook/useCheckLogin";
import { thisImageNeedDark } from "../../utils/thisImageNeedDark";
import ToggleFavorite from "../Favorite/toggleFavorite";
import ToggleFavoriteV3 from "../Favorite/toggleFavorite_v3";

const LIVEGAMEITEMV3 = ({
  originProps,
  gameProps,
  specMask,
  isHot,
  itemIndex,
  forceShow,
  fullImageLayout, // 是否使用全圖模式
  liveLayout,
  customHotTag,
}) => {
  const { t } = useTranslation();
  const i18n_common = (key) => t(`common.${key}`);
  const { gameCode, platform, gameName, gameSubType } = gameProps;
  const { gameNameEn } = originProps;
  const isMobile = useDeviceType() === "Mobile";

  const { isLobby, isLiveLayoutOpen, showLogoInCenter } = liveLayout || {};

  const isHandleAllGame = useSelector((state) => state.isHandleAllGame);
  const onlinePlayer = useSelector((state) => state.isGameOnlinePlayer);
  const { game } = onlinePlayer || {};

  const textColor = useColorModeValue("dark.100", "light.100");
  const itemBg = useColorModeValue("light.100", "dark.250");
  const textBgColor = useColorModeValue("light.200", "dark.100");

  const { isLogin } = useCheckLogin();

  const { allGamePlatform = [] } = isHandleAllGame;

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  const getPrevPath = useMemo(() => {
    const path = location.pathname.split("/");
    const currentPathIndex = path.findIndex((item) => item === "game-confirm");

    if (currentPathIndex === -1) return "";

    return `${path.slice(0, currentPathIndex).join("/")}/`;
  }, [location.pathname]);

  const currentPlayer = useMemo(() => {
    return game?.[originProps.gameUid] || 0;
  }, [game]);

  const getPlatformIsMaintain = useMemo(() => {
    const findPlatform = allGamePlatform.find(
      (item) => item.platform === platform
    );
    const { maintenanceEndTime, maintenanceStartTime } = findPlatform || {};
    const isMaintain = findPlatform?.isUnderMaintenance;
    if (isMaintain) {
      return {
        state: true,
        component: (
          <Box
            borderRadius={"15px"}
            bg="rgba(0,0,0,0.7)"
            position="absolute"
            top="0"
            left="0"
            w="100%"
            h="100%"
            zIndex="2"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection={"column"}
            gap="10px"
          >
            <Icon as={BsTools} fontSize="2xl" />
            <Flex
              direction={"column"}
              alignItems={"center"}
              justifyContent={"center"}
              fontSize="sm"
              fontWeight={"500"}
            >
              {maintenanceStartTime ? (
                <>
                  <Text as="span">{maintenanceStartTime}</Text>
                  <Text as="span">~</Text>
                  <Text as="span">{maintenanceEndTime}</Text>
                </>
              ) : (
                <>
                  <Text as="span">{i18n_common("platformMaintain")}</Text>
                </>
              )}
            </Flex>
          </Box>
        ),
      };
    }
  }, [platform]);
  const handleEnterGame = useCallback(() => {
    if (!isLogin) {
      if (isLiveLayoutOpen && !isLobby) {
        navigate(`${getPrevPath}category/${originProps.game_platform}`);
      } else {
        dispatch(
          notice({
            title: i18n_common("Please login first"),
            type: "error",
          })
        );
        navigate("signin");
        return;
      }
    }
    if (getPlatformIsMaintain?.state) return;
    dispatch(storeGameConfig(originProps));
    dispatch(clearGameLink());
    dispatch(CloseMenu());
    dispatch(startGameLoading());

    const game_name = gameNameEn.replace(/ /g, "-");

    if (isMobile) {
      if (isLiveLayoutOpen && !isLobby) {
        navigate(`${getPrevPath}category/${originProps.game_platform}`);
      } else {
        navigate(
          `${getPrevPath}game-confirm/${game_name}/${originProps.game_platform}/${originProps.gameUid}`
        );
      }
    } else {
      if (isLiveLayoutOpen && !isLobby) {
        navigate(`${getPrevPath}category/${originProps.game_platform}`);
      } else {
        navigate(
          `/gamebox/${game_name}/${originProps.game_platform}/${originProps.gameUid}`
        );
      }
    }
  }, [originProps, gameNameEn]);
  return (
    <Flex
      role="group"
      direction={`column`}
      position={`relative`}
      cursor={
        getPlatformIsMaintain?.state && !forceShow ? `not-allowed` : `pointer`
      }
      onClick={handleEnterGame}
      overflow="hidden"
      h="110px"
      bgGradient={"linear(to-b,brand.400,brand.600,brand.900)"}
      boxShadow="inset 6px -8px 14px -12px"
      borderRadius={`15px`}
      outline=""
      hover={{
        background: {
          md: getPlatformIsMaintain?.state
            ? `none`
            : specMask
            ? `rgba(0,0,0,0.1)`
            : `brand.500`,
        },
      }}
    >
      {/* <Box>
        <ToggleFavoriteV3
          customGameUid={originProps?.gameUid}
          fontSize={"15px"}
          wrapperClass={{
            w: "30px",
            h: "30px",
            border: "2px solid",
            borderColor: "white",
            bg: "#AEB5D1",
            zIndex: 2,
            position: "absolute",
            top: "-5px",
            right: "-5px",
            borderRadius: `full`,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
          }}
        />
      </Box> */}

      <Box borderBottomRadius={`25px`} overflow="hidden">
        {currentPlayer > 0 && (
          <Box
            position="absolute"
            right="5px"
            top="5px"
            borderRadius={`full`}
            py="3px"
            px="10px"
            zIndex={1}
            bg={itemBg}
            color={textColor}
            display="flex"
            alignItems={`center`}
            gap="3px"
            fontSize="12px"
          >
            <Icon as={RiUser3Fill} />
            {currentPlayer}
          </Box>
        )}
        {getPlatformIsMaintain?.component}
        {isHot && itemIndex + 1 < 11 ? (
          <Box position={`absolute`} top={`13px`} left={`13px`} zIndex={`1`}>
            {/* <BiSolidTag className="rotate-[-90deg] text-[55px] text-[#E40A13] absolute top-[-5px] right-[-7px] z-[1]" /> */}

            <Flex
              gap="-5px"
              width={`55px`}
              // height={`21px`}
              py="5px"
              borderRadius={`full`}
              // direction={`column`}
              alignItems={`center`}
              justifyContent={`center`}
              fontWeight={`bold`}
              color="white"
              bgGradient={
                customHotTag?.color || `linear(to-r, #FF9A27, #E41010)`
              }
            >
              {" "}
              {customHotTag?.text || (
                <>
                  <Text as="span" fontSize={`sm`} letterSpacing={`tighter`}>
                    Top
                  </Text>
                  <Text fontSize={`sm`} as="span">
                    {itemIndex + 1}
                  </Text>
                </>
              )}
            </Flex>
          </Box>
        ) : null}
      </Box>
      <Box
        h="100%"
        w="100%"
        px="10px"
        display="flex"
        justifyContent={"space-between"}
        position="relative"
        ml="20px"
      >
        <Flex
          h="100%"
          direction={"column"}
          gap="10px"
          justifyContent={"center"}
          alignItems={"center"}
          position="relative"
          zIndex={1}
        >
          <Image
            src={`${COMMON_WEB_PATH}/platformIcon/${thisImageNeedDark(
              platform,
              true
            )}.png`}
            w="70px"
            h="40px"
            objectFit={"contain"}
            transform={"scale(1.3)"}
          />
          <Box>
            <Text letterSpacing={"tighter"} fontWeight={500}>
              {platform}
            </Text>
          </Box>
        </Flex>
        <Image
          position={"absolute"}
          bottom="0px"
          right="-5px"
          w="190px"
          src={`${COMMON_WEB_PATH}/b69-home/platform-person/${platform}_person.png `}
        />
      </Box>
    </Flex>
  );
};

export default React.memo(LIVEGAMEITEMV3);
