const GameBoxStatusReducers = (state = "default", action) => {
  switch (action.type) {
    case "playing":
      return "playing";
    case "played":
      return "played";
    case "default":
      return "default";
    default:
      return state;
  }
};

export default GameBoxStatusReducers;
