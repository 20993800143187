import React, { useEffect, useMemo, useState } from "react";
import { formatNumber } from "../../../utils/formatNumber";
import { useTranslation } from "react-i18next";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { AiFillDollarCircle } from "react-icons/ai";
import PromotionCard from "../../Vip/components/promotionCard";
import { IoCheckmarkCircle } from "react-icons/io5";
import { FaExclamationCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import PaymentCurrencySettings from "../../Settings/components/PaymentCurrencySettings";
import {
  Box,
  Divider,
  Flex,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import { getRestrictWagering } from "../../../api/getApi";
import useCurrentWager from "../../../hook/useCurrentWager";
import LoadingIcon from "../../../components/Loading/LoadingIcon";
import { waitTime } from "../../../utils/waitTime";

const FromWithdrawHint = ({
  washConfig,
  onCurrencyChange,
  selectedCurrency,
}) => {
  const { t } = useTranslation();
  const i18n = (key, props) => t(`vip.${key}`, { ...props });

  const isCurrencyExchange = useSelector((state) => state.isCurrencyExchange);

  const currencyList = useMemo(() => {
    return Object.keys(isCurrencyExchange).filter(
      (item) => isCurrencyExchange[item]?.status_front === 1
    );
  }, [isCurrencyExchange]);

  const { wagering, restricted_amount, can_withdraw } =
    useCurrentWager({
      customCurrency: selectedCurrency,
    }) || {};

  const [loading, setLoading] = useState(false);

  const boxBg = useColorModeValue("light.100", "dark.100");
  const textColor = useColorModeValue("dark.100", "light.100");

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await waitTime(2000);
      setLoading(false);
    };
    fetchData();
  }, []);

  return (
    <>
      {!can_withdraw && (
        <Box
          as="section"
          borderRadius={`common`}
          display="flex"
          flexDirection={`column`}
          gap="5px"
          alignItems={`center`}
          justifyContent={`center`}
          p="10px"
          bg={boxBg}
        >
          <>
            <Box as="section" w="100%">
              <Flex
                w="100%"
                alignItems={`center`}
                justifyContent={`space-between`}
              >
                <Flex alignItems={`center`} gap="5px">
                  <Box
                    w="35px"
                    h="35px"
                    position="relative"
                    className="w-[35px] h-[35px] relative"
                  >
                    <CircularProgressbar
                      className="!w-[35px]"
                      styles={buildStyles({
                        trailColor: "rgb(196 196 196) !important",
                        pathColor: `rgba(100,72,255,1)`,
                      })}
                      strokeWidth={6}
                      value={
                        wagering ? 0 : (wagering / restricted_amount) * 100
                      }
                    />
                    <AiFillDollarCircle className="absolute absolute-center text-2xl text-[#D99E0A]" />
                  </Box>
                  <Text
                    as="span"
                    color={textColor}
                    fontSize="sm"
                    fontWeight={`medium`}
                    letterSpacing={`tighter`}
                  >
                    {i18n("withdrawNeedCondition")}
                  </Text>
                </Flex>
                <Flex direction={`column`} alignItems={`flex-end`}>
                  <LoadingIcon commonLoading={loading}>
                    <Text
                      as="span"
                      color={textColor}
                      fontSize="md"
                      fontWeight={`medium`}
                      letterSpacing={`tighter`}
                      className="text-overflow"
                    >
                      {formatNumber(wagering, { point: false })} /{" "}
                      {formatNumber(restricted_amount, {
                        point: false,
                      })}
                    </Text>
                  </LoadingIcon>
                </Flex>
              </Flex>
              <Flex justifyContent={`flex-end`} w="100%">
                <Text
                  as="span"
                  color="special.100"
                  fontSize="sm"
                  letterSpacing={`tighter`}
                >
                  {i18n("bet")}
                  {""}/{""}
                  {i18n("washPoint")}.
                </Text>
              </Flex>
            </Box>
            {currencyList?.length > 1 ? (
              <>
                <Divider />
                <Flex
                  alignItems={`center`}
                  justifyContent={`space-between`}
                  w="100%"
                  gap="10px"
                  my="5px"
                >
                  <Text
                    as="span"
                    w="100%"
                    color={textColor}
                    letterSpacing={`tighter`}
                    fontWeight={`medium`}
                  >
                    {i18n("withdrawCurrency")}
                  </Text>
                  <PaymentCurrencySettings
                    onCurrencyChange={onCurrencyChange}
                    selectedCurrency={selectedCurrency}
                    filterFrontStatus
                  />
                </Flex>
              </>
            ) : null}
          </>
        </Box>
      )}
    </>
  );
};

export default FromWithdrawHint;
