import { IconButton, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import { BiSolidUser } from "react-icons/bi";
import { Link } from "react-router-dom";

const ProfileButton = ({ className, path }) => {
  const iconBg = useColorModeValue("light.200", "dark.100");
  const iconText = useColorModeValue("brand.500", "light.200");
  return (
    <Link to={`${path || ""}settings/personal-data`}>
      <IconButton
        bg={className?.bg || iconBg}
        color={iconText}
        aria-label="Search database"
        icon={<BiSolidUser className="text-xl" />}
      />
    </Link>
  );
};

export default ProfileButton;
