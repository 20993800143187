import React, { useMemo } from "react";
import { WEB_LAYOUT } from "../../constant";
import ButtonTabV4 from "./ButtonTab_v4";
import ButtonTab from "./ButtonTab";

const ButtonTabSelector = (props) => {
  const buttonTab = useMemo(() => {
    switch (WEB_LAYOUT) {
      case "v4":
        return <ButtonTabV4 {...props} />;
      default:
        return <ButtonTab {...props} />;
    }
  }, [props]);
  return buttonTab;
};

export default ButtonTabSelector;
