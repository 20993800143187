import React, { useMemo } from "react";

export const handleTriggerMenu = (
  className,
  itemName,
  { pathname, casinoType, subType }
) => {
  const result =
    (pathname.split("/").join("") === `${casinoType}` && itemName === "Home") ||
    pathname.includes(itemName) ||
    (!subType && itemName === "Home")
      ? className
      : "";
  return result;
};
