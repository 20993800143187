import React, { useMemo } from "react";
import { VipList } from "../vipList";
import LazyImage from "../../../components/Loading/lazyImage";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { formatNumber } from "../../../utils/formatNumber";
import { vipPromotion } from "../helper/detailList";
import { Box, Text, Flex, Image, useColorModeValue } from "@chakra-ui/react";
import { COMMON_WEB_PATH } from "../../../constant";
import useCurrentVipLevelConfig from "../../../hook/useCurrentVipLevelConfig";
import useNextVipLevelConfig from "../../../hook/useNextVipLevelConfig";
import useCurrencySymbol from "../../../hook/useCurrencySymbol";
import useVipInfo from "../../../hook/useVipInfo";

const MainCard = ({ currentWager, currentLevel }) => {
  const { t } = useTranslation();
  const i18n = (key, props) => t(`vip.${key}`, { ...props });
  const i18n_unit = (key) => t(`unit.${key}`);

  const vipList = useSelector((state) => state.isVipDetail);

  const currencySymbol = useCurrencySymbol();

  const textColor = useColorModeValue("dark.100", "light.100");
  const cardBg = useColorModeValue("light.100", "dark.100");

  const { current_symbol } = useVipInfo();

  const { next_level_criteria_data } = useNextVipLevelConfig({
    level: currentLevel + 1,
  });

  const { rebate_days, vip_payment_tags, vip_withdrawal_rate } =
    useCurrentVipLevelConfig({ level: currentLevel });
  return (
    <Flex
      rounded="md"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      py="15px"
      px="10px"
      bgGradient={`linear(to-br, #697ABA,#2F2164)`}
    >
      <Box w="132px" h="132px" mb="12px">
        <Image
          className="!w-[132px] !h-[132px] object-scale-down"
          src={`${COMMON_WEB_PATH}/vip/vip${currentLevel}_l.avif`}
        />
      </Box>
      {/* <p className="text-light-white text-3xl font-medium tracking-tight mb-[12px]">
        {i18n(vipList[nowLevel]?.label?.toLowerCase())}
      </p> */}
      <Box>
        <Text color="base.light.dark" _dark={{ color: "base.light.white" }}>
          {formatNumber(currentWager, { point: true })} {current_symbol} /{" "}
          {formatNumber(next_level_criteria_data?.criteria_amount, {
            point: false,
          })}{" "}
          {current_symbol}
          {i18n("until")} {vipList[currentLevel + 1]?.name?.toUpperCase()}
        </Text>
      </Box>
      <Flex w="100%" mt="10px" flexDirection="column" gap="5px">
        <Flex
          px="8px"
          py="5px"
          borderRadius={`5px`}
          display={`flex`}
          alignItems={`center`}
          justifyContent={`space-between`}
          bg={cardBg}
        >
          <Flex alignItems="center" gap="10px" color="#3FAD66">
            <Text
              as="span"
              color={textColor}
              fontSize="xs"
              letterSpacing={`tighter`}
            >
              {i18n("rebateDays")}
            </Text>
          </Flex>
          <Text
            as="span"
            color={textColor}
            fontSize="xs"
            letterSpacing={`tighter`}
          >
            {rebate_days} {i18n_unit("day")}
          </Text>
        </Flex>
        <Flex
          px="8px"
          py="5px"
          borderRadius={`5px`}
          display={`flex`}
          alignItems={`center`}
          justifyContent={`space-between`}
          bg={cardBg}
        >
          <Flex alignItems="center" gap="10px" color="#3FAD66">
            <Text
              as="span"
              color={textColor}
              fontSize="xs"
              letterSpacing={`tighter`}
            >
              {i18n("withdrawalTimes")}
            </Text>
          </Flex>
          <Text
            as="span"
            color={textColor}
            fontSize="xs"
            letterSpacing={`tighter`}
          >
            {vip_payment_tags?.["withdrawTimes"]} {i18n_unit("time")}
          </Text>
        </Flex>
        <Flex
          px="8px"
          py="5px"
          borderRadius={`5px`}
          display={`flex`}
          alignItems={`center`}
          justifyContent={`space-between`}
          bg={cardBg}
        >
          <Flex alignItems="center" gap="10px" color="#3FAD66">
            <Text
              as="span"
              color={textColor}
              fontSize="xs"
              letterSpacing={`tighter`}
            >
              {i18n("minWithdrawal")}
            </Text>
          </Flex>
          <Text
            as="span"
            color={textColor}
            fontSize="xs"
            letterSpacing={`tighter`}
          >
            {vip_payment_tags?.["withdrawMini"]} {currencySymbol}
          </Text>
        </Flex>
        <Flex
          px="8px"
          py="5px"
          borderRadius={`5px`}
          display={`flex`}
          alignItems={`center`}
          justifyContent={`space-between`}
          bg={cardBg}
        >
          <Flex alignItems="center" gap="10px" color="#3FAD66">
            <Text
              as="span"
              color={textColor}
              fontSize="xs"
              letterSpacing={`tighter`}
            >
              {i18n("withdrawalFee")}
            </Text>
          </Flex>
          <Text
            as="span"
            color={textColor}
            fontSize="xs"
            letterSpacing={`tighter`}
          >
            {vip_withdrawal_rate?.map((item) => {
              return (
                <Text fontSize="xs">
                  {i18n("withdrawalFeeDescription", {
                    times: item.number,
                    rate: item.rate * 100,
                  })}
                </Text>
              );
            })}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default MainCard;
