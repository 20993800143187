import React, { useEffect, useMemo, useState } from "react";
import DetailWrapper from "../../../components/detailWrapper";
import CloseDetailPage from "../../../components/CloseComponent/CloseDetailPage";
import { HiChevronLeft } from "react-icons/hi";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { notice } from "../../../redux/action/action";
import { useTranslation } from "react-i18next";
import {
  Box,
  Divider,
  List,
  ListIcon,
  ListItem,
  Text,
  useColorModeValue,
  Button,
} from "@chakra-ui/react";
import { MdAccountBox, MdCheckCircle } from "react-icons/md";
import { FaAddressCard, FaArrowRight } from "react-icons/fa";
import CustomInput from "../../../components/InputComponents/customInput";
import { RiUser3Fill } from "react-icons/ri";
import { IoMdShare } from "react-icons/io";
import { waitTime } from "../../../utils/waitTime";
import CustomInputV4 from "../../../components/InputComponents/customInput_v4";

const iconStyle = "text-gray-300";

const BindBankAccountV4 = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`settings.${key}`);
  const i18n_bindIdCard = (key) => t(`settings.idCard.bindIdCard.${key}`);
  const i18n_common = (key) => t(`common.${key}`);

  const memberData = useSelector((state) => state.isMemberInfo);
  const isCurrencyExchange = useSelector((state) => state.isCurrencyExchange);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const textColor = useColorModeValue("dark.100", "light.100");
  const noteBg = useColorModeValue("light.100", "dark.100");
  const inputBg = useColorModeValue("light.250", "dark.250");

  const [data, setData] = useState({
    bank_card_number: localStorage.getItem("bank_card_number") || "",
    bank_account: localStorage.getItem("bank_account") || "",
    bank_account_again: "",
    bank_name: localStorage.getItem("bank_name") || "",
    bank_branch_name: localStorage.getItem("bank_branch_name") || "",
    bank_account_name: localStorage.getItem("bank_account_name") || "",
  });
  const [buttonLoading, setButtonLoading] = useState(false);

  const bankList = useMemo(() => {
    const currencyCode = localStorage.getItem("currency_code");
    const bank_list = isCurrencyExchange?.[currencyCode]?.bank;
    return bank_list || [];
  }, [isCurrencyExchange]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (value == "null") {
      dispatch(
        notice(i18n_common("pleaseConfirmInputContentIsCorrect"), "error")
      );
      return;
    }
    setData((prev) => ({ ...prev, [name]: value.trim() }));
    localStorage.setItem(name, value.trim());
  };

  const handleInputName = (e) => {
    const { name, value } = e.target;
    if (value == "null") {
      dispatch(
        notice(i18n_common("pleaseConfirmInputContentIsCorrect"), "error")
      );
      return;
    }
    if (value == " ") {
      return;
    }
    setData((prev) => ({ ...prev, [name]: value }));
    localStorage.setItem(name, value);
  };

  const {
    bank_card_number,
    bank_account,
    bank_account_again,
    bank_name,
    bank_branch_name,
    bank_account_name,
  } = data;

  const handleNextStep = async () => {
    setButtonLoading(true);
    if (bank_account !== bank_account_again) {
      setButtonLoading(false);
      return dispatch(
        notice({
          title: i18n("Please confirm your bank account"),
          type: "error",
        })
      );
    }
    localStorage.setItem("step", "3");
    await waitTime(1000);
    setButtonLoading(false);
    navigate("../bind-transaction-password");
  };

  useEffect(() => {
    if (memberData.isBindAccount == 1) {
      navigate("../bind-id-card");
      return;
    }
    if (
      localStorage.getItem("step") === null &&
      memberData.isBindAccount == 0
    ) {
      navigate("../bind-id-card");
    }
  }, []);

  const formData = [
    {
      name: "bank_card_number",
      label: "銀行卡號",
      type: "text",
      value: bank_card_number,
      onChange: (e) => {
        handleInputChange(e);
      },
      icon: <FaAddressCard className={iconStyle} />,
    },
    {
      name: "bank_account",
      label: "銀行帳號",
      type: "text",
      value: bank_account,
      onChange: (e) => {
        handleInputChange(e);
      },
      icon: <MdAccountBox className={iconStyle} />,
    },
    {
      name: "bank_account_again",
      label: "再次輸入銀行帳號",
      type: "text",
      value: bank_account_again,
      onChange: (e) => {
        handleInputChange(e);
      },
      icon: <MdAccountBox className={iconStyle} />,
    },
    {
      name: "bank_name",
      label: "選擇銀行",
      type: "text",
      inputType: "select",
      options: bankList.map((item) => {
        return { value: item, label: item };
      }),
      value: bank_name,
      onChange: (e) => {
        handleInputChange(e);
      },
    },
    {
      name: "bank_branch_name",
      label: "銀行開戶分行",
      type: "text",
      value: bank_branch_name,
      onChange: (e) => {
        handleInputName(e);
      },
      icon: <IoMdShare className={iconStyle} />,
    },
    {
      name: "bank_account_name",
      label: "銀行戶名",
      type: "text",
      value: bank_account_name,
      onChange: (e) => {
        handleInputName(e);
      },
      icon: <RiUser3Fill className={iconStyle} />,
    },
  ];

  return (
    <DetailWrapper
      wrapperClassName={`h-full w-full md:absolute fixed top-0 left-0 max-h-full z-[10] bg-gradient-to-br`}
    >
      <CloseDetailPage
        hasPrev={
          <HiChevronLeft
            className="cursor-pointer"
            onClick={() => navigate(-1)}
          />
        }
        title={i18n("bindIdCard")}
      />
      <Box
        as="article"
        display="flex"
        flexDirection={`column`}
        gap="15px"
        p="15px"
        fontWeight={`medium`}
        color={textColor}
      >
        <Text as="span" color="brand.500" fontSize="md">
          {i18n_bindIdCard("bindBankAccount")}
        </Text>
        <Box p="5px" borderRadius={`common`} bg={noteBg}>
          <Text as="span" color="brand.500">
            {i18n_bindIdCard("notice")}：
          </Text>
          <List spacing={2}>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              1. {i18n_bindIdCard("hint.1")}
            </ListItem>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              2. {i18n_bindIdCard("hint.2")}
            </ListItem>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              3. {i18n_bindIdCard("hint.3")}
            </ListItem>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              4. {i18n_bindIdCard("hint.4")}
            </ListItem>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              5. {i18n_bindIdCard("hint.5")}
            </ListItem>
          </List>
        </Box>
        <Divider />
        {formData.map((item, index) => {
          return (
            <Box>
              <CustomInputV4
                props={{
                  ...item,
                  label: i18n(`form.${item.name}`),
                  type: item.inputType,
                  bg: inputBg,
                  sx: {
                    background: inputBg,
                    _dark: {
                      background: "dark.250",
                      borderColor: "dark.450",
                      color: "white",
                    },
                  },
                }}
              />
            </Box>
          );
        })}
        <Divider my="10px" />
        <Button
          variant={`brandPrimary`}
          rightIcon={<FaArrowRight />}
          isLoading={buttonLoading}
          onClick={handleNextStep}
          size="md"
        >
          {i18n_common("nextStep")}
        </Button>
      </Box>
    </DetailWrapper>
  );
};

export default BindBankAccountV4;
