import React, { useEffect, useState } from "react";
import DetailWrapper from "../../components/detailWrapper";
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Divider,
  Flex,
  Icon,
  Image,
  Stack,
  Text,
  chakra,
  shouldForwardProp,
  useColorModeValue,
} from "@chakra-ui/react";
import { isValidMotionProp, motion } from "framer-motion";
import { animatePage } from "../../animateConstant";
import CloseDetailPage from "../../components/CloseComponent/CloseDetailPage";
import { HiChevronLeft } from "react-icons/hi";
import { COMMON_WEB_PATH } from "../../constant";
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import CopyIcon from "../../components/CopyComponent/CopyIcon";
import GetParams from "../../utils/getParams";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomInput from "../../components/InputComponents/customInput";
import { MdOutlineNumbers } from "react-icons/md";
import { AiFillBank, AiFillDollarCircle } from "react-icons/ai";
import { FaCircleDollarToSlot } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { waitTime } from "../../utils/waitTime";
import { createBankDepositPayment } from "../../api/postApi";
import { notice } from "../../redux/action/action";
import { useTranslation } from "react-i18next";

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});
const iconStyle = "text-gray-300";

const DepositStepVipInput = () => {
  const { t } = useTranslation();
  const i18n = (key, props) => t(`deposit.${key}`, { ...props });

  const walletCurrency = useSelector((state) => state.isWalletCurrency);
  const dispatch = useDispatch();

  const [data, setData] = useState({
    deposit_amount: "",
    bank_account_number: "",
    currency_code: "",
    company_uid: "",
  });
  const [buttonLoading, setButtonLoading] = useState(false);
  const [apiLoading, setApiLoading] = useState(true);
  const [paymentList, setPaymentList] = useState({});
  const { deposit_amount, bank_account_number, currency_code, company_uid } =
    data || {};

  const textColor = useColorModeValue("dark.100", "light.100");
  const methodBoxBg = useColorModeValue("light.100", "dark.100");
  const iconColor = useColorModeValue("light.400", "dark.200");

  const navigate = useNavigate();
  const location = useLocation();

  const { uid } = useParams();

  const method = GetParams("method");

  const handleChange = (e) => {
    localStorage.setItem(e.target.name, e.target.value);
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleInputNumber = (value, name) => {
    localStorage.setItem(name, value);
    setData({ ...data, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonLoading(true);
    await waitTime(2000);
    await createBankDepositPayment({
      formData: {
        deposit_amount,
        currency_code,
        bank_account_number,
        company_uid,
      },
    })
      .then(async (data) => {
        const orderData = data?.data?.[0]?.member_deposits;

        localStorage.setItem("deposit_number", orderData?.deposit_number);

        await dispatch(
          notice({
            title: i18n("deposit_order_sent"),
            type: "success",
          })
        );
        navigate(`../deposit/payment-vip-success/${uid}${location.search}`);
      })
      .finally(() => {
        setButtonLoading(false);
      });
  };

  const form_list = [
    // {
    //   label: i18n("company_bank_code"),
    //   name: "company_uid",
    //   type: "text",
    //   isDisabled: true,
    //   value: company_uid,
    //   icon: <MdOutlineNumbers className={iconStyle} />,
    //   isReadOnly: true,
    // },
    {
      label: i18n("deposit_currency"),
      name: "currency_code",
      type: "text",
      value: currency_code,
      icon: <AiFillDollarCircle className={iconStyle} />,
      onChange: (e) => {
        handleChange(e);
      },
      isDisabled: true,
      isReadOnly: true,
    },

    {
      label: i18n("deposit_amount"),
      name: "deposit_amount",
      type: "number",
      min: 100,
      value: deposit_amount,
      icon: <FaCircleDollarToSlot className={iconStyle} />,
      onChange: (value) => {
        handleInputNumber(value, "deposit_amount");
      },
      required: true,
      isDisabled: buttonLoading,
    },
    // {
    //   label: i18n("your_bank_account"),
    //   name: "bank_account_number",
    //   type: "text",
    //   value: bank_account_number,
    //   icon: <AiFillBank className={iconStyle} />,
    //   onChange: (e) => {
    //     handleChange(e);
    //   },
    //   required: true,
    //   isDisabled: buttonLoading,
    // },
  ];

  useEffect(() => {
    setData({
      company_uid: uid,
      currency_code: walletCurrency,
    });
  }, [uid, walletCurrency]);
  return (
    <DetailWrapper>
      <ChakraBox
        h="auto"
        display="flex"
        flexDirection={`column`}
        justifyContent={`space-between`}
        animate="visible"
        variants={animatePage}
      >
        <form onSubmit={handleSubmit}>
          <Box as="section">
            <CloseDetailPage
              hasPrev={
                <HiChevronLeft
                  className="cursor-pointer"
                  onClick={() => navigate("../deposit")}
                />
              }
              title={
                <Text
                  as="span"
                  display="flex"
                  alignItems={`center`}
                  justifyContent={`center`}
                >
                  {method?.toUpperCase()}
                </Text>
              }
            />

            <Flex alignItems={`center`} justifyContent={`center`} mb="30px">
              <Image
                src={`${COMMON_WEB_PATH}/paymentIcon/${method?.toLowerCase()}pay.png`}
                className="!w-[50px] !h-[50px] object-cover"
              />
            </Flex>

            <Box as="section">
              <Text
                as="p"
                fontSize="xl"
                fontWeight={`semibold`}
                color={textColor}
              >
                <Icon
                  as={IoCheckmarkCircleSharp}
                  color={"green.300"}
                  fontSize="1.5rem"
                  ml="5px"
                />{" "}
                {i18n("writing_your_bank_information")}
              </Text>
            </Box>
            <Stack mb="20px" mt="10px" spacing="10px">
              {form_list.map((item) => {
                return (
                  <Box>
                    <CustomInput props={item} />
                  </Box>
                );
              })}
            </Stack>

            <Box as="section">
              <Button
                type="submit"
                variant={`brandPrimary`}
                w="100%"
                isLoading={buttonLoading}
                size="lg"
              >
                {i18n("submit_deposit_order_apply")}
              </Button>
            </Box>
          </Box>
        </form>
      </ChakraBox>
    </DetailWrapper>
  );
};

export default DepositStepVipInput;
