import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getVipInfo } from "../api/getApi";

const useVipInfo = () => {
  const memberData = useSelector((state) => state.isMemberInfo);
  const currentCurrency = useSelector((state) => state.isWalletCurrency);
  const exchangeCurrency = useSelector((state) => state.isCurrencyExchange);

  const { vipInfo } = memberData || {};

  const current_symbol = useMemo(() => {
    return exchangeCurrency?.[currentCurrency]?.origin_symbol;
  }, [currentCurrency, exchangeCurrency]);

  const vipConfig = useMemo(() => {
    return {
      current_level: memberData?.vip_level,
      current_wager: vipInfo?.wagering,
      current_symbol: current_symbol,
    };
  }, [vipInfo, memberData, current_symbol]);

  return vipConfig;
};

export default useVipInfo;
