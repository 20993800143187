import React, { useMemo } from "react";
import GameCatalog from "../../../components/GameCatalog";
import useProcessEventList from "../../../hook/useProcessEventList";
import { useSelector } from "react-redux";
import { DATA2_PATH } from "../../../constant";
import i18next from "i18next";
import { languageMapping } from "../../../i18n/config";

const EventCarousel = () => {
  const eventList = useSelector((state) => state.isEventList);

  const processEventList = useProcessEventList({ event_list: eventList });

  const filter_event_list = useMemo(() => {
    return processEventList
      ?.filter((item) => item?.copywriting_is_active == 1)
      ?.map((item) => {
        const { uid, reward_title, reward_sub_title } = item || {};
        const lang = languageMapping(i18next.language);

        return {
          main_title: reward_title,
          sub_title: reward_sub_title,
          uid: uid,
          error_url: `${DATA2_PATH}/event/${uid}/en_US/${uid}_en_US_mobile_image.png`,
          url: `${DATA2_PATH}/event/${uid}/${lang}/${uid}_${lang}_mobile_image.png`,
        };
      });
  }, [processEventList, i18next]);

  return (
    filter_event_list?.length !== 0 && (
      <GameCatalog
        list={filter_event_list}
        mainTitle={"Promotions"}
        listType="promotion"
        itemClass="!h-[250px] h-[180px]"
        swiperProps={{
          delay: 1,
          speed: 25000,
          swiperClassName: "custom-swiper-wrapper",
          customPerView: "promotion-layout",
        }}
        observeFalse
      />
    )
  );
};

export default EventCarousel;
