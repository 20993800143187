import React, { useEffect, useState } from "react";
import { isEmptyObject } from "../utils/isEmptyObject";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

const useGetNowGame = () => {
  const gameConfig = useSelector((state) => state.isGameConfig);
  const handleAllGame = useSelector((state) => state.isHandleAllGame);
  const dispatch = useDispatch();
  const { allGames, allGamePlatform } = handleAllGame;

  const { to, gameUid } = useParams();
  const [saveGameConfig, setSaveGameConfig] = useState({});
  const createCompositeKey = (games) => {
    let map = new Map();
    games?.forEach((game) => {
      if (game.game_platform && game.gameUid) {
        const compositeKey = `${game.game_platform}_${game.gameUid}`;
        map.set(compositeKey, game);
      }
    });
    return map;
  };

  const getObjectByUIDs = (map, platformUid, gameUid) => {
    const key = `${platformUid}_${gameUid}`;
    return map.get(key);
  };

  useEffect(() => {
    if (isEmptyObject(gameConfig)) {
      //O(1)搜尋
      const gameMap = createCompositeKey(allGames);
      const retrievedGameConfig = getObjectByUIDs(
        gameMap,
        to.replace("-", " "),
        gameUid
      );

      if (retrievedGameConfig) {
        setSaveGameConfig(retrievedGameConfig);
      }
    }
  }, [gameConfig, allGames, to, gameUid, dispatch]);

  return saveGameConfig;
};

export default useGetNowGame;
