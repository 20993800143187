import React, { useState } from "react";
import CloseDetailPage from "../../../components/CloseComponent/CloseDetailPage";
import DetailWrapper from "../../../components/detailWrapper";
import OriginInputCustom from "../../../components/InputComponents/originInputCustom";
import { useNavigate } from "react-router";
import { HiChevronLeft } from "react-icons/hi";
import {
  passwordExpress,
  passwordExpressForInput,
} from "../../../regex_constanst";
import { useDispatch } from "react-redux";
import { notice } from "../../../redux/action/action";
import { useTranslation } from "react-i18next";
import { resetPassword } from "../../../api/postApi";
import { Box, Button, Divider, useColorModeValue } from "@chakra-ui/react";
import CustomInput from "../../../components/InputComponents/customInput";
import { FaArrowRight } from "react-icons/fa";
import { RiLockPasswordFill } from "react-icons/ri";
import { waitTime } from "../../../utils/waitTime";
import CustomInputV4 from "../../../components/InputComponents/customInput_v4";

const iconStyle = `text-gray-300`;

const ResetPasswordV4 = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`settings.${key}`);
  const i18n_common = (key) => t(`common.${key}`);
  const i18n_formHint = (key, props) => t(`formHint.${key}`, { ...props });

  const inputBg = useColorModeValue("light.200", "dark.250");

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [data, setData] = useState({
    old_password: "",
    new_password: "",
    new_password_again: "",
  });
  const [buttonLoading, setButtonLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { old_password, new_password, new_password_again } = data;
    if (passwordExpress.test(new_password)) {
      setButtonLoading(true);
      await waitTime(1500);
      if (new_password === new_password_again) {
        resetPassword({
          old_pwd: old_password,
          new_pwd: new_password,
        })
          .then((data) => {
            dispatch(
              notice({ title: i18n("setting_success"), type: "success" })
            );
            navigate("../reset-password-success");
          })
          .catch((err) => {})
          .finally(() => {
            setButtonLoading(false);
          });
      } else {
        dispatch(
          notice({ title: i18n("tow_input_password_different"), type: "error" })
        );
        setButtonLoading(false);
      }
    } else {
      dispatch(notice({ title: i18n("password_format_error"), type: "error" }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const formData = [
    {
      name: "old_password",
      label: i18n("form.old_password"),
      type: "password",
      onChange: (e) => {
        handleChange(e);
      },
      icon: <RiLockPasswordFill className={iconStyle} />,
    },
    {
      name: "new_password",
      label: "New Password",
      type: "password",
      placeholder: i18n_formHint("includeEightEnglishAndNumber"),
      pattern: passwordExpressForInput,
      onChange: (e) => {
        handleChange(e);
      },
      icon: <RiLockPasswordFill className={iconStyle} />,
    },
    {
      name: "new_password_again",
      label: "New Password Again",
      type: "password",
      placeholder: i18n_formHint("includeEightEnglishAndNumber"),
      pattern: passwordExpressForInput,
      onChange: (e) => {
        handleChange(e);
      },
      icon: <RiLockPasswordFill className={iconStyle} />,
    },
  ];
  return (
    <DetailWrapper
      wrapperClassName={`h-full w-full md:absolute fixed top-0 left-0 max-h-full z-[10] bg-gradient-to-br`}
    >
      <CloseDetailPage
        hasPrev={
          <HiChevronLeft
            className="cursor-pointer"
            onClick={() => navigate("..")}
          />
        }
        title={i18n("resetPassword")}
      />
      <Box
        as="article"
        display="flex"
        flexDirection={`column`}
        gap="10px"
        p="15px"
      >
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          {formData.map((item, index) => {
            return (
              <Box mb="12px">
                <CustomInputV4
                  props={{
                    ...item,
                    label: i18n(`form.${item.name}`),
                    bg: inputBg,
                    sx: {
                      background: inputBg,
                      _dark: {
                        background: "dark.250",
                        borderColor: "dark.450",
                        color: "white",
                      },
                    },
                    labelProps: {
                      sx: {
                        color: "gray.600",
                        fontSize: "sm",
                        fontWeight: "bold",
                        _dark: {
                          color: "gray.300",
                        },
                      },
                    },
                  }}
                />
              </Box>
            );
          })}{" "}
          <Divider my="10px" />
          <Button
            variant={`brandPrimary`}
            rightIcon={<FaArrowRight />}
            type="submit"
            isLoading={buttonLoading}
            size="md"
            w="100%"
          >
            {" "}
            {i18n_common("SAVE")}
          </Button>
        </form>
      </Box>
    </DetailWrapper>
  );
};

export default ResetPasswordV4;
