import { useDispatch } from "react-redux";
import { notice } from "../../redux/action/action";
import { Flex, Input, useClipboard } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { IoIosCopy } from "react-icons/io";
import { TiTick } from "react-icons/ti";

const InputCopy = ({ copyLink }) => {
  const { t } = useTranslation();
  const i18n_common = (key) => t(`common.${key}`);
  const dispatch = useDispatch();

  const { onCopy, value, setValue, hasCopied } = useClipboard("");

  const handleCopy = async () => {
    await onCopy();

    dispatch(
      notice({
        title: i18n_common("Copy Success"),
        content: "",
        type: "success",
      })
    );
  };

  useEffect(() => {
    setValue(copyLink);
  }, [copyLink]);

  return (
    <>
      <Flex>
        <Input value={value} mr={2} />
        {hasCopied ? (
          <TiTick className="text-sm text-light-text cursor-pointer" />
        ) : (
          <IoIosCopy
            className="text-sm text-light-text cursor-pointer"
            onClick={handleCopy}
          />
        )}
      </Flex>
    </>
  );
};

export default InputCopy;
