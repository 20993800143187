import React, { useEffect } from "react";
import useDeviceType from "../hook/useDeviceType";
import PopWindow from "./PopWindow";

const OverflowHidden = ({ children, closePop }) => {
  const deviceType = useDeviceType();

  useEffect(() => {
    if ((deviceType === "PC" || deviceType === "Tablet") && closePop) {
      document.body.style.overflow = "unset";
    } else {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [children, deviceType]);
  return (
    <section>
      {(deviceType === "PC" || deviceType === "Tablet") && !closePop ? (
        <PopWindow>{children}</PopWindow>
      ) : (
        children
      )}
    </section>
  );
};

export default OverflowHidden;
