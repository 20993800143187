import { Tab, TabIndicator, TabList, Tabs } from "@chakra-ui/react";

const CommonTab = ({ setTab, tabList }) => {
  return (
    <Tabs
      onChange={(index) => {
        setTab(tabList[index].value);
      }}
      position="relative"
      variant="unstyled"
      w="100%"
    >
      <TabList w="100%">
        {tabList?.map((item, index) => {
          return (
            <Tab fontWeight={`semibold`} w={"100%"}>
              {item.label}
            </Tab>
          );
        })}
      </TabList>
      <TabIndicator mt="-1.5px" height="2px" bg="blue.500" borderRadius="1px" />
    </Tabs>
    // <div
    //   key={key}
    //   onClick={() => setTab(value)}
    //   className={`${
    //     length > 1 && "max-w-[50%]"
    //   } w-full text-lg cursor-pointer relative ${
    //     tab === value
    //       ? "text-transaction-text dark:text-base-light-white common-tab-bottom after:bg-main-color"
    //       : "text-transaction-text dark:text-base-light-white"
    //   } font-medium`}
    // >
    //   <p
    //     className={`${textClassName} whitespace-nowrap text-center pb-[12px]`}
    //   >{`${label}`}</p>
    // </div>
  );
};

export default CommonTab;
