import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { languageTransfer } from "../../utils/languageTransfer";
import GAMEITEM from "../../components/GAMEITEM/GAMEITEM";
import i18next from "i18next";
import TopTab from "../Home/components/topTab";
import MobileSearch from "../Search/mobile-search";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import useWindowWidth from "../../hook/useWindowWidth";
import LoadingBlock from "../../components/Loading/LoadingBlock";
import useDeviceType from "../../hook/useDeviceType";
import useCasinoType from "../../hook/useCasinoType";
import { filterType } from "../../hook/useGameListFilter";
import NotFound from "../../components/ProgressBlock/NotFound";
import LoaderGameList from "../../components/Loading/LoadingGameList";
import { getUserGameClickList } from "../../api/getApi";
import useCurrentCurrencyIsCrypto from "../../hook/useCurrentCurrencyIsCrypto";
import {
  Box,
  Flex,
  Text,
  Grid,
  useBreakpointValue,
  chakra,
  shouldForwardProp,
} from "@chakra-ui/react";
import useCheckLogin from "../../hook/useCheckLogin";
import SupplierList from "../../components/GameSelect/SupplierList";
import { isValidMotionProp, motion } from "framer-motion";
import { gameListFromBottom } from "../../animateConstant";

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const GameSubTypeList = () => {
  const { t } = useTranslation();
  const i18n_common = (key) => t(`common.${key}`);

  const { subType, gameType, gamePlatform } = useParams();
  const windowWidth = useWindowWidth();
  const casinoType = useCasinoType();

  const { isLogin } = useCheckLogin();

  const isMobile = useDeviceType() === "Mobile";

  const handleAllGame = useSelector((state) => state.isHandleAllGame);
  const isHandleAllGame = useSelector((state) => state.isHandleAllGame);
  const currentCurrency = useSelector((state) => state.isWalletCurrency); //從redux store中拿到isNowCurrency的值 用來判斷幣別
  const isCurrencyExchange = useSelector((state) => state.isCurrencyExchange);

  const { allGamePlatform = [] } = isHandleAllGame;

  const currentCurrencyIsCrypto = useCurrentCurrencyIsCrypto();
  const walletCurrency = useSelector((state) => state.isWalletCurrency); //從redux store中取isWalletCurrency的值 用來判斷幣別

  const {
    topGameList = [],
    newGameList = [],
    allGames = [],
    platform = [],
    categorizedGames,
    sortedByPlatformAndCategoryGames,
    favoriteGameList = [],
    exclusiveBrandGames = [],
    sortedByPlatformGames = [],
  } = handleAllGame;

  const [userClickGames, setUserClickGames] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isLogin) {
      getUserGameClickList() //取得點擊過的遊戲 來自這個api
        .then((data) => {
          setUserClickGames(data.userUserGameClick || []);
        })
        .catch(() => {})
        .finally(() => {});
    }
  }, []);

  const gameListFilter = useMemo(() => {
    switch (subType) {
      case "News":
        return [...newGameList, ...allGames];
      case "Top":
        return [...topGameList, ...allGames];
      case "Home":
        return allGames;
      case "AllGames":
        if (gamePlatform !== "all" && gamePlatform) {
          return sortedByPlatformGames?.[gamePlatform];
        } else {
          return allGames;
        }
      case "ExclusiveGame":
        return exclusiveBrandGames;
      case "ClickedGames": // 增加 點擊過遊戲的subType
        return userClickGames; // 過濾出 userClickGames 中的內容
      default:
        if (
          gamePlatform &&
          gamePlatform !== "all" &&
          subType !== "MyFavorite"
        ) {
          return sortedByPlatformAndCategoryGames?.[gamePlatform]?.[subType];
        } else {
          if (subType === "MyFavorite" && isLogin) {
            return favoriteGameList;
          }
          return categorizedGames?.[subType];
        }
    }
  }, [subType, categorizedGames, userClickGames, gamePlatform]); //加上gamePlatform讓他可以根據platform重新render

  //過濾掉正在維護的遊戲並且檢查幣別代碼
  const resultGameList = useMemo(() => {
    if (!allGamePlatform) {
      return [];
    }
    const filteredList = filterType({
      gameList: gameListFilter?.filter((item) => {
        const platformInfo = allGamePlatform.find(
          (p) => p.platform === item.game_platform
        );
        if (!isLogin) {
          return platformInfo && !platformInfo.isUnderMaintenance;
        } else if (currentCurrencyIsCrypto) {
          return platformInfo && !platformInfo.isUnderMaintenance;
        } else {
          return (
            platformInfo &&
            !platformInfo.isUnderMaintenance &&
            item.currencyCode?.includes(currentCurrency)
          );
        }
        // 檢查遊戲是否正在維護，和是否支援幣別代碼
      }),
      casinoType: casinoType,
    });

    return filteredList;
  }, [
    gameListFilter,
    gamePlatform,
    allGamePlatform,
    casinoType,
    currentCurrency,
    walletCurrency,
  ]);

  const [displayedItems, setDisplayedItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setCurrentPage(1);
    setIsLoading(true);
  }, [subType, gamePlatform]);

  const ITEMS_PER_PAGE = useBreakpointValue({
    base: 12,
    sm: 25,
    md: 35,
    lg: 32,
  });

  useEffect(() => {
    setDisplayedItems(resultGameList?.slice(0, ITEMS_PER_PAGE));
  }, [resultGameList, ITEMS_PER_PAGE]);

  const loadMore = useCallback(() => {
    setTimeout(() => {
      const moreItems = resultGameList?.slice(
        0,
        (currentPage + 1) * ITEMS_PER_PAGE
      );
      if (moreItems?.length >= displayedItems?.length) {
        setDisplayedItems(moreItems);
        setIsLoading(false);
        setCurrentPage((prevPage) => prevPage + 1);
      }
    }, 300);
  }, [currentPage, displayedItems?.length]);

  const hasMoreItems = useMemo(() => {
    return displayedItems?.length < resultGameList?.length;
  }, [displayedItems?.length]);

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1500);
    }
  }, [isLoading]);
  return (
    <Box>
      <Flex direction="column" gap="10px" display={{ md: "none" }}>
        <TopTab />
        <Box
          mt={{
            md: "0px",
            base: "10px",
          }}
        >
          <MobileSearch />
        </Box>
      </Flex>

      <Box
        my="8px"
        h="30px"
        md={{ h: "56px" }}
        display="flex"
        alignItems="center"
      >
        <Text
          mx="16px"
          color="base.light.dark"
          _dark={{ color: "base.light.white" }}
          fontWeight="semibold"
          fontSize="lg"
        >
          {gamePlatform && subType === "AllGames" && gamePlatform !== "all"
            ? gamePlatform
            : ""}{" "}
          {i18n_common(subType)}
        </Text>
      </Box>
      <InfiniteScroll
        pageStart={0}
        loadMore={loadMore}
        hasMore={hasMoreItems}
        useWindow={isMobile}
        loader={<LoadingBlock commonLoading={true} />}
      >
        <LoadingBlock commonLoading={isLoading}>
          {displayedItems?.length === 0 ? (
            <NotFound />
          ) : (
            <ChakraBox {...gameListFromBottom}>
              <Grid
                templateColumns={{
                  lg: "repeat(6, minmax(0, 1fr))",
                  md: "repeat(4, minmax(0, 1fr))",
                  base: "repeat(3, minmax(0, 1fr))",
                  xl: "repeat(8, minmax(0, 1fr))",
                  "2xl": "repeat(10, minmax(0, 1fr))",
                }}
                gap={{ md: "16px", base: "10px" }}
                pb="24px"
                px="common-x"
              >
                {displayedItems?.map((item, index) => {
                  const gameName =
                    item?.[
                      `gameName${languageTransfer({
                        lang: i18next.language,
                      })}`
                    ];
                  const gameNameEn = item?.gameNameEn;
                  const platform = item?.game_platform;
                  const gameSubType = item?.game_subtype;

                  const gameCode = item?.gameCode;
                  const gameType = item?.gameType;
                  return (
                    <GAMEITEM
                      forceShow={subType === "MyFavorite"}
                      gameProps={{
                        gameName,
                        platform,
                        gameCode,
                        gameType,
                        gameNameEn,
                        gameSubType,
                      }}
                      originProps={item}
                      itemClass={`!h-[155px]`}
                      index={index}
                    />
                  );
                })}
              </Grid>
            </ChakraBox>
          )}
        </LoadingBlock>
      </InfiniteScroll>
      <Box px="common-x" mb="20px">
        <SupplierList LIST={platform} />
      </Box>
    </Box>
  );
};

export default GameSubTypeList;
