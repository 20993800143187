import React, { useEffect, useState } from "react";
import { CURRENCY_SYMBOL } from "../../../constant";
import LoadingIcon from "../../../components/Loading/LoadingIcon";
import { useDispatch, useSelector } from "react-redux";
import MyBalance from "../../../utils/myBalance";
import { CURRENCY_MENU } from "../../../i18n/config";
import useCurrencyIcon from "../../../hook/useCurrencyIcon";
import { Text } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import {
  defaultGameStatus,
  playedGameStatus,
  playingGameStatus,
} from "../../../redux/action/member/memberAction";

const Balance = ({ balanceStyle }) => {
  const walletCurrencyIcon = useCurrencyIcon();
  const isCurrencyExchange = useSelector((state) => state.isCurrencyExchange);
  const selectedWalletCurrency = useSelector((state) => state.isWalletCurrency);
  const gameBoxStatus = useSelector((state) => state.isGameBoxStatus);
  const dispatch = useDispatch();

  // default 預設值 playing 正在遊玩中 played 遊玩結束
  // 根據選擇的錢包幣別去取得isCurrencyExchange裡面對應的幣別符號

  return (
    <Text
      size={`xl`}
      fontWeight={`semibold`}
      mb={`0px`}
      display={`flex`}
      gap="5px"
      alignItems={`center`}
    >
      {/* {selectWalletCurrencySymbol} */}
      <LoadingIcon commonLoading={gameBoxStatus === "played"}>
        <MyBalance balanceStyle={balanceStyle} />
      </LoadingIcon>
      <Text as="span" mb="1px">
        {walletCurrencyIcon}
      </Text>
    </Text>
  );
};

export default Balance;
