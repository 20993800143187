export const startLoading = () => {
  return {
    type: "startLoading",
  };
};
export const stopLoading = () => {
  return {
    type: "stopLoading",
  };
};
