import { Spinner, Flex } from "@chakra-ui/react";
import React from "react";

const LoadingBlock = ({ commonLoading, children, size }) => {
  return commonLoading ? (
    <Flex align="center" justify="center" h="250px">
      <Spinner variant={`brandPrimary`} size={"xl"}>
        {/* {children} */}
      </Spinner>
    </Flex>
  ) : (
    children
  );
};

export default LoadingBlock;
