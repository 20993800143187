import { AbsoluteCenter, Box, Flex, Image, Link, Text } from "@chakra-ui/react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import i18next from "i18next";
import { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink, useLocation, useMatches, useParams } from "react-router-dom";
import { routeMapping } from "./utils/routeMapping";
import { COMMON_WEB_PATH } from "../../../constant";
import { gameTypeColor } from "./utils/gameTypeColor";
import { exceptGameType } from "../../v3Layout/GameTypeSelector/utils/exceptGameType";

const GameTypeSelector = ({ gameMainTypeFromProps } = {}) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);

  const isHandleAllGame = useSelector((state) => state.isHandleAllGame);
  const { gameType, gameList } = isHandleAllGame || {};

  const splideRef = useRef();

  const location = useLocation();

  const { pathname } = location;

  //   const getGameType = useMemo(() => {
  //     return pathname.split("/")[1];
  //   }, [pathname]);
  const { gameMainType } = useParams();

  const gameSubTypeList = useMemo(() => {
    if (gameType) {
      const combileType = [...gameType];
      return [
        ...combileType.map((item, index) => {
          const { link } = routeMapping(item);
          const current_game_type = routeMapping(item)?.origin;
          const platform_list = Object?.keys(gameList[current_game_type] || {});

          return platform_list?.length == 0 && !exceptGameType(item) ? null : (
            <SplideSlide key={index}>
              <Link
                as={(props) => <NavLink {...props} />}
                to={`${link}`}
                _hover={{
                  textDecoration: "none",
                }}
              >
                {(props) => {
                  return (
                    <Box
                      p="5px"
                      position={`relative`}
                      pb={"common-y"}
                      _after={
                        props.isActive && {
                          content: '""',
                          position: "absolute",
                          top: "98%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          width: "50%",
                          height: "1.5px",
                          borderRadius: "30px",
                          bg: gameTypeColor[item],
                        }
                      }
                    >
                      {/* <Flex
                        direction={`column`}
                        alignItems={`center`}
                        overflow={`hidden`}
                        position={`relative`}
                        borderRadius={`2px`}
                        color={`white`}
                        height={`65px`}
                        gap={`5px`}
                        justifyContent={`space-between`}
                        bgGradient="linear(to-l,  #8f6B29, #FDE08D, #DF9F28)"
                        p="1px"
                        boxShadow={props.isActive && "0px 0px 9px #cbae6a"}
                      >
                        <Box
                          display={`flex`}
                          borderRadius={`2px`}
                          w="100%"
                          h="100%"
                          direction={`column`}
                          alignItems={`center`}
                          bgGradient="linear(to-b,  #141614, #2c2b2e, #141614)"
                        >
                          <Text
                            as="span"
                            fontSize={`xs`}
                            fontWeight={`semibold`}
                            letterSpacings="tighter"
                            width="100%"
                            textAlign={`center`}
                            className="text-overflow"
                          >
                            {item}
                          </Text>
                        </Box>
                      </Flex> */}
                      <Flex
                        direction={`column`}
                        alignItems={`center`}
                        overflow={`hidden`}
                        position={`relative`}
                        borderRadius={`15px`}
                        color={`white`}
                        height={`68px`}
                        gap={`3px`}
                        justifyContent={`flex-start`}
                        p="1px"
                        py="7px"
                        boxShadow={props.isActive && "0px 0px 11px #cbae6a"}
                      >
                        <AbsoluteCenter w={`100%`} h="100%">
                          <Image
                            w="100%"
                            h="100%"
                            src={`${COMMON_WEB_PATH}/b69-home/game-type-icon/${item}.svg`}
                          />
                        </AbsoluteCenter>
                        <Image
                          w="31px"
                          h="31px"
                          position="relative"
                          src={`${COMMON_WEB_PATH}/b69-home/game-type-icon/${item}_icon.svg`}
                        />
                        <Text
                          fontWeight={`600`}
                          fontSize={"12px"}
                          position="relative"
                        >
                          {i18n(item)}
                        </Text>
                      </Flex>
                    </Box>
                  );
                }}
              </Link>
            </SplideSlide>
          );
        }),
      ];
    }
  }, [gameType, i18next.language, gameList]);

  const findCurrentGameTypeIndex = useMemo(() => {
    const mainType = gameMainTypeFromProps || gameMainType;

    const processGameType = gameType?.map((item) => {
      const textMapping = routeMapping(item);

      return textMapping?.link.replace("/", "");
    });

    return processGameType?.indexOf(mainType);
  }, [gameType, gameMainType, gameMainTypeFromProps]);

  const splideOptions = useMemo(() => {
    return {
      start: findCurrentGameTypeIndex,
      pagination: false,
      arrows: false,
      drag: "free",
      // perPage: 5,
      // perMove: 1,
      gap: 1,
      lazyLoad: "nearby",
      fixedWidth: "80px",
      dragMinThreshold: {
        mouse: 0,
        touch: 10,
      },
    };
  }, [findCurrentGameTypeIndex]);

  useEffect(() => {
    if (splideRef.current) {
      const mainType = gameMainTypeFromProps || gameMainType;
      const splide = splideRef.current.splide;
      const processGameType = gameType?.map((item) => {
        const textMapping = routeMapping(item);
        return textMapping?.link.replace("/", "");
      });
      splide.go(processGameType?.indexOf(mainType));
    }
  }, [gameType, gameMainType, gameMainTypeFromProps]);

  return (
    <Splide ref={splideRef} options={splideOptions}>
      {gameSubTypeList}
    </Splide>
  );
};

export default GameTypeSelector;
