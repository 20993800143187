import { useDispatch, useSelector } from "react-redux";
import { closeGameDescriptionPop } from "../../../redux/action/member/memberAction";
import { isValidMotionProp, motion } from "framer-motion";
import useDescriptionConfig from "./descriptionConfig";
import { MdOutlineClose } from "react-icons/md";
import Cookies from "js-cookie";
import useDeviceType from "../../../hook/useDeviceType";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  chakra,
  shouldForwardProp,
} from "@chakra-ui/react";

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const DescriptionDashboard = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);

  const dispatch = useDispatch();
  const isGameDescriptionConfig = useSelector(
    (state) => state.isGameDescriptionPop
  );
  const descriptionComponent = useDescriptionConfig({
    game: isGameDescriptionConfig.game,
  });

  const isMobile = useDeviceType() === "Mobile";

  return (
    <Modal
      onClose={() => {
        dispatch(closeGameDescriptionPop());
      }}
      size={`lg`}
      isOpen={isGameDescriptionConfig?.state}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{i18n("Rules")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{descriptionComponent?.children}</ModalBody>
        <Divider />
        <Flex alignItems={`center`} justifyContent={`center`}>
          {!isMobile && (
            <Checkbox
              my="10px"
              variant={`brandPrimary`}
              defaultChecked={
                Cookies.get(`notice_${isGameDescriptionConfig.game}`) == 1
              }
              onChange={() => {
                if (
                  Cookies.get(`notice_${isGameDescriptionConfig.game}`) == 1
                ) {
                  Cookies.remove(`notice_${isGameDescriptionConfig.game}`);
                } else {
                  Cookies.set(`notice_${isGameDescriptionConfig.game}`, 1, {
                    expires: 999,
                  });
                }
              }}
            >
              {i18n("noRemind")}
            </Checkbox>
          )}
        </Flex>
        <ModalFooter>
          <Button
            variant={`brandPrimary`}
            onClick={() => {
              dispatch(closeGameDescriptionPop());
            }}
          >
            {" "}
            {i18n("iknow")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DescriptionDashboard;
