import Cookies from "js-cookie";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTheme } from "../../../redux/action/action";
import {
  AbsoluteCenter,
  Box,
  Checkbox,
  Flex,
  Icon,
  Text,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { MdSunny } from "react-icons/md";
import { IoMdMoon } from "react-icons/io";

const ThemeTab = () => {
  const { colorMode, toggleColorMode } = useColorMode();

  const boxHoverBg = useColorModeValue("light.300", "dark.300");
  return (
    <Box
      w="100%"
      h="100%"
      as="section"
      display="flex"
      alignItems={`center`}
      justifyContent={`center`}
      gap="30px"
      position="relative"
    >
      <Flex
        direction={`column`}
        gap="20px"
        cursor="pointer"
        w="50%"
        alignItems={`center`}
        justifyContent={`center`}
        onClick={() => {
          if (colorMode === "dark") {
            return;
          }
          Cookies.set("color-mode", "dark", 3);
          toggleColorMode();
        }}
      >
        {/* <Box
          w="36px"
          h="36px"
          borderRadius={`full`}
          border={colorMode === "dark" && `1px solid`}
          borderColor={colorMode === "dark" && `brand.500`}
          bg={`dark.200`}
        ></Box> */}
        <Icon
          as={IoMdMoon}
          color={colorMode === "dark" ? "light.200" : "dark.200"}
          fontSize="36px"
        />
        {/* <Text
          as="span"
          color={colorMode === "dark" ? "brand.500" : "special.1"}
        >
          Dark
        </Text> */}
        <Checkbox
          variant={"brandPrimary"}
          isChecked={colorMode === "dark"}
        ></Checkbox>
      </Flex>

      <Flex
        direction={`column`}
        gap="20px"
        cursor="pointer"
        w="50%"
        alignItems={`center`}
        justifyContent={`center`}
        onClick={() => {
          if (colorMode === "light") {
            return;
          }
          Cookies.set("color-mode", "light", 3);
          toggleColorMode();
        }}
      >
        {/* <Box
          w="36px"
          h="36px"
          borderRadius={`full`}
          border={colorMode === "light" && `1px solid`}
          borderColor={colorMode === "light" && `brand.500`}
          bg={`light.200`}
        ></Box> */}
        <Icon as={MdSunny} color="yellow.500" fontSize="36px" />
        {/* <Text
          as="span"
          color={colorMode === "light" ? "brand.500" : "special.1"}
        >
          Light
        </Text> */}
        <Checkbox
          variant={"brandPrimary"}
          isChecked={colorMode === "light"}
        ></Checkbox>
      </Flex>

      <AbsoluteCenter h="100%" w="1px" bg="#C4C4C4"></AbsoluteCenter>
    </Box>
  );
};

export default ThemeTab;
