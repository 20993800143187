const languagePopReducers = (state = false, action) => {
  switch (action.type) {
    case "openLanguagePop":
      return (state = true);
    case "closeLanguagePop":
      return (state = false);
    default:
      return state;
  }
};
export default languagePopReducers;
