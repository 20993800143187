import { motion } from "framer-motion";
import { commonOpacity } from "../../../animateConstant";
import { getFixedStyle } from "../../../hook/useDeviceType";
import { HiChevronLeft } from "react-icons/hi";
import { useNavigate, useParams } from "react-router";
import CloseComponent from "../../../components/CloseComponent/CloseComponent";
import { VipList } from "../vipList";
import PromotionCard from "./promotionCard";
import DetailWrapper from "../../../components/detailWrapper";
import { LazyLoadImage } from "react-lazy-load-image-component";
import LoadingImage from "../../../components/Loading/LoadingImage";
import { useState } from "react";
import LazyImage from "../../../components/Loading/lazyImage";
import CloseDetailPage from "../../../components/CloseComponent/CloseDetailPage";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { vipPromotion } from "../helper/detailList";
import VipPromotionList from "./vipPromotionList";
import {
  Box,
  Flex,
  Image,
  Text,
  IconButton,
  useColorModeValue,
} from "@chakra-ui/react";
import { COMMON_WEB_PATH } from "../../../constant";
import useVipInfo from "../../../hook/useVipInfo";

const VipDetail = () => {
  const { t } = useTranslation();
  const i18n = (key, props) => t(`vip.${key}`, { ...props });

  const navigate = useNavigate();

  const boxBg = useColorModeValue("light.100", "dark.100");

  const { vipId } = useParams();

  const { current_wager, current_level } = useVipInfo();

  return (
    <DetailWrapper
      closeAnimate
      wrapperClassName={`h-full w-full md:absolute fixed top-0 left-0 max-h-full z-[1] bg-gradient-to-br ${VipList[vipId]?.background}`}
    >
      <article className={`w-full h-full`}>
        <CloseDetailPage
          hasPrev={
            <HiChevronLeft
              className="cursor-pointer"
              onClick={() => navigate("../")}
            />
          }
          title={i18n("title")}
        />
        <Flex direction="column" align="center" gap={4}>
          <Box>
            <LazyImage
              className="!w-[180px] !h-[180px] object-scale-down"
              src={`${COMMON_WEB_PATH}/vip/vip${vipId}_l.avif`}
              loadingClassName={`rounded-full`}
            />
          </Box>
          <Flex direction="column" align="center" gap={4}>
            <div
              className={`rounded-full py-[5px] px-[10px] text-sm text-base-white tracking-tighter ${
                current_level >= vipId ? "bg-green-500" : "bg-yellow-500"
              } flex justify-center`}
            >
              {current_level >= vipId ? i18n("completed") : i18n("inProgress")}
            </div>
          </Flex>
          <Box
            w="80%"
            mx="auto"
            display="flex"
            flexDirection="column"
            gap="3px"
          >
            <VipPromotionList customLevel={vipId} />
          </Box>
        </Flex>
      </article>
    </DetailWrapper>
  );
};

export default VipDetail;
