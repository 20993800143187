import CloseDetailPage from "../../components/CloseComponent/CloseDetailPage";
import { TbPokerChip } from "react-icons/tb";
import "react-circular-progressbar/dist/styles.css";
import { IoLogoGameControllerA } from "react-icons/io";
import { VipList } from "./vipList";
import { Route, useNavigate } from "react-router";
import PromotionCard from "./components/promotionCard";
import ProgressCard from "./components/progressCard";
import MainCard from "./components/mainCard";
import DetailWrapper from "../../components/detailWrapper";
import LazyImage from "../../components/Loading/lazyImage";
import { common_route } from "../../utils/routes";
import VipListCard from "./components/vipList";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import { vipPromotion } from "./helper/detailList";
import useVipInfo from "../../hook/useVipInfo";
import VipPromotionList from "./components/vipPromotionList";
import { Box, Flex, Text, VStack, useColorModeValue } from "@chakra-ui/react";

const SimpleVip = () => {
  const { t } = useTranslation();
  const i18n = (key, props) => t(`vip.${key}`, { ...props });

  const vipInfo = useVipInfo();
  const vipList = useSelector((state) => state.isVipDetail);

  const { current_level, current_wager } = vipInfo;

  const navigate = useNavigate();

  return (
    <DetailWrapper>
      <CloseDetailPage title={i18n("title")} />
      <article className="mt-[20px] flex flex-col gap-[20px]">
        <Flex alignItems="center" justifyContent="between" px={"5px"}>
          <Text
            fontSize="xl"
            fontWeight="medium"
            color="base.light.dark"
            _dark={{ color: "base.light.white" }}
          >
            {i18n("myLevel")}
          </Text>
          {/* <span className="py-[1px] px-[10px] rounded-full bg-base-light-white dark:bg-base-light-dark text-sm text-dark-gray-text dark:text-base-light-white font-semibold tracking-tighter">
            {i18n("leftDays", { day: 30 })}
          </span> */}
        </Flex>

        <MainCard currentWager={current_wager} currentLevel={current_level} />
        <Box>
          <Text
            fontSize="xl"
            fontWeight="medium"
            color="base.light.dark"
            _dark={{ color: "base.light.white" }}
          >
            {i18n("nextLevel")}
          </Text>
        </Box>
        <Flex
          bg="background.transactionCard"
          _dark={{ bg: "light.400" }}
          p="8px"
          rounded="5px"
          flexDirection="column"
          gap="10px"
        >
          <ProgressCard
            currentWager={current_wager}
            currentLevel={current_level}
          />
          <VipPromotionList customLevel={1} />
        </Flex>

        <VipListCard />
      </article>
    </DetailWrapper>
  );
};

export default SimpleVip;
