import React, { Fragment, useMemo } from "react";
import Seo from "../Slot/seo";
import MarqueeListV2 from "../../components/marquee/marqueeList_v2";
import { Box, Flex, useColorModeValue } from "@chakra-ui/react";
import SubListSelector from "../../components/v4Layout/SubListSelector/SubListSelector";
import GameCatalogV3 from "../../components/GameCatalog_v3";
import { useSelector } from "react-redux";
import useSubTypeListV2 from "../../hook/useSubTypeList_v2";
import CarouselV4 from "../../components/Carousel_v4";
import MobileSearchV4 from "../Search/mobile-search_v4";
import TopGameBoard from "../../components/v4Layout/TopGameBoard/TopGameBoard";
import GameSelector from "../../components/v4Layout/GameSelector/GameSelector";
import EventCarouselV4 from "./components/event_carousel_v4";
import GameTypeSelectorV4 from "../../components/v4Layout/GameTypeSelector/GameTypeSelector_v4";
import MarqueeListV4 from "../../components/marquee/marqueeList_v4";
import TopGameBoardV4 from "../../components/v4Layout/TopGameBoard/TopGameBoard_v4";
import SubListSelectorV4 from "../../components/v4Layout/SubListSelector/SubListSelector_v4";

const HomeV4 = () => {
  const handleAllGame = useSelector((state) => state.isHandleAllGame);
  const currentCurrency = useSelector((state) => state.isWalletCurrency);

  const boxBg = useColorModeValue("#EBEDFE", "#394367");

  const {
    platform,
    topGameList,
    newGameList,
    categorizedGames,
    exclusiveBrandGames,
    gameListByTypeAndSubType,
  } = handleAllGame;

  const subList = useSubTypeListV2({
    forceShowAll: true,
    gameMainTypeFromProps: "SLOT",
  });
  const subGames = useMemo(() => {
    const slot_game_list = gameListByTypeAndSubType?.["SLOT"];
    return Object?.keys(slot_game_list || {})?.map((sub_type, index) => {
      const subGamelist = slot_game_list[sub_type];
      return (
        subGamelist?.length > 0 && (
          <Fragment key={sub_type}>
            <GameCatalogV3
              list={subGamelist}
              //   wrapperClassName={subGamelist?.length === 0 && "hidden"}
              mainTitle={sub_type}
              observeFalse
              swiperProps={{
                customPerView: "v3-normal-layout",
              }}
              fullImageLayout
              itemClass="2xl:h-[225px] xl:h-[265px] h-[180px]"
              hiddenButtonNavigator
            />
          </Fragment>
        )
      );
    });
  }, [gameListByTypeAndSubType, subList, currentCurrency]);
  return (
    <Fragment>
      <Seo />
      <Box as="section" px="common-y" display={{ base: "block", md: "none" }}>
        <GameTypeSelectorV4 />
      </Box>
      {/* <Box as="section" pt="home-space" px="common-y">
        <SubListSelectorV4 forceShowAll gameMainTypeFromProps={"SLOT"} />
      </Box> */}
      <Flex
        mb={{
          base: "0px",
          md: "10px",
        }}
      >
        <Box
          as="section"
          pt="home-space"
          px="common-y"
          w={{
            base: "100%",
            md: "80%",
          }}
        >
          <CarouselV4 />
        </Box>

        {/* 電腦版熱門遊戲 */}
        <Box
          w={{
            base: "100%",
            md: "20%",
          }}
          px={{ base: "20px", md: "common-y" }}
          display={{ base: "none", md: "block" }}
          bg={{
            base: "transparent",
            md: boxBg,
          }}
          borderRadius={{
            base: "0px",
            md: "common",
          }}
          p={{
            base: "0px",
            md: "10px",
          }}
          mt={{
            base: "0px",
            md: "10px",
          }}
          mx={{
            base: "0px",
            md: "10px",
          }}
        >
          <TopGameBoardV4 />
        </Box>
      </Flex>

      {/* <Box as="section" pt="home-space" px="common-y">
        <MarqueeListV4 />
      </Box> */}
      <Box
        display={{ base: "block", md: "none" }}
        mt={{
          md: "0px",
          // base: "10px",
        }}
        pt="home-space"
        px={{ base: "20px", md: "common-y" }}
      >
        <MobileSearchV4 listHidden />
      </Box>

      {/* 手機板熱門遊戲 */}
      <Box
        w="100%"
        px={{ base: "20px", md: "common-y" }}
        display={{ base: "block", md: "none" }}
      >
        <TopGameBoardV4 />
      </Box>
      <Box
        overflowY="scroll"
        flex={1}
        // h={{
        //   base: "80vh",
        //   md: "auto",
        // }}
      >
        <Box p="10px" px={{ base: "20px", md: "10px" }}>
          <GameSelector
            gameMainTypeFromProps={"SLOT"}
            customGamePlatform={"BNG"}
            hiddenTitle
          />
        </Box>
        {/* <Box px="common-y">
            <GameCatalogV2
              wrapperClassName={`!px-[0px]`}
              list={topGameList}
              mainTitle={`Top`}
              // swiperProps={{
              //   customPerView: "popular-layout",
              // }}
              isHot
              observeFalse
              customFixedWidth="155px"
            />
          </Box>{" "}
          <Box px="common-y">
            <GameCatalogV2
              wrapperClassName={`!px-[0px]`}
              list={newGameList}
              mainTitle={`News`}
              customHotTag={{
                color: "linear(to-r,#9DF87B,#0E9117)",
                text: (
                  <Text as="span" fontSize={`sm`} letterSpacing={`tighter`}>
                    New
                  </Text>
                ),
              }}
              observeFalse
              // swiperProps={{
              //   customPerView: "popular-layout",
              // }}
              isHot
              customFixedWidth="155px"
            />
          </Box> */}
        {/* <Box py="home-space" px="common-y">
            <GameSelector
              hiddenTitle
              gameMainTypeFromProps={gameMainTypeFromProps}
            />
          </Box> */}
      </Box>
    </Fragment>
  );
};

export default HomeV4;
