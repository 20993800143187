import { isEmptyObject } from "../../../utils/isEmptyObject";

export const storeGameList = (data) => {
  return {
    type: "storeGameList",
    payload: data,
  };
};
export const storeGameLink = (link) => {
  return {
    type: "storeGameLink",
    payload: link,
  };
};
export const openGame = () => {
  return {
    type: "openGame",
  };
};
export const closeGame = () => {
  return {
    type: "closeGame",
  };
};
export const openFullScreen = () => {
  return {
    type: "openFullScreen",
  };
};
export const closeFullScreen = () => {
  return {
    type: "closeFullScreen",
  };
};

export const filterGameList = ({
  allGameList,
  allGamePlatform,
  allGameType,
  allGame_subtype,
  allNewGameList = [],
  allTopGameList = [],
  randomlySelectedGames = [],
  userFavoriteGame = {},
  exclusiveBrandGames = [],
  origin_platform = [],
}) => {
  // 全部遊戲
  const ALLGAMES = Object?.values(allGameList || {})?.flat();

  //所有遊戲平台
  const allPlatform = allGamePlatform?.map((item) => {
    return item.platform;
  });

  //所有遊戲種類
  const allType = allGameType?.map((item) => {
    return item.gameTypeName;
  });

  const allSubType = allGame_subtype?.map((item) => {
    return item.game_subtype;
  });

  //所有遊戲
  const constructGameList = (types, platforms) => {
    return types?.reduce((acc, type) => {
      const gameTypeCode = allGameType.find(
        (t) => t.gameTypeName === type
      )?.gameTypeUid;
      const platformData = platforms?.reduce((subAcc, platform) => {
        const platformCode = allGamePlatform.find(
          (p) => p.platform === platform
        )?.platformUid;

        const subPlatformCode = allGamePlatform?.find((p) => {
          return p.SubPlatformEnum === platform;
        });

        const platformTypeGame = allGameList[gameTypeCode]?.filter((game) => {
          if (subPlatformCode) {
            return (
              game?.SubPlatformEnumUid == subPlatformCode?.SubPlatformEnumUid
            );
          }
          return game.platformUid === platformCode;
        });
        return platformTypeGame?.length
          ? { ...subAcc, [platform]: platformTypeGame }
          : subAcc;
      }, {});
      return isEmptyObject(platformData)
        ? acc
        : { ...acc, [type]: platformData };
    }, {});
  };

  const sortByGameTypeAndPlatformAndSubType = (types, platforms, subType) => {
    return types?.reduce((acc, type) => {
      const gameTypeCode = allGameType.find(
        (t) => t.gameTypeName === type
      )?.gameTypeUid;
      const platformData = platforms?.reduce((subAcc, platform) => {
        const platformCode = allGamePlatform.find(
          (p) => p.platform === platform
        )?.platformUid;

        const platformTypeGame = allGameList[gameTypeCode]?.filter(
          (game) => game.platformUid === platformCode
        );

        const platformTypeGameSortBySubType = platformTypeGame?.reduce(
          (acc, item) => {
            if (!acc[item?.game_subtype]) {
              acc[item?.game_subtype] = [];
            }
            acc[item?.game_subtype].push(item);
            return acc;
          },
          {}
        );
        return platformTypeGame?.length
          ? { ...subAcc, [platform]: platformTypeGameSortBySubType }
          : subAcc;
      }, {});

      return isEmptyObject(platformData)
        ? acc
        : { ...acc, [type]: platformData };
    }, {});
  };

  const byGameTypeAndPlatformAndSubType = sortByGameTypeAndPlatformAndSubType(
    allType,
    allPlatform,
    ...allSubType
  );

  const favoriteGameList = Object.values(userFavoriteGame || {}).flat();

  const sortByGameTypeAndSubType = (types, subType) => {
    return types?.reduce((acc, type) => {
      const gameTypeCode = allGameType.find(
        (t) => t.gameTypeName === type
      )?.gameTypeUid;
      const platformData = subType?.reduce((subAcc, subType) => {
        const subTypeCode = allGame_subtype.find(
          (p) => p.game_subtype === subType
        )?.game_subtype;

        const processGameList = (sub_type) => {
          switch (sub_type) {
            case "AllGames":
              return ALLGAMES?.filter(
                (game) => game.gameTypeUid === gameTypeCode
              );
            case "MyFavorite":
              return favoriteGameList?.filter(
                (game) => game.gameTypeUid === gameTypeCode
              );
            case "ClickedGames":
              return randomlySelectedGames?.filter(
                (game) => game.gameTypeUid === gameTypeCode
              );
            case "News":
              return allNewGameList?.filter(
                (game) => game.gameTypeUid === gameTypeCode
              );
            case "Top":
              return allTopGameList?.filter(
                (game) => game.gameTypeUid === gameTypeCode
              );
            default:
              return allGameList[gameTypeCode]?.filter(
                (game) => game.game_subtype === subTypeCode
              );
          }
        };

        const platformTypeGame = processGameList(subType);

        return platformTypeGame?.length
          ? { ...subAcc, [subType]: platformTypeGame }
          : subAcc;
      }, {});
      return isEmptyObject(platformData)
        ? acc
        : { ...acc, [type]: platformData };
    }, {});
  };

  const gameList = constructGameList(allType, allPlatform);

  const gameListByTypeAndSubType = sortByGameTypeAndSubType(allType, [
    // "AllGames",
    // "MyFavorite",
    // "ClickedGames",
    // "News",
    // "Top",
    ...allSubType,
  ]);

  const categorizedGames = ALLGAMES?.reduce((acc, game) => {
    const subType = allGame_subtype?.map((item) => item.game_subtype);
    subType.forEach((type) => {
      acc[type] = acc[type] || [];
    });

    if (subType.includes(game.game_subtype)) {
      acc[game.game_subtype].push(game);
    }

    return acc;
  }, {});

  const gamePlatformSortGames = () => {
    const objectValues = Object.values(gameList || {});
    return objectValues.reduce((acc, cur) => {
      Object.entries(cur).forEach(([key, value]) => {
        if (!acc[key]) {
          acc[key] = value;
        } else {
          acc[key] = [...acc[key], ...value];
        }
      });

      return acc;
    }, {});
  };

  const platformAndCategory = () => {
    const result = {};
    const platformGames = gamePlatformSortGames();
    for (const [key, array] of Object.entries(platformGames)) {
      result[key] = {};

      for (const item of array) {
        const type = item.game_subtype;
        if (!result[key][type]) {
          result[key][type] = [];
        }
        result[key][type].push(item);
      }
    }
    return result;
  };

  return {
    type: "filterGameList",
    payload: {
      allGames: ALLGAMES,
      allGameType: allGameType,
      gameList: gameList,
      platform: allPlatform,
      gameType: [...allType, "PROMOTION"],
      allGamePlatform: allGamePlatform,
      game_subtype: allGame_subtype,
      newGameList: allNewGameList,
      topGameList: allTopGameList,
      categorizedGames: { ...categorizedGames, MyFavorite: favoriteGameList },
      randomlySelectedGames: randomlySelectedGames,
      sortedByPlatformGames: gamePlatformSortGames(),
      sortedByPlatformAndCategoryGames: platformAndCategory(),
      favoriteGameList: favoriteGameList,
      exclusiveBrandGames: exclusiveBrandGames,
      gameListByTypeAndSubType: gameListByTypeAndSubType,
      sortByGameTypeAndPlatformAndSubType: byGameTypeAndPlatformAndSubType,
      origin_platform,
    },
  };
};
export const openVipBar = () => {
  return {
    type: "openVipBar",
  };
};

export const closeVipBar = () => {
  return {
    type: "closeVipBar",
  };
};

export const openAccessTool = () => {
  return {
    type: "openAccessTool",
  };
};

export const closeAccessTool = () => {
  return {
    type: "closeAccessTool",
  };
};

export const storeGameConfig = (data) => {
  return {
    type: "storeGameConfig",
    payload: data,
  };
};
export const clearGameConfig = () => {
  return {
    type: "clearGameConfig",
  };
};

export const storeFavoriteGameList = (data) => {
  return {
    type: "storeFavoriteGameList",
    payload: Object.values(data || {}).flat(),
  };
};

export const clearFavoriteGameList = () => {
  return {
    type: "clearFavoriteGameList",
  };
};

export const clearGameLink = () => {
  return {
    type: "clearGameLink",
  };
};

export const triggerGame = () => {
  return {
    type: "triggerGame",
  };
};

export const startGameLoading = () => {
  return {
    type: "startGameLoading",
  };
};
export const endGameLoading = () => {
  return {
    type: "endGameLoading",
  };
};
export const storeGameOnlinePlayer = (player) => {
  return {
    type: "storeGameOnlinePlayer",
    payload: player,
  };
};
