export const VipList = [
  {
    level: 0,
    // icon: "https://marketing-cdn-lb.gcdn.co/webdav/a8715d5a-1276-4669-ac1f-ac85b232f9ac-Level=Beginner, Style=Color.svg",
    icon: "https://911win.s3.ap-southeast-1.amazonaws.com/vip/member_vip0.png",
    background: "from-[#697ABA] to-[#2F2164]",
    lineBackground: "100,72,255",
    label: "Beginner",
    multiplier: 1,
    needExp: 200,
    freeSpin: 10,
  },
  {
    level: 1,
    // icon: "https://marketing-cdn-lb.gcdn.co/webdav/52ba02f3-870a-412e-84e5-7f49f1c2df91-Level=Steel, Style=Color.svg",
    icon: "https://911win.s3.ap-southeast-1.amazonaws.com/vip/member_vip1.png",
    background: "from-[#525C6E] to-[#323842]",
    lineBackground: "69,78,92",
    label: "Steel",
    multiplier: 1,
    needExp: 200,
    freeSpin: 10,
  },
  {
    level: 2,
    // icon: "https://marketing-cdn-lb.gcdn.co/webdav/f836d258-e8a0-4503-8993-ca9b0179316b-Level=Bronze, Style=Color.svg",
    icon: "https://911win.s3.ap-southeast-1.amazonaws.com/vip/member_vip2.png",
    background: "from-[#855F36] to-[#392C1D]",
    lineBackground: "133,95,54",
    label: "Bronze",
    multiplier: 1.05,
    needExp: 1000,
    freeSpin: 10,
  },
  {
    level: 3,
    // icon: "https://marketing-cdn-lb.gcdn.co/webdav/ef2ccf13-c2d9-403a-a6ce-fc820a1d1511-Level=Gold, Style=Color.svg",
    icon: "https://911win.s3.ap-southeast-1.amazonaws.com/vip/member_vip3.png",
    background: "from-[#8B8249] to-[#3E3D25]",
    lineBackground: "114,107,62",
    label: "Gold",
    multiplier: 1.2,
    needExp: 10000,
    freeSpin: 50,
  },
  {
    level: 4,
    // icon: "https://marketing-cdn-lb.gcdn.co/webdav/82596543-e505-4c60-9a6e-a1d0fe7cac1e-Level=Diamond, Style=Color.svg",
    icon: "https://911win.s3.ap-southeast-1.amazonaws.com/vip/member_vip4.png",
    background: "from-[#6D6291] to-[#313041]",
    lineBackground: "109,98,145",
    label: "Diamond",
    multiplier: 1.5,
    needExp: 50000,
    freeSpin: 100,
  },
  {
    level: 5,
    // icon: "https://marketing-cdn-lb.gcdn.co/webdav/218e3524-8762-4459-b4ad-5ebb5a7480ab-Level=Legend, Style=Color.svg",
    icon: "https://911win.s3.ap-southeast-1.amazonaws.com/vip/member_vip5.png",
    background: "from-[#8A7531] to-[#3F381C]",
    lineBackground: "135,114,48",
    label: "Legend",
    multiplier: 2,
    needExp: 100000,
    freeSpin: 200,
  },
];
