import React, { useMemo } from "react";
import useDescriptionConfig from "./descriptionConfig";
import { useLocation, useParams } from "react-router";

const useGameIsHasDescription = ({ game_platform }) => {
  const location = useLocation();
  const findDescription = useDescriptionConfig({ game: game_platform });
  const gameIsHasDescription = useMemo(() => {
    if (findDescription?.children) {
      return true;
    }
    return false;
  }, [findDescription, location.pathname]);
  return gameIsHasDescription;
};

export default useGameIsHasDescription;
