import React, { useMemo } from "react";
import { WEB_LAYOUT } from "../../constant";
import SettingsV4 from "./Settings_v4";
import Settings from "./Settings";

const SettingsVersionSelector = () => {
  const settingsPage = useMemo(() => {
    switch (WEB_LAYOUT) {
      case "v2":
        return <Settings />;
      case "v3":
        return <Settings />;
      case "v4":
        return <SettingsV4 />;
      default:
        return <Settings />;
    }
  }, [WEB_LAYOUT]);
  return settingsPage;
};

export default SettingsVersionSelector;
