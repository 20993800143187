import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { REPLACE_AIR } from "../../replace_str_constant";
import { useDispatch, useSelector } from "react-redux";
import {
  clearGameLink,
  startGameLoading,
  storeGameConfig,
} from "../../redux/action/game/gameAction";
import LazyImage from "../Loading/lazyImage";
import { COMMON_WEB_PATH } from "../../constant";
import useDeviceType from "../../hook/useDeviceType";
import { useTranslation } from "react-i18next";
import { CloseMenu, globalTrigger, notice } from "../../redux/action/action";
import { BsTools } from "react-icons/bs";
import {
  AbsoluteCenter,
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Skeleton,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { RiUser3Fill } from "react-icons/ri";
import useCheckLogin from "../../hook/useCheckLogin";
import { thisImageNeedDark } from "../../utils/thisImageNeedDark";
import { languageTransfer } from "../../utils/languageTransfer";
import i18next from "i18next";

const GAMEITEMV4 = ({
  originProps,
  gameProps,
  specMask,
  isHot,
  itemIndex,
  forceShow,
  fullImageLayout, // 是否使用全圖模式
  liveLayout,
  customHotTag,
}) => {
  const { t } = useTranslation();
  const i18n_common = (key) => t(`common.${key}`);
  const { gameCode, platform, gameName, gameSubType } = gameProps;
  const { gameNameEn } = originProps;
  const isMobile = useDeviceType() === "Mobile";

  const { isLobby, isLiveLayoutOpen, showLogoInCenter } = liveLayout || {};

  const isHandleAllGame = useSelector((state) => state.isHandleAllGame);
  const onlinePlayer = useSelector((state) => state.isGameOnlinePlayer);
  const { game } = onlinePlayer || {};

  const textColor = useColorModeValue("dark.100", "light.100");
  const textColor2 = useColorModeValue("#6F6F6F", "#8D8D8D");
  const itemBg = useColorModeValue("light.100", "dark.250");
  const textBgColor = useColorModeValue("light.200", "dark.100");

  const { isLogin } = useCheckLogin();

  const { allGamePlatform = [] } = isHandleAllGame;

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  const getPrevPath = useMemo(() => {
    const path = location.pathname.split("/");
    const currentPathIndex = path.findIndex((item) => item === "game-confirm");

    if (currentPathIndex === -1) return "";

    return `${path.slice(0, currentPathIndex).join("/")}/`;
  }, [location.pathname]);

  const currentPlayer = useMemo(() => {
    return game?.[originProps.gameUid] || 0;
  }, [game]);

  const getPlatformIsMaintain = useMemo(() => {
    const findPlatform = allGamePlatform.find(
      (item) => item.platform === platform
    );
    const { maintenanceEndTime, maintenanceStartTime } = findPlatform || {};
    const isMaintain = findPlatform?.isUnderMaintenance;
    if (isMaintain) {
      return {
        state: true,
        component: (
          <Box
            borderRadius={"25px"}
            bg="rgba(0,0,0,0.7)"
            position="absolute"
            top="0"
            left="0"
            w="100%"
            h="104%"
            zIndex="2"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection={"column"}
            gap="10px"
          >
            <Icon as={BsTools} fontSize="2xl" />
            <Flex
              direction={"column"}
              alignItems={"center"}
              justifyContent={"center"}
              fontSize="sm"
              fontWeight={"500"}
            >
              {maintenanceStartTime ? (
                <>
                  <Text as="span">{maintenanceStartTime}</Text>
                  <Text as="span">~</Text>
                  <Text as="span">{maintenanceEndTime}</Text>
                </>
              ) : (
                <>
                  <Text as="span">{i18n_common("platformMaintain")}</Text>
                </>
              )}
            </Flex>
          </Box>
        ),
      };
    }
  }, [platform]);
  const handleEnterGame = useCallback(() => {
    if (!isLogin) {
      if (isLiveLayoutOpen && !isLobby) {
        navigate(`${getPrevPath}category/${originProps.game_platform}`);
      } else {
        dispatch(
          notice({
            title: i18n_common("Please login first"),
            type: "error",
          })
        );
        navigate("signin");
        return;
      }
    }
    if (getPlatformIsMaintain?.state) return;
    dispatch(storeGameConfig(originProps));
    dispatch(clearGameLink());
    dispatch(CloseMenu());
    dispatch(startGameLoading());

    const game_name = gameNameEn.replace(/ /g, "-");
    const game_platform = originProps.game_platform.replace(/ /g, "-");

    if (isMobile) {
      if (isLiveLayoutOpen && !isLobby) {
        navigate(`${getPrevPath}category/${originProps.game_platform}`);
      } else {
        navigate(
          `${getPrevPath}game-confirm/${game_name}/${originProps.game_platform}/${originProps.gameUid}`
        );
      }
    } else {
      if (isLiveLayoutOpen && !isLobby) {
        navigate(`${getPrevPath}category/${originProps.game_platform}`);
      } else {
        navigate(
          `/gamebox/${game_name}/${game_platform}/${originProps.gameUid}`
        );
      }
    }
  }, [originProps, gameNameEn]);
  return (
    <>
      <Flex
        role="group"
        direction={`column`}
        position={`relative`}
        cursor={
          getPlatformIsMaintain?.state && !forceShow ? `not-allowed` : `pointer`
        }
        boxShadow={`0px 2px 9px #323232`}
        // bg="brand.900"
        borderRadius={`15px`}
        // border={`3.5px solid`}
        // borderColor="brand.300"
        // borderBottom="4px solid"
        // borderBottomColor={"brand.900"}
        // borderBottomRadius={"25px"}
        outline=""
        // overflow="hidden"
        // my="2px"
      >
        {/* <Box>
        <ToggleFavoriteV3
          customGameUid={originProps?.gameUid}
          fontSize={"15px"}
          wrapperClass={{
            w: "30px",
            h: "30px",
            border: "2px solid",
            borderColor: "white",
            bg: "#AEB5D1",
            zIndex: 2,
            position: "absolute",
            top: "-5px",
            right: "-5px",
            borderRadius: `full`,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
          }}
        />
      </Box> */}

        <Box
          onClick={handleEnterGame}
          // borderBottomRadius={`25px`}
          overflow="hidden"
        >
          {showLogoInCenter ? (
            <>
              <Box
                position="absolute"
                bg="rgba(0,0,0,0.7)"
                w="100%"
                h="100%"
                top="0px"
                left="0px"
                zIndex={1}
                borderRadius={`25px`}
              ></Box>
              <AbsoluteCenter top="40%" zIndex={2}>
                <Image
                  src={`${COMMON_WEB_PATH}/platformIcon/${thisImageNeedDark(
                    platform,
                    true
                  )}.png`}
                  w="140px"
                  transform={"scale(1.3)"}
                />
              </AbsoluteCenter>
            </>
          ) : null}
          {currentPlayer > 0 && (
            <Box
              position="absolute"
              right="5px"
              top="5px"
              borderRadius={`full`}
              py="3px"
              px="10px"
              zIndex={1}
              bg={itemBg}
              color={textColor}
              display="flex"
              alignItems={`center`}
              gap="3px"
              fontSize="12px"
            >
              <Icon as={RiUser3Fill} />
              {currentPlayer}
            </Box>
          )}
          {getPlatformIsMaintain?.component}
          {isHot && itemIndex + 1 < 11 ? (
            <Box position={`absolute`} top={`4px`} left={`4px`} zIndex={`1`}>
              {/* <BiSolidTag className="rotate-[-90deg] text-[55px] text-[#E40A13] absolute top-[-5px] right-[-7px] z-[1]" /> */}

              <Flex
                gap="-5px"
                width={`55px`}
                // height={`21px`}
                py="5px"
                borderRadius={`full`}
                // direction={`column`}
                alignItems={`center`}
                justifyContent={`center`}
                fontWeight={`bold`}
                color="white"
                bgGradient={
                  customHotTag?.color || `linear(to-r, #FF9A27, #E41010)`
                }
              >
                {" "}
                {customHotTag?.text || (
                  <>
                    <Text as="span" fontSize={`sm`} letterSpacing={`tighter`}>
                      Top
                    </Text>
                    <Text fontSize={`sm`} as="span">
                      {itemIndex + 1}
                    </Text>
                  </>
                )}
              </Flex>
            </Box>
          ) : null}
          <Box
            pt={{
              base: fullImageLayout ? "130%" : "130%",
              // 105 120
              md: fullImageLayout ? "150%" : "130%",
            }}
            position="relative"
            borderRadius={`common`}
            // borderTopRadius={`23px`}
            overflow="hidden"
            // borderTop="4.5px solid"
            // borderColor="brand.900"
          >
            <Box
              position="absolute"
              top="0px"
              left="0px"
              height="100%"
              width="100%"
              // overflow="hidden"
              isolation={`isolate`}
              borderRadius={`common`}
            >
              <Box
                position="absolute"
                top="0px"
                left="0px"
                height="100%"
                width="100%"
                // overflow="hidden"
              >
                <LazyImage
                  src={`${COMMON_WEB_PATH}/game-icon-v2/en/${platform}/${REPLACE_AIR(
                    gameCode
                  )}.webp`}
                  alt={gameNameEn}
                  closeInverted
                  className={`w-full h-full ${
                    fullImageLayout ? "rounded-[5px]" : "rounded-t-[5px]"
                  } !object-cover ${
                    specMask ? "object-scale-down" : "object-cover"
                  }`}
                />

                {/* <Image
                src={`${COMMON_WEB_PATH}/game-icon-v2/en/${platform}/${REPLACE_AIR(
                  gameCode
                )}.webp`}
                position="absolute"
                top="100%"
                w="100%"
                h="100%"
                opacity={`0.4`}
                objectFit={"cover"}
                style={{
                  transform: "scaleY(-1)",
                }}
                className="rotate-180"
              /> */}
              </Box>
              {/* <Box
              position={`absolute`}
              top="0px"
              left="0px"
              height="100%"
              width="100%"
              borderRadius={`common`}
              display={{
                base: "none",
                md: "flex",
              }}
              alignItems={`center`}
              justifyContent={`center`}
              opacity={0}
              className="transition duration-300"
              _groupHover={{
                md: {
                  opacity: 1,
                },
              }}
              bg={specMask ? "rgba(0,0,0,0.6)" : "game-item.mask"}
            >
              <Button
                variant={`brandPrimary`}
                // bgGradient={
                //   "linear(to-br,brand.300,brand.900,brand.700,brand.500,brand.900)"
                // }
                // _hover={{
                //   bgGradient:
                //     "linear(to-br,brand.300,brand.900,brand.700,brand.500,brand.900)",
                // }}
                type="button"
                border={`1px solid #ECBE8C`}
                display={`flex`}
                alignItems={`center`}
                justifyContent={`center`}
                fontWeight={`bold`}
                onClick={handleEnterGame}
              >
                <Text as="span">PLAY</Text>
              </Button>
            </Box> */}
            </Box>
          </Box>
        </Box>
      </Flex>
      <Flex justifyContent={"space-between"} gap="10px" alignItems={"center"}>
        <Flex
          // _groupHover={{
          //   background: {
          //     md: `brand.500`,
          //   },
          // }}

          direction={`column`}
          alignItems={`flex-start`}
          // bg={textBgColor}
          // position="relative"
          // h="40px"
          bottom="0px"
          left="0px"
          w="60%"
          p="5px"
          justifyContent="flex-end"
        >
          <Text
            _groupHover={{
              color: {
                md: `white`,
              },
            }}
            mb="0px"
            // px="5px"
            // textAlign={`center`}
            width={`100%`}
            fontWeight={`700`}
            color={textColor}
            className="text-overflow"
            position="relative"
            zIndex="4"
            fontSize="12px"
          >
            {isLiveLayoutOpen
              ? platform
              : originProps?.[
                  `gameName${languageTransfer({ lang: i18next.language })}`
                ] || "-"}
          </Text>
          <Text fontSize="10px" color={textColor2}>
            {i18n_common(gameSubType)}
          </Text>
        </Flex>
        <Button
          variant={`brandPrimary`}
          type="button"
          bgGradient="linear(to-b, #4D77FB, #2755FE)"
          //border={`1px solid #ECBE8C`}
          shadow={`0px 1px 1px #9D9D9D`}
          h="1.25rem"
          display={`flex`}
          alignItems={`center`}
          justifyContent={`center`}
          fontWeight={`bold`}
          size="xs"
          fontSize="9px"
          onClick={handleEnterGame}
        >
          <Text px="5px" as="span">
            PLAY
          </Text>
        </Button>
      </Flex>
    </>
  );
};

export default React.memo(GAMEITEMV4);
