import React, { useEffect, useState } from "react";
import LoadingIcon from "../../../components/Loading/LoadingIcon";
import { useDispatch, useSelector } from "react-redux";
import MyBalance from "../../../utils/myBalance";
import { Text } from "@chakra-ui/react";
import useCurrencySymbol from "../../../hook/useCurrencySymbol";

const BalanceV3 = ({ balanceStyle, fontSize, hiddenSymbol, iconColor }) => {
  const gameBoxStatus = useSelector((state) => state.isGameBoxStatus);
  const currentCurrencySymbol = useCurrencySymbol();

  // default 預設值 playing 正在遊玩中 played 遊玩結束
  // 根據選擇的錢包幣別去取得isCurrencyExchange裡面對應的幣別符號

  return (
    <Text
      fontSize={fontSize || "md"}
      fontWeight={`semibold`}
      mb={`0px`}
      display={`flex`}
      gap="5px"
      alignItems={`center`}
    >
      {hiddenSymbol ? null : (
        <Text color={iconColor || "brand.500"}>{currentCurrencySymbol}</Text>
      )}
      <LoadingIcon commonLoading={gameBoxStatus === "played"}>
        <MyBalance balanceStyle={balanceStyle} />
      </LoadingIcon>
    </Text>
  );
};

export default BalanceV3;
