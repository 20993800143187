// import Cookies from "js-cookie";
// import { storeBasicConfig } from "../redux/action/agent/action";
// import { storeBetLimit, storeGame } from "../redux/action/game/action";

import { filterGameList } from "../redux/action/game/gameAction";
import {
  storeMarqueeList,
  storeVipDetail,
} from "../redux/action/member/memberAction";
import { getMarquee, getVipList } from "./getApi";

export const BasicApi = (dispatch) => async () => {
  try {
    const process_platform =
      window?.platforms
        .map((item) => {
          if (item?.SubPlatformEnum?.length > 0) {
            const origin_data = item;
            return item.SubPlatformEnum.map((subItem) => {
              return {
                ...origin_data,
                ...subItem,
                platform: subItem.SubPlatformEnum,
                platformEnum: subItem.SubPlatformEnum,
              };
            });
          } else {
            return item;
          }
        })
        ?.flat() || [];
    dispatch(
      filterGameList({
        allGameList: window.gameTypeGroup,
        allGamePlatform: process_platform,
        allGameType: window.gameType,
        allGame_subtype: window.gameSubType,
        allNewGameList: window.newGames,
        allTopGameList: window.topGames,
        randomlySelectedGames: window.randomlySelectedGames,
        exclusiveBrandGames: window.exclusiveBrandGames,
      })
    );
    getVipList().then((data) => {
      dispatch(storeVipDetail(data.data));
    });
    // dispatch(storeVipDetail(window.vipDetails));

    getMarquee().then((data) => {
      dispatch(storeMarqueeList(data.Marquees));
    });
  } catch (error) {
    console.error(error);
  } finally {
  }
};
