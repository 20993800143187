import React from "react";
import { MdOutlineSearch } from "react-icons/md";
import { RiMenuSearchFill } from "react-icons/ri";
import ListSelect from "./listSelect";
import { useNavigate, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import Roulette from "../../images/EventListSelect/active_04.webp";
import CheckIn from "../../images/EventListSelect/active_07.webp";
import { COMMON_ALT } from "../../constant";
import {
  Box,
  Flex,
  Icon,
  Image,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { use } from "i18next";
import { useDispatch } from "react-redux";
import { notice } from "../../redux/action/action";
import useCheckLogin from "../../hook/useCheckLogin";
import CreditPage from "../../utils/credit/credit_page";
import { FaCalendarAlt } from "react-icons/fa";
import { IoSearch, IoSearchOutline } from "react-icons/io5";

const MobileSearchV4 = ({ listHidden }) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);

  const dispatch = useDispatch();

  const { isLogin } = useCheckLogin();

  const { gamePlatform } = useParams();

  const iconBg = useColorModeValue("light.200", "dark.100");
  const searchBg = useColorModeValue("light.100", "#262D47");
  const searchText = useColorModeValue("#8E8E8E", "#9D9D9D");

  return (
    <Flex
      direction={`column`}
      gap="8px"
      //px="common-y"
      mb={{
        base: "0px",
        md: "0px",
      }}
    >
      <Flex alignItems={`center`} gap="5px" height="44px">
        {" "}
        {window.roulettes_activity == 1 ? (
          <Link to={"n-roulette"}>
            <Flex
              alignItems={`center`}
              justifyContent={`center`}
              borderRadius={`10px`}
              width="39px"
              height="39px"
              bg={iconBg}
            >
              <Image
                src={Roulette}
                cursor={`pointer`}
                maxWidth={`39px`}
                borderRadius={`common`}
                className="animate-spin"
                alt={COMMON_ALT}
              />
            </Flex>
          </Link>
        ) : null}
        {window.check_in_activity == 1 ? (
          <Link to={"checkin"}>
            <Flex
              alignItems={`center`}
              justifyContent={`center`}
              borderRadius={`10px`}
              width="39px"
              height="39px"
              bg={iconBg}
            >
              <Image
                src={CheckIn}
                cursor={`pointer`}
                maxWidth={`39px`}
                borderRadius={`common`}
                alt={COMMON_ALT}
              />
              {/* <Icon as={FaCalendarAlt} fontSize="xl" /> */}
            </Flex>
          </Link>
        ) : null}
        <Link className="w-full" to="casino-group">
          <Flex
            alignItems={`center`}
            // justifyContent={`space-between`}
            gap="10px"
            borderRadius={`10px`}
            width={`100%`}
            height="39px"
            bg={searchBg}
            color={searchText}
            px="10px"
            // py="5px"
            shadow={`0px 1px 5px #9D9D9D`}
          >
            <Box
              display="flex"
              alignItems={"center"}
              pr="10px"
              borderRight="1px solid"
              borderColor="brand.500"
            >
              <Icon as={IoSearch} fontSize="1xl" color={searchText} />
            </Box>

            <Text as="span">
              {gamePlatform && gamePlatform !== "all-platform"
                ? gamePlatform
                : i18n("All providers")}
            </Text>
          </Flex>
        </Link>
        {/* <Link to="search">
          <Flex
            alignItems={`center`}
            justifyContent={`center`}
            borderRadius={`common`}
            height="39px"
            px="20px"
            gap="5px"
            bgGradient="linear(to-br,brand.300,brand.900,brand.700,brand.500,brand.900)"
          >
            <Text wordBreak={`keep-all`} fontSize="14px" fontWeight={`600`}>
              {i18n("Search")}
            </Text>
            <Icon as={MdOutlineSearch} fontSize="2xl" color="white" />
          </Flex>
        </Link> */}
      </Flex>
      <Box>{listHidden || <ListSelect />}</Box>
    </Flex>
  );
};

export default MobileSearchV4;
