import React, { useEffect, useMemo, useState } from "react";
import { getRestrictWagering } from "../api/getApi";
import { useDispatch, useSelector } from "react-redux";
import { storeWagerConfig } from "../redux/action/member/memberAction";

const useCurrentWager = ({ customCurrency = "" } = {}) => {
  const currentCurrency = useSelector((state) => state.isWalletCurrency);
  const wagerConfig = useSelector((state) => state.isWagerConfig);
  const dispatch = useDispatch();

  useEffect(() => {
    getRestrictWagering().then((data) => {
      const compareCurrency = customCurrency || currentCurrency;
      const res = data?.data?.base;
      const result = res?.find((item) => item.currency === compareCurrency);
      dispatch(
        storeWagerConfig(
          result
            ? result
            : {
                can_withdraw: true,
              }
        )
      );
    });
  }, [customCurrency]);
  return wagerConfig;
};

export default useCurrentWager;
