import React, { useMemo } from "react";
import { WEB_LAYOUT } from "../../constant";
import FromWithdrawHintV4 from "./components/fromWithdrawHint_v4";
import FromWithdrawHint from "./components/fromWithdrawHint";

const FromWithdrawHintSelector = (props) => {
  const fromWithdrawHint = useMemo(() => {
    switch (WEB_LAYOUT) {
      case "v4":
        return <FromWithdrawHintV4 {...props} />;
      default:
        return <FromWithdrawHint {...props} />;
    }
  }, [props]);
  return fromWithdrawHint;
};

export default FromWithdrawHintSelector;
