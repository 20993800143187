import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingLine from "../../../components/Loading/LoadingLine";
import {
  Flex,
  IconButton,
  Text,
  useClipboard,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { IoIosCopy } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { waitTime } from "../../../utils/waitTime";
import { CloseMenu } from "../../../redux/action/action";

const CustomerNameText = ({ className }) => {
  const { t } = useTranslation();
  const i18n_common = (key) => t(`common.${key}`);
  const i18n_profile = (key) => t(`profile.${key}`);

  const memberData = useSelector((state) => state.isMemberInfo);
  const dispatch = useDispatch();

  const { name } = memberData || {};
  const toast = useToast();

  const { onCopy, value, setValue, hasCopied } = useClipboard("");

  const textColor = useColorModeValue("dark.100", "light.100");
  const buttonBg = useColorModeValue("light.300", "dark.300");

  const handleCopy = async () => {
    await onCopy();

    toast({
      title: i18n_common("Copy Success"),
      status: "success",
      duration: 1500,
      isClosable: true,
    });
  };

  useEffect(() => {
    setValue(memberData?.name);
  }, [memberData]);

  return (
    <Text
      fontSize="xl"
      color={textColor}
      mb="0px"
      display="flex"
      gap="10px"
      alignItems={`center`}
      className={`${className}`}
    >
      <LoadingLine animate>
        <Flex alignItems={`center`} gap="5px">
          {name ? (
            name
          ) : (
            <Link
              onClick={async () => {
                await waitTime(100);
                dispatch(CloseMenu());
              }}
              to="../profile/settings/personal-data"
            >
              ({i18n_profile("go_to_set_nick_name")})
            </Link>
          )}
          <IoIosCopy
            className="text-base text-light-text cursor-pointer"
            onClick={handleCopy}
          />
        </Flex>
      </LoadingLine>
    </Text>
  );
};

export default CustomerNameText;
