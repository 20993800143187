import {
  AbsoluteCenter,
  Box,
  Flex,
  Grid,
  GridItem,
  Image,
  Text,
  chakra,
  shouldForwardProp,
} from "@chakra-ui/react";
import { COMMON_WEB_PATH } from "../../../constant";
import useCurrencySymbol from "../../../hook/useCurrencySymbol";
import { Link, useNavigate, useParams } from "react-router-dom";
import ToggleFavorite from "../../Favorite/toggleFavorite";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { isValidMotionProp, motion } from "framer-motion";
import { gameListFromBottom } from "../../../animateConstant";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getFavoriteGameList, getRankingList } from "../../../api/getApi";
import { formatNumber } from "../../../utils/formatNumber";
import useCheckLogin from "../../../hook/useCheckLogin";
import { useDispatch, useSelector } from "react-redux";
import { notice } from "../../../redux/action/action";
import { useTranslation } from "react-i18next";
import useDeviceType from "../../../hook/useDeviceType";
import { thisImageNeedDark } from "../../../utils/thisImageNeedDark";
import LeaderBoardWrapper from "./LeaderBoardWrapper";
import RankingCard from "./components/RankingCard";

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const LeaderBoard = ({ gameMainTypeFromProps } = {}) => {
  const { t } = useTranslation();
  const i18n_common = (key) => t(`common.${key}`);

  const symbol = useCurrencySymbol();

  const { gameMainType } = useParams();

  const { isLogin } = useCheckLogin();

  const dispatch = useDispatch();
  const isHandleAllGame = useSelector((state) => state.isHandleAllGame);

  const { favoriteGameList } = isHandleAllGame;

  const navigate = useNavigate();

  const isMobile = useDeviceType() === "Mobile";

  const [dataSort, setDataSort] = useState({
    odd: [],
    even: [],
    all: [],
  });

  const [currentOddIndex, setCurrentOddIndex] = useState(0);
  const [currentEvenIndex, setCurrentEvenIndex] = useState(0);
  const { odd = [], even = [], all = [] } = dataSort || {};

  const handleEnterGame = async (gameItemProps) => {
    const { platform, game_name_en, game_uid } = gameItemProps || {};
    if (!isLogin) {
      dispatch(
        notice({
          title: i18n_common("Please login first"),
          type: "error",
        })
      );
      navigate("signin");
      return;
    }

    if (isMobile) {
      navigate(`game-confirm/${game_name_en}/${platform}/${game_uid}`);
    } else {
      navigate(`/gamebox/${game_name_en}/${platform}/${game_uid}`);
    }
  };

  const splideOptions = useMemo(() => {
    return {
      direction: "ttb",
      focus: "center",
      height: "45px",
      pagination: false,
      arrows: false,
      // drag: "free",
      autoplay: true,
      perPage: 1,
      updateOnMove: true,
      gap: 5,
      interval: 3000,
      lazyLoad: "nearby",
      dragMinThreshold: {
        mouse: 0,
        touch: 10,
      },
    };
  }, [dataSort]);

  useEffect(() => {
    getRankingList().then((data) => {
      const result = data?.data;

      const odd = result?.filter((_, index) => index % 2 == 0);
      const even = result?.filter((_, index) => index % 2 != 0);

      setDataSort({
        odd,
        even,
        all: result,
      });
    });
  }, []);

  const oddRef = useRef(null);
  const evenRef = useRef(null);
  useEffect(() => {
    if (oddRef?.current) {
      setInterval(() => {
        oddRef?.current?.splide?.go(">");
      }, 5000);
    }

    if (evenRef?.current) {
      setInterval(() => {
        evenRef?.current?.splide?.go(">");
      }, 5000);
    }
  }, []);
  return (
    <LeaderBoardWrapper>
      <ChakraBox
        key={gameMainTypeFromProps || gameMainType}
        {...gameListFromBottom}
        display={{
          base: "block",
          md: "none",
        }}
      >
        <Box>
          <Box
            borderRadius={`common`}
            overflow="hidden"
            border="1px solid"
            borderColor="brand.500"
            position="relative"
          >
            <Grid templateColumns="repeat(2, 1fr)" h="128px">
              <Grid
                templateRows={`repeat(2, 1fr)`}
                borderRight="1px solid"
                borderColor="leaderBoard.border"
              >
                <GridItem
                  borderBottom="1px solid"
                  borderColor="leaderBoard.border"
                  w="100%"
                  bg="leaderBoard.left"
                  px="10px"
                  py="5px"
                  h="100%"
                >
                  <Flex justifyContent={`space-between`} alignItems={`center`}>
                    <Box>
                      <Image
                        w="55px"
                        h="55px"
                        objectFit={`contain`}
                        // src={`${COMMON_WEB_PATH}/platformIcon/JILI.png`}
                        src={`${COMMON_WEB_PATH}/platformIcon/${thisImageNeedDark(
                          odd?.[currentOddIndex]?.platform,
                          true
                        )}.png`}
                      />
                    </Box>
                    <Box alignSelf={`flex-end`}>
                      <Flex
                        direction={`column`}
                        alignItems={`flex-end`}
                        gap="0px"
                      >
                        <Text fontSize="10px" color="special.1">
                          Daily Prize
                        </Text>
                        <Text fontSize="14px">
                          {symbol}{" "}
                          {formatNumber(odd?.[currentOddIndex]?.profit, {
                            point: false,
                          })}
                        </Text>
                      </Flex>
                    </Box>
                  </Flex>
                </GridItem>
                <GridItem
                  h="100%"
                  p="10px"
                  w="100%"
                  bg="leaderBoard.left"
                  px="10px"
                  py="5px"
                  display="flex"
                  alignItems={`center`}
                >
                  <Splide
                    ref={oddRef}
                    onMove={(props, currentIndex) => {
                      setCurrentOddIndex(currentIndex);
                    }}
                    className="w-full"
                    options={{
                      // type: "loop",
                      direction: "ttb",
                      focus: "center",
                      height: "45px",
                      pagination: false,
                      arrows: false,
                      // drag: "free",
                      autoplay: true,
                      perPage: 1,
                      gap: 5,
                      lazyLoad: "nearby",
                      dragMinThreshold: {
                        mouse: 0,
                        touch: 10,
                      },
                    }}
                  >
                    {odd?.map((item, index) => {
                      return (
                        <SplideSlide key={`${index}-${item?.game_code}`}>
                          <RankingCard props={item} />
                        </SplideSlide>
                      );
                    })}
                  </Splide>
                </GridItem>
              </Grid>
              <Grid templateRows={`repeat(2, 1fr)`}>
                <GridItem
                  borderBottom="1px solid"
                  borderColor="leaderBoard.border"
                  w="100%"
                  bg="leaderBoard.right"
                  px="10px"
                  py="5px"
                  h="100%"
                >
                  <Flex
                    direction={`row-reverse`}
                    justifyContent={`space-between`}
                    alignItems={`center`}
                  >
                    <Box>
                      <Image
                        w="55px"
                        h="55px"
                        objectFit={"contain"}
                        // src={`${COMMON_WEB_PATH}/platformIcon/JILI.png`}
                        src={`${COMMON_WEB_PATH}/platformIcon/${thisImageNeedDark(
                          even?.[currentEvenIndex]?.platform,
                          true
                        )}.png`}
                      />
                    </Box>
                    <Box alignSelf={`flex-end`}>
                      <Flex
                        direction={`column`}
                        alignItems={`flex-start`}
                        gap="0px"
                      >
                        <Text fontSize="10px" color="special.1">
                          Daily Prize
                        </Text>
                        <Text fontSize="14px">
                          {symbol}{" "}
                          {formatNumber(even?.[currentEvenIndex]?.profit, {
                            point: false,
                          })}
                        </Text>
                      </Flex>
                    </Box>
                  </Flex>
                </GridItem>
                <GridItem
                  h="100%"
                  w="100%"
                  bg="leaderBoard.right"
                  px="10px"
                  py="5px"
                  display="flex"
                  alignItems={`center`}
                >
                  <Splide
                    ref={evenRef}
                    onMove={(props, currentIndex) => {
                      setCurrentEvenIndex(currentIndex);
                    }}
                    className="w-full"
                    options={{
                      // type: "loop",
                      direction: "ttb",
                      focus: "center",
                      height: "45px",
                      pagination: false,
                      arrows: false,
                      // drag: "free",
                      autoplay: true,
                      perPage: 1,
                      gap: 5,
                      lazyLoad: "nearby",
                      dragMinThreshold: {
                        mouse: 0,
                        touch: 10,
                      },
                    }}
                  >
                    {even?.map((item, index) => {
                      return (
                        <SplideSlide key={`${index}-${item?.game_code}`}>
                          <RankingCard props={item} reverse />
                        </SplideSlide>
                      );
                    })}
                  </Splide>
                </GridItem>
              </Grid>
            </Grid>
            <AbsoluteCenter top="8px">
              <Box
                bg="brand.500"
                py="1px"
                px="30px"
                borderBottomRadius={"18px"}
              >
                <Text fontSize="14px">Leader Board</Text>
              </Box>
            </AbsoluteCenter>
          </Box>
        </Box>
      </ChakraBox>
      <ChakraBox
        key={gameMainTypeFromProps || gameMainType}
        {...gameListFromBottom}
        display={{
          base: "none",
          md: "block",
        }}
      >
        <Box>
          <Box
            borderRadius={`common`}
            overflow="hidden"
            border="1px solid"
            borderColor="brand.500"
            bg="leaderBoard.right"
            position="relative"
            px="10px"
            py="5px"
          >
            <Text mb="10px">Leader Board</Text>
            <Box>
              <Splide
                ref={oddRef}
                onMove={(props, currentIndex) => {
                  setCurrentOddIndex(currentIndex);
                }}
                className="w-full"
                options={{
                  // type: "loop",
                  focus: "center",
                  fixedWidth: "230px",
                  pagination: false,
                  arrows: false,
                  // drag: "free",
                  autoplay: true,
                  perPage: 1,
                  gap: "10px",
                  lazyLoad: "nearby",
                  dragMinThreshold: {
                    mouse: 0,
                    touch: 10,
                  },
                }}
              >
                {all?.map((item, index) => {
                  return (
                    <SplideSlide key={`${index}-${item?.game_code}`}>
                      <Box
                        bg="leaderBoard.left"
                        px="10px"
                        py="10px"
                        borderRadius={`common`}
                      >
                        <RankingCard showPlatformAvatar props={item} />
                      </Box>
                    </SplideSlide>
                  );
                })}
              </Splide>
            </Box>
          </Box>
        </Box>
      </ChakraBox>
    </LeaderBoardWrapper>
  );
};

export default LeaderBoard;
