import { useEffect, useState } from "react";
import { loadGapiInsideDOM, loadAuth2 } from "gapi-script";

const GoogleLogout = () => {
  const [gapi, setGapi] = useState(null);

  useEffect(() => {
    const loadGapi = async () => {
      const newGapi = await loadGapiInsideDOM();
      setGapi(newGapi);
    };
    loadGapi();
  }, []);

  const signOut = () => {
    const auth2 = gapi.auth2.getAuthInstance();
    auth2
      .signOut()
      .then(() => {
        console.log("User signed out.");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return { signOut };
};

export default GoogleLogout;
