import { useEffect, useMemo, useRef } from "react";
import useSubTypeList from "../../../hook/useSubTypeList";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { NavLink, useLocation, useParams } from "react-router-dom";
import {
  AbsoluteCenter,
  Box,
  Flex,
  Grid,
  GridItem,
  Icon,
  Link,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { menuIcon } from "../../Menu/helper/menuIcon";
import { useSelector } from "react-redux";
import { routeMapping } from "../GameTypeSelector/utils/routeMapping";
import useSubTypeListV2 from "../../../hook/useSubTypeList_v2";

const SubListSelector = ({ forceShowAll, gameMainTypeFromProps }) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);

  const isHandleAllGame = useSelector((state) => state.isHandleAllGame);
  const subList = useSubTypeListV2({
    forceShowAll: forceShowAll,
    gameMainTypeFromProps: gameMainTypeFromProps,
  });
  const splideRef = useRef();

  const { gameMainType, gamePlatform, subType } = useParams();

  const { pathname } = useLocation();

  const gameSubTypeList = useMemo(() => {
    if (subList) {
      const calculateColSpan = (index) => {
        const row = Math.floor(index / 4);
        if (row % 2 === 0) {
          return 3;
        } else {
          return 4;
        }
      };

      const filter_odd_even = {
        odd: subList.filter((_, index) => index % 2 === 0),
        even: subList.filter((_, index) => index % 2 === 1),
      };
      const { odd, even } = filter_odd_even;

      const mainType = gameMainTypeFromProps || gameMainType;

      return {
        slide: subList?.map((item, index) => {
          const is_default = item === "AllGames" && !subType;
          return (
            <SplideSlide key={index}>
              <Link
                as={NavLink}
                to={`/${mainType}/category/${
                  gamePlatform || "all-platform"
                }/${item}`}
                _hover={{
                  textDecoration: "none",
                }}
              >
                {({ isActive }) => {
                  return (
                    <Box
                      borderRadius={`30px`}
                      // bgGradient="linear(to-b,  #141614, #2c2b2e, #141614)"
                      // bgGradient={
                      //   isActive || is_default
                      //     ? "linear(to-b, transparent, brand.500 120%)"
                      //     : "linear(to-b, transparent, brand.500 400%)"
                      // }
                      justifyContent={`center`}
                      display="flex"
                      height="35px"
                      // overflow="hidden"
                      bg={isActive || is_default ? "brand.500" : ""}
                      // bg={isActive || is_default ? `brand.700` : ``}
                      borderTop={isActive || is_default ? `3px solid` : ``}
                      borderColor={isActive || is_default ? `brand.700` : ``}
                      position="relative"
                      overflow={`hidden`}
                    >
                      {/* {isActive || is_default ? (
                        <AbsoluteCenter
                          h="120%"
                          borderRadius={"full"}
                          w="110%"
                          top="-5%"
                          bg="brand.500"
                        ></AbsoluteCenter>
                      ) : null} */}
                      <Flex
                        justifyContent="center"
                        minW="100px"
                        maxW="100px"
                        gap="3px"
                        px="15px"
                        alignItems={`center`}
                        borderRadius={`common`}
                        position="relative"
                        zIndex={1}
                        color={isActive || is_default ? `white` : `brand.500`}
                        // borderBottom={isActive ? `3px solid` : ``}
                        // borderColor={isActive ? `brand.300` : ``}
                      >
                        <Icon as={menuIcon(item, "icon")} fontSize={`16px`} />
                        <Text
                          className="text-overflow"
                          fontSize="10px"
                          fontWeight="600"
                          letterSpacing={"-0.2px"}
                        >
                          {i18n(item)}
                        </Text>
                      </Flex>
                    </Box>
                  );
                }}
              </Link>
            </SplideSlide>
          );
        }),

        grid: subList?.map((item, index) => {
          return (
            <GridItem colSpan={calculateColSpan(index)} key={index}>
              <Link>
                <Box
                  w="90%"
                  mx="auto"
                  borderRadius={`common`}
                  bgGradient="linear(to-b,  #141614, #2c2b2e, #141614)"
                >
                  <Flex
                    justifyContent="center"
                    // minW="100px"
                    // maxW="100px"
                    height="35px"
                    gap="5px"
                    alignItems={`center`}
                  >
                    <Icon as={menuIcon(item, "icon")} fontSize={`20px`} />
                    <Text fontSize="sm" fontWeight="600">
                      {i18n(item)}
                    </Text>
                  </Flex>
                </Box>
              </Link>
            </GridItem>
          );
        }),
        odd: (
          <>
            {odd?.map((item) => {
              return (
                <Link
                  as={NavLink}
                  to={`/${mainType}/category/AllGames/${item}`}
                  _hover={{
                    textDecoration: "none",
                  }}
                >
                  {({ isActive }) => {
                    return (
                      <Box
                        borderRadius={`full`}
                        // bgGradient="linear(to-b,  #141614, #2c2b2e, #141614)"
                        bgGradient={
                          isActive
                            ? "linear(to-b, transparent, brand.500 100%)"
                            : "linear(to-b, transparent, brand.500 400%)"
                        }
                      >
                        <Flex
                          justifyContent="center"
                          minW="100px"
                          maxW="100px"
                          height="35px"
                          gap="5px"
                          alignItems={`center`}
                          borderRadius={`full`}
                          borderBottom={isActive ? `3px solid` : ``}
                          borderColor={isActive ? `brand.300` : ``}
                        >
                          <Icon as={menuIcon(item, "icon")} fontSize={`20px`} />
                          <Text fontSize="sm" fontWeight="600">
                            {i18n(item)}
                          </Text>
                        </Flex>
                      </Box>
                    );
                  }}
                </Link>
              );
            })}
          </>
        ),
        even: (
          <>
            {even?.map((item) => {
              return (
                <Link
                  as={NavLink}
                  to={`/${mainType}/category/AllGames/${item}`}
                  _hover={{
                    textDecoration: "none",
                  }}
                >
                  {({ isActive }) => {
                    return (
                      <Box
                        borderRadius={`full`}
                        // bgGradient="linear(to-b,  #141614, #2c2b2e, #141614)"
                        bgGradient={
                          isActive
                            ? "linear(to-b, transparent, brand.500 100%)"
                            : "linear(to-b, transparent, brand.500 400%)"
                        }
                      >
                        <Flex
                          justifyContent="center"
                          minW="100px"
                          maxW="100px"
                          height="35px"
                          gap="5px"
                          alignItems={`center`}
                          borderRadius={`full`}
                          borderBottom={isActive ? `3px solid` : ``}
                          borderColor={isActive ? `brand.300` : ``}
                        >
                          <Icon as={menuIcon(item, "icon")} fontSize={`20px`} />
                          <Text fontSize="sm" fontWeight="600">
                            {i18n(item)}
                          </Text>
                        </Flex>
                      </Box>
                    );
                  }}
                </Link>
              );
            })}
          </>
        ),
      };
    }
  }, [
    subList,
    gameMainType,
    i18next.language,
    gamePlatform,
    gameMainTypeFromProps,
  ]);

  const {
    odd = <></>,
    even = <></>,
    grid = <></>,
    slide = <></>,
  } = gameSubTypeList || {};

  useEffect(() => {
    if (splideRef.current) {
      const splide = splideRef.current.splide;

      splide.go(subList?.indexOf(subType));
    }
  }, [subList, subType]);

  return (
    <Box
      className="no-scrollbar"
      bg="dark.100"
      borderRadius={`30px`}
      overflow="hidden"
      overflowX="scroll"
    >
      {/* <Flex direction={`column`} gap="5px">
        <Flex gap="10px">{odd}</Flex>
        <Flex gap="10px" pl="30px">
          {even}
        </Flex>
      </Flex> */}
      {/* <Grid templateColumns="repeat(12, 1fr)" gap="10px">
        {grid}
      </Grid> */}
      <Splide
        ref={splideRef}
        options={{
          pagination: false,
          arrows: false,
          drag: "free",
          // perPage: 4,
          perMove: 1,
          gap: 5,
          lazyLoad: "nearby",
          fixedWidth: "85px",
          dragMinThreshold: {
            mouse: 0,
            touch: 10,
          },
        }}
      >
        {slide}
      </Splide>
    </Box>
  );
};

export default SubListSelector;
