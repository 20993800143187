import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkEventWagerStatus } from "../api/postApi";
import { Button, IconButton } from "@chakra-ui/react";
import { TbReload } from "react-icons/tb";
import { waitTime } from "../utils/waitTime";

const useCheckPlatformBonus = ({ game_platform } = {}) => {
  const bonus_wallet = useSelector((state) => state.isBonus);

  const [bonusConfig, setBonusConfig] = useState({
    current_wager: 0,
    target_wager: 0,
    wager_currency: "",
    status: 0,
    bonus_uid: null,
  });
  const [trigger, setTrigger] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);

  const { current_wager, target_wager, wager_currency, status, bonus_uid } =
    bonusConfig;

  const current_platform_has_bonus = useMemo(() => {
    return bonus_wallet?.[game_platform] || false;
  }, [bonus_wallet, game_platform]);

  useEffect(() => {
    checkEventWagerStatus({
      formData: {
        event: current_platform_has_bonus ? 1 : 0,
      },
    }).then((data) => {
      const find_platform_data = data?.data?.find(
        (item) => item.restricted_platform === game_platform
      );
      setBonusConfig({
        current_wager: find_platform_data?.current_wagering,
        target_wager: find_platform_data?.restricted_amount,
        wager_currency: find_platform_data?.restricted_currency,
        status: find_platform_data?.restricted_status,
        bonus_uid: find_platform_data?.uid,
      });
    });
  }, [trigger, game_platform]);

  useEffect(() => {
    const calling = async () => {
      await waitTime(1500);
      setInitialLoading(false);
    };
    calling();
  }, []);

  return {
    current_platform_has_bonus,
    current_wager,
    target_wager,
    wager_currency,
    status,
    bonus_uid,
    trigger: (
      <IconButton
        isLoading={buttonLoading}
        isDisabled={buttonLoading}
        onClick={async () => {
          setButtonLoading(true);
          setTrigger(!trigger);
          await waitTime(2000);
          setButtonLoading(false);
        }}
        size="xs"
        icon={<TbReload />}
      />
    ),
    check_loading_status: buttonLoading || initialLoading,
  };
};

export default useCheckPlatformBonus;
