import React, { useEffect, useMemo, useState } from "react";
import ServicePing from "../../pages/Service/ServicePing";
import { useLocation, useNavigate } from "react-router";
import Roulette from "../../images/EventListSelect/active_04.webp";
import { COMMON_ALT } from "../../constant";
import ScrollToTopIcon from "../scrollToTopIcon/scrollToTopIcon";
import LiveChat from "../LiveChat/LiveChat";
import { Box } from "@chakra-ui/react";

const FloatButton = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [showScrollToTop, setShowScrollToTop] = useState(false);

  const isGameBox = useMemo(() => {
    return location.pathname.includes("gamebox");
  }, [location.pathname]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > 500) {
        setShowScrollToTop(true);
      } else {
        setShowScrollToTop(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Box
      position="fixed"
      bottom="100px"
      right={{
        md: "50px",
        base: "15px",
      }}
      zIndex={999999}
      display="flex"
      flexDirection="column"
      gap="10px"
      alignItems="center"
    >
      {showScrollToTop && <ScrollToTopIcon handleScrollTop={handleScrollTop} />}
    </Box>
  );
};

export default FloatButton;
