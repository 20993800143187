import {
  AbsoluteCenter,
  Box,
  Flex,
  Icon,
  Image,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import i18next from "i18next";
import { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink, useLocation, useMatches, useParams } from "react-router-dom";
import { routeMapping } from "./utils/routeMapping";
import { COMMON_WEB_PATH } from "../../../constant";
import { gameTypeColor } from "./utils/gameTypeColor";
import { exceptGameType } from "./utils/exceptGameType";
import { routeMappingV4 } from "./utils/routeMapping_v4";

const GameTypeSelectorV4 = ({ gameMainTypeFromProps } = {}) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);

  const textColor = useColorModeValue("dark.100", "light.100");

  const isHandleAllGame = useSelector((state) => state.isHandleAllGame);
  const { gameType, gameList } = isHandleAllGame || {};
  //   console.log(gameList);
  console.log(gameType);

  const splideRef = useRef();

  const location = useLocation();

  const { pathname } = location;

  //   const getGameType = useMemo(() => {
  //     return pathname.split("/")[1];
  //   }, [pathname]);
  const { gameMainType } = useParams();

  //顯示的遊戲類型
  const combileType = useMemo(() => {
    if (gameType) {
      // 定義要保留的遊戲類型
      const allowedTypes = [
        "SLOT",
        "LIVE",
        "SPORT",
        "LOTTERY",
        "EGAME",
        "FH",
        "TABLE",
        "BINGO",
        "BLOCKCHAIN",
      ];
      //   // 過濾 gameType，只保留 allowedTypes 中的類型
      //   const filteredGameType = gameType.filter((type) =>
      //     allowedTypes.includes(type)
      //   );
      // 過濾 gameType，只保留 allowedTypes 中的類型，並按照 allowedTypes 的順序排序
      const filteredGameType = allowedTypes.filter((type) =>
        gameType.includes(type)
      );
      // 加上'home'放在最前面 和 'provider'放在最後面
      return ["home", ...filteredGameType, "Providers"];
    }
    return [];
  }, [gameType]);

  const gameSubTypeList = useMemo(() => {
    if (gameType) {
      const convertType = combileType.map((item) => {
        return routeMappingV4(item)?.link;
      });

      return [
        ...combileType.map((item, index) => {
          const { link } = routeMappingV4(item);
          const current_game_type = routeMappingV4(item)?.origin;
          console.log(current_game_type);
          const current_game_icon = routeMappingV4(item)?.icon;
          // 對於home 和 provider 類型 不需要檢查 platform_list
          const platform_list =
            item === "home"
              ? ["home"]
              : item === "Providers"
              ? ["Providers"]
              : Object?.keys(gameList[current_game_type] || {});
          return platform_list?.length == 0 && !exceptGameType(item) ? null : (
            <SplideSlide key={index}>
              <Link
                as={(props) => <NavLink {...props} />}
                to={`${link}`}
                _hover={{
                  textDecoration: "none",
                }}
              >
                {(props) => {
                  const { isActive } = props;
                  return (
                    <Box position={`relative`}>
                      <Flex
                        role="group"
                        // bg={props.isActive ? "brand.500" : "#EEEEF1"}
                        // _hover={{
                        //   bg: "brand.500",
                        // }}
                        direction={`column`}
                        alignItems={`center`}
                        // overflow={`hidden`}
                        position={`relative`}
                        // borderRadius={`30px`}
                        // color={`white`}
                        height={`55px`}
                        justifyContent={`center`}
                        p="1px"
                        gap="1px"
                        color="black"
                        _after={{
                          content: '""',
                          display: isActive ? "block" : "none",
                          position: "absolute",
                          top: "97%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          h: "3px",
                          w: "40%",
                          bg: "brand.500",
                          borderRadius: "5px",
                        }}
                      >
                        {/* {typeof current_game_icon === "string" ? (
                          // 如果是SVG文件路徑，使用Image組件
                          <Image src={`${process.env.REACT_APP_AWS_PATH}/v4-layout/game-type-icon/${current_game_type?.toUpperCase()}.svg`} w="24px" h="24px" />
                        ) : (
                          // 如果是React Icon，使用Icon組件
                          <Icon
                            as={current_game_icon}
                            w="24px"
                            h="24px"
                            _groupHover={{
                              color: "brand.500", // icon hover時變成品牌色
                            }}
                          />
                        )} */}
                        <Image
                          src={`${COMMON_WEB_PATH}/v4-layout/game-type-icon/${current_game_type?.toUpperCase()}.svg`}
                          w="27px"
                          h="27px"
                        />

                        <Text
                          fontWeight={`600`}
                          fontSize={"12px"}
                          position="relative"
                          color={props.isActive ? "brand.500" : textColor}
                          _after={{
                            // 底線效果
                            content: '""',
                            position: "absolute",
                            bottom: "-5px",
                            left: "50%",
                            transform: "translateX(-50%)",
                            width: "0", // 預設寬度為0
                            height: "3px", // 底線粗細
                            bg: "brand.500",
                            transition: "all 0.3s ease", // 過渡效果
                          }}
                          _groupHover={{
                            color: "brand.500",
                            _after: {
                              width: "100%", // hover時底線寬度變成100%
                            },
                          }}
                        >
                          {i18n(item)}
                        </Text>
                      </Flex>
                    </Box>
                  );
                }}
              </Link>
            </SplideSlide>
          );
        }),
      ];
    }
  }, [gameType, i18next.language, gameList, combileType, textColor]);

  const findCurrentGameTypeIndex = useMemo(() => {
    const mainType = gameMainTypeFromProps || gameMainType;

    const processGameType = gameType?.map((item) => {
      const textMapping = routeMappingV4(item);

      return textMapping?.link.replace("/", "");
    });

    return processGameType?.indexOf(mainType);
  }, [gameType, gameMainType, gameMainTypeFromProps]);

  const splideOptions = useMemo(() => {
    return {
      start: findCurrentGameTypeIndex,
      pagination: false,
      arrows: false,
      drag: "free",
      direction: "ttb",
      height: "auto",
      // perPage: 5,
      // perMove: 1,
      gap: 0,
      lazyLoad: "nearby",
      fixedWidth: "70px",
      dragMinThreshold: {
        mouse: 0,
        touch: 10,
      },
      breakpoints: {
        640: {
          fixedHeight: "70px",
          height: "350px",
        },
      },
    };
  }, [findCurrentGameTypeIndex]);

  useEffect(() => {
    if (splideRef.current) {
      const mainType = gameMainTypeFromProps || gameMainType;
      const splide = splideRef.current.splide;
      const processGameType = gameType?.map((item) => {
        const textMapping = routeMappingV4(item);
        return textMapping?.link.replace("/", "");
      });
      splide.go(processGameType?.indexOf(mainType));
    }
  }, [gameType, gameMainType, gameMainTypeFromProps]);

  return (
    <Box
      borderRadius={"common"}
      overflow="hidden"
      // bgGradient={"linear(to-b,brand.500,brand.600,brand.300,brand.700)"}
      pt="3px"
      position="sticky"
      top={{
        base: "65px",
        md: "10px",
      }}
    >
      <Splide
        ref={splideRef}
        options={{
          start: findCurrentGameTypeIndex,
          pagination: false,
          arrows: false,
          drag: "free",
          // perPage: 5,
          // perMove: 1,
          gap: "10px",
          lazyLoad: "nearby",
          fixedWidth: `${100 / combileType?.length}%`,
          dragMinThreshold: {
            mouse: 0,
            touch: 10,
          },
          breakpoints: {
            mediaQuery: "min",
            640: {
              fixedHeight: "55px",
              height: "365px",
              fixedWidth: "60px",
            },

            5000: {
              fixedHeight: "55px",
              height: "auto",
            },
          },
        }}
      >
        {gameSubTypeList}
      </Splide>
    </Box>
  );
};

export default GameTypeSelectorV4;
