export const routeMapping = (route) => {
  switch (route) {
    case "Home":
      return {
        origin: "Home",
        link: "/",
      };
    case "fish":
    case "FH":
      return {
        origin: "FH",
        link: "/fish",
      };
    case "table":
    case "TABLE":
      return {
        origin: "TABLE",
        link: "/table",
      };
    case "slots":
    case "SLOT":
      return {
        origin: "SLOT",
        link: "/slots",
      };
    case "live-casino":
    case "LIVE":
      return {
        origin: "LIVE",
        link: "/live-casino",
      };
    case "arcade":
    case "EGAME":
      return {
        origin: "EGAME",
        link: "/arcade",
      };
    case "sports":
    case "SPORT":
      return {
        origin: "SPORT",
        link: "/sports",
      };
    case "lottery":
    case "LOTTERY":
      return {
        origin: "LOTTERY",
        link: "/lottery",
      };
    case "block-chain":
    case "BLOCKCHAIN":
      return {
        origin: "BLOCKCHAIN",
        link: "/block-chain",
      };
    case "bingo":
    case "BINGO":
      return {
        origin: "BINGO",
        link: "/bingo",
      };
    case "promotion":
    case "PROMOTION":
      return {
        origin: "PROMOTION",
        link: "/promotion",
      };
    default:
      return {
        link: `/`,
      };
  }
};
