import React, { useMemo } from "react";
import { useSelector } from "react-redux";

const useGetFullGamePlatform = () => {
  const handleAllGame = useSelector((state) => state.isHandleAllGame);

  const platformDefined = (casinoType) => {
    //根據 casinoType 的值返回一個陣列，該陣列包含了應該從遊戲列表中提取的平台類別
    switch (casinoType) {
      case "casino":
        return ["FH", "SLOT", "EGAME", "TABLE", "BLOCKCHAIN"];
      case "live-casino":
        return ["LIVE"];
      case "sports":
        return ["SPORT"];
      case "lottery":
        return ["LOTTERY"];
      default:
        return [];
    }
  };

  // 從 gameList 中提取出 categories 中指定的類別的平台，並去除重複的平台
  const getUniquePlatforms = (
    categories,
    gameList = handleAllGame?.gameList
  ) => {
    const platforms = new Set();
    if (gameList) {
      // 確保 gameList 存在
      categories?.forEach((category) => {
        if (gameList[category]) {
          // 確保 gameList[category] 存在
          Object?.keys(gameList[category])?.forEach((platform) => {
            platforms?.add(platform); //將當前的平台添加到 platforms Set 中。由於 Set 只能存儲唯一的值，所以這將確保 platforms 中不會有重複的平台
          });
        }
      });
    }
    return Array.from(platforms);
  };
  const fullPlatform = useMemo(() => {
    if (handleAllGame.gameList) {
      const data = {
        casino: getUniquePlatforms(platformDefined("casino")),
        sports: getUniquePlatforms(platformDefined("sports")),
        lottery: getUniquePlatforms(platformDefined("lottery")),
        "live-casino": getUniquePlatforms(platformDefined("live-casino")),
      };
      let result = {};

      for (let key in data) {
        if (data?.hasOwnProperty(key)) {
          let categoryObject = data?.[key]?.reduce((obj, item) => {
            obj[item] = key;
            return obj;
          }, {});
          Object.assign(result, categoryObject);
        }
      }
      return result;
    }
    return [];
  }, [handleAllGame]);
  return fullPlatform;
};

export default useGetFullGamePlatform;
