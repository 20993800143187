import { useEffect, useState } from "react";
import { motion } from "framer-motion";

import DepositStepTwo from "./DepositStepTwo";
import SelectMethod from "./components/SelectMethod";
import DepositStepOne from "./DepositStepOne";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import LoadingBlock from "../../components/Loading/LoadingBlock";
import DetailWrapper from "../../components/detailWrapper";
import { useTranslation } from "react-i18next";
import { getDepositPaymentList, getThirdPartyPayment } from "../../api/getApi";
import useSpecPath from "../../hook/useSpecPath";
import GetParams from "../../utils/getParams";
import { Link } from "react-router-dom";
import { Divider, Flex, Icon, Text } from "@chakra-ui/react";
import { waitTime } from "../../utils/waitTime";
import { FaTriangleExclamation } from "react-icons/fa6";
import useCurrentWager from "../../hook/useCurrentWager";
import DepositStepOneSelector from "./DepositStepOneSelector";

const Deposit = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`deposit.${key}`);

  const memberData = useSelector((state) => state.isMemberInfo);
  const walletCurrencyCode = useSelector((state) => state.isWalletCurrency);

  const navigate = useNavigate();

  const [paymentList, setPaymentList] = useState({
    vip: [],
    third_party: [],
  });
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCurrency, setSelectedCurrency] = useState(
    process.env.REACT_APP_DEFAULT_CURRENCY
  );

  const method = GetParams("method");
  const currency = GetParams("currency");

  const getSpecPath = useSpecPath({ specPath: "deposit" });

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await waitTime(1000);
      await getThirdPartyPayment({
        paramsData: {
          currency_code: currency || selectedCurrency,
        },
      }).then((data) => {
        const vip = data?.vip || [];
        const third_party = data?.third_party || [];
        setPaymentList({
          vip,
          third_party,
        });
      });

      setIsLoading(false);
    };

    fetchData();
  }, [selectedCurrency, currency]);
  return (
    <DetailWrapper>
      <DepositStepOneSelector
        selectedCurrencyConfig={{
          selectedCurrency: selectedCurrency,
          setSelectedCurrency: setSelectedCurrency,
        }}
        isLoading={isLoading}
        paymentList={paymentList}
      />

      {/* <Link to={`${getSpecPath}withdraw`}>
        <Text
          textAlign={`center`}
          my="10px"
          color="special.100"
          cursor={`pointer`}
        >
          {i18n("withdraw")}
        </Text>
      </Link> */}
    </DetailWrapper>
  );
};

export default Deposit;
