import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Divider,
  Flex,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { FaGift } from "react-icons/fa6";
import { formatNumber } from "../../../utils/formatNumber";
import useVipInfo from "../../../hook/useVipInfo";
import { settlePlatformReward } from "../../../api/postApi";
import useCheckPlatformBonus from "../../../hook/useCheckPlatformBonus";
import LoadingIcon from "../../../components/Loading/LoadingIcon";
import { notice } from "../../../redux/action/action";
import { RiCloseFill } from "react-icons/ri";

const BonusWalletDetail = () => {
  const { t } = useTranslation();
  const i18n = (key, props) => t(`gamebox.${key}`, { ...props });
  const i18n_common = (key) => t(`common.${key}`);

  const gameConfig = useSelector((state) => state.isGameConfig);
  const bonus_wallet = useSelector((state) => state.isBonus);
  const currentCurrency = useSelector((state) => state.isWalletCurrency);
  const dispatch = useDispatch();

  const { game_platform, gameCode, game_subtype } = gameConfig;

  const [buttonLoading, setButtonLoading] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [abandonLoading, setAbandonLoading] = useState(false);

  const { current_symbol } = useVipInfo();

  const {
    current_platform_has_bonus,
    status,
    current_wager,
    target_wager,
    trigger,
    check_loading_status,
    bonus_uid,
  } = useCheckPlatformBonus({
    game_platform: game_platform,
  });
  const handleOpenConfirm = async () => {
    setOpenConfirm(true);
  };

  const handleAbandonBonus = async () => {
    setAbandonLoading(true);
    try {
      await settlePlatformReward({
        paramsData: {
          abandon: 1,
          uid: bonus_uid,
          currency: currentCurrency,
          platform: game_platform,
        },
      }).then((res) => {
        dispatch(
          notice({
            content: "Abandon Success",
            type: "success",
          })
        );
        setOpenConfirm(false);
      });
    } catch {
    } finally {
      setAbandonLoading(false);
    }
  };

  const handleSettleReward = async () => {
    setButtonLoading(true);
    try {
      await settlePlatformReward({
        paramsData: {
          abandon: 0,
          uid: bonus_uid,
          currency: currentCurrency,
          platform: game_platform,
        },
      }).then((res) => {
        dispatch(
          notice({
            content: "Receive Success",
            type: "success",
          })
        );
        dispatch({ type: "GET_BALANCE" });
        dispatch({ type: "GET_MEMBER_DATA" });
      });
    } catch {
    } finally {
      setButtonLoading(false);
    }
  };

  return (
    <>
      {current_platform_has_bonus && (
        <>
          <Divider my="5px" />
          <Flex alignItems={`flex-end`} justifyContent={`space-between`}>
            <Flex gap="5px" color="brand.500" alignItems={`center`}>
              <Icon as={FaGift} fontSize="lg" />
              <Text fontWeight={`semibold`} fontSize="sm">
                {i18n("current_is_use_bonus_wallet_play")}
              </Text>
            </Flex>
            <Flex gap="5px">
              {trigger}
              <IconButton
                onClick={handleOpenConfirm}
                colorScheme="red"
                icon={<RiCloseFill />}
                size="xs"
              />
            </Flex>
          </Flex>
          <Flex alignItems={`flex-end`} justifyContent={`space-between`}>
            <LoadingIcon commonLoading={check_loading_status}>
              <Text
                as="span"
                fontWeight={`semibold`}
                color={`white`}
                fontSize="lg"
                letterSpacing={`wide`}
              >
                {formatNumber(current_platform_has_bonus, { point: false })}{" "}
                {current_symbol}
              </Text>
            </LoadingIcon>
          </Flex>
          {check_loading_status ? null : (
            <Text
              fontSize="xs"
              fontWeight={`semibold`}
              color={current_wager >= target_wager ? "green.500" : "red.500"}
            >
              {i18n(
                current_wager >= target_wager
                  ? "already_arrived"
                  : "please_satisfy_wager",
                {
                  current: current_wager,
                  target: target_wager,
                }
              )}
            </Text>
          )}
          <Button
            isDisabled={status != 1 || check_loading_status || buttonLoading}
            w="100%"
            variant={`brandPrimary`}
            isLoading={check_loading_status || buttonLoading}
            size="md"
            onClick={handleSettleReward}
          >
            {i18n("settle_reward")}
          </Button>
        </>
      )}
      {openConfirm && (
        <Modal
          variant={`brandPrimary`}
          isOpen={openConfirm}
          onClose={() => {
            setOpenConfirm(false);
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              {i18n("abandon_title", { platform: game_platform })}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text fontSize="md" fontWeight={`semibold`}>
                {i18n("abandon_content", { platform: game_platform })}
              </Text>
            </ModalBody>

            <ModalFooter>
              <Button
                isLoading={abandonLoading}
                isDisabled={abandonLoading}
                mr={3}
                onClick={() => {
                  setOpenConfirm(false);
                }}
              >
                {i18n("cancel")}
              </Button>
              <Button
                isLoading={abandonLoading}
                isDisabled={abandonLoading}
                onClick={handleAbandonBonus}
                variant="brandPrimary"
              >
                {i18n("confirm")}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default BonusWalletDetail;
