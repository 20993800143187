import { AiFillDollarCircle, AiFillGift } from "react-icons/ai";
import { FaBirthdayCake } from "react-icons/fa";
import { TbPokerChip } from "react-icons/tb";

export const vipPromotion = [
  {
    label: "upgradeBonus",
    icon: <AiFillGift className="text-red-300" />,
    symbol: "",
  },
  {
    label: "freeWithdrawalCount",
    icon: <AiFillDollarCircle className="text-gold-text" />,
    symbol: "times",
  },
  {
    label: "birthdayBonus",
    icon: <FaBirthdayCake className="text-gray-300" />,
    symbol: "",
  },
  { label: "maxRebate", icon: <TbPokerChip />, symbol: "x" },
];
