import React from "react";
import { useNavigate } from "react-router";
import { Flex } from "@chakra-ui/react";

const CommonOpacityBackground = ({
  children,
  path,
  customFunction,
  className,
  disabledFunction,
}) => {
  const navigate = useNavigate();
  return (
    <Flex
      onClick={() => {
        if (disabledFunction) {
          return;
        }
        if (customFunction) {
          customFunction();
        } else {
          navigate(path);
        }
      }}
      bg="#171a1ce6"
      zIndex="99999"
      position="fixed"
      top="0"
      left="0"
      w="full"
      h="full"
      alignItems="start"
      py={{ md: "46px" }}
      justifyContent="center"
      className={className}
    >
      {children}
    </Flex>
  );
};

export default CommonOpacityBackground;
