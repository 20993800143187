import React, { useMemo } from "react";
import { useSelector } from "react-redux";

const useRouterType = () => {
  const router = useSelector((state) => state.router);

  const type = useMemo(() => {
    return router?.action;
  }, [router]);

  return type;
};

export default useRouterType;
