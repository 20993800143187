import React, { useMemo } from "react";
import ResetWithdrawPassword from "./resetWithdrawPassword";
import ResetWithdrawPasswordV4 from "./resetWithdrawPassword_v4";
import { WEB_LAYOUT } from "../../../constant";

const ResetWithdrawPasswordSelector = () => {
  const resetWithdrawPassword = useMemo(() => {
    switch (WEB_LAYOUT) {
      case "v2":
        return <ResetWithdrawPassword />;
      case "v3":
        return <ResetWithdrawPassword />;
      case "v4":
        return <ResetWithdrawPasswordV4 />;
      default:
        return <ResetWithdrawPassword />;
    }
  }, [WEB_LAYOUT]);
  return resetWithdrawPassword;
};

export default ResetWithdrawPasswordSelector;
