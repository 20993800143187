import { Box, Flex } from "@chakra-ui/react";
import React, { Fragment, useEffect } from "react";
import PlatformSelector from "../../components/v4Layout/PlatformSelector/PlatformSelector";
import GameSelector from "../../components/v4Layout/GameSelector/GameSelector";
import MarqueeListV2 from "../../components/marquee/marqueeList_v2";
import { useSelector } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import ExceptWrapper from "./utils/exceptWrapper";
import CarouselV4 from "../../components/Carousel_v4";
import GameTypeSelectorV4 from "../../components/v4Layout/GameTypeSelector/GameTypeSelector_v4";
import SubListSelector from "../../components/v4Layout/SubListSelector/SubListSelector";
import SubListSelectorV4 from "../../components/v4Layout/SubListSelector/SubListSelector_v4";
import PlatformSelectorV4 from "../../components/v4Layout/PlatformSelector/PlatformSelector_v4";

const GamePlatformPageV4 = () => {
  const isHandleAllGame = useSelector((state) => state.isHandleAllGame);
  const { gameType, topGameList, newGameList } = isHandleAllGame;

  const { gameMainType } = useParams();

  // const navigate = useNavigate();

  // useEffect(() => {
  //   if (gameMainType) {
  //     const current_type = routeMapping(gameMainType)?.origin;
  //     if (!gameType?.includes(current_type)) {
  //       return navigate("/slots");
  //     }
  //   }
  // }, [gameType, gameMainType]);
  return (
    <Fragment>
      <Box as="section" px="common-y">
        <GameTypeSelectorV4 />
      </Box>
      <Box as="section" pt="home-space" px="common-y">
        <SubListSelectorV4 forceShowAll />
      </Box>
      {/* <ExceptWrapper
        exceptGameType={[
          "live-casino",
          "sports",
          "lottery",
          "fish",
          "arcade",
          "block-chain",
          "table",
        ]}
      >
        <Box as="section" py="home-space" px="common-y">
          <CarouselV4 />
        </Box>
      </ExceptWrapper>
      <ExceptWrapper
        exceptGameType={[
          "live-casino",
          "sports",
          "lottery",
          "fish",
          "arcade",
          "block-chain",
          "table",
        ]}
      >
        <Box as="section" pb={{ md: "0", base: "home-space" }} px="common-y">
          <MarqueeListV2 />
        </Box>
      </ExceptWrapper> */}

      <Box
        overflowY="scroll"
        flex={1}
        id="scrollableDiv"
        h="100%"
        // py={{
        //   md: "home-space",
        //   base: "0",
        // }}
        // h={{
        //   base: "80vh",
        //   md: "auto",
        // }}
      >
        <Box py="home-space" px="common-y">
          <PlatformSelectorV4 />
        </Box>

        <Box py="home-space" px="common-y">
          {/* <SubListSelector /> */}
          <GameSelector />
        </Box>
      </Box>
    </Fragment>
  );
};

export default GamePlatformPageV4;
