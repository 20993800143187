import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const useHideZeroAmountStatus = () => {
  const memberInfo = useSelector((state) => state.isMemberInfo);

  const [hideZeroAmountStatus, setHideZeroAmountStatus] = useState(false);

  const { hide_zero } = memberInfo || [];

  useEffect(() => {
    if (hide_zero == 1) {
      setHideZeroAmountStatus(true);
    } else {
      setHideZeroAmountStatus(false);
    }
  }, [memberInfo]);

  return hideZeroAmountStatus;
};

export default useHideZeroAmountStatus;
