import React, { useMemo } from "react";
import { useSelector } from "react-redux";

const useCurrentVipLevelConfig = ({ currentLevel = 0, customCurrency }) => {
  const walletCurrency = useSelector((state) => state.isWalletCurrency);
  const vipList = useSelector((state) => state.isVipDetail);

  const current_level_data = useMemo(() => {
    const { rebate_days, vip_payment_tags, vip_withdrawal_rate, name } =
      vipList?.[currentLevel] || {};
    const compareCurrency = customCurrency || walletCurrency;
    const vip_payment_tags_data = vip_payment_tags?.find((item) => {
      return item.currency_code === compareCurrency;
    });

    return {
      rebate_days: rebate_days,
      vip_payment_tags: vip_payment_tags_data,
      vip_withdrawal_rate: vip_withdrawal_rate,
      name: name,
    };
  }, [vipList, currentLevel, walletCurrency, customCurrency]);

  return current_level_data || {};
};

export default useCurrentVipLevelConfig;
