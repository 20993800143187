import React, { useMemo } from "react";
import { vipPromotion } from "../helper/detailList";
import useVipInfo from "../../../hook/useVipInfo";
import { useSelector } from "react-redux";
import PromotionCard from "./promotionCard";
import { useTranslation } from "react-i18next";
import { Flex, Text, useColorModeValue } from "@chakra-ui/react";
import useNextVipLevelConfig from "../../../hook/useNextVipLevelConfig";
import useCurrencySymbol from "../../../hook/useCurrencySymbol";

const VipPromotionList = ({ className, customLevel }) => {
  const { t } = useTranslation();
  const i18n = (key, props) => t(`vip.${key}`, { ...props });
  const i18n_unit = (key, props) => t(`unit.${key}`, { ...props });

  const vipConfig = useVipInfo();
  const { current_level, current_wager, current_symbol } = vipConfig;

  const level = useMemo(() => {
    return customLevel ? customLevel : current_level;
  }, [customLevel, current_level]);

  const { rebate_days, vip_payment_tags, vip_withdrawal_rate } =
    useNextVipLevelConfig({ level: level });

  return (
    <Flex direction={`column`} w="100%" gap="5px">
      <PromotionCard
        className={className}
        icon={null}
        label={i18n("rebateDays")}
        promotionProps={{
          description: `${rebate_days} ${i18n_unit("day")}`,
        }}
      />
      <PromotionCard
        className={className}
        icon={null}
        label={i18n("withdrawalTimes")}
        promotionProps={{
          description: `${vip_payment_tags?.["withdrawTimes"]} ${i18n_unit(
            "time"
          )}`,
        }}
      />
      <PromotionCard
        className={className}
        icon={null}
        label={i18n("minWithdrawal")}
        promotionProps={{
          description: `${vip_payment_tags?.["withdrawMini"]} ${current_symbol}`,
        }}
      />
      <PromotionCard
        className={className}
        icon={null}
        label={i18n("withdrawalFee")}
        promotionProps={{
          description: (
            <>
              {vip_withdrawal_rate?.map((item) => {
                return (
                  <Text>
                    {i18n("withdrawalFeeDescription", {
                      times: item.number,
                      rate: item.rate * 100,
                    })}{" "}
                  </Text>
                );
              })}
            </>
          ),
        }}
      />
    </Flex>
  );
};

export default VipPromotionList;
