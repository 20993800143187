import {
  AbsoluteCenter,
  Button,
  Flex,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { GoAlertFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";

const BindCardPlease = ({ selectedCurrency } = {}) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`formHint.${key}`);

  const navigate = useNavigate();

  const textColor = useColorModeValue("dark.100", "light.100");

  const handleClick = () => {
    navigate(`../settings/bind-id-card?currency=${selectedCurrency}`);
  };

  return (
    <Flex
      as="article"
      direction={`column`}
      gap="15px"
      p="15px"
      pt="50px"
      fontWeight={`medium`}
    >
      <Flex
        alignItems={`center`}
        justifyContent={`center`}
        mb="40px"
        position="relative"
      >
        <AbsoluteCenter
          w="100px"
          h="100px"
          opacity={0.15}
          borderRadius="full"
          className={`bg-yellow-500`}
        ></AbsoluteCenter>
        <GoAlertFill className={`text-yellow-500 text-5xl`} />
      </Flex>
      <Flex alignItems={`center`} justifyContent={`center`}>
        <Text fontSize="lg" textAlign={`center`} color={textColor}>
          {" "}
          {`${i18n("youCanGo")} ${i18n("bindRoute")}，${i18n("view")}
      ${i18n("bindData")}`}
          !!
        </Text>
      </Flex>
      <Flex alignItems={`center`} justifyContent={`center`} color="special.100">
        <Text as="span">{i18n("pleaseGoToBindCard")}</Text>
      </Flex>
      <Button
        type="button"
        variant={`brandPrimary`}
        onClick={() => {
          localStorage.clear();
          handleClick();
        }}
        size="lg"
        fontWeight={`medium`}
      >
        {i18n("goToBindCard")}
      </Button>
    </Flex>
  );
};

export default BindCardPlease;
