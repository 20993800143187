import React, { useMemo } from "react";
import { WEB_LAYOUT } from "../../../constant";
import PersonalData from "./personalData";
import PersonalDataV4 from "./personalData_v4";

const PersonalDataSelector = () => {
  const personalData = useMemo(() => {
    switch (WEB_LAYOUT) {
      case "v2":
        return <PersonalData />;
      case "v3":
        return <PersonalData />;
      case "v4":
        return <PersonalDataV4 />;
      default:
        return <PersonalData />;
    }
  }, [WEB_LAYOUT]);
  return personalData;
};

export default PersonalDataSelector;
