import { useTranslation } from "react-i18next";
import { COMISSION_RULES, RULES } from "./Rule";
import RuleTable from "./RuleTable";
import { Box, Text, useColorModeValue } from "@chakra-ui/react";
import { appName } from "../../../config";

const COMMON_TEXT_STYLE =
  "text-xs sm:text-sm mb-[5px] text-light-text leading-5";

const RuleDetail = ({ noTable }) => {
  const { t } = useTranslation();
  const i18n = (key, props) => t(`referral.${key}`, { ...props });

  const textColor = useColorModeValue("dark.100", "light.100");
  const qrBg = useColorModeValue("light.100", "dark.100");
  const commissionBg = useColorModeValue("light.200", "dark.200");
  const commissionColumnBg = useColorModeValue("light.100", "dark.100");

  return (
    <>
      <Text color="textColor" fontWeight={`medium`} fontSize="lg">
        {i18n(`Rule.tip1`)}
      </Text>
      <Box
        as="section"
        fontSize="sm"
        bg={commissionColumnBg}
        color={textColor}
        p="16px"
        borderRadius={`common`}
        display="flex"
        flexDirection={`column`}
        gap="5px"
      >
        {RULES.map((rule, INDEX) => {
          return (
            <Box
              key={INDEX}
              className={`${INDEX !== RULES.length - 1 && "mb-[20px]"}`}
            >
              <Box
                display="flex"
                flexDirection={`column`}
                gap="5px"
                as="section"
              >
                {rule.details.map((detail, index) => {
                  return (
                    <Text as="p" fontWeight={`bold`} color="special.1">
                      {index + 1}{" "}
                      {i18n(`Rule.detail${INDEX + 1}.${index + 1}`, {
                        casino: appName,
                      })}
                    </Text>
                  );
                })}
              </Box>

              {noTable || (
                <>
                  <Text
                    fontSize="sm"
                    fontWeight={`semibold`}
                    my="15px"
                    className="text-gold-text"
                  >
                    {i18n("Reward Calculation")}:
                  </Text>
                  <RuleTable />
                </>
              )}
              <p className="text-xs font-semibold text-gold-text my-[15px] sm:text-xl">
                {i18n("Rule.example.notice.1")}
              </p>
              <Box
                display="flex"
                flexDirection={`column`}
                gap="5px"
                as="section"
              >
                <Text as="p" fontWeight={`bold`} color="special.1">
                  1. {i18n("Rule.example.notice.2")}
                </Text>
                <Text as="p" fontWeight={`bold`} color="special.1">
                  2. {i18n("Rule.example.notice.3")}
                </Text>
                <Text as="p" fontWeight={`bold`} color="special.1">
                  3. {i18n("Rule.example.notice.4")}
                </Text>
              </Box>
              {/* <p className="text-gold-text font-bold">
              {i18n("Rule.example.notice.5")}
            </p> */}
            </Box>
          );
        })}
      </Box>
    </>
  );
};

export default RuleDetail;
