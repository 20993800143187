import React, { useMemo } from "react";
import { useSelector } from "react-redux";

const useNextVipLevelConfig = ({ level }) => {
  const walletCurrency = useSelector((state) => state.isWalletCurrency);
  const vipList = useSelector((state) => state.isVipDetail);

  const next_level_promo_data = useMemo(() => {
    const {
      rebate_days,
      vip_payment_tags,
      vip_withdrawal_rate,
      vip_criteria,
      name,
    } = vipList?.[level] || {};
    const vip_payment_tags_data = vip_payment_tags?.find((item) => {
      return item.currency_code === walletCurrency;
    });
    const next_level_criteria_data = vip_criteria?.find(
      (item) => item.currency_code === walletCurrency
    );
    return {
      rebate_days: rebate_days,
      vip_payment_tags: vip_payment_tags_data,
      vip_withdrawal_rate: vip_withdrawal_rate,
      next_level_criteria_data: next_level_criteria_data,
      name,
    };
  }, [level, vipList, walletCurrency]);
  return next_level_promo_data || {};
};

export default useNextVipLevelConfig;
