import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { openChat } from "../redux/action/member/memberAction";

const VerifyPage = ({ children, verify }) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`profile.${key}`);
  const i18n_gamebox = (key) => t(`gamebox.${key}`);
  const i18n_common = (key) => t(`common.${key}`);

  const dispatch = useDispatch();

  const textColor = useColorModeValue("dark.100", "light.100");

  return verify ? (
    children
  ) : (
    <>
      <Alert
        status="warning"
        variant="subtle"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
      >
        <AlertIcon boxSize="40px" mr={0} />
        <AlertTitle color={textColor} mt={4} mb={1} fontSize="lg">
          {i18n_common("notice_you")}
        </AlertTitle>
        <AlertDescription color={textColor} maxWidth="sm">
          <Text>{i18n("you_cant_use_Hint")}</Text>
          <Button
            mt="15px"
            type="button"
            variant={`brandPrimary`}
            onClick={() => {
              dispatch(openChat());
            }}
            size="md"
            fontWeight={`medium`}
          >
            {i18n_gamebox("contactCustomerServiceNow")}
          </Button>
        </AlertDescription>
      </Alert>
    </>
  );
};

export default VerifyPage;
