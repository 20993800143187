import React, { useMemo } from "react";
import { WEB_LAYOUT } from "../../constant";
import DateSelectV4 from "./DateSelect_v4";
import DateSelect from "./DateSelect";

const DateSelectSelector = (props) => {
  const dateSelector = useMemo(() => {
    switch (WEB_LAYOUT) {
      case "v4":
        return <DateSelectV4 {...props} />;
      default:
        return <DateSelect {...props} />;
    }
  }, [props]);
  return dateSelector;
};

export default DateSelectSelector;
