import React, { useEffect, useState } from "react";
import { getBetLog, getBufferHistory } from "../../../api/getApi";
import LoadingBetRecord from "../../../components/Loading/LoadingBetRecord";
import NotFound from "../../../components/ProgressBlock/NotFound";
import HistoryBetLogDashboard from "./HistoryBetLogDashboard";
import BufferBetLogDashboard from "./BufferBetLogDashboard";

const BufferBetLog = ({ datePicker, platformSelect }) => {
  const [gameRecord, setGameRecord] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    getBufferHistory({
      paramsData: {
        str_date: datePicker.str_date,
        end_date: datePicker.end_date,
        platform: platformSelect === "All" ? "" : platformSelect,
      },
    })
      .then((data) => {
        setGameRecord(data?.data?.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [datePicker]);

  return (
    <LoadingBetRecord commonLoading={isLoading}>
      {gameRecord?.length === 0 ? (
        <NotFound />
      ) : (
        gameRecord?.map((item, index) => {
          const {
            platform,
            buffer_amount,
            order_number,
            lock_time,
            unlock_time,
            currency,
            updated_at,
            status,
          } = item;
          return (
            <BufferBetLogDashboard
              index={`${order_number}-${index}`}
              gameName={platform}
              bufferAmount={buffer_amount}
              lockTime={lock_time}
              unlockTime={unlock_time}
              orderNumber={order_number}
              currency={currency}
              status={status}
              updatedAt={updated_at}
            />
          );
        })
      )}
    </LoadingBetRecord>
  );
};

export default BufferBetLog;
