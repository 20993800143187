import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import CommonLoading from "./CommonLoading";
import { Box, Image, Skeleton, useColorModeValue } from "@chakra-ui/react";
import { COMMON_ALT } from "../../constant";
import { stylesConfig } from "../../helpers/stylesConfig";

const LazyImageV4 = ({
  src,
  alt,
  className,
  fetchPriority,
  onError,
  isPromotion,
}) => {
  const [loaded, setLoaded] = useState(false);

  const bgColor = useColorModeValue("light.200", "dark.100");

  // useEffect(() => {
  //   setTimeout(() => {
  //     setLoaded(true);
  //   }, 500);
  // }, []);
  // CommonLoading isLoaded={loaded}
  return (
    <>
      <LazyLoadImage
        src={src}
        alt={alt}
        onLoad={() => {
          setLoaded(true);
        }}
        onError={onError}
        fetchpriority={fetchPriority}
        className={`${className}`}
      />
      {!loaded && (
        <Box
          position="absolute"
          top="0px"
          left="0px"
          bg={bgColor}
          zIndex={1}
          w="100%"
          h="100%"
          display={`flex`}
          alignItems={`center`}
          justifyContent={`center`}
          borderTopRadius={`common`}
          borderBottomRadius={`common`}
        >
          <Image
            src={stylesConfig.mainLogo}
            cursor={"pointer"}
            w="120px"
            objectFit={`cover`}
            mb={isPromotion && "100px"}
            pointerEvents={"all"}
            alt={COMMON_ALT}
            filter="grayscale(100%)"
          />
        </Box>
      )}
    </>
  );
};

export default React.memo(LazyImageV4);
