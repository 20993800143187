import { isValidMotionProp, motion } from "framer-motion";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { IoCheckmarkCircleSharp } from "react-icons/io5";
import { HiChevronLeft } from "react-icons/hi";

import { animatePage } from "../../animateConstant";
import { COMMON_WEB_PATH } from "../../constant";
import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  Icon,
  Image,
  Skeleton,
  Text,
  chakra,
  shouldForwardProp,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { methodIcon } from "./components/methodIcon";
import CustomInput from "../../components/InputComponents/customInput";
import { AiFillDollarCircle } from "react-icons/ai";
import { waitTime } from "../../utils/waitTime";
import LoadingContainer from "../../components/Loading/LoadingContainer";
import { getThirdPartyPayment } from "../../api/getApi";
import DetailWrapper from "../../components/detailWrapper";
import GetParams from "../../utils/getParams";
import CloseDetailPage from "../../components/CloseComponent/CloseDetailPage";
import { formatNumber } from "../../utils/formatNumber";
import { depositPayment } from "../../api/postApi";
import { notice } from "../../redux/action/action";
import { storeDepositLink } from "../../redux/action/cashflow/cashflowAction";
import InApp from "detect-inapp";
import PageAnimateWrapper from "../../components/Animate/pageAnimateWrapper";

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const DepositStepTwo = ({}) => {
  const { t } = useTranslation();
  const i18n = (key, props) => t(`deposit.${key}`, { ...props });

  const currency_code = useSelector((state) => state.isWalletCurrency);
  const currency_config = useSelector((state) => state.isCurrencyExchange);

  const [data, setData] = useState({
    method_type: "",
    amount: 0,
    min: 0,
    max: 0,
    providers: [],
    selected_provider: "",
    method_id: "",
  });
  const [buttonLoading, setButtonLoading] = useState(false);
  const [apiLoading, setApiLoading] = useState(true);
  const [paymentList, setPaymentList] = useState({});
  const {
    method_type,
    amount,
    min,
    max,
    providers,
    selected_provider,
    method_id,
  } = data || {};

  const textColor = useColorModeValue("dark.100", "light.100");
  const methodBoxBg = useColorModeValue("light.100", "dark.100");
  const iconColor = useColorModeValue("light.400", "light.200");

  const { third_party, vip } = paymentList || {};
  const navigate = useNavigate();
  const location = useLocation();
  const { uid } = useParams();

  const dispatch = useDispatch();

  const method = GetParams("method");
  const currency = GetParams("currency");

  const inapp = new InApp(
    navigator.userAgent || navigator.vendor || window.opera
  );

  const find_payment_method = useMemo(() => {
    return third_party?.filter((item) => {
      return item.method_name?.toLowerCase() === method?.toLowerCase();
    });
  }, [method, paymentList, currency]);

  const recommended_amount = useMemo(() => {
    return currency_config?.[currency]?.default_amount || [];
  }, [currency_config, currency]);

  const expand_all_providers = useMemo(() => {
    return find_payment_method
      ?.map((item) => {
        return item.providers.map((provider) => {
          return {
            ...provider,
            ...item,
          };
        });
      })
      ?.flat();
  }, [find_payment_method]);

  const handleSubmit = async () => {
    setButtonLoading(true);
    // const newWindow = window.open("", "_blank");

    await depositPayment({
      deposit_amount: amount,
      method_id: method_id,
      provider_uid: selected_provider,
      currency_code: currency_code,
    })
      .then(async (data) => {
        // const game_link = data?.url;
        // newWindow.location.href = game_link;
        if (data?.not_support_iframe == 1) {
          const newWindow = !inapp.isInApp ? window.open("", "_blank") : null;

          setTimeout(() => {
            if (inapp.isInApp) {
              window.location.href = data?.url;
            } else {
              newWindow.location = data?.url;
            }
          });
          await waitTime(2000);
          navigate(-1);
        } else {
          await waitTime(2000);

          dispatch(storeDepositLink(data.url));
          navigate("deposit-page" + location.search);
        }
        setButtonLoading(false);
      })
      .catch(async (err) => {
        await waitTime(2000);
        setButtonLoading(false);
      });
  };

  const handleSelectMethod = ({
    method_type,
    min,
    max,
    providers,
    selected_provider,
    method_id,
  } = {}) => {
    setData({
      ...data,
      method_type: method_type,
      amount: 0,
      min: min,
      max: max,
      providers,
      selected_provider,
      method_id,
    });
  };

  const is_less_than_min_or_more_than_max = useMemo(() => {
    return amount < min || amount > max;
  }, [min, max, amount]);

  useEffect(() => {
    const fetchData = async () => {
      await getThirdPartyPayment({
        paramsData: {
          currency_code: currency,
        },
      }).then((data) => {
        const vip = data?.vip || [];
        const third_party = data?.third_party || [];
        setPaymentList({
          vip,
          third_party,
        });
      });
      setApiLoading(false);
    };

    fetchData();
  }, []);

  return (
    <DetailWrapper>
      <PageAnimateWrapper>
        <Box as="section">
          <CloseDetailPage
            hasPrev={
              <HiChevronLeft
                className="cursor-pointer"
                onClick={() => navigate(-1)}
              />
            }
            title={
              <Text
                as="span"
                display="flex"
                alignItems={`center`}
                justifyContent={`center`}
              >
                {method?.toUpperCase()}
              </Text>
            }
          />

          <Flex alignItems={`center`} justifyContent={`center`} mb="30px">
            <Image
              src={`${COMMON_WEB_PATH}/paymentIcon/${method?.toLowerCase()}pay.png`}
              className="!w-[50px] !h-[50px] object-cover"
              borderRadius={"10px"}
            />
          </Flex>
          <Divider borderColor="gray.300" mt="5px" mb="10px" />
          {/* <Box as="section">
            <Text
              as="p"
              fontSize="xl"
              fontWeight={`semibold`}
              color={textColor}
              mb="5px"
            >
              <Icon
                as={IoCheckmarkCircleSharp}
                color={method_type ? "green.300" : "gray.300"}
                fontSize="1.5rem"
                ml="5px"
              />{" "}
              {i18n("selectPaymentMethod")}
            </Text>
            <Grid templateColumns="repeat(4, minmax(0, 1fr))" gap={6}>
              {apiLoading
                ? Array.from({ length: 4 }, (v, i) => {
                    return <Skeleton w="100%" h="75px"></Skeleton>;
                  })
                : find_payment_method?.map((item) => {
                    return (
                      <Box
                        onClick={() => {
                          if (buttonLoading) return;
                          handleSelectMethod({
                            method_type: item.method_type,
                            providers: item.providers,
                          });
                        }}
                        cursor={`pointer`}
                        _hover={{
                          border: "1px solid",
                          borderColor: "brand.500",
                        }}
                        border={
                          method_type === item.method_type ? "1px solid" : ""
                        }
                        borderColor={
                          method_type === item.method_type ? "brand.500" : ""
                        }
                        w="100%"
                        h="75px"
                        borderRadius={`common`}
                        display="flex"
                        flexDirection={`column`}
                        shadow="md"
                        p="5px"
                        bg={methodBoxBg}
                        alignItems={`center`}
                        justifyContent={`center`}
                      >
                        <Icon
                          as={methodIcon(item.method_type)}
                          fontSize="40px"
                          color={
                            method_type === item.method_type
                              ? "brand.500"
                              : iconColor
                          }
                        />
                        <Text
                          as="span"
                          fontSize="lg"
                          color={
                            method_type === item.method_type
                              ? "brand.500"
                              : iconColor
                          }
                        >
                          {item.method_type}
                        </Text>
                      </Box>
                    );
                  })}
            </Grid>
            <Divider borderColor="gray.300" my="10px" />
          </Box> */}
          <Box as="section" mb="10px">
            <Text
              as="p"
              fontSize="xl"
              fontWeight={`semibold`}
              color={textColor}
              mb="5px"
            >
              <Icon
                as={IoCheckmarkCircleSharp}
                color={selected_provider ? "green.300" : "gray.300"}
                fontSize="1.5rem"
                ml="5px"
              />{" "}
              {i18n("selectChannel")}
            </Text>
            <Grid templateColumns="repeat(3, minmax(0, 1fr))" gap={"10px"}>
              {apiLoading
                ? Array.from({ length: 3 }, (v, i) => {
                    return <Skeleton w="100%" h="75px"></Skeleton>;
                  })
                : expand_all_providers?.map((item) => {
                    const {
                      min,
                      max,
                      provider_name,
                      uid,
                      method_id,
                      method_type,
                    } = item;
                    return (
                      <Box
                        onClick={() => {
                          if (buttonLoading) return;
                          handleSelectMethod({
                            method_type,
                            min,
                            max,
                            providers,
                            selected_provider: uid,
                            method_id,
                          });
                        }}
                        cursor={`pointer`}
                        _hover={{
                          border: "1px solid",
                          borderColor: "brand.500",
                        }}
                        border={selected_provider === uid ? "1px solid" : ""}
                        borderColor={
                          selected_provider === uid ? "brand.500" : ""
                        }
                        w="100%"
                        h="75px"
                        borderRadius={`common`}
                        display="flex"
                        flexDirection={`column`}
                        shadow="md"
                        p="5px"
                        gap="5px"
                        bg={methodBoxBg}
                        alignItems={`center`}
                        justifyContent={`center`}
                      >
                        <Image
                          src={`${COMMON_WEB_PATH}/paymentIcon/${method?.toLowerCase()}pay.png`}
                          className="!w-[35px] !h-[35px] object-cover"
                        />

                        <Text
                          as="span"
                          fontSize="xs"
                          color={
                            selected_provider === uid ? "brand.500" : iconColor
                          }
                        >
                          {provider_name}
                          <Text as="span" fontSize="xs">
                            -{method_type?.toUpperCase()}
                          </Text>
                        </Text>
                      </Box>
                    );
                  })}
            </Grid>
          </Box>
          <Box as="section">
            <Text
              as="p"
              fontSize="xl"
              fontWeight={`semibold`}
              color={textColor}
              mb="5px"
            >
              <Icon
                as={IoCheckmarkCircleSharp}
                color={amount ? "green.300" : "gray.300"}
                fontSize="1.5rem"
                ml="5px"
              />{" "}
              {i18n("inputPaymentAmount")}
            </Text>
            <Grid mb="10px" templateColumns="repeat(3, 1fr)" gap={3}>
              {recommended_amount.map((item, index) => {
                return (
                  <Button
                    variant={`brandPrimary`}
                    borderRadius={"10px"}
                    size="sm"
                    key={index}
                    bg={amount === item ? "brand.500" : "white"}
                    border={"1px solid"}
                    borderColor={amount === item ? "brand.500" : "#d8d8d8"}
                    color={amount === item ? "white" : "#d8d8d8"}
                    isDisabled={!method_type || buttonLoading}
                    onClick={() => {
                      setData({ ...data, amount: item });
                    }}
                  >
                    {formatNumber(item, {
                      point: false,
                    })}
                  </Button>
                );
              })}{" "}
            </Grid>
            <CustomInput
              props={{
                type: "number",
                label: "",
                icon: <AiFillDollarCircle className="text-gray-300" />,
                value: amount,
                onChange: (value) => {
                  setData({ ...data, amount: value });
                },
                isDisabled: !method_type || !selected_provider || buttonLoading,
                min: min,
                max: max,
                clampValueOnBlur: false,
              }}
            />
          </Box>
          {is_less_than_min_or_more_than_max && (
            <Text color="red.500" fontSize="sm">
              *{i18n("deposit_restrict_range", { min, max })}
            </Text>
          )}
          <Divider borderColor="gray.300" my="10px" />
          <Box as="section">
            <Button
              type="button"
              onClick={handleSubmit}
              variant={`brandPrimary`}
              w="100%"
              isLoading={buttonLoading}
              isDisabled={
                !amount ||
                !method_type ||
                !selected_provider ||
                is_less_than_min_or_more_than_max
              }
              size="lg"
            >
              {i18n("submitDeposit")}
            </Button>
          </Box>
        </Box>
      </PageAnimateWrapper>
    </DetailWrapper>
  );
};

export default DepositStepTwo;
