import Cookies from "js-cookie";

const CurrencyReducers = (
  state = Cookies.get("current_currency") || "IDR",
  action
) => {
  switch (action.type) {
    case "storeCurrency":
      return action.payload;
    default:
      return state;
  }
};
export default CurrencyReducers;
