import React, { useMemo } from "react";
import { WEB_LAYOUT } from "../../constant";
import WithdrawNotice from "./components/withdrawNotice";
import WithdrawNoticeV4 from "./components/withdrawNotice_v4";

const WithdrawNoticeSelector = (props) => {
  const withdrawNotice = useMemo(() => {
    switch (WEB_LAYOUT) {
      case "v4":
        return <WithdrawNoticeV4 {...props} />;
      default:
        return <WithdrawNotice {...props} />;
    }
  }, [props]);
  return withdrawNotice;
};

export default WithdrawNoticeSelector;
