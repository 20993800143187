const gameDescriptionPopReducers = (
  state = {
    state: false,
    game: "",
  },
  action
) => {
  switch (action.type) {
    case "openGameDescriptionPop":
      return action.payload;
    case "closeGameDescriptionPop":
      return {
        state: false,
        game: "",
      };
    default:
      return state;
  }
};
export default gameDescriptionPopReducers;
