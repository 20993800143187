import {
  AbsoluteCenter,
  Box,
  Flex,
  Image,
  Text,
  chakra,
  shouldForwardProp,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { API_RELOAD, COMMON_WEB_PATH } from "../../../constant";
import {
  animate,
  isValidMotionProp,
  motion,
  useMotionValue,
  useTransform,
} from "framer-motion";
import { formatNumber } from "../../../utils/formatNumber";
import { getJackpot } from "../../../api/getApi";

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const Jackpot = () => {
  const [number, setNumber] = useState(0);

  const count = useMotionValue(0);
  const rounded = useTransform(count, (value) =>
    formatNumber(value, { point: false })
  );

  useEffect(() => {
    const animation = animate(count, Number(number), {
      duration: 2,

      ease: "easeInOut",
    });

    return animation.stop;
  }, [number]);

  useEffect(() => {
    const fetchDataPeriodically = () => {
      getJackpot().then((data) => {
        const result = data?.data;

        const getFirstJackpot = result[Object?.keys(result)?.[1]];
        const { jackpot } = getFirstJackpot || {};
        setNumber(jackpot);
      });
    };

    fetchDataPeriodically();

    const apiIterval = setInterval(() => {
      fetchDataPeriodically();
    }, API_RELOAD);

    return () => clearInterval(apiIterval);
  }, []);

  return (
    <Box w="100%">
      <Box
        w="100%"
        pt="8px"
        pb="8px"
        px="10px"
        borderRadius={`common`}
        position={`relative`}
        display="flex"
        flexDirection={`column`}
        gap="5px"
        alignItems={`center`}
        justifyContent={`center`}
        bgGradient="linear(to-b,v3-second-brand.300,v3-second-brand.700)"
        border="2px solid"
        borderColor="v3-second-brand.400"
      >
        {/* <AbsoluteCenter zIndex={1} w="100%" h="100%">
          <Box
            w="100%"
            h="100%"
            bgSize="100% 100%"
            bgImage={`url(${COMMON_WEB_PATH}/b69-home/game-type-icon/jackpot-bg.png)`}
          />
        </AbsoluteCenter> */}
        <Flex w="100%" alignItems="center" direction={`column`} gap="0px">
          <Box
            position="relative"
            bg="v3-second-brand.1000"
            borderRadius={`10px`}
            px="25px"
            w={{
              base: "100%",
              md: "60%",
            }}
            display={`flex`}
            alignItems={`center`}
            justifyContent={`center`}
          >
            <Text
              // bgGradient="linear(to-l,  #c1b199, #FDE08D, #DF9F28)"
              // bgClip="text"
              fontSize={{
                base: "30px",
                md: "40px",
              }}
              fontWeight="900"
            >
              <ChakraBox>{rounded}</ChakraBox>
            </Text>
          </Box>
        </Flex>
        <Image
          display={{
            base: "none",
            md: "block",
          }}
          left={{
            md: "0px",
            lg: "10%",
          }}
          bottom="0px"
          width="135px"
          position={`absolute`}
          src={`${COMMON_WEB_PATH}/b69-home/jackpot/fc-jackpot-left.png`}
        />
        <Image
          display={{
            base: "none",
            md: "block",
          }}
          right={{
            md: "0px",
            lg: "10%",
          }}
          bottom="0px"
          width="175px"
          position={`absolute`}
          src={`${COMMON_WEB_PATH}/b69-home/jackpot/fc-jackpot-right.png`}
        />
      </Box>
    </Box>
  );
};

export default Jackpot;
