import React, { Fragment, useMemo } from "react";
import Seo from "./seo";
import Carousel from "../../components/Carousel";
import { Box, Text } from "@chakra-ui/react";
import MarqueeList from "../../components/marquee/marqueeList";
import GameTypeSelector from "../../components/v2Layout/GameTypeSelector/GameTypeSelector";
import SubListSelector from "../../components/v2Layout/SubListSelector/SubListSelector";
import JackpotWrapper from "../../components/v2Layout/Jackpot/JackpotWrapper";
import PlatformSelector from "../../components/v2Layout/PlatformSelector/PlatformSelector";
import CarouselV2 from "../../components/Carousel_v2";
import MarqueeListV2 from "../../components/marquee/marqueeList_v2";
import MobileSearchV2 from "../Search/mobile-search_v2";
import LeaderBoard from "../../components/v2Layout/LeaderBoard/LeaderBoard";
import GameCatalogV2 from "../../components/GameCatalog_v2";
import GameSelector from "../../components/v2Layout/GameSelector/GameSelector";
import { useSelector } from "react-redux";
import { routeMapping } from "../../components/v2Layout/GameTypeSelector/utils/routeMapping";

const SlotV2 = ({ gameMainTypeFromProps }) => {
  const isHandleAllGame = useSelector((state) => state.isHandleAllGame);
  const { gameType, topGameList, newGameList, gameListByTypeAndSubType } =
    isHandleAllGame;

  const gameSubTypeCategory = useMemo(() => {
    const mainType = routeMapping(gameMainTypeFromProps)?.origin;

    const getSubTypeCategory = Object?.keys(
      gameListByTypeAndSubType?.[mainType] || {}
    )?.reverse();

    return getSubTypeCategory?.map((item) => {
      return (
        <GameCatalogV2
          observeFalse
          wrapperClassName={`!px-[0px]`}
          list={gameListByTypeAndSubType?.[mainType]?.[item]}
          mainTitle={`${item}`}
          customFixedWidth="120px"
        />
      );
    });
  }, [gameListByTypeAndSubType, gameMainTypeFromProps]);

  return (
    <Fragment>
      <Seo />
      <Box as="section" py="home-space" px="common-y">
        <CarouselV2 />
      </Box>
      <Box as="section" pb={{ md: "0", base: "home-space" }} px="common-y">
        <MarqueeListV2 />
      </Box>
      <Box
        py={{
          md: "home-space",
          base: "0",
        }}
        px="common-y"
      >
        <GameTypeSelector gameMainTypeFromProps={gameMainTypeFromProps} />
      </Box>
      <Box
        mt={{
          md: "0px",
          base: "10px",
        }}
      >
        <MobileSearchV2 listHidden />
      </Box>
      <Box pb="home-space" px="common-y">
        <PlatformSelector gameMainTypeFromProps={gameMainTypeFromProps} />
      </Box>
      <Box py="home-space" px="common-y">
        <LeaderBoard />
      </Box>
      {/* <Box px="common-y">
        <SubListSelector />
      </Box> */}
      <Box mt="home-space" px="common-y">
        <JackpotWrapper />
      </Box>
      <Box px="common-y">
        <GameCatalogV2
          wrapperClassName={`!px-[0px]`}
          list={topGameList}
          mainTitle={`Top`}
          // swiperProps={{
          //   customPerView: "popular-layout",
          // }}
          isHot
          observeFalse
          customFixedWidth="155px"
        />
      </Box>{" "}
      <Box px="common-y">
        <GameCatalogV2
          wrapperClassName={`!px-[0px]`}
          list={newGameList}
          mainTitle={`News`}
          customHotTag={{
            color: "linear(to-r,#9DF87B,#0E9117)",
            text: (
              <Text as="span" fontSize={`sm`} letterSpacing={`tighter`}>
                New
              </Text>
            ),
          }}
          observeFalse
          // swiperProps={{
          //   customPerView: "popular-layout",
          // }}
          isHot
          customFixedWidth="155px"
        />
      </Box>
      {/* <Box py="home-space" px="common-y">
         <GameSelector
          gameMainTypeFromProps={gameMainTypeFromProps}
          subListSelector
        />
      </Box> */}
      <Box py="home-space" px="common-y">
        {gameSubTypeCategory}
      </Box>
    </Fragment>
  );
};

export default SlotV2;
