import React from "react";
import { useTranslation } from "react-i18next";

const OriginInputCustom = (props) => {
  const { t } = useTranslation();
  const i18n_common = (key) => t(`common.${key}`);
  switch (props.inputType) {
    case "input":
      return (
        <input
          {...props}
          placeholder={
            props.placeholder
              ? props.placeholder
              : `${i18n_common("pleaseInput")} ${
                  props.i18nLabel ? props.i18nLabel : props.label
                }`
          }
          className={`w-full !text-base-light-dark dark:!text-base-light-white bg-base-light-white dark:!bg-base-light-black border dark:border-base-light-dark rounded-[5px] py-[14px] px-[12px] outline-0 text-transaction-text`}
        />
      );
    case "select":
      return (
        <select
          {...props}
          className={`w-full !text-base-light-dark dark:!text-base-light-white bg-base-light-white dark:!bg-base-light-black border dark:border-base-light-dark rounded-[5px] py-[10px] px-[12px] outline-0 text-transaction-text`}
        >
          <option value="" disabled selected>
            {i18n_common("pleaseSelect")}
          </option>
          {props?.selectOption?.map((item, index) => {
            return <option value={item.value}>{item.label}</option>;
          })}
        </select>
      );
    default:
      return (
        <input
          {...props}
          placeholder={
            props.placeholder
              ? props.placeholder
              : `${i18n_common("pleaseInput")} ${
                  props.i18nLabel ? props.i18nLabel : props.label
                }`
          }
          className={`w-full !text-base-light-dark dark:!text-base-light-white bg-base-light-white dark:!bg-base-light-black border dark:border-base-light-dark rounded-[5px] py-[14px] px-[12px] outline-0 text-transaction-text`}
        />
      );
  }
};

export default OriginInputCustom;
