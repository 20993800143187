import { useMemo } from "react";
import C911 from "./C911";
import WS168 from "./WS168";

const useDescriptionConfig = ({ game }) => {
  const config = {
    C911: {
      children: <C911 />,
    },
    WS168: {
      children: <WS168 />,
    },
  };
  const descriptionComponent = useMemo(() => {
    return config[game];
  }, [game]);
  return descriptionComponent;
};

export default useDescriptionConfig;
