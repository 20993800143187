import { useEffect, useState } from "react";

import { animatePage, animatePageNotOpacity } from "../../animateConstant";

import CloseDetailPage from "../../components/CloseComponent/CloseDetailPage";
import InboxItem from "./InboxItem";
import { useDispatch, useSelector } from "react-redux";
import { getFixedStyle } from "../../hook/useDeviceType";
import { useTranslation } from "react-i18next";
import LoadingBlock from "../../components/Loading/LoadingBlock";
import NotFound from "../../components/ProgressBlock/NotFound";
import DetailWrapper from "../../components/detailWrapper";
import { getInboxList } from "../../api/getApi";
import ButtonTab from "../../components/TabComponent/ButtonTab";
import InboxList from "./components/inboxList";
import NewsList from "./components/newsList";
import { Box, Divider } from "@chakra-ui/react";

const Inbox = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`inbox.${key}`);

  const TAB = [
    { label: i18n("inbox"), value: "inbox" },
    // { label: i18n("news"), value: "news" },
  ];

  const [tab, setTab] = useState(TAB[0].value);
  return (
    <DetailWrapper>
      <CloseDetailPage title={i18n("title")} />
      <Box mb="10px">
        <ButtonTab TABS={TAB} setTab={setTab} tab={tab} />
      </Box>
      <Divider my="10px" />
      <Box>{tab === "inbox" ? <InboxList isInbox /> : <NewsList />}</Box>
    </DetailWrapper>
  );
};

export default Inbox;
