import {
  Box,
  Flex,
  Icon,
  Image,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { COMMON_WEB_PATH } from "../../../constant";
import { REPLACE_AIR } from "../../../replace_str_constant";
import { languageTransfer } from "../../../utils/languageTransfer";
import i18next from "i18next";
import { RiUserFill } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import useCheckLogin from "../../../hook/useCheckLogin";
import { CloseMenu, notice } from "../../../redux/action/action";
import {
  clearGameLink,
  startGameLoading,
  storeGameConfig,
} from "../../../redux/action/game/gameAction";
import useDeviceType from "../../../hook/useDeviceType";

const TopGameBoardV4 = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);

  const boxBg = useColorModeValue("#EBEDFE", "#394367");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.200");

  const handleAllGame = useSelector((state) => state.isHandleAllGame);

  const splideRef = useRef();

  const { randomlySelectedGames } = handleAllGame;
  console.log(randomlySelectedGames);

  const isMobile = useDeviceType() === "Mobile";
  const dispatch = useDispatch();
  const { isLogin } = useCheckLogin();
  const navigate = useNavigate();
  const location = useLocation();

  const handleEnterGame = useCallback(
    (gameItem) => {
      if (!isLogin) {
        dispatch(
          notice({
            title: i18n("Please login first"),
            type: "error",
          })
        );
        navigate("signin");
        return;
      }

      dispatch(storeGameConfig(gameItem));
      dispatch(clearGameLink());
      dispatch(CloseMenu());
      dispatch(startGameLoading());

      const game_name = gameItem.gameNameEn.replace(/ /g, "-");
      const game_platform = gameItem.game_platform.replace(/ /g, "-");

      if (isMobile) {
        navigate(
          `/game-confirm/${game_name}/${gameItem.game_platform}/${gameItem.gameUid}`
        );
      } else {
        navigate(`/gamebox/${game_name}/${game_platform}/${gameItem.gameUid}`);
      }
    },
    [isLogin, navigate, dispatch, isMobile, i18next.language]
  );

  return (
    <>
      <Flex
        w="100%"
        justifyContent={"space-between"}
        mb={{
          base: "5px",
          md: "0px",
        }}
        alignItems={"flex-end"}
      >
        <Text fontSize="lg" fontWeight={700}>
          {i18n("TOP GAMES")}
        </Text>
        {/* <Text color="brand.500" fontSize="sm">
          more
        </Text> */}
      </Flex>

      {/* 在 md 以上使用垂直列表 */}
      <Box
        display={{ base: "none", md: "block" }}
        maxH="280px" // 設置最大高度，超出後可滾動
        overflowY="auto" // 啟用垂直滾動
        className="no-scrollbar" // 隱藏滾動條但保持功能
        pr="10px" // 為滾動條預留空間
      >
        <Flex
          direction="column"
          gap={{
            base: "10px",
            md: "0px",
          }}
        >
          {randomlySelectedGames?.map((item, index) => {
            const { game_platform, gameCode } = item;
            return (
              <Box
                key={index}
                bg={boxBg}
                display={"flex"}
                h="65px"
                px="10px"
                alignItems={"center"}
                gap="10px"
                w="full"
                cursor="pointer"
                borderBottom="1px solid"
                borderBottomColor={borderColor}
                _last={{ borderBottom: "none" }} // 最後一個元素不要邊框
                _hover={{
                  // 添加 hover 效果
                  transform: "translateX(5px)",
                  transition: "all 0.2s",
                }}
                onClick={() => handleEnterGame(item)}
              >
                <Box
                  bg="brand.500"
                  borderRadius={"full"}
                  minW="22px"
                  minH="20px"
                  display="flex"
                  alignItems={"center"}
                  justifyContent={"center"}
                  color="#fff"
                >
                  <Text fontSize="12pt">{index + 1}</Text>
                </Box>
                <Box
                  alignItems={"center"}
                  display="flex"
                  gap="5px"
                  overflow={"hidden"}
                >
                  <Image
                    src={`${COMMON_WEB_PATH}/game-icon-v2/en/${game_platform}/${REPLACE_AIR(
                      gameCode
                    )}.webp`}
                    w="40px"
                    h="40px"
                    borderRadius={"full"}
                  />
                  <Box>
                    <Text
                      className="text-overflow"
                      fontSize="12pt"
                      fontWeight={700}
                    >
                      {item?.[
                        `gameName${languageTransfer({
                          lang: i18next.language,
                        })}`
                      ] || "-"}
                    </Text>
                    <Flex
                      fontSize="10pt"
                      color="#8D8D8D"
                      alignItems={"center"}
                      gap="5px"
                    >
                      <Icon as={RiUserFill} />
                      <Text>{0}</Text>
                    </Flex>
                  </Box>
                </Box>
              </Box>
            );
          })}
        </Flex>
      </Box>

      {/* 在 md 以下使用原有的水平輪播 */}
      <Box display={{ base: "block", md: "none" }}>
        <Splide
          ref={splideRef}
          options={{
            pagination: false,
            arrows: false,
            drag: "free",
            // perPage: 5,
            // perMove: 1,
            gap: "10px",
            lazyLoad: "nearby",
            fixedWidth: "175px",
            dragMinThreshold: {
              mouse: 0,
              touch: 10,
            },
            breakpoints: {
              mediaQuery: "min",
              640: {
                fixedHeight: "70px",
                height: "365px",
              },
              5000: {
                fixedHeight: "70px",
                height: "auto",
              },
            },
          }}
        >
          {randomlySelectedGames?.map((item, index) => {
            const { game_platform, gameCode } = item;
            return (
              <SplideSlide>
                <Box
                  bg={boxBg}
                  borderRadius={"common"}
                  display={"flex"}
                  h={{
                    base: "65px",
                  }}
                  px="10px"
                  alignItems={"center"}
                  gap="10px"
                  cursor={"pointer"}
                  onClick={() => handleEnterGame(item)}
                >
                  <Box
                    bg="brand.500"
                    borderRadius={"full"}
                    minW="22px"
                    minH="20px"
                    display="flex"
                    alignItems={"center"}
                    justifyContent={"center"}
                    color="#fff"
                  >
                    <Text fontSize="12pt">{index + 1}</Text>
                  </Box>
                  <Box
                    alignItems={"center"}
                    display="flex"
                    gap="5px"
                    overflow={"hidden"}
                  >
                    <Image
                      src={`${COMMON_WEB_PATH}/game-icon-v2/en/${game_platform}/${REPLACE_AIR(
                        gameCode
                      )}.webp`}
                      w="40px"
                      h="40px"
                      borderRadius={"full"}
                    />

                    <Box>
                      <Text
                        className="text-overflow"
                        fontSize="12pt"
                        fontWeight={700}
                      >
                        {item?.[
                          `gameName${languageTransfer({
                            lang: i18next.language,
                          })}`
                        ] || "-"}
                      </Text>
                      <Flex
                        fontSize="10pt"
                        color="#8D8D8D"
                        alignItems={"center"}
                        gap="5px"
                      >
                        <Icon as={RiUserFill} />
                        <Text>{0}</Text>
                      </Flex>
                    </Box>
                  </Box>
                </Box>
              </SplideSlide>
            );
          })}
        </Splide>
      </Box>
    </>
  );
};

export default TopGameBoardV4;
