import { useTranslation } from "react-i18next";
import { RiExchangeDollarLine } from "react-icons/ri";
import { TbZoomMoney } from "react-icons/tb";
import { useDispatch } from "react-redux";
import {
  closeGameBoxTransfer,
  triggerGameBoxTransfer,
} from "../../../redux/action/member/memberAction";
import { openVipBar } from "../../../redux/action/game/gameAction";
import { AnimatePresence, motion } from "framer-motion";
import { Button } from "@chakra-ui/react";

const NoticeTransfer = ({ setTriggerTransferPop, triggerTransferPop }) => {
  const { t } = useTranslation();
  const i18n_common = (key) => t(`common.${key}`);

  const dispatch = useDispatch();

  return (
    <div
      className={`bg-[#171a1ce6] z-[99999999] fixed top-0 left-0 w-full h-full flex items-center md:py-[46px] justify-center `}
    >
      <motion.section
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        exit={{ scale: 0 }}
        transition={{ duration: 0.2 }}
        className="bg-base-light-white dark:bg-base-light-dark rounded-[5px] w-[330px] h-[270px] pb-[20px] pt-[40px] px-[30px] flex flex-col items-center justify-between gap-[20px]"
      >
        <TbZoomMoney className="text-[80px] text-gold-text mb-[10px]" />
        <span className="font-medium dark:text-light-white">
          {i18n_common("noticeYourBalanceIsNotEnough")}
        </span>
        <Button
          type="button"
          size="xl"
          onClick={() => {
            dispatch(openVipBar());
            dispatch(triggerGameBoxTransfer());
            setTimeout(() => {
              dispatch(closeGameBoxTransfer());
              setTriggerTransferPop(false);
            }, 100);
          }}
          variant={`brandPrimary`}
        >
          {i18n_common("TRANSFER")}
        </Button>
      </motion.section>
    </div>
  );
};

export default NoticeTransfer;
