import React, { useMemo } from "react";
import { WEB_LAYOUT } from "../../../constant";
import BindIdCard from "./bindIdCard";
import BindIdCardV4 from "./bindIdCard_v4";

const BindIdCardSelector = () => {
  const bindIdCard = useMemo(() => {
    switch (WEB_LAYOUT) {
      case "v2":
        return <BindIdCard />;
      case "v3":
        return <BindIdCard />;
      case "v4":
        return <BindIdCardV4 />;
      default:
        return <BindIdCard />;
    }
  }, [WEB_LAYOUT]);
  return bindIdCard;
};

export default BindIdCardSelector;
