import {
  Box,
  Flex,
  Grid,
  GridItem,
  Image,
  Text,
  chakra,
  shouldForwardProp,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import NotFound from "../../ProgressBlock/NotFound";
import LoadingBlock from "../../Loading/LoadingBlock";
import useDeviceType from "../../../hook/useDeviceType";
import GAMEITEM from "../../GAMEITEM/GAMEITEM";
import { languageTransfer } from "../../../utils/languageTransfer";
import i18next from "i18next";
import GAMEITEMV2 from "../../GAMEITEM/GAMEITEM_v2";
import { routeMapping } from "../GameTypeSelector/utils/routeMapping";
import { useTranslation } from "react-i18next";
import SubListSelector from "../SubListSelector/SubListSelector";
import TitleIcon from "../PlatformSelector/components/titleIcon";
import { menuIcon } from "../../Menu/helper/menuIcon";
import { isValidMotionProp, motion } from "framer-motion";
import { gameListFromBottom } from "../../../animateConstant";
import { menuIconV2 } from "../../Menu/helper/menuIcon_v2";
import GAMEITEMV4 from "../../GAMEITEM/GAMEITEM_v4";
import { COMMON_WEB_PATH } from "../../../constant";

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const GameSelector = ({
  subListSelector,
  hiddenTitle,
  gameMainTypeFromProps,
  customSubType,
  customGamePlatform,
}) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);

  const scrollRef = useRef();

  const [displayedItems, setDisplayedItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  const { gameMainType, gamePlatform, subType } = useParams();

  const custom_subType = useMemo(() => {
    return customSubType || subType;
  }, [customSubType, subType]);

  const custom_gameplatform = useMemo(() => {
    return customGamePlatform || gamePlatform;
  }, [customGamePlatform, gamePlatform]);

  const isMobile = useDeviceType() === "Mobile";

  const isHandleAllGame = useSelector((state) => state.isHandleAllGame);
  const {
    sortedByPlatformGames,
    sortedByPlatformAndCategoryGames,
    gameList,
    gameListByTypeAndSubType,
    allGames,
    favoriteGameList,
    randomlySelectedGames,
    newGameList,
    topGameList,
    allGameType,
  } = isHandleAllGame || {};
  const platformGamesList = useMemo(() => {
    const mainType = gameMainTypeFromProps || gameMainType;
    const current_game_type = routeMapping(mainType)?.origin;

    const gameTypeCode = allGameType?.find(
      (t) => t.gameTypeName === current_game_type
    )?.gameTypeUid;
    if (custom_subType) {
      const determineIsAllPlatform = (gameList) => {
        if (custom_gameplatform === "all-platform") {
          return (
            gameList?.filter((game) => game.gameTypeUid === gameTypeCode) || []
          );
        } else {
          return (
            gameList
              ?.filter((game) => game.gameTypeUid === gameTypeCode)
              ?.filter(
                (game) =>
                  game.game_platform === custom_gameplatform ||
                  game.SubPlatformEnum === custom_gameplatform
              ) || []
          );
        }
      };
      switch (custom_subType) {
        case "AllGames":
          return determineIsAllPlatform(allGames);
        case "MyFavorite":
          return determineIsAllPlatform(favoriteGameList);
        case "ClickedGames":
          return determineIsAllPlatform(randomlySelectedGames);
        case "News":
          return determineIsAllPlatform(newGameList);
        case "Top":
          return determineIsAllPlatform(topGameList);
        default:
          if (custom_gameplatform === "all-platform") {
            return (
              gameListByTypeAndSubType?.[current_game_type]?.[
                custom_subType
              ]?.filter((game) => game.gameTypeUid === gameTypeCode) || []
            );
          } else {
            return (
              sortedByPlatformAndCategoryGames?.[custom_gameplatform]?.[
                custom_subType
              ] || []
            );
          }
      }
    }
    if (gameList && gameMainType && custom_gameplatform) {
      return gameList?.[current_game_type]?.[custom_gameplatform] || [];
    } else {
      return Object?.values(gameListByTypeAndSubType?.[current_game_type] || [])
        ?.flat()
        ?.filter((game) => game.gameTypeUid === gameTypeCode)
        ?.slice(0, 150);
    }
  }, [
    gameList,
    custom_gameplatform,
    gameMainType,
    custom_subType,
    sortedByPlatformAndCategoryGames,
    gameListByTypeAndSubType,
    gameMainTypeFromProps,
    allGameType,
  ]);

  const ITEMS_PER_PAGE = useBreakpointValue({
    base: 12,
    md: 25,
    lg: 35,
    xl: 32,
  });

  const loadMore = () => {
    setTimeout(() => {
      const moreItems = platformGamesList?.slice(
        0,
        (currentPage + 1) * ITEMS_PER_PAGE
      );
      if (moreItems?.length >= displayedItems?.length) {
        setDisplayedItems(moreItems);
        setIsLoading(false);
        setCurrentPage((prevPage) => prevPage + 1);
      }
    }, 3000);
  };

  const hasMoreItems = useMemo(() => {
    return displayedItems?.length < platformGamesList?.length;
  }, [displayedItems?.length]);

  // useEffect(() => {
  //   setDisplayedItems(platformGamesList?.slice(0, ITEMS_PER_PAGE));
  // }, [platformGamesList, ITEMS_PER_PAGE]);

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [isLoading]);

  useEffect(() => {
    setCurrentPage(1);
    setIsLoading(true);
    setDisplayedItems(platformGamesList?.slice(0, ITEMS_PER_PAGE));
  }, [platformGamesList, custom_gameplatform, subType, gameMainType]);

  useEffect(() => {
    if (scrollRef.current) {
      if (subType || custom_gameplatform) {
        const elementPosition = scrollRef.current.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - 55;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    }
  }, [subType, custom_gameplatform]);

  return (
    <Box
      ref={scrollRef}
      // borderTopRadius={`common`}
      // borderTop="3px solid"
      // borderColor={`brand.400`}
      // bgGrdi="linear(to-b, transparent, dark.500 400%)"
      // px="common-y"
    >
      {hiddenTitle ? null : (
        <Box mb="10px">
          <Flex alignItems={`center`} gap="5px">
            {/* <TitleIcon
              icon={menuIconV2(gameMainTypeFromProps || gameMainType)?.icon}
            /> */}
            <Image
              src={`${COMMON_WEB_PATH}/v4-layout/game-type-icon/${menuIconV2(
                gameMainTypeFromProps || gameMainType
              )?.text?.toUpperCase()}.svg`}
              w="27px"
              h="27px"
            />

            <Box display="flex" alignItems={`center`}>
              <Text
                fontWeight={`700`}
                fontSize={{
                  base: "12px",
                  md: "16px",
                }}
                display="flex"
                alignItems={`center`}
              >
                {i18n(gameMainTypeFromProps || gameMainType)}
                {/* {subType ? `｜${i18n(subType)}` : `｜${i18n("all-platform")}`}
            {subType ? `｜${i18n(subType)}` : ""} */}
              </Text>
              <Text
                fontWeight={`700`}
                fontSize={{
                  base: "12px",
                  md: "16px",
                }}
                display="flex"
                alignItems={`center`}
              >
                {custom_gameplatform
                  ? custom_gameplatform === "all-platform"
                    ? `｜${i18n("all-platform")}`
                    : `｜${custom_gameplatform}`
                  : null}
              </Text>

              <Text
                fontWeight={`700`}
                fontSize={{
                  base: "12px",
                  md: "16px",
                }}
                display="flex"
                alignItems={`center`}
              >
                {subType ? (
                  <>
                    ｜{/* <TitleIcon icon={menuIcon(subType, "icon")} /> */}
                    {i18n(subType)}
                  </>
                ) : (
                  <>
                    ｜
                    {/* <TitleIcon icon={menuIcon(subType || "AllGames", "icon")} /> */}
                    {i18n("all-platform")}
                  </>
                )}
              </Text>
            </Box>
          </Flex>
        </Box>
      )}
      {subListSelector && (
        <Box mb="10px">
          <SubListSelector
            gameMainTypeFromProps={gameMainTypeFromProps}
            forceShowAll={
              !custom_gameplatform || custom_gameplatform == "all-platform"
            }
          />
        </Box>
      )}
      {/* <InfiniteScroll
        pageStart={0}
        loadMore={loadMore}
        hasMore={hasMoreItems}
        useWindow={false}
        loader={<LoadingBlock commonLoading={true} />}
      > */}
      <LoadingBlock commonLoading={isLoading}>
        {displayedItems?.length === 0 && <NotFound />}
        <ChakraBox {...gameListFromBottom}>
          <Grid
            templateColumns={{
              base: "repeat(3, minmax(0, 1fr))",
              md: "repeat(4, minmax(0, 1fr))",
              lg: "repeat(6, minmax(0, 1fr))",
              xl: "repeat(6, minmax(0, 1fr))",
              "2xl": "repeat(6, minmax(0, 1fr))",
            }}
            rowGap={"8px"}
            columnGap="12px"
          >
            {platformGamesList?.map((item, index) => {
              const gameName =
                item?.[
                  `gameName${languageTransfer({
                    lang: i18next.language,
                  })}`
                ];
              const gameNameEn = item?.gameNameEn;
              const platform = item?.game_platform;
              const gameSubType = item?.game_subtype;

              const gameCode = item?.gameCode;
              const gameType = item?.gameType;
              return (
                <GridItem
                  colSpan={1}
                  rowSpan={1}
                  pb="2.5px"
                  // bg="brand.900"
                  borderRadius={"15px"}
                >
                  <GAMEITEMV4
                    gameProps={{
                      gameName,
                      platform,
                      gameCode,
                      gameType,
                      gameNameEn,
                      gameSubType,
                    }}
                    originProps={item}
                    itemClass={`!h-[130px]`}
                    itemIndex={index}
                  />
                </GridItem>
              );
            })}
          </Grid>
        </ChakraBox>
      </LoadingBlock>
      {/* </InfiniteScroll> */}
    </Box>
  );
};

export default GameSelector;
