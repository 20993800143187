import React from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Divider,
  Flex,
  SkeletonCircle,
  SkeletonText,
  useColorModeValue,
} from "@chakra-ui/react";

const LoadingReward = ({
  commonLoading,
  children,
  mapIndex = 2,
  gameConfirmMode,
}) => {
  const loadingStatus = useSelector((state) => state.isLoaderStatus);

  const cardBg = useColorModeValue("light.100", "dark.100");

  return commonLoading || loadingStatus
    ? Array.from({ length: mapIndex }, (_, i) => i).map((item) => {
        return (
          <>
            <Box
              minW={gameConfirmMode ? "95%" : ""}
              h={gameConfirmMode ? "239px" : ""}
              padding="3"
              boxShadow="lg"
              borderRadius="common"
              bg={cardBg}
            >
              <Flex
                h="100%"
                direction={`column`}
                justifyContent={`space-between`}
              >
                <Box>
                  <SkeletonCircle size="10" />
                  <SkeletonText
                    mt="4"
                    noOfLines={3}
                    spacing="2"
                    skeletonHeight="2"
                  />
                </Box>
                <SkeletonText
                  mt="4"
                  noOfLines={1}
                  spacing="4"
                  skeletonHeight="45"
                />
              </Flex>
            </Box>
          </>
        );
      })
    : children;
};

export default LoadingReward;
