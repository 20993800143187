import { Box } from "@chakra-ui/react";
import React, { Fragment } from "react";
import Carousel from "../../components/Carousel";
import MarqueeList from "../../components/marquee/marqueeList";
import GameTypeSelector from "../../components/v2Layout/GameTypeSelector/GameTypeSelector";
import SubListSelector from "../../components/v2Layout/SubListSelector/SubListSelector";
import JackpotWrapper from "../../components/v2Layout/Jackpot/JackpotWrapper";
import PlatformSelector from "../../components/v2Layout/PlatformSelector/PlatformSelector";
import MobileSearchV2 from "../Search/mobile-search_v2";
import LiveGameSelector from "../../components/v2Layout/GameSelector/LiveGameSelector";
import GameSelector from "../../components/v2Layout/GameSelector/GameSelector";
import LeaderBoard from "../../components/v2Layout/LeaderBoard/LeaderBoard";
import MarqueeListV2 from "../../components/marquee/marqueeList_v2";
import Seo from "./seo";

const FishV2 = ({ gameMainTypeFromProps }) => {
  return (
    <Fragment>
      <Seo />
      <Box
        py={{
          md: "home-space",
          base: "0",
        }}
        px="common-y"
      >
        <GameTypeSelector gameMainTypeFromProps={gameMainTypeFromProps} />
      </Box>
      <Box
        mt={{
          md: "0px",
          base: "10px",
        }}
      >
        <MobileSearchV2 listHidden />
      </Box>
      <Box pb="home-space" px="common-y">
        <PlatformSelector gameMainTypeFromProps={gameMainTypeFromProps} />
      </Box>
      {/* <Box py="home-space" px="common-y">
        <LeaderBoard />
      </Box> */}
      {/* <Box px="common-y">
        <SubListSelector />
      </Box> */}
      <Box mt="home-space" px="common-y">
        <JackpotWrapper />
      </Box>

      <Box py="home-space" px="common-y">
        {/* <SubListSelector /> */}
        <GameSelector
          gameMainTypeFromProps={gameMainTypeFromProps}
          subListSelector
        />
      </Box>
    </Fragment>
  );
};

export default FishV2;
