import { motion } from "framer-motion";
import { useEffect, useMemo, useRef, useState } from "react";

import CloseDetailPage from "../../components/CloseComponent/CloseDetailPage";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getToday, getYesterday } from "../../utils/getDay";
import DateSelect from "../../components/TimePicker/DateSelect";
import DetailWrapper from "../../components/detailWrapper";
import ButtonTab from "../../components/TabComponent/ButtonTab";
import TotalBet from "./components/totalBet";
import { Box, Divider, Flex, Text } from "@chakra-ui/react";
import GamePlatformSelect from "../../components/TimePicker/GamePlatformSelect";
import HistoryBet from "./components/HistoryBet";
import BufferBetLog from "./components/BufferBetLog";
import { useSearchParams } from "react-router-dom";
import ButtonTabSelector from "../../components/TabComponent/ButtonTabSelector";
import DateSelectSelector from "../../components/TimePicker/DateSelectSelector";

const isCredit = process.env.REACT_APP_CREDIT == 1;

const BetRecordV4 = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`betRecord.${key}`);
  const dispatch = useDispatch();

  const scrollContainerRef = useRef();

  const [searchParams, setSearchParams] = useSearchParams();

  const page = searchParams.get("page") || 1;
  const tab = searchParams.get("tab") || "Total Win";

  const TAB = [
    {
      label: i18n("Total Win"),
      value: "Total Win",
    },
    {
      label: i18n("Game History"),
      value: "Game History",
    },
    {
      label: i18n("Buffer Wallet"),
      value: "Buffer Wallet",
      hidden: isCredit,
    },
  ];

  const [datePicker, setDatePicker] = useState({
    str_date: getYesterday(),
    end_date: getToday(),
  }); //這個是時間選擇器的state 对象的初始值是通过调用 getToday() 函数获取的，这个函数用于获取当前日期
  const [platformSelect, setPlatformSelect] = useState("");
  const tabComponents = useMemo(() => {
    switch (tab) {
      case "Total Win":
        return (
          <TotalBet datePicker={datePicker} platformSelect={platformSelect} />
        );
      case "Game History":
        return (
          <HistoryBet datePicker={datePicker} platformSelect={platformSelect} />
        );
      case "Buffer Wallet":
        return (
          <BufferBetLog
            datePicker={datePicker}
            platformSelect={platformSelect}
          />
        );
      default:
        return <></>;
    }
  }, [tab, datePicker, platformSelect]);

  useEffect(() => {
    if (scrollContainerRef.current) {
      setTimeout(() => {
        scrollContainerRef.current?.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
        scrollContainerRef.current?.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, 100);
    }
  }, [page]);

  return (
    <DetailWrapper closeAnimate>
      <CloseDetailPage title={i18n("title")} />
      <Box ref={scrollContainerRef}>
        <Box as="section" py="common-y">
          <Flex as="section" alignItems={`center`} mb="10px">
            <ButtonTabSelector TABS={TAB} tab={tab} />
          </Flex>
          <Box
            mb="10px"
            display="flex"
            alignItems={`center`}
            justifyContent={`space-between`}
            gap="15px"
            as="section"
          >
            <DateSelectSelector
              trigger={tab}
              setDatePicker={setDatePicker}
              className={{
                w: "150px",
              }}
            />
          </Box>
          <GamePlatformSelect
            setPlatformSelect={setPlatformSelect}
            className={{
              w: "150px",
            }}
            customList={[
              {
                label: "All",
                value: "All",
              },
            ]}
          />
          <Box
            mb="10px"
            display="flex"
            alignItems={`center`}
            justifyContent={`space-between`}
            gap="15px"
            as="section"
          ></Box>
          <Divider my="10px" />
          <Flex as="section" direction={`column`} gap="10px">
            {tabComponents}
          </Flex>
        </Box>
      </Box>
    </DetailWrapper>
  );
};

export default BetRecordV4;
