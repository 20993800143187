import {
  Box,
  Flex,
  Image,
  Text,
  useColorMode,
  Icon,
  chakra,
  shouldForwardProp,
  Divider,
  useColorModeValue,
  IconButton,
} from "@chakra-ui/react";
import React, { useCallback, useMemo, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { stylesConfig } from "../../helpers/stylesConfig";
import { COMMON_ALT } from "../../constant";
import { useTranslation } from "react-i18next";
import { IoIosArrowDown, IoIosArrowForward, IoMdClose } from "react-icons/io";
import { MdEventNote, MdOutlineLanguage } from "react-icons/md";
import { AnimatePresence, isValidMotionProp, motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { CloseMenu, controlSubMenu } from "../../redux/action/action";
import useDeviceType from "../../hook/useDeviceType";
import { RiHomeSmileFill, RiMenuUnfoldLine } from "react-icons/ri";
import rouletteImage from "../../images/EventListSelect/active_04.webp";
import checkInImage from "../../images/EventListSelect/active_07.webp";
import useSubTypeList from "../../hook/useSubTypeList";
import { menuIcon } from "./helper/menuIcon";
import useCasinoType from "../../hook/useCasinoType";
import ProfileButton from "../../pages/PROFILE_SETTING/components/profileButton";
import CustomerNameText from "../../pages/PROFILE_SETTING/components/customerNameText";
import Balance from "../../pages/Profile/components/balance";
import { waitTime } from "../../utils/waitTime";
import {
  FaCalendarAlt,
  FaHeadphonesAlt,
  FaMobileAlt,
  FaNewspaper,
} from "react-icons/fa";
import {
  openChat,
  openLanguagePop,
} from "../../redux/action/member/memberAction";
import { headerMenu, pcMenu, v4Menu } from "./helper/headerMenu";
import useProcessEventList from "../../hook/useProcessEventList";
import ProfileCardNotLogin from "../../pages/PROFILE_SETTING/components/profileCardNotLogin";
import useCheckLogin from "../../hook/useCheckLogin";
import CreditPage from "../../utils/credit/credit_page";
import useAppDownloadLink from "../../pages/App/hooks/useAppDownloadLink";
import AppWrapper from "../../pages/App/AppWrapper";
import { TbPigMoney } from "react-icons/tb";
import { BiMoneyWithdraw } from "react-icons/bi";
import ProfileCardNotLoginV4 from "../../pages/PROFILE_SETTING/components/profileCardNotLogin_v4";
import { GoHomeFill } from "react-icons/go";
import ProfileCardV4 from "./components/ProfileCard_v4";

const isCredit = process.env.REACT_APP_CREDIT == 1;

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const MenuPcV4 = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);
  const i18n_header = (key) => t(`header.${key}`);

  const textColor = useColorModeValue("light.600", "light.100");
  const menuBg = useColorModeValue("light.200", "#1a2036");
  const textColorHover = useColorModeValue("dark.600", "brand.500");
  const logoBg = useColorModeValue("light.100", "transparent");
  const borderColor = useColorModeValue("#707070", "#FAFAFA");
  const { colorMode } = useColorMode();

  const { isLogin } = useCheckLogin();

  const app_download_link = useAppDownloadLink();

  const { gamePlatform } = useParams();

  const casinoType = useCasinoType();

  const deviceType = useDeviceType();

  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const currentPath = useMemo(() => {
    const paths = pathname.split("/").filter(Boolean); // 過濾空字串
    return paths[0] || "/"; // 如果是空的（首頁），返回 '/'
  }, [pathname]);

  const animationProps = useMemo(() => {
    if (deviceType) {
      return deviceType === "PC"
        ? {
            initial: { x: "0%" },
            animate: { x: "0%" },
            exit: { x: "0%" },
            transition: { duration: 0.15, ease: "easeInOut" },
          }
        : {
            initial: { x: "-100%" },
            animate: { x: "0%" },
            exit: { x: "-100%" },
            transition: { duration: 0.15, ease: "easeInOut" },
          };
    }
  }, [deviceType]);

  const dispatch = useDispatch();
  const isStickyLeft = useSelector((state) => state.isStickyLeft);
  const subMenuControl = useSelector((state) => state.isSubMenuControl);

  return (
    <ChakraBox
      h={`100%`}
      position={isStickyLeft ? "fixed" : "relative"}
      left="0px"
      pb={{ base: "0px" }}
      {...animationProps}
    >
      <Flex
        w={{
          base: "315px",
          md: "100%",
          lg: "100%",
        }}
        minH="100%"
        maxH="100vh"
        bg="transparent"
        direction={`column`}
        alignItems={`center`}
        gap={{
          base: "10px",
          lg: "25px",
        }}
        py="20px"
        onClick={(event) => {
          event.stopPropagation();
        }}
        overflowY="scroll"
        overflowX="hidden"
        className="no-scrollbar"
        position="fixed"
      >
        <Flex
          w="100%"
          direction={`column`}
          align="center"
          textAlign={`center`}
          gap="20px"
        >
          {pcMenu.map((item) => {
            // 統一格式來比對
            const itemPath = item.link.startsWith("/")
              ? item.link.slice(1)
              : item.link;
            const isActive =
              (itemPath === "" && currentPath === "/") ||
              (itemPath !== "" && currentPath === itemPath);

            return (
              <Box
                as={Link}
                key={item.text}
                to={item.link}
                display="flex"
                flexDirection={"column"}
                alignItems="center"
                justifyContent="center"
                gap="5px"
              >
                <Flex
                  direction="column"
                  align="center"
                  justify="center"
                  w={item.text === "Lobby" ? "60px" : "50px"} // Lobby 特別大
                  h={item.text === "Lobby" ? "60px" : "50px"} // Lobby 特別大
                  borderRadius="full"
                  border="1px solid"
                  borderColor={isActive ? "transparent" : borderColor}
                  bg={isActive ? item.activeBg : "transparent"}
                  _hover={{
                    borderColor: item.activeBg,
                  }}
                  transition="all 0.2s"
                >
                  <Image
                    src={
                      isActive || colorMode === "dark"
                        ? item.image.dark
                        : item.image.light
                    }
                    w={item.text === "Lobby" ? "34px" : "28px"} // Lobby icon比較大
                    h={item.text === "Lobby" ? "34px" : "28px"} // Lobby icon比較大
                  />
                </Flex>
                <Text fontSize="14px" color={borderColor}>
                  {i18n(item.text)}
                </Text>
              </Box>
            );
          })}
        </Flex>
      </Flex>
    </ChakraBox>
  );
};

export default MenuPcV4;
