import {
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  NumberDecrementStepper,
  NumberIncrementStepper,
  InputLeftElement,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";

const CustomInputV4 = ({ props }) => {
  const { type, icon, label, required, isInvalid } = props || {};

  const inputBg = useColorModeValue("light.200", "dark.250");
  const borderColor = useColorModeValue("light.550", "dark.450");
  const textColor = useColorModeValue("light.450", "light.100");

  // 預設 label 樣式
  const defaultLabelStyle = {
    color: textColor,
    fontSize: "16px",
    fontWeight: "bold",
  };

  // 預設 input 樣式
  const defaultInputStyle = {
    background: inputBg,
    borderColor: borderColor,
    color: textColor,
  };

  switch (type) {
    case "text":
      return (
        <FormControl isInvalid={isInvalid} isRequired={required}>
          <FormLabel sx={defaultLabelStyle}>{label}</FormLabel>
          <InputGroup>
            {/* <InputLeftElement pointerEvents="none">{icon}</InputLeftElement> */}
            <Input variant={`brandPrimary`} {...props}></Input>
          </InputGroup>
        </FormControl>
      );
    case "number":
      return (
        <FormControl isRequired={required}>
          <FormLabel sx={defaultLabelStyle}>{label}</FormLabel>
          <InputGroup>
            {/* <InputLeftElement pointerEvents="none">{icon}</InputLeftElement> */}
            <NumberInput w="100%" variant={`brandPrimary`} {...props}>
              <NumberInputField pl="35px" />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </InputGroup>
        </FormControl>
      );
    case "select":
      return (
        <FormControl isRequired={required}>
          <FormLabel sx={defaultLabelStyle}>{label}</FormLabel>
          <InputGroup>
            <Select
              variant={`brandPrimary`}
              {...props}
              sx={{
                "& option": {
                  background: "transparent",
                },
              }}
            >
              {props?.options.map((item) => {
                return <option value={item.value}>{item.label}</option>;
              })}
            </Select>
          </InputGroup>
        </FormControl>
      );
    case "date":
      return (
        <FormControl isRequired={required}>
          <FormLabel sx={defaultLabelStyle}>{label}</FormLabel>
          <InputGroup>
            {/* <InputLeftElement pointerEvents="none">{icon}</InputLeftElement> */}
            <Input variant={`brandPrimary`} {...props}></Input>
          </InputGroup>
        </FormControl>
      );
    default:
      return (
        <FormControl isRequired={required}>
          <FormLabel sx={defaultLabelStyle}>{label}</FormLabel>
          <InputGroup>
            {/* <InputLeftElement pointerEvents="none">{icon}</InputLeftElement> */}
            <Input variant={`brandPrimary`} {...props}></Input>
          </InputGroup>
        </FormControl>
      );
  }
};

export default CustomInputV4;
