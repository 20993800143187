import React, { useMemo } from "react";
import {
  filterNumberColor,
  filterSign,
  formatNumber,
} from "../../../utils/formatNumber";
import LoadingIcon from "../../../components/Loading/LoadingIcon";
import { useTranslation } from "react-i18next";
import {
  Box,
  Divider,
  Flex,
  Tag,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import CopyIcon from "../../../components/CopyComponent/CopyIcon";
import useVipInfo from "../../../hook/useVipInfo";

const HistoryBetLogDashboard = ({
  index,
  gameName,
  bet,
  winLose,
  roundId,
  settleTime,
  status,
} = {}) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`betRecord.${key}`);

  const cardBg = useColorModeValue("light.100", "dark.100");
  const textColor = useColorModeValue("dark.100", "light.100");

  const { current_symbol } = useVipInfo();

  const statusBar = useMemo(() => {
    switch (status) {
      case 1:
        return {
          color: "green.500",
          text: i18n("Settled"),
        };
      case 0:
        return {
          color: "gray.500",
          text: i18n("Bet"),
        };
      case 2:
        return {
          color: "red.500",
          text: i18n("Canceled"),
        };
      default:
        return {
          color: "red.500",
          text: i18n("Error"),
        };
    }
  }, [status]);

  return (
    <Box
      index={index}
      as="section"
      bg={cardBg}
      borderRadius={`common`}
      py="common-y"
      px="common-x"
      borderLeft="2px solid"
      borderColor={statusBar.color}
    >
      <Flex alignItems={`flex-start`} justifyContent={`space-between`}>
        <Flex gap="5px">
          <Text
            color={textColor}
            fontSize={`lg`}
            fontWeight={`semibold`}
            display="flex"
            alignItems={`center`}
          >
            {gameName}{" "}
          </Text>
          <Tag bg={statusBar.color} color={`white`} size={"sm"}>
            {statusBar.text}
          </Tag>
        </Flex>
        <Text fontSize={`10px`} fontWeight={600} color={textColor}>
          {settleTime}
        </Text>
      </Flex>

      {/* <Flex alignItems={`center`}> */}
      <Text fontSize={`xs`} color="special.1">
        {i18n("orderNumber")}：
      </Text>
      <Flex gap="5px" alignItems={`center`}>
        <Text fontSize={`sm`} fontWeight={`bold`} color={textColor}>
          {roundId}
        </Text>
        <CopyIcon copyLink={roundId} />
      </Flex>

      {/* </Flex> */}
      <Flex justifyContent={`space-between`}>
        <Flex direction={`column`} color="special.1">
          <Text fontSize={`xs`}>{i18n("Bet")}</Text>
          <Text color={textColor} fontWeight={`semibold`}>
            {formatNumber(bet)} {current_symbol}
          </Text>
        </Flex>

        <Flex direction={`column`} alignItems="flex-end" color="special.1">
          <Text fontSize="xs">{i18n("Win/Lose")}</Text>
          <Text
            fontWeight={`semibold`}
            className={`${filterNumberColor(winLose)}`}
          >
            {formatNumber(winLose)} {current_symbol}
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
};

export default HistoryBetLogDashboard;
