import {
  Button,
  Divider,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  PinInput,
  PinInputField,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { waitTime } from "../../utils/waitTime";
import { pointTransferToMember } from "../../api/postApi";
import { useDispatch, useSelector } from "react-redux";
import { notice } from "../../redux/action/action";
import { Link, useNavigate } from "react-router-dom";

const ConfirmModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`settings.${key}`);
  const i18n_common = (key) => t(`common.${key}`);

  const dispatch = useDispatch();

  const memberInfo = useSelector((state) => state.isMemberInfo);

  const { withdraw_password_set } = memberInfo || {};

  const [buttonLoading, setButtonLoading] = useState(false);

  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const memId = localStorage.getItem("memId");
  const currency = localStorage.getItem("currency");
  const amount = localStorage.getItem("amount");

  const handleSubmit = async () => {
    setButtonLoading(true);

    await waitTime(1000);
    await pointTransferToMember({
      memId,
      currency,
      amount,
      password,
    })
      .then((data) => {
        dispatch(
          notice({
            title: i18n("send_success"),
            type: "success",
          })
        );
        onClose();
        navigate("/profile/transaction");
      })
      .finally(() => {
        setButtonLoading(false);
      });
  };
  return (
    <Modal
      variant={`brandPrimary`}
      blockScrollOnMount={false}
      isOpen={isOpen}
      onClose={onClose}
      size="xs"
      isCentered
      closeOnOverlayClick
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader alignSelf={`center`}>
          {i18n("input_transaction_password")}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <HStack justify="center">
            <PinInput
              onChange={(v) => {
                setPassword(v);
              }}
            >
              <PinInputField
                _focus={{
                  boxShadow: "none",
                  borderColor: "brand.500",
                }}
              />
              <PinInputField
                _focus={{
                  boxShadow: "none",
                  borderColor: "brand.500",
                }}
              />
              <PinInputField
                _focus={{
                  boxShadow: "none",
                  borderColor: "brand.500",
                }}
              />
              <PinInputField
                _focus={{
                  boxShadow: "none",
                  borderColor: "brand.500",
                }}
              />
            </PinInput>
          </HStack>
          <Flex
            my="10px"
            justifyContent={`center`}
            alignItems={`center`}
            gap="5px"
          >
            {withdraw_password_set != 1 && (
              <Link to="../../settings/reset-withdraw-password">
                <Text
                  fontSize="sm"
                  color="brand.500"
                  textDecoration={`underline`}
                >
                  {t("settings.not_yet_set")}？
                </Text>
              </Link>
            )}
          </Flex>
          <Divider my="10px" />
          <Flex direction={`column`} gap="5px">
            <Flex alignItems={`center`} justifyContent={`space-between`}>
              <Flex alignItems={`center`} gap="3px">
                <Text>{i18n("send_target_id")}:</Text>
              </Flex>
              <Text color="brand.500">{memId}</Text>
            </Flex>
            <Flex alignItems={`center`} justifyContent={`space-between`}>
              <Flex alignItems={`center`} gap="3px">
                <Text>{i18n("currency")}:</Text>
              </Flex>
              <Text color="brand.500">{currency}</Text>
            </Flex>
            <Flex alignItems={`center`} justifyContent={`space-between`}>
              <Flex alignItems={`center`} gap="3px">
                <Text>{i18n("amount")}:</Text>
              </Flex>
              <Text color="brand.500">{amount}</Text>
            </Flex>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button
            w="full"
            isDisabled={buttonLoading}
            isLoading={buttonLoading}
            variant={`brandPrimary`}
            size="sm"
            onClick={handleSubmit}
          >
            {i18n("confirm_send")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmModal;
