import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
const weekday = require("dayjs/plugin/weekday");

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(weekday);
dayjs.tz.setDefault("Asia/Taipei");

export const formatTime = (time) => {
  return dayjs(time).format("YYYY-MM-DD");
};

export const getWeek = (weekStart, weekEnd) => {
  const start = dayjs().weekday(weekStart).toDate();
  const end = dayjs().weekday(weekEnd).toDate();
  return { start, end };
};
export const getMonth = (month) => {
  const start = dayjs().subtract(month, "month").startOf("month").toDate();
  const end = dayjs().subtract(month, "month").endOf("month").toDate();
  return { start, end };
};
export const getToday = () => {
  const today = dayjs().toDate();
  return today;
};
export const getTomorrow = () => {
  const tomorrow = dayjs().add(1, "day").toDate();
  return tomorrow;
};
export const getYesterday = () => {
  const yesterday = dayjs().add(-1, "day").toDate();
  return yesterday;
};
export const getThreeDays = () => {
  const threeDay = dayjs().add(-3, "day").toDate();
  return threeDay;
};

export const getSevenDays = () => {
  const sevenDays = dayjs().add(-7, "day").toDate();
  return sevenDays;
};
export const getThirtyDays = () => {
  const thirtyDays = dayjs().add(-30, "day").toDate();
  return thirtyDays;
};

export const getCustomDays = (day) => {
  const customDays = dayjs().add(-day, "day").toDate();
  return customDays;
};
