import { Divider, Flex, Icon, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { FaUser } from "react-icons/fa";
import MainText from "./main_text";
import SubText from "./sub_text";
import { useTranslation } from "react-i18next";

const AccountIssues = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`help-center.${key}`);

  return (
    <VStack spacing={`10px`} alignItems={`flex-start`}>
      <Flex alignItems={`flex-end`} gap="10px">
        <Icon color="brand.500" as={FaUser} fontSize="40px" />
        <Text mb="0px" fontSize="xl" fontWeight={`semibold`} as="h5">
          {i18n("Account Issues")}
        </Text>
      </Flex>
      <Divider my="10px" />
      <MainText>{i18n("Forgot Your Password?")}</MainText>
      <SubText>
        {i18n(
          "Please contact our customer service, and we will assist you promptly."
        )}
      </SubText>
      <Divider my="10px" />
      <MainText>{i18n("Can Basic Information Be Modified?")}</MainText>
      <SubText>
        {i18n(
          "Basic information cannot be modified once filled out. For any related issues, please contact our customer service."
        )}
      </SubText>
    </VStack>
  );
};

export default AccountIssues;
