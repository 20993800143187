import React from "react";
import DetailWrapper from "../../../components/detailWrapper";
import CloseDetailPage from "../../../components/CloseComponent/CloseDetailPage";
import { useNavigate } from "react-router";
import { HiChevronLeft } from "react-icons/hi";
import { BsShieldFillCheck } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import {
  AbsoluteCenter,
  Box,
  Flex,
  Text,
  useColorModeValue,
  Button,
  Divider,
} from "@chakra-ui/react";

const UserSecureVerity = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`settings.${key}`);
  const i18n_common = (key) => t(`common.${key}`);

  const textColor = useColorModeValue("dark.100", "light.100");

  const navigate = useNavigate();
  return (
    <DetailWrapper
      closeAnimate
      wrapperClassName={`h-full w-full md:absolute fixed top-0 left-0 max-h-full z-[10] bg-gradient-to-br`}
    >
      <CloseDetailPage
        hasPrev={
          <HiChevronLeft
            className="cursor-pointer"
            onClick={() => navigate(-1)}
          />
        }
        title={i18n("userSecureVerity")}
      />
      <Box
        as="article"
        display="flex"
        flexDirection={`column`}
        gap="15px"
        p="15px"
        fontWeight={`medium`}
        pt="50px"
        color={textColor}
      >
        <Flex
          alignItems={`center`}
          justifyContent={`center`}
          position="relative"
          mb="40px"
        >
          <AbsoluteCenter
            w="100px"
            h="100px"
            bg="green.500"
            opacity={0.15}
            borderRadius={`full`}
          ></AbsoluteCenter>
          <BsShieldFillCheck className="text-green-500 text-5xl" />
        </Flex>
        <Flex alignItems={`center`} justifyContent={`center`}>
          <Text as="span" fontSize="2xl" color={textColor}>
            {i18n("userSecureVerity")}{" "}
          </Text>
        </Flex>
        <Flex
          alignItems={`center`}
          justifyContent={`center`}
          direction={`column`}
          color="special.1"
        >
          <Text as="span">{i18n("secureVerityHint.1")}</Text>
          <br />
          <Text as="span">{i18n("secureVerityHint.2")}</Text>
        </Flex>
        <Divider my="10px" />
        <Button
          variant={`brandPrimary`}
          type="button"
          onClick={() => {
            navigate(-1);
          }}
          size="md"
        >
          {i18n_common("iKnow")}
        </Button>
      </Box>
    </DetailWrapper>
  );
};

export default UserSecureVerity;
