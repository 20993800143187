import { useTranslation } from "react-i18next";
import { formatNumber } from "../../../utils/formatNumber";
import { WITHDRAW_AMOUNT_MIN } from "../../../constant";
import {
  Box,
  List,
  ListIcon,
  ListItem,
  Text,
  UnorderedList,
  useColorModeValue,
} from "@chakra-ui/react";
import { MdCheckCircle } from "react-icons/md";
import useCurrentVipLevelConfig from "../../../hook/useCurrentVipLevelConfig";
import useVipInfo from "../../../hook/useVipInfo";
import { FaCircle } from "react-icons/fa6";

const WithdrawNoticeV4 = ({ selectedCurrency }) => {
  const { t } = useTranslation();
  const i18n_withdraw = (key, props) => t(`withdraw.${key}`, { ...props });
  const i18n_vip = (key, props) => t(`vip.${key}`, { ...props });
  const boxBg = useColorModeValue("light.100", "dark.100");
  const boxColor = useColorModeValue("dark.100", "light.100");
  const listColor = useColorModeValue("light.400", "dark.400");
  const titleColor = useColorModeValue("light.500", "dark.500");

  const { current_level } = useVipInfo();

  const current_vip_config = useCurrentVipLevelConfig({
    currentLevel: current_level,
    customCurrency: selectedCurrency,
  });
  const { vip_payment_tags, vip_withdrawal_rate = {} } =
    current_vip_config || {};

  return (
    <>
      <Text mt="10px" fontSize="md" color={titleColor} fontWeight="bold">
        {i18n_withdraw("notice")}：
      </Text>
      <Box
        as="section"
        display="flex"
        flexDirection={`column`}
        gap="5px"
        //bg={boxBg}
        color={boxColor}
        borderRadius={`common`}
        py="8px"
        px="5px"
      >
        {" "}
        <UnorderedList spacing={2} color={listColor}>
          <ListItem fontSize="12px">
            {/* <ListIcon as={FaCircle} color="green.500" /> */}
            {i18n_withdraw("hint.1")}
          </ListItem>
          <ListItem fontSize="12px">
            {i18n_withdraw("hint.min_withdrawal", {
              min: formatNumber(vip_payment_tags?.withdrawMini),
              currency_code: vip_payment_tags?.currency_code,
            })}
          </ListItem>
          <ListItem fontSize="12px">
            {i18n_withdraw("hint.withdrawal_times", {
              times: vip_payment_tags?.withdrawTimes,
            })}
          </ListItem>
          {Object?.values(vip_withdrawal_rate)?.map((item) => {
            return (
              <ListItem fontSize="12px">
                {i18n_withdraw("hint.withdrawalFeeDescription", {
                  times: item?.number,
                  rate: item?.rate * 100,
                })}
              </ListItem>
            );
          })}
        </UnorderedList>
      </Box>
    </>
  );
};

export default WithdrawNoticeV4;
