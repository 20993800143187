import { useMemo } from "react";
import { useLocation } from "react-router";

const useSpecPath = ({ specPath }) => {
  const location = useLocation();

  const getPrevPath = useMemo(() => {
    const path = location.pathname.split("/");
    const currentPathIndex = path.findIndex((item) => item === specPath);

    if (currentPathIndex === -1) return "";

    return `${path.slice(0, currentPathIndex).join("/")}/`;
  }, [location.pathname]);

  return getPrevPath;
};

export default useSpecPath;
