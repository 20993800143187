export const REPLACE_SPACE = (str) => {
  return str.replace(/ /g, "_");
};

export const REPLACE_AIR = (str) => {
  return str?.replace(/ /g, "");
};

export const REPLACE_UNDERSCORE_TO_AIR = (str) => {
  return str.replace(/_/g, " ");
};

export const capitalizeFirstLetter = (str) => {
  if (typeof str !== "string") return "";
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const REPLACE_NUMBER_TO_STAR = (str) => {
  return str?.replace(/.(?=.{4})/g, "*");
};
