const AllGameReducers = (
  state = {
    platform: [],
  },
  action
) => {
  switch (action.type) {
    case "filterGameList":
      return action.payload;
    case "storeFavoriteGameList":
      const favoriteGameList = Object.values(action.payload || {}).flat();
      return {
        ...state,
        categorizedGames: {
          ...state.categorizedGames,
          MyFavorite: favoriteGameList,
        },
        favoriteGameList: favoriteGameList,
      };
    default:
      return state;
  }
};
export default AllGameReducers;
