import React, { useMemo } from "react";
import { WEB_LAYOUT } from "../../constant";
import SimpleReferCode from "./simpleReferCode";
import SimpleReferCodeV4 from "./simpleReferCode_v4";

const SimpleReferCodeVersionSelector = () => {
  const simpleReferCode = useMemo(() => {
    switch (WEB_LAYOUT) {
      case "v2":
        return <SimpleReferCode />;
      case "v3":
        return <SimpleReferCode />;
      case "v4":
        return <SimpleReferCodeV4 />;
      default:
        return <SimpleReferCode />;
    }
  }, [WEB_LAYOUT]);
  return simpleReferCode;
};

export default SimpleReferCodeVersionSelector;
