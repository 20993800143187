import { useNavigate } from "react-router";

import { useDispatch } from "react-redux";

import { goToDetailPage } from "../../redux/action/action";
import { Base64 } from "js-base64";
import { storeInboxDetail } from "../../redux/action/member/memberAction";
import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { useMemo } from "react";

const InboxItem = ({ data, isInbox }) => {
  const {
    message,
    notification_id,
    status,
    notification_content,
    subject,
    send_date,
  } = data || {};

  const boxBg = useColorModeValue("light.100", "dark.100");
  const boxColor = useColorModeValue("dark.100", "light.100");

  const navigate = useNavigate();

  const already_read = useMemo(() => {
    return status === "Unread";
  }, [status]);
  const dispatch = useDispatch();

  return (
    <Box
      bg={boxBg}
      p="common-x"
      borderRadius="common"
      display="flex"
      gap="10px"
      cursor="pointer"
      onClick={() => {
        navigate(`${notification_id}`);
        dispatch(
          storeInboxDetail({
            subject,
            notification_content,
            send_date,
            notification_id,
            isInbox: isInbox ? "1" : "0",
          })
        );
        dispatch(goToDetailPage());
      }}
    >
      <Flex alignItems="center" justifyContent="center" w="20px">
        {" "}
        <Box
          w="8px"
          h="8px"
          borderRadius={`full`}
          bg={already_read ? "brand.500" : "#32383E"}
        ></Box>
      </Flex>
      <Flex direction={`column`} gap="5px" justifyContent={`space-between`}>
        <Text as="p" fontWeight={`medium`} color={boxColor} mb="0px">
          {subject}
        </Text>
        <Text
          as="p"
          fontSize="sm"
          fontWeight={`medium`}
          color="special.1"
          mb="0px"
          className="text-overflow"
        >
          {send_date}
        </Text>
      </Flex>
    </Box>
  );
};

export default InboxItem;
