import { useCallback, useState } from "react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { BiLogoTelegram } from "react-icons/bi";
import { FaTelegram, FaTelegramPlane } from "react-icons/fa";
import TelegramLoginButton, { TelegramUser } from "telegram-login-button";
import useGetAgentName from "../../../hook/useGetAgentName";
import { useLocation, useNavigate } from "react-router";
import { useEffect } from "react";
import { actionTelegramLogin } from "../../../api/postApi";
import { MD5 } from "crypto-js";
import useCookiesAgentName from "./useCookiesAgentName";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { loading, loadingDestroy } from "../../../redux/action/action";
import GetParams from "../../../utils/getParams";
import useCookiesUacName from "./useCookiesUacName";
import { removeCookies } from "./removeCookies";
import { Box, Button, Text } from "@chakra-ui/react";

const telegramLink = "tglogin";

const TelegramLogin = ({ setButtonLoading, buttonLoading, props }) => {
  const { t } = useTranslation();
  const i18n_common = (key) => t(`common.${key}`);

  const buttonRef = useRef();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [saveData, setSaveData] = useState({
    first_name: "",
    hash: "",
    id: null,
    last_name: "",
  });

  const agentName = useGetAgentName();

  const cookiesFromAgent = useCookiesAgentName();
  const referCodeParams = useCookiesUacName();

  const handleLoginTelegram = ({ first_name, hash, id, last_name }) => {
    const randomNumber = Math.random().toString().slice(2, 14);
    dispatch(loading());
    setButtonLoading(true);

    actionTelegramLogin({
      first_name,
      hash,
      id: id,
      last_name,
      unlimit_code: referCodeParams,
      invite: cookiesFromAgent,
      s_auth: randomNumber,
      r_auth: MD5(`Tgs${randomNumber}mov9191Login`).toString(),
      nick_name: `${first_name === "" ? "" : first_name}${
        last_name === "" ? "" : last_name
      }`,
    })
      .then((data) => {
        if (data.message === "Sign up success.") {
          removeCookies();
          actionTelegramLogin({
            first_name,
            hash,
            id: id,
            last_name,
            unlimit_code: "",
            invite: cookiesFromAgent,
            s_auth: randomNumber,
            r_auth: MD5(`Tgs${randomNumber}mov9191Login`).toString(),
          }).then((data) => {
            if (Cookies.get("token")) {
              Cookies.remove("token");
            }
            Cookies.set("token", data.token, { expires: 3 });
            dispatch({ type: "INITIALIZE_API", navigate: navigate });
            navigate("/");
          });
        } else {
          if (Cookies.get("token")) {
            Cookies.remove("token");
          }
          Cookies.set("token", data.token, { expires: 3 });
          dispatch({ type: "INITIALIZE_API", navigate: navigate });
          navigate("/");
        }
      })
      .catch((err) => {})
      .finally(() => {
        setButtonLoading(false);
        dispatch(loadingDestroy());
      });
  };

  return (
    <Box position="relative">
      <Button
        w={`100%`}
        display={`flex`}
        alignItems={`center`}
        fontWeight={`medium`}
        cursor={`pointer`}
        bg={`#53A9EB`}
        py="20px"
        type="button"
        id="customBtn"
        border={`1px solid`}
        borderColor={`#53A9EB`}
        color={`gray.600`}
        className=""
        zIndex={10}
        onClick={() => {
          if (buttonRef.current) {
            buttonRef.current.click();
          }
        }}
      >
        <FaTelegramPlane color="white" className="text-xl" />
        <Text color="white" my="0" ml="5px">
          Telegram
        </Text>
      </Button>
      <Box ref={buttonRef}>
        <TelegramLoginButton
          className={`flex w-full cursor-pointer z-[1] items-center justify-center bg-[#53A9EB] h-[45.5px] rounded-[5px] telegram-button opacity-[0.0001] absolute top-0 left-0`}
          botName={process.env.REACT_APP_TELEGRAM_BOT_NAME}
          cornerRadius="5"
          usePic={false}
          dataOnauth={(user: TelegramUser) => {
            const { first_name, hash, id, last_name } = user;
            handleLoginTelegram({ first_name, hash, id, last_name });
          }}
        />
      </Box>
    </Box>
  );
};

export default TelegramLogin;
