import { Box, useColorModeValue } from "@chakra-ui/react";
import React from "react";

const ReferWrap = ({ children }) => {
  const boxBg = useColorModeValue("light.100", "dark.200");
  return (
    <Box as="section" bg={boxBg} p="5px" borderRadius={`common`}>
      {children}
    </Box>
  );
};

export default ReferWrap;
